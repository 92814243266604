import React, { useState, useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers'
import DisposeModal from './Modal/DisposeModal'
import { useAuth } from '../Auth'
import axios from 'axios'
import { Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetAllSitesQuery } from '../Services/Sites'
import { useGetAllLocationsQuery } from '../Services/Locations'
import { useGetAllDepartmentsQuery } from '../Services/Departments'
import { getEmployee } from '../Apps/CompanySetup/Database/Core/_requests'
import { DisposeInterface, defaultDisposeDetail as initialValues } from './Core/SetupModel'
import { toast } from 'react-toastify'
import { useAddDisposeMutation } from '../Services/Dispose'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { checkUserUnauthorized } from '../Auth'

const accountDetailsSchema = Yup.object().shape({
	dateDispose: Yup.date()
		.min(new Date(), 'Date dispose cannot be a past date')
		.required('Dispose Date is required'),
})

const Dispose = () => {
	const intl = useIntl()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [assets, setAssets] = useState<any>([])
	const [showDisposeModal, setShowDisposeModal] = useState(false)
	const [pendingAssets, setPendingAssets] = useState<any>([])
	const [selectedAssets, setSelectedAssets] = useState<any>([])
	const [selectAll, setSelectAll] = useState(false)
	const [emailEnabled, setEmailEnabled] = useState(false)
	const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
	const [selected, setSelected] = useState<any>([])
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
	const { data: userSitesList } = useGetAllSitesQuery({ userId: currentUser?.id })
	const { data: userLocationList } = useGetAllLocationsQuery(currentUser?.id)
	const { data: userDepartmentList } = useGetAllDepartmentsQuery({ userId: currentUser?.id })
	const [addDispose, { isLoading: isAdding }] = useAddDisposeMutation()
	const {
		data: assetsData,
		isLoading,
		error,
		isSuccess,
	} = useGetAllAssetsQuery({
		body: new URLSearchParams({ status: 'dispose' }).toString(),
		page: page,
		limit: limit,
	})

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: accountDetailsSchema,
		onSubmit: async (values) => {
			try {
				handleDisposeSubmit(values)
			} catch (error) {
				console.error(error)
				toast.error('Something went wrong')
			}
		},
	})
	useEffect(() => {
		if (assetsData) {
			getAssetListing()
		}
	}, [assetsData])
	useEffect(() => {
		if (assets && pendingAssets && showDisposeModal == false) {
			handlePendingList()
		}
	}, [assets, showDisposeModal])
	const getAssetListing = async () => {
		setAssets(assetsData?.userAssets)
	}
	const handlePendingList = () => {
		const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
	}
	const handleDisposeSubmit = async (values: DisposeInterface) => {
		const data = {
			dateDispose: values?.dateDispose,
			disposedTo: values?.disposedTo,
			notes: values?.notes,
			assetId: pendingAssets,
		}
		try {
			const result = await addDispose(data).unwrap()
			toast.dismiss()
			toast.success(result?.message ? result?.message : result?.data?.message)
			setSelectedAssets([])
		} catch (err: any) {
			toast.error(err?.data?.message)
			checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
		}
	}

	const handleShowDisposeModal = () => {
		setShowDisposeModal(true)
	}
	const handleCloseDisposeModal = () => {
		setShowDisposeModal(false)
	}
	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = assets?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const handleCheckboxChange = (id: number) => {
		setSelected((prevSelected) => {
			const updatedSelected = prevSelected.includes(id)
				? prevSelected.filter((itemId) => itemId !== id)
				: [...prevSelected, id]
			const isAllSelected = updatedSelected.length === assets?.length
			setSelectAll(isAllSelected)
			return updatedSelected
		})
		setDeleteDataArr((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((itemId) => itemId !== id)
			} else {
				return [...prevSelected, id]
			}
		})
	}
	const deleteSelectedAssests = async () => {
		const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
		setSelectAll(false)
		setSelected([])
	}

	const handleShowCustomerModal = () => {
		setShowCustomerModal(true)
	}

	const handleCloseCustomerModal = () => {
		setShowCustomerModal(false)
	}

	const handleCancel = () => {
		setSelectedAssets([])
		setPendingAssets([])
		setShowDisposeModal(false)
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Dispose' })}</PageTitle>

			<div className='card mb-5 mb-xl-10'>
				<div className='card-header border-0'>
					<div className='card-title m-0 '>
						<h3 className='fw-bolder m-0'>
							<span className='me-2 align-middle'>
								<i className='las la-recycle fs-1'></i>
							</span>{' '}
							Dispose
						</h3>
					</div>
				</div>

				<div id='kt_account_profile_details' className='collapse show'>
					<div className='form'>
						<div className='card-body border-top p-lg-9 p-md-7 p-6'>
							<div>
								{' '}
								<p className='fw-bolder m-0 mb-3'>
									Follow your assets every step of the way until they’re gone. Rather than deleting
									an asset from your database, you can dispose of it and have documentation of its
									entire life.
								</p>
								<div className='card-footer d-flex justify-content-start border-0 p-0'>
									<button
										type='button'
										className='btn btn-light-primary me-3'
										onClick={() => handleShowDisposeModal()}
									>
										<i className='la la-plus fs-3' />
										Select Assets
									</button>
									<DisposeModal
										show={showDisposeModal}
										setPendingAssets={setPendingAssets}
										selectedAssets={selectedAssets}
										handleClose={handleCloseDisposeModal}
										handleCancel={handleCancel}
										setPage={setPage}
										setLimit={setLimit}
										page={page}
										limit={limit}
									/>
								</div>
								{selectedAssets && selectedAssets?.length > 0 ? (
									<>
										<div className='separator border-2 my-10'></div>
										<div className='d-flex justify-content-between align-items-center'>
											<h4 className='mt-3'>Assets Pending Dispose</h4>
											{deleteDataArr.length > 0 && (
												<button
													type='button'
													className='btn btn-danger  mb-2'
													onClick={() => {
														deleteSelectedAssests()
													}}
												>
													Delete Selected
												</button>
											)}
										</div>
										<div className='checkout-listing-table mb-3 table-responsive'>
											<table
												id='kt_table_users'
												className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
												role='table'
											>
												<thead>
													<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
														<th role='columnheader' className='w-10px pe-2'>
															<div className='me-3'>
																<input
																	type='checkbox'
																	className='form-check-input custom-form-check-input'
																	checked={selectAll}
																	onChange={handleSelectAll}
																/>
															</div>
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Asset Tag ID{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Description{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Status{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Assigned to{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Site{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Location{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Lease to{' '}
														</th>
													</tr>
												</thead>
												<tbody className='text-gray-600 fw-bol' role='rowgroup'>
													{selectedAssets?.length > 0 ? (
														selectedAssets?.map((item: any) => {
															return (
																<tr role='row'>
																	<td role='cell'>
																		<div>
																			<input
																				type='checkbox'
																				className='form-check-input custom-form-check-input'
																				checked={selected.includes(item.id)}
																				onChange={() => handleCheckboxChange(item.id)}
																			/>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.assetTagId}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.description}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item.statusType === 'check_out'
																					? 'Checked Out'
																					: item.statusType === 'lease'
																						? 'Leased'
																						: item.statusType === 'dispose'
																							? 'Disposed'
																							: item.statusType === 'available'
																								? 'Available'
																								: item.statusType}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='overflow-hidden me-3'>
																				<Link to='/dummy-link'>
																					{item?.assetstatus &&
																						item?.assetstatus?.statusType !== 'lease'
																						? item?.location
																							? item?.site?.name + '/' + item?.location?.location
																							: item?.site?.name
																						: item?.assetstatus?.statusType == 'lease'
																							? ''
																							: ''}
																				</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.site?.name}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.location?.location}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.statusType == 'lease' && item?.assetslease
																					? item?.assetslease?.customer?.fullName
																					: ''}
																			</div>
																		</div>
																	</td>
																</tr>
															)
														})
													) : (
														<tr>
															<td colSpan={12}>
																<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																	No records found
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<div className='separator border-2 my-10'></div>
										<form onSubmit={formik.handleSubmit} noValidate className='form'>
											<div className='row'>
												<div className='row mt-3'>
													<div className='col-lg-6'>
														<div className='row mb-6'>
															<label className='col-lg-3 col-form-label required fw-bold fs-6'>
																Date Disposed
															</label>
															<div className='col-lg-9 fv-row'>
																<div className='input-group'>
																	<input
																		{...formik.getFieldProps('dateDispose')}
																		type='date'
																		className='form-control  form-control-solid'
																		placeholder='Pick date rage'
																		id='kt_daterangepicker_3'
																		aria-label='Amount (to the nearest dollar)'
																	/>
																	<span className='input-group-text border-0'>
																		<i className='fa fa-calendar'></i>
																	</span>
																</div>
																{formik.touched.dateDispose && formik.errors.dateDispose && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>{formik.errors.dateDispose}</div>
																	</div>
																)}
															</div>
														</div>

														<div className='row mb-6'>
															<label className='col-lg-3 col-form-label fw-bold fs-6'>
																<span>Dispose to</span>
															</label>
															<div className='col-lg-9 fv-row d-flex'>
																<input
																	placeholder=''
																	type='text'
																	className='form-control form-control-solid mb-3 mb-lg-0'
																	autoComplete='off'
																	{...formik.getFieldProps('disposedTo')}
																/>
															</div>
														</div>
													</div>

													<div className='col-lg-6'>
														<div className='row mb-6'>
															<label className='col-lg-3 col-form-label fw-bold fs-6'>Notes</label>
															<div className='col-lg-9 fv-row'>
																<textarea
																	className='form-control'
																	{...formik.getFieldProps('notes')}
																	cols={20}
																	rows={4}
																></textarea>
															</div>
														</div>
													</div>
												</div>
												<div className=' d-flex align-items-center justify-content-start'>
													<button
														type='submit'
														className='btn btn-primary me-3'
														disabled={isAdding}
													>
														{!isAdding && 'Dispose'}
														{isAdding && (
															<span className='indicator-progress d-block'>
																Please wait...{' '}
																<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
															</span>
														)}
													</button>
													<button
														onClick={handleCancel}
														className='btn btn-light-secondary main-btn-style'
													>
														Cancel
													</button>
												</div>
											</div>
										</form>
									</>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Dispose
