
function privilegesTab({ checked, handleCheckboxChange }) {
	return (
		<div>
			<div className='mb-5'>
				<h4 className="mb-3">Tools</h4>
				<p className="mb-5">User can use these tools:</p>
				<div className="row">
					<div className='col-md-10 offset-md-1'>
						<div className="row">
							<div className="col-md-6">
								<div className="table-responsive mb-4">
									<table className="table table-bordered mb-4">
										<tbody>
											<tr>
												<th>
													<label className='radio mb-0 d-inline-flex' htmlFor='imageGallery'>
														<div>
															<input
																type='checkbox'
																className='form-check-input custom-form-check-input sig_input me-2'

																id='imageGallery'
																name='hasCheckOut'
																value='True'
															/>
														</div>
														<span className='vertically_align'>Image Gallery</span>
													</label>
												</th>
											</tr>
										</tbody>
										<tbody>
											<tr>
												<td>
													<div>
														<label className='radio mb-0 d-inline-flex' htmlFor='documentGallery'>
															<div>
																<input
																	type='checkbox'
																	className='form-check-input custom-form-check-input sig_input me-2'

																	id='documentGallery'
																	name='hasCheckOut'
																	value='True'
																/>
															</div>
															<span className='vertically_align'>Documents Gallery</span>
														</label>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="col-md-6">
								<div className="table-responsive mb-4">
									<table className="table table-bordered mb-4">
										<tbody>
											<tr>
												<th>
													<label className='radio mb-0 d-inline-flex' htmlFor='audit'>
														<div>
															<input
																type='checkbox'
																className='form-check-input custom-form-check-input sig_input me-2'

																id='audit'
																name='hasCheckOut'
																value='True'
															/>
														</div>
														<span className='vertically_align'>Audit</span>
													</label>
												</th>
											</tr>
										</tbody>
										<tbody>
											<tr>
												<td>
													<div>
														<label className='radio mb-0 d-inline-flex' htmlFor='fundingAudit'>
															<div>
																<input
																	type='checkbox'
																	className='form-check-input custom-form-check-input sig_input me-2'

																	id='fundingAudit'
																	name='hasCheckOut'
																	value='True'
																/>
															</div>
															<span className='vertically_align'>Funding Audit</span>
														</label>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='mb-5'>
				<h4 className="mb-3">View and Edit Assets</h4>
				<p className="mb-5">User has permission to add / edit / delete / view records of the following tables:</p>
				<div className="table-responsive mb-4">
					<table className="table table-bordered mb-4">
						<thead className="thead-light  light-yellow-table">
							<tr>
								<th className="w-14">
									<label className='radio mb-0 d-inline-flex' htmlFor='canView'>
										<span className='vertically_align'>Table</span>
									</label>
								</th>
								<th className="w-14">
									<label className='radio mb-0 d-inline-flex' htmlFor='viewAssets'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.viewAssets}
												id='viewAssets'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('viewAssets', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>View Only</span>
									</label>
								</th>
								<th className="w-14">
									<label className='radio mb-0 d-inline-flex' htmlFor='canAdd'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canAdd'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add All</span>
									</label>
								</th>
								<th className="w-14">
									<label className='radio mb-0 d-inline-flex' htmlFor='canEdit'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canEdit'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit All</span>
									</label>
								</th>
								<th className="w-14">
									<label className='radio mb-0 d-inline-flex' htmlFor='canDelete'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canDelete'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete All</span>
									</label>
								</th>
								<th className="w-14">
									<label className='radio mb-0 d-inline-flex' htmlFor='canImport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canImport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import All</span>
									</label>
								</th>
								<th className="w-14">
									<label className='radio mb-0 d-inline-flex' htmlFor='canExport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canExport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export All</span>
									</label>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>&nbsp;</td>
								<td>
									<p>	User can only view table.</p>
								</td>
								<td>
									<p>User can view and create new assets. User can edit or delete <strong>self-created</strong> reacords.</p>
								</td>
								<td>
									<p>User can view and edit <strong>ANY</strong> record.</p>
								</td>
								<td>
									<p>User can view and delete <strong>ANY</strong> record.</p>
								</td>
								<td>
									<p>User can <strong>Import</strong> table.</p>
								</td>
								<td>
									<p>User can <strong>Export</strong> table.</p>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Site/Location</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='viewSite'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.viewSite}
												id='viewSite'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('viewSite', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='addSite'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.addSite}
												id='addSite'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('addSite', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='updateSite'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.updateSite}
												id='updateSite'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('updateSite', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='deleteSite'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.deleteSite}
												id='deleteSite'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('deleteSite', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='importSite'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.importSite}
												id='importSite'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('importSite', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='exportSite'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.exportSite}
												id='exportSite'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('exportSite', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Category/Sub Category</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='viewCategory'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.viewCategory}
												id='viewCategory'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('viewCategory', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='addCategory'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.addCategory}
												id='addCategory'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('addCategory', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='updateCategory'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.updateCategory}
												id='updateCategory'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('updateCategory', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='deleteCategory'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.deleteCategory}
												id='deleteCategory'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('deleteCategory', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='importCategory'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.importCategory}
												id='importCategory'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('importCategory', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='exportCategory'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.exportCategory}
												id='exportCategory'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('exportCategory', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Departments</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='viewDepartment'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.viewDepartment}
												id='viewDepartment'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('viewDepartment', e.target.checked)}

												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='addDepartment'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.addDepartment}
												id='addDepartment'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('addDepartment', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='updateDepartment'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.updateDepartment}
												id='updateDepartment'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('updateDepartment', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='deleteDepartment'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.deleteDepartment}
												id='deleteDepartment'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('deleteDepartment', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='importDepartment'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.importDepartment}
												id='importDepartment'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('importDepartment', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='exportDepartment'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.exportDepartment}
												id='exportDepartment'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('exportDepartment', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Contracts / Licenses</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canView'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canView'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canAdd'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canAdd'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canEdit'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canEdit'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canDelete'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canDelete'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canImport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canImport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canExport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canExport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Insurance</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canView'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canView'
												name='hasCheckOut'
												value='True'
											/>
										</div>

										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canAdd'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canAdd'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canEdit'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canEdit'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canDelete'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canDelete'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canImport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canImport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canExport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canExport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Funding</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canView'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canView'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canAdd'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canAdd'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canEdit'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canEdit'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canDelete'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canDelete'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canImport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canImport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canExport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canExport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Warranties</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canView'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canView'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canAdd'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canAdd'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canEdit'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canEdit'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canDelete'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canDelete'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canImport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canImport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canExport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canExport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Employees</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='viewPerson'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.viewPerson}
												id='viewPerson'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('viewPerson', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='addPerson'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.addPerson}
												id='addPerson'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('addPerson', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='updatePerson'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.updatePerson}
												id='updatePerson'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('updatePerson', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='deletePerson'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.deletePerson}
												id='deletePerson'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('deletePerson', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='importPerson'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.importPerson}
												id='importPerson'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('importPerson', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='exportPerson'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.exportPerson}
												id='exportPerson'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('exportPerson', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Customers</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='viewCustomer'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.viewCustomer}
												id='viewCustomer'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('viewCustomer', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='addCustomer'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.addCustomer}
												id='addCustomer'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('addCustomer', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='updateCustomer'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.updateCustomer}
												id='updateCustomer'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('updateCustomer', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='deleteCustomer'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.deleteCustomer}
												id='deleteCustomer'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('deleteCustomer', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='importCustomer'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.importCustomer}
												id='importCustomer'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('importCustomer', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='exportCustomer'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'
												checked={checked?.exportCustomer}
												id='exportCustomer'
												name='hasCheckOut'
												onChange={(e) => handleCheckboxChange('exportCustomer', e.target.checked)}
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
							<tr>
								<td>
									<span className='fw-bold'>Audit</span>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canView'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canView'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>View</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canAdd'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canAdd'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Add</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canEdit'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canEdit'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Edit</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canDelete'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canDelete'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Delete</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canImport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canImport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Import</span>
									</label>
								</td>
								<td>
									<label className='radio mb-0 d-inline-flex' htmlFor='canExport'>
										<div>
											<input
												type='checkbox'
												className='form-check-input custom-form-check-input sig_input me-2'

												id='canExport'
												name='hasCheckOut'
												value='True'
											/>
										</div>
										<span className='vertically_align'>Export</span>
									</label>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className='mb-5'>
				<h4 className="mb-3">List Setup</h4>
				<div className="row">
					<div className='offset-lg-1 col-lg-10'>
						<div className="table-responsive mb-4">
							<table className="table table-bordered mb-4">
								<tbody>
									<tr>
										<td>
											<p className='mb-0'>
												<label className='radio mb-0 d-inline-flex' htmlFor='setupCoulumn'>
													<div>
														<input
															type='checkbox'
															className='form-check-input custom-form-check-input sig_input me-2'

															id='setupCoulumn'
															name='hasCheckOut'
															value='True'
														/>
													</div>
													<span className='vertically_align'>Allow the User to save 'column setup' of various lists and reports <strong>for 'all' users</strong>.</span>
												</label>
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<p>(Every user can save 'column setup' of various lists and reports for themselves. This permission will allow the user to save 'column setup' for all users, overwriting their existing setup, if any.)</p>
			</div>
			<div className='mb-5'>
				<h4 className="mb-3">Account Details (Of Billing, Account Balance, Payment)</h4>
				<p>Give user permission to view invoices, purchase orders, past payments, account balance And make payment.</p>
				<div className="row">
					<div className="offset-lg-1 col-lg-10">
						<div className="table-responsive mb-4">
							<table className="table table-bordered mb-4">
								<tbody>
									<tr>
										<td>
											<p className='mb-0'>
												<label className='radio mb-0 d-inline-flex' htmlFor='accountDetails'>
													<div>
														<input
															type='checkbox'
															className='form-check-input custom-form-check-input sig_input me-2'

															id='accountDetails'
															name='hasCheckOut'
															value='True'
														/>
													</div>
													<span className='vertically_align'>Permission to view 'Account Details' and 'Subscription Plans' pages</span>
												</label>
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default privilegesTab