
import {CustomEmail} from './Components/CustomEmail'
const CustomEmails = () => {
  return (
    <>
      <CustomEmail />
    </>
  )
}

export default CustomEmails
