import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const setupApi = createApi({
	reducerPath: 'setupApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}/setup-columns/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`)
			}
			return headers
		},
	}),
	tagTypes: ['SetupColumns', 'SetupColumns'],
	endpoints: (build) => ({
		getSetupColumns: build.query<any, {path:any}>({
			query: ({path}) => ({
				url: `get/${path}`,
				method: 'GET',
			}),
			providesTags: ['SetupColumns'],
		}),
		storeSetupColumns: build.mutation<any, { selectedColumns: any , pathName:any }>({
			query: ({selectedColumns, pathName}) => ({
				url: 'store',
				method: 'POST',
				body: { selectedColumns, pathName },
			}),
			invalidatesTags: () => [{ type: 'SetupColumns' }],
		}),
	}),
})

export const { useGetSetupColumnsQuery, useStoreSetupColumnsMutation } = setupApi
