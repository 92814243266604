import React, { useState, useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import MaintenanceModal from './Modal/MaintenanceModal'
import { useAuth } from '../Auth'
import { Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { MaintenanceInterface, defaultMaintenanceDetail as initialValues } from './Core/SetupModel'
import { toast } from 'react-toastify'
import { useAddMaintenanceMutation, useGetAllMaintenancesQuery } from '../Services/Maintenance'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useGetCompanyQuery } from '../Services/Company'
import getSymbolFromCurrency from 'currency-symbol-map'
import { checkUserUnauthorized } from '../Auth'
import SplashScreen from 'App/SplashScreen'

const accountDetailsSchema = Yup.object().shape({
	maintenanceTitle: Yup.string()
		.required('Title is required')
		.test('no-empty-spaces', 'Title should not be empty', (value = '') => {
			return !/^\s*$/.test(value)
		}),
})

const Maintenance = () => {
	const intl = useIntl()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [assets, setAssets] = useState<any>([])
	const [showMaintenanceModal, setShowMaintenanceModal] = useState(false)
	const [pendingAssets, setPendingAssets] = useState<any>([])
	const [selectedAssets, setSelectedAssets] = useState<any>([])
	const [selectAll, setSelectAll] = useState(false)
	const [repeatingMaintenance, setRepeatingMaintenance] = useState(false)
	const [repeatingFrequency, setRepeatingFrequency] = useState('')
	const [selected, setSelected] = useState<any>([])
	const [skip, setSkip] = useState<boolean>(false)
	const [siteId, setSiteId] = useState<boolean>(false)
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
	const [addMaintenance, { isLoading: isAdding }] = useAddMaintenanceMutation()
	const {
		data: assetsData,
		isLoading,
		error,
		isSuccess,
	} = useGetAllAssetsQuery({
		body: new URLSearchParams({}).toString(),
		page: page,
		limit: limit,
	})
	const [currencyLogo, setCurrencyLogo] = useState<any>()
	const { data: companyData, isSuccess: company } = useGetCompanyQuery()

	useEffect(() => {
		const res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
		setCurrencyLogo(res)
	}, [company])

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: accountDetailsSchema,
		onSubmit: async (values) => {
			try {
				handleMaintenanceSubmit(values)
			} catch (error: any) {
				toast.error('Something went wrong', error)
			}
		},
	})

	useEffect(() => {
		setSkip(false)
	}, [])
	const handleSiteClick = (e: any) => {
		const id = e.target.value
		setSiteId(id)
		setSkip(false)
	}
	useEffect(() => {
		if (assetsData) {
			getAssetListing()
		}
	}, [assetsData])
	useEffect(() => {
		if (assets && pendingAssets && showMaintenanceModal == false) {
			handlePendingList()
		}
	}, [assets, showMaintenanceModal])
	const getAssetListing = async () => {
		setAssets(assetsData?.userAssets)
	}
	const handlePendingList = () => {
		const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
	}
	const handleMaintenanceSubmit = async (values: MaintenanceInterface) => {
		const data = {
			maintenanceTitle: values?.maintenanceTitle,
			maintenanceDetails: values?.maintenanceDetails,
			maintenanceDueDate: values?.dueDate,
			maintenanceBy: values?.maintenanceBy,
			maintenanceStatus: values?.maintenanceStatus,
			maintenanceDateCompleted: values?.leaseExpires,
			maintenanceCost: values?.cost,
			maintenanceReapting: values?.maintenanceReapting,
			maintenanceFrequency: values?.maintenanceFrequency,
			recureOnEvery: values?.recureOnEvery,
			recureOn: values?.recureOn,
			assetId: pendingAssets,
		}
		try {
			const result = await addMaintenance(data).unwrap()
			if (result) {
				toast.dismiss()
				toast.success(result?.message ? result?.message : result?.data?.message)
				setSelectedAssets([])
			}
		} catch (error: any) {
			checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
		}
	}

	const handleShowMaintenanceModal = () => {
		setShowMaintenanceModal(true)
	}
	const handleCloseMaintenanceModal = () => {
		setShowMaintenanceModal(false)
	}
	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = assets?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const handleCheckboxChange = (id: number) => {
		setSelected((prevSelected) => {
			const updatedSelected = prevSelected.includes(id)
				? prevSelected.filter((itemId) => itemId !== id)
				: [...prevSelected, id]
			const isAllSelected = updatedSelected.length === assets?.length
			setSelectAll(isAllSelected)
			return updatedSelected
		})
		setDeleteDataArr((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((itemId) => itemId !== id)
			} else {
				return [...prevSelected, id]
			}
		})
	}
	const deleteSelectedAssests = async () => {
		const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
		setSelectAll(false)
		setSelected([])
	}

	const handleCancel = () => {
		setSelectedAssets([])
		setPendingAssets([])
		setShowMaintenanceModal(false)
	}

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoading} />
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Maintenance' })}</PageTitle>
			<div className='card mb-5 mb-xl-10'>
				<div className='card-header border-0'>
					<div className='card-title m-0 '>
						<h3 className='fw-bolder m-0'>
							<span className='me-2 align-middle'>
								<i className='la la-cogs fs-1'></i>
							</span>{' '}
							Maintenance
						</h3>
					</div>
				</div>
				<div id='kt_account_profile_details' className='collapse show'>
					<div className='form'>
						<div className='card-body border-top p-lg-9 p-md-7 p-6'>
							<div >
								{' '}
								<p className='fw-bolder m-0 mb-3'>Bulk define maintenance of assets.</p>
								<div className='card-footer d-flex justify-content-start border-0 p-0'>
									<button
										type='button'
										className='btn btn-light-primary me-3'
										onClick={() => handleShowMaintenanceModal()}
									>
										<i className='la la-plus fs-3' />
										Select Assets
									</button>
									<MaintenanceModal
										show={showMaintenanceModal}
										setPendingAssets={setPendingAssets}
										selectedAssets={selectedAssets}
										handleClose={handleCloseMaintenanceModal}
										handleCancel={handleCancel}
										setPage={setPage}
										setLimit={setLimit}
										page={page}
										limit={limit}
									/>
								</div>
								{selectedAssets && selectedAssets?.length > 0 ? (
									<>
										<div className='separator border-2 my-10'></div>
										<div className='d-flex justify-content-between align-items-center'>
											<p className='fw-bolder m-0'>Assets Pending Maintenance</p>
											{deleteDataArr.length > 0 && (
												<button
													type='button'
													className='btn btn-danger  mb-2'
													onClick={() => {
														deleteSelectedAssests()
													}}
												>
													Delete Selected
												</button>
											)}
										</div>
										<div className='checkout-listing-table mb-3 table-responsive'>
											<table
												id='kt_table_users'
												className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
												role='table'
											>
												<thead>
													<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
														<th role='columnheader' className='w-10px pe-2'>
															<div className='me-3'>
																<input
																	type='checkbox'
																	className='form-check-input custom-form-check-input'
																	checked={selectAll}
																	onChange={handleSelectAll}
																/>
															</div>
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Asset Tag ID{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Description{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Status{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Assigned to{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Site{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Location{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Lease to{' '}
														</th>
													</tr>
												</thead>
												<tbody className='text-gray-600 fw-bol' role='rowgroup'>
													{selectedAssets?.length > 0 ? (
														selectedAssets?.map((item: any) => {
															return (
																<tr role='row'>
																	<td role='cell' >
																		<div >
																			<input
																				type='checkbox'
																				className='form-check-input custom-form-check-input'
																				checked={selected.includes(item.id)}
																				onChange={() => handleCheckboxChange(item.id)}
																			/>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.assetTagId}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.description}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item.statusType === 'check_out'
																					? 'Checked Out'
																					: item.statusType === 'lease'
																						? 'Leased'
																						: item.statusType === 'dispose'
																							? 'Disposed'
																							: item.statusType === 'available'
																								? 'Available'
																								: item.statusType}
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>
																					{item?.assetstatus &&
																						item?.assetstatus?.statusType !== 'lease'
																						? item?.location
																							? item?.site?.name + '/' + item?.location?.location
																							: item?.site?.name
																						: item?.assetstatus?.statusType == 'lease'
																							? ''
																							: ''}
																				</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.site?.name}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.location?.location}
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.statusType == 'lease' && item?.assetslease
																					? item?.assetslease?.customer?.fullName
																					: ''}
																			</div>
																		</div>
																	</td>
																</tr>
															)
														})
													) : (
														<tr>
															<td colSpan={12}>
																<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																	No records found
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<div className='separator border-2 my-10'></div>
										<form onSubmit={formik.handleSubmit} noValidate className='form'>
											<div className='row'>
												<div >
													<div className='row mt-3'>
														<div className='col-lg-6'>
															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label required fw-bold fs-6'>
																	Maintenance Title
																</label>
																<div className='col-lg-9 fv-row'>
																	<div>
																		<input
																			placeholder=''
																			type='text'
																			className='form-control form-control-solid mb-3 mb-lg-0'
																			autoComplete='off'
																			{...formik.getFieldProps('maintenanceTitle')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																		/>
																	</div>
																	{formik.touched.maintenanceTitle &&
																		formik.errors.maintenanceTitle && (
																			<div className='fv-plugins-message-container'>
																				<div className='fv-help-block'>
																					{formik.errors.maintenanceTitle}
																				</div>
																			</div>
																		)}
																</div>
															</div>

															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label fw-bold fs-6'>
																	Maintenance Details
																</label>
																<div className='col-lg-9 fv-row'>
																	<textarea
																		className='form-control'
																		{...formik.getFieldProps('maintenanceDetails')}
																		onChange={(e) => {
																			formik.handleChange(e)
																		}}
																		data-val-length='Maximum length is 1000.'
																		data-val-length-max='1000'
																		cols={20}
																		rows={4}
																		data-val='true'
																		id='Notes'
																		name='maintenanceDetails'
																	></textarea>
																</div>
															</div>

															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>
																	Maint. Due Date
																</label>
																<div className='col-lg-9 fv-row'>
																	<div className='input-group'>
																		<input
																			type='date'
																			className='form-control  form-control-solid'
																			aria-label='Amount (to the nearest dollar)'
																			{...formik.getFieldProps('dueDate')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																		/>
																		<span className='input-group-text border-0'>
																			<i className='fa fa-calendar'></i>
																		</span>
																	</div>
																</div>
															</div>

															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label fw-bold fs-6'>
																	Maintenance By
																</label>
																<div className='col-lg-9 fv-row'>
																	<textarea
																		className='form-control'
																		data-val-length='Maximum length is 1000.'
																		data-val-length-max='1000'
																		cols={20}
																		rows={4}
																		data-val='true'
																		id='Notes'
																		{...formik.getFieldProps('maintenanceBy')}
																		onChange={(e) => {
																			formik.handleChange(e)
																		}}
																	></textarea>
																</div>
															</div>
														</div>
														<div className='col-lg-6'>
															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label fw-bold fs-6'>
																	<span >Maintenance Status</span>
																</label>
																<div className='col-lg-9 fv-row'>
																	<select
																		{...formik.getFieldProps('maintenanceStatus')}
																		onChange={(e) => {
																			formik.handleChange(e)
																		}}
																		className='form-select form-control form-control-solid form-select-solid  '
																	>
																		<option value=''>Select ...</option>
																		<option value='scheduled'>Scheduled</option>
																		<option value='inProgress'>In progress</option>
																		<option value='onHold'>On Hold</option>
																		<option value='cancelled'>Cancelled</option>
																		<option value='completed'>Completed</option>
																	</select>
																</div>
															</div>

															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label fw-bold fs-6'>
																	Date Completed
																</label>
																<div className='col-lg-9 fv-row'>
																	<div className='input-group'>
																		<input
																			type='date'
																			className='form-control  form-control-solid'
																			aria-label='Amount (to the nearest dollar)'
																			{...formik.getFieldProps('leaseExpires')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																		/>
																		<span className='input-group-text border-0'>
																			<i className='fa fa-calendar'></i>
																		</span>
																	</div>
																</div>
															</div>

															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>
																	Maintenance Cost
																</label>
																<div className='col-lg-9 fv-row'>
																	<div className='input-group currency-field'>
																		<span className='input-group-text border-0'>
																			{currencyLogo}
																		</span>
																		<input
																			type='text'
																			className='form-control  form-control-solid'
																			aria-label='Amount (to the nearest dollar)'
																			{...formik.getFieldProps('cost')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																		/>
																	</div>
																</div>
															</div>
															<div className='row mb-6'>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>
																	Repeating
																</label>
																<div className='col-lg-9 fv-row'>
																	<div className='d-flex'>
																		<div className='m-2 form-check-custom fw-bold'>
																			<div>
																				<input
																					className='form-check-input custom-form-check-input'
																					{...formik.getFieldProps('maintenanceReapting')}
																					onClick={() => {
																						setRepeatingMaintenance(true)
																					}}
																					type='checkbox'
																					value={'yes'}
																					checked={repeatingMaintenance == false ? false : true}
																					name={'checkedTo'}
																					id='person'
																				/>
																			</div>
																			<label className='form-check-label' htmlFor='person'>
																				Yes
																			</label>
																		</div>
																		<div className='m-2 form-check-custom fw-bold'>
																			<div>
																				<input
																					className='form-check-input custom-form-check-input'
																					type='checkbox'
																					{...formik.getFieldProps('maintenanceReapting')}
																					onClick={() => {
																						setRepeatingMaintenance(false)
																					}}
																					value={'no'}
																					checked={repeatingMaintenance == false ? true : false}
																					name={'checkedTo'}
																					id='location'
																				/>
																			</div>
																			<label className='form-check-label' htmlFor='location'>
																				No
																			</label>
																		</div>
																	</div>
																</div>
															</div>
															<div className={repeatingMaintenance ? 'row mb-6' : 'd-none'}>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>
																	Frequency
																</label>
																<div className='col-lg-9 fv-row'>
																	<div className='d-flex'>
																		<div className='m-2 form-check-custom fw-bold'>
																			<div>
																				<input
																					className='form-check-input custom-form-check-input'
																					{...formik.getFieldProps('maintenanceFrequency')}
																					onClick={() => {
																						setRepeatingFrequency('daily')
																					}}
																					type='checkbox'
																					value={'daily'}
																					checked={repeatingFrequency == 'daily' ? true : false}
																					name={'frequency'}
																					id='person'
																				/>
																			</div>
																			<label className='form-check-label' htmlFor='person'>
																				Daily
																			</label>
																		</div>
																		<div className='m-2 form-check-custom fw-bold'>
																			<div>
																				<input
																					className='form-check-input custom-form-check-input'
																					{...formik.getFieldProps('maintenanceFrequency')}
																					onClick={() => {
																						setRepeatingFrequency('weekly')
																					}}
																					type='checkbox'
																					value={'weekly'}
																					checked={repeatingFrequency == 'weekly' ? true : false}
																					name={'frequency'}
																					id='person'
																				/>
																			</div>
																			<label className='form-check-label' htmlFor='person'>
																				Weekly
																			</label>
																		</div>
																		<div className='m-2 form-check-custom fw-bold'>
																			<div>
																				<input
																					className='form-check-input custom-form-check-input'
																					{...formik.getFieldProps('maintenanceFrequency')}
																					onClick={() => {
																						setRepeatingFrequency('monthly')
																					}}
																					type='checkbox'
																					value={'monthly'}
																					checked={repeatingFrequency == 'monthly' ? true : false}
																					name={'frequency'}
																					id='person'
																				/>
																			</div>
																			<label className='form-check-label' htmlFor='person'>
																				Monthly
																			</label>
																		</div>
																		<div className='m-2 form-check-custom fw-bold'>
																			<div>
																				<input
																					className='form-check-input custom-form-check-input'
																					{...formik.getFieldProps('maintenanceFrequency')}
																					onClick={() => {
																						setRepeatingFrequency('yearly')
																					}}
																					type='checkbox'
																					value={'yearly'}
																					checked={repeatingFrequency == 'yearly' ? true : false}
																					name={'frequency'}
																					id='person'
																				/>
																			</div>
																			<label className='form-check-label' htmlFor='person'>
																				Yearly
																			</label>
																		</div>
																	</div>
																</div>
															</div>
															{repeatingFrequency == 'weekly' ||
																repeatingFrequency == 'monthly' ||
																(repeatingFrequency == 'yearly' && (
																	<label className='col-lg-3 col-form-label  fw-bold fs-6'>
																		Recur on:
																	</label>
																))}

															<div
																className={
																	repeatingFrequency == 'weekly' || repeatingFrequency == 'monthly'
																		? 'row mb-6'
																		: 'd-none'
																}
															>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>
																	Every
																</label>
																<div className='col-lg-9 fv-row'>
																	<div className='input-group'>
																		<input
																			type='number'
																			className='form-control  form-control-solid'
																			{...formik.getFieldProps('recureOnEvery')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																		/>
																	</div>
																</div>
															</div>
															<div
																className={repeatingFrequency == 'yearly' ? 'row mb-6' : 'd-none'}
															>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>
																	Every
																</label>
																<div className='col-lg-9 fv-row'>
																	<div className='input-group'>
																		<select
																			{...formik.getFieldProps('recureOnEvery')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																			className='form-select form-control form-control-solid form-select-solid  '
																		>
																			<option value=''>Select Month</option>
																			<option value='january'>January</option>
																			<option value='february'>February</option>
																			<option value='march'>March</option>
																			<option value='april'>April</option>
																			<option value='may'>May</option>
																			<option value='june'>June</option>
																			<option value='july'>July</option>
																			<option value='august'>August</option>
																			<option value='september'>September</option>
																			<option value='october'>October</option>
																			<option value='november'>November</option>
																			<option value='december'>December</option>
																		</select>
																	</div>
																</div>
															</div>
															<div
																className={repeatingFrequency == 'weekly' ? 'row mb-6' : 'd-none'}
															>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>On</label>
																<div className='col-lg-9 fv-row'>
																	<div className='input-group'>
																		<select
																			{...formik.getFieldProps('recureOn')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																			className='form-select form-control form-control-solid form-select-solid  '
																		>
																			<option value=''>Please Select Day</option>
																			<option value='monday'>Monday</option>
																			<option value='tuesday'>Tuesday</option>
																			<option value='wednesday'>Wednesday</option>
																			<option value='thursday'>Thursday</option>
																			<option value='friday'>Friday</option>
																			<option value='saturday'>Saturday</option>
																			<option value='sunday'>Sunday</option>
																		</select>
																	</div>
																</div>
															</div>
															<div
																className={
																	repeatingFrequency == 'yearly' || repeatingFrequency == 'monthly'
																		? 'row mb-6'
																		: 'd-none'
																}
															>
																<label className='col-lg-3 col-form-label  fw-bold fs-6'>On</label>
																<div className='col-lg-9 fv-row'>
																	<div className='input-group'>
																		<select
																			{...formik.getFieldProps('recureOn')}
																			onChange={(e) => {
																				formik.handleChange(e)
																			}}
																			className='form-select form-control form-control-solid form-select-solid  '
																		>
																			<option value=''>Please Select..</option>
																			<option value='1'>1</option>
																			<option value='2'>2</option>
																			<option value='3'>3</option>
																			<option value='4'>4</option>
																			<option value='5'>5</option>
																			<option value='6'>6</option>
																			<option value='7'>7</option>
																			<option value='8'>8</option>
																			<option value='9'>9</option>
																			<option value='10'>10</option>
																			<option value='11'>11</option>
																			<option value='12'>12</option>
																			<option value='13'>13</option>
																			<option value='14'>14</option>
																			<option value='15'>15</option>
																			<option value='16'>16</option>
																			<option value='17'>17</option>
																			<option value='18'>18</option>
																			<option value='19'>19</option>
																			<option value='20'>20</option>
																			<option value='21'>21</option>
																			<option value='22'>22</option>
																			<option value='23'>23</option>
																			<option value='24'>24</option>
																			<option value='25'>25</option>
																			<option value='26'>26</option>
																			<option value='27'>27</option>
																			<option value='28'>28</option>
																			<option value='29'>29</option>
																			<option value='30'>30</option>
																			<option value='31'>31</option>
																		</select>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className=' d-flex align-items-center justify-content-start'>
													<button type='submit' className='btn btn-primary me-3' disabled={isAdding}>
														{!isAdding && 'Add'}
														{isAdding && (
															<span className='indicator-progress'>
																Please wait...{' '}
																<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
															</span>
														)}
													</button>
													<button onClick={handleCancel} className='btn btn-light-secondary main-btn-style'>
														Cancel
													</button>
												</div>
											</div>
										</form>
									</>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Maintenance
