import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const customersApi = createApi({
  reducerPath: 'customersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/list-customer/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Customers'],

  endpoints: (build) => ({
    getAllCustomers: build.query<any, any>({
      query: ({ search, searchField, page = 1, limit = 10 }) => ({
        url: 'get',
        params: { search, searchField, limit, page },
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),

    getSingleCustomer: build.query<any, any>({
      query: ({ id }) => ({
        url: `get-customer/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),

    addCustomer: build.mutation<any, any>({
      query: (body) => ({
        url: `add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Customers' }],
    }),
    updateCustomer: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Customers' }],
    }),
    deleteCustomers: build.mutation<any, any>({
      query({ id }) {
        return {
          url: `delete`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(assetsApi.util.invalidateTags(['Assets']))
        })
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Customers', userId }],
    }),
  }),
})

export const {
  useGetAllCustomersQuery,
  useGetSingleCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomersMutation,
} = customersApi
