import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleAssetQuery, useGetAllAssetsQuery } from '../../../../Services/Assets';
import { Link, useNavigate } from 'react-router-dom';
import ReactToPdf from 'react-to-pdf';
import { toAbsoluteUrlImage } from '_metronic/helpers';
import moment from 'moment';
import { useGetCompanyQuery } from 'App/Modules/Services/Company';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useAuth } from '../../../../Auth';
import { Images } from '_metronic/assets/Assets';
import { toast } from 'react-toastify';
import SplashScreen from 'App/SplashScreen';

const AssetDetails = () => {
  const { id } = useParams()
  const { currentUser } = useAuth()
  const [searchAssets, setSearchAssets] = useState<any>({
    keyword: '',
    searchField: '',
    siteId: '',
    locationId: '',
    categoryId: '',
    departmentId: '',
    person: '',
    customer: '',
    status: '',
    groupBy: '',
    recordCount: '',
    dateRangeBy: 'purchaseDate',
    quickDateRange: '',
    startDate: '',
    endDate: '',
  })
  const [page, setPage] = useState<number>(1)
  const [assetIds, setAssetIds] = useState<any>([])
  const [currentAssetIndex, setCurrentAssetIndex] = useState<any>(0)
  const [limit, setLimit] = useState<number>(10000)
  const {
    data: AssetDetail,
    isLoading: isLoadingViewAsset,
    error,
  } = useGetSingleAssetQuery({ assetId: id })
  const { data: companyData, isSuccess: company } = useGetCompanyQuery()
  const { data: assetsData, isSuccess } = useGetAllAssetsQuery({
    body: new URLSearchParams(searchAssets).toString(),
    page,
    limit,
  })

  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const navigate = useNavigate()
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    let res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
    setCurrencyLogo(res)
  }, [company])
  useEffect(() => {
    if (assetsData) {
      const ids = assetsData?.userAssets?.map((asset) => asset.id)
      setAssetIds(ids)
      const initialAssetId = id
      const initialAssetIndex = ids?.indexOf(initialAssetId)

      if (initialAssetIndex !== -1) {
        setCurrentAssetIndex(initialAssetIndex)
      }
    }
  }, [assetsData, id])
  const goToNextAsset = () => {
    if (currentAssetIndex < assetIds.length - 1) {
      const nextAssetIndex = currentAssetIndex + 1
      setCurrentAssetIndex(nextAssetIndex)
      const nextAssetId = assetIds[nextAssetIndex]
      navigate(`/asset/view/${nextAssetId}`)
    } else {
      toast.info('No more assets found!')
    }
  }

  const goToPreviousAsset = () => {
    if (currentAssetIndex > 0) {
      const previousAssetIndex = currentAssetIndex - 1
      setCurrentAssetIndex(previousAssetIndex)
      const previousAssetId = assetIds[previousAssetIndex]
      navigate(`/asset/view/${previousAssetId}`)
    } else {
      toast.info('No more assets found!')
    }
  }

  return (
    <>
      <h3 className='fw-bolder mb-4'>
        <span className='me-2 align-middle'>
          <i className='la la-puzzle-piece fs-1' aria-hidden='true'></i>
        </span>{' '}
        Asset View
      </h3>
      <SplashScreen isLoadingTemplate={isLoadingViewAsset} />
      {!isLoadingViewAsset ? (
        <div id='kt_content' className='d-flex flex-column flex-column-fluid asset-details'>
          <div className='post d-flex flex-column-fluid' id='kt_post' ref={ref}>
            <div id='kt_content_container' className={`w-100 ${showSearch && 'd-none'}`}>
              <div className='card mb-5'>
                <div className='card-body py-9'>
                  <div className='card_title_inner'>
                    <div className='card_caption mb-6 d-flex align-items-center justify-content-between'>
                      <h3 className='form-title'>{AssetDetail?.asset?.brand}</h3>
                      <div className='d-flex'>
                        <Link className='btn btn-light-primary me-3' to={`/asset/edit/${id}`}>
                          <i className='fa fa-pencil-alt me-1 fs-6'></i> Edit
                        </Link>
                        <ReactToPdf targetRef={ref} filename='div-blue.pdf' scale={0.5}>
                          {({ toPdf }) => (
                            <button className='btn btn-light-primary' onClick={toPdf}>
                              <i className='la la-print me-1 fs-4'></i> Print
                            </button>
                          )}
                        </ReactToPdf>
                        <button className='btn btn-light-primary mx-3' onClick={goToPreviousAsset}>
                          Previous
                        </button>
                        <button className='btn btn-light-primary' onClick={goToNextAsset}>
                          Next
                        </button>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-3 text-center mb-4 mb-md-0'>
                        <div className='card card-custom overlay d-inline-block mb-xl-0 mb-8'>
                          <div className='card-body p-0'>
                            <div className='overlay-wrapper asset-image'>
                              {AssetDetail?.asset?.photo ? (
                                <img
                                  id='assetDefaultImg'
                                  src={toAbsoluteUrlImage(AssetDetail?.asset?.photo)}
                                  alt='Asset'
                                  title='assets-img'
                                  crossOrigin='anonymous'
                                  className='img-fluid center-block asset_img'
                                />
                              ) : (
                                <img
                                  src={Images.AssetsPlaceholder}
                                  alt='Placeholder'
                                  className='img-fluid center-block asset_img'
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-9'>
                        <div className='row'>
                          <div className='col-lg-7'>
                            <table
                              className='assets-details table table-bordered table-view-detail table-sm'
                              aria-label='Assets View'
                              role='presentation'
                            >
                              <tbody>
                                <tr>
                                  <td className='label_col w-50'>Asset Tag ID</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.assetTagId}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col w-50'>Purchase Date</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.purchasedDate
                                      ? moment(AssetDetail?.asset?.purchasedDate).format(
                                        'DD/MM/YYYY'
                                      )
                                      : ''}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col w-50'>Cost</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.cost}
                                    {AssetDetail?.asset?.cost &&
                                      AssetDetail?.asset?.cost >= 0 &&
                                      ' ' + currencyLogo}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col w-50'>Brand</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.brand}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col w-50'>Model</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.model}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='col-lg-5'>
                            <table
                              className='assets-details table table-bordered table-view-detail table-sm mb-0'
                              aria-label='Assets View'
                              role='presentation'
                            >
                              <tbody>
                                <tr>
                                  <td className='label_col'>Site</td>
                                  <td className='detail-column fw-bold'>
                                    {AssetDetail?.asset?.site?.name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col'>Location</td>
                                  <td className='detail-column fw-bold'>
                                    {AssetDetail?.asset?.location?.location}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col'>Category</td>
                                  <td className='detail-column fw-bold'>
                                    {AssetDetail?.asset?.category?.category}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col'>Department</td>
                                  <td className='detail-column fw-bold'>
                                    {AssetDetail?.asset?.department?.department}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col'>Assigned to</td>
                                  <td className='detail-column fw-bold'>
                                    {AssetDetail?.asset?.assetstatus?.person
                                      ? AssetDetail?.asset?.assetstatus?.person.fullName
                                      : 'Site / Location'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col'>Status</td>
                                  <td className='detail-column fw-bold success'>
                                    {AssetDetail?.asset?.statusType === 'check_out'
                                      ? 'Checked Out'
                                      : AssetDetail?.asset?.statusType === 'lease'
                                        ? 'Leased'
                                        : AssetDetail?.asset?.statusType === 'dispose'
                                          ? 'Disposed'
                                          : AssetDetail?.asset?.statusType === 'available'
                                            ? 'Available'
                                            : AssetDetail?.asset?.statusType}
                                  </td>
                                </tr>
                                <tr>
                                  {AssetDetail?.asset?.reserveassets &&
                                    AssetDetail?.asset?.reserveassets?.length > 0 && (
                                      <>
                                        <td className='label_col'>Reservation</td>
                                        <td className='detail-column fw-bold success'>
                                          <div>
                                            {AssetDetail?.asset?.reserveassets &&
                                              AssetDetail?.asset?.reserveassets[0]?.startDate
                                              ? moment(
                                                AssetDetail?.asset?.reserveassets[0]?.startDate
                                              ).format('MMM DD, YYYY') + ' to '
                                              : ''}

                                            {AssetDetail?.asset?.reserveassets[0] &&
                                              AssetDetail?.asset?.reserveassets[0]?.endDate
                                              ? moment(
                                                AssetDetail?.asset?.reserveassets[0]?.endDate
                                              ).format('MMM DD, YYYY')
                                              : ''}
                                          </div>
                                          <span className='pe-2'>By</span>
                                          {AssetDetail?.asset?.reserveassets[0]?.customer
                                            ? AssetDetail?.asset?.reserveassets[0]?.customer
                                              ?.fullName
                                            : AssetDetail?.asset?.reserveassets[0]?.person
                                              ? AssetDetail?.asset?.reserveassets[0]?.person?.fullName
                                              : ''}
                                          {AssetDetail?.asset?.reserveassets[0]?.site
                                            ? AssetDetail?.asset?.reserveassets[0]?.site?.name
                                            : ''}
                                          {AssetDetail?.asset?.reserveassets[0]?.location
                                            ? ' / ' +
                                            AssetDetail?.asset?.reserveassets[0]?.location
                                              ?.location
                                            : ''}
                                        </td>
                                      </>
                                    )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-body py-9'>
                  <ul className='nav nav-tabs view-assets-tabs mb-5' id='myTab' role='tablist'>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link active'
                        id='details-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#details'
                        type='button'
                        role='tab'
                        aria-controls='details'
                        aria-selected='true'
                      >
                        Details
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='events-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#events'
                        type='button'
                        role='tab'
                        aria-controls='events'
                        aria-selected='false'
                      >
                        Events
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='photos-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#photos'
                        type='button'
                        role='tab'
                        aria-controls='photos'
                        aria-selected='false'
                      >
                        Photos
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='docs-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#docs'
                        type='button'
                        role='tab'
                        aria-controls='docs'
                        aria-selected='false'
                      >
                        Docs.
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='warranty-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#warranty'
                        type='button'
                        role='tab'
                        aria-controls='warranty'
                        aria-selected='false'
                      >
                        Warranty
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='linking-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#linking'
                        type='button'
                        role='tab'
                        aria-controls='linking'
                        aria-selected='false'
                      >
                        Linking
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='maint-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#maint'
                        type='button'
                        role='tab'
                        aria-controls='maint'
                        aria-selected='false'
                      >
                        Maint.
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='reserve-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#reserve'
                        type='button'
                        role='tab'
                        aria-controls='reserve'
                        aria-selected='false'
                      >
                        Reserve
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='audit-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#audit'
                        type='button'
                        role='tab'
                        aria-controls='audit'
                        aria-selected='false'
                      >
                        Audit
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='history-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#history'
                        type='button'
                        role='tab'
                        aria-controls='history'
                        aria-selected='false'
                      >
                        History
                      </button>
                    </li>
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade show active'
                      id='details'
                      role='tabpanel'
                      aria-labelledby='details-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Asset Details</h4>
                        <div className='form-group row mb-3'>
                          <div className='col-lg-2 d-flex align-items-center'>
                            <h6 className='fw-normal'>Miscellaneous</h6>
                          </div>
                          <div className='col-lg-5'>
                            <table
                              className='table table-bordered table-view-detail table-sm mb-lg-0'
                              role='presentation'
                              aria-label='Asset Details'
                            >
                              <tbody>
                                <tr>
                                  <td className='label_col w-50'>Serial No</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.serialNo}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='col-lg-5'>
                            <table
                              className='table table-bordered table-view-detail table-sm mb-lg-0'
                              role='presentation'
                              aria-label='Asset Details'
                            >
                              <tbody>
                                <tr>
                                  <td className='label_col w-50'>Purchased from</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.purchasedFrom}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {!AssetDetail?.asset?.assetsdispose && AssetDetail?.asset?.assetslease && (
                          <div className='form-group row mb-3'>
                            <div className='col-lg-2 d-flex align-items-center'>
                              <h6 className='fw-normal'>Lease</h6>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Leased To</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {AssetDetail?.asset?.assetslease?.customer?.fullName}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Lease Begins</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {moment(AssetDetail?.asset?.assetslease?.leaseBegins).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='offset-lg-2 col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Lease Notes</td>
                                    <td className='detail_col fw-bold w-50'>
                                      {AssetDetail?.asset?.assetslease?.leaseNotes}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Lease Expires</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {AssetDetail?.asset?.assetslease?.leaseExpire !== null
                                        ? moment(
                                          AssetDetail?.asset?.assetslease?.leaseExpire
                                        ).format('DD/MM/YYYY')
                                        : ''}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        {!AssetDetail?.asset?.assetsdispose && AssetDetail?.asset?.assetstatus && (
                          <div className='form-group row mb-3'>
                            <div className='col-lg-2 d-flex align-items-center'>
                              <h6 className='fw-normal'>Check out</h6>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Assign To</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {AssetDetail?.asset?.assetstatus?.person
                                        ? AssetDetail?.asset?.assetstatus?.person?.fullName
                                        : AssetDetail?.asset?.site
                                          ? 'Site/ Location'
                                          : ''}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Check-out Date</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {AssetDetail?.asset?.assetstatus?.checkoutDate &&
                                        moment(
                                          AssetDetail?.asset?.assetslease?.checkoutDate
                                        ).format('DD/MM/YYYY')}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='offset-lg-2 col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Check-out Notes</td>
                                    <td className='detail_col fw-bold w-50'>
                                      {AssetDetail?.asset?.assetstatus?.notes}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col w-50'>Due date</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {AssetDetail?.asset?.assetstatus?.dueDate &&
                                        moment(AssetDetail?.asset?.assetstatus?.dueDate).format(
                                          'DD/MM/YYYY'
                                        )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        {AssetDetail?.asset?.assetsdispose && (
                          <div className='form-group row mb-3'>
                            <div className='col-lg-2 d-flex align-items-center'>
                              <h6 className='fw-normal'>Dispose</h6>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col'>Date Disposed</td>
                                    <td className='detail-column fw-bold'>
                                      {moment(
                                        AssetDetail?.asset?.assetsdispose?.dateDispose
                                      ).format('DD/MM/YYYY')}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col'>Notes</td>
                                    <td className='detail-column fw-bold'>
                                      {AssetDetail?.asset?.assetsdispose?.notes}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='offset-lg-2 col-lg-5'>
                              <table
                                className='table table-bordered table-view-detail table-sm mb-lg-0'
                                role='presentation'
                                aria-label='Asset Details'
                              >
                                <tbody>
                                  <tr>
                                    <td className='label_col'>Dispose To</td>
                                    <td className='detail_col fw-bold'>
                                      {AssetDetail?.asset?.assetsdispose?.disposedTo}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        <div className='form-group row mat-asset-detail-event'>
                          <div className='col-lg-2 d-flex align-items-center'>
                            <h6 className='fw-normal'>Creation</h6>
                          </div>
                          <div className='col-lg-5'>
                            <table
                              className='table table-bordered table-view-detail table-sm mb-0'
                              aria-label='Assets View'
                              role='presentation'
                            >
                              <tbody>
                                <tr>
                                  <td className='label_col w-50'>Date Created</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {moment(AssetDetail?.asset?.createdAt).format('DD/MM/YYYY')}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='col-lg-5'>
                            <table
                              className='table table-bordered table-view-detail table-sm mb-0'
                              aria-label='Assets View'
                              role='presentation'
                            >
                              <tbody>
                                <tr>
                                  <td className='label_col w-50'>Created by</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {AssetDetail?.asset?.user?.firstName +
                                      ' ' +
                                      AssetDetail?.asset?.user?.lastName}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='events'
                      role='tabpanel'
                      aria-labelledby='events-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Events</h4>
                        <h3 className='text-center'>No Events Found!</h3>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='photos'
                      role='tabpanel'
                      aria-labelledby='photos-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Photos</h4>
                        {AssetDetail?.asset?.photo ? (
                          <div className='col-md-3 mb-4 mb-md-0'>
                            <div className='card card-custom overlay d-inline-block'>
                              <div className='card-body p-0'>
                                <div className='overlay-wrapper asset-image'>
                                  <img
                                    id='assetDefaultImg'
                                    src={
                                      AssetDetail?.asset?.photo && AssetDetail?.asset?.photo !== ''
                                        ? toAbsoluteUrlImage(AssetDetail?.asset?.photo)
                                        : ''
                                    }
                                    crossOrigin='anonymous'
                                    alt='Asset Image'
                                    title='assets-img'
                                    className='img-fluid center-block asset_img'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <h3 className='text-center'>No Photos Found!</h3>
                        )}
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='docs'
                      role='tabpanel'
                      aria-labelledby='docs-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Documents</h4>
                        <div className='lease-modal-table mb-3 table-responsive'>
                          <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            role='table'
                          >
                            <thead>
                              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Description{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  File Type{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  File Name{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Upload Date{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Upload By{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Action{' '}
                                </th>
                              </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                              <tr role='row'>
                                <td role='cell' colSpan={6} className='text-center'>
                                  No data available in table
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='warranty'
                      role='tabpanel'
                      aria-labelledby='warranty-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Warranty</h4>
                        <h3 className='text-center'>No Warranty Found!</h3>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='linking'
                      role='tabpanel'
                      aria-labelledby='linking-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Linking</h4>
                        <h3 className='text-center'>No Linking Found!</h3>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='maint'
                      role='tabpanel'
                      aria-labelledby='maint-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Maintenance</h4>
                        <div className='table-responsive'>
                          <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            role='table'
                          >
                            <thead>
                              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Schedule Date{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Title{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Maintenance By{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Status{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Completion Date{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Maintenance Cost{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Details{' '}
                                </th>
                              </tr>
                            </thead>

                            <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                              {AssetDetail?.asset?.assetsmaintenances?.length ? (
                                AssetDetail?.asset?.assetsmaintenances?.map(
                                  (item: any, idx: any) => {
                                    return (
                                      <tr key={idx} role='row'>
                                        <td role='cell' >
                                          <div >
                                            <div className='table-elipses'>
                                              {item?.maintenanceDueDate
                                                ? moment(item?.maintenanceDueDate).format(
                                                  'DD/MM/YYYY'
                                                )
                                                : ''}
                                            </div>
                                          </div>
                                        </td>
                                        <td role='cell' >
                                          <div >
                                            <div className='table-elipses'>
                                              {item?.maintenanceTitle}
                                            </div>
                                          </div>
                                        </td>
                                        <td role='cell' >
                                          <div >
                                            <div className='table-elipses'>
                                              {item?.maintenanceBy}
                                            </div>
                                          </div>
                                        </td>
                                        <td role='cell' >
                                          <div >
                                            <div className='table-elipses'>
                                              {item?.maintenanceStatus}
                                            </div>
                                          </div>
                                        </td>
                                        <td role='cell' >
                                          <div >
                                            <div className='table-elipses'>
                                              {item?.maintenanceDateCompleted
                                                ? moment(item?.maintenanceDateCompleted).format(
                                                  'DD/MM/YYYY'
                                                )
                                                : ''}
                                            </div>
                                          </div>
                                        </td>
                                        <td role='cell' >
                                          <div >
                                            <div className='table-elipses'>
                                              {item?.maintenanceCost}
                                            </div>
                                          </div>
                                        </td>
                                        <td role='cell' >
                                          <div >
                                            <div className='table-elipses'>
                                              {item?.maintenanceDetails}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )
                              ) : (
                                <tr>
                                  <td colSpan={8}>
                                    <h3 className='text-center'>No Maintenance Found!</h3>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='reserve'
                      role='tabpanel'
                      aria-labelledby='reserve-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Reserve</h4>
                        <div className='table-responsive'>
                          <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            role='table'
                          >
                            <thead>
                              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Reserved For{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Reserved By{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Start Date{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  End Date{' '}
                                </th>
                              </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                              {AssetDetail?.asset?.reserveassets?.length ? (
                                AssetDetail?.asset?.reserveassets?.map((item: any, idx: any) => {
                                  return (
                                    <tr key={idx} role='row'>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>{item?.reserveFor}</div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>
                                            {item?.customer
                                              ? item?.customer?.fullName
                                              : item?.person
                                                ? item?.person?.fullName
                                                : ''}
                                            {item?.site ? item?.site?.name : ''}
                                            {item?.location ? ' / ' + item?.location?.location : ''}
                                          </div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>
                                            {item?.startDate
                                              ? moment(item?.startDate).format('DD/MM/YYYY')
                                              : ''}
                                          </div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>
                                            {item?.endDate
                                              ? moment(item?.endDate).format('DD/MM/YYYY')
                                              : ''}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan={8}>
                                    <h3 className='text-center'>No Reservation Found!</h3>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='audit'
                      role='tabpanel'
                      aria-labelledby='audit-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>Audit</h4>
                        <div className='lease-modal-table mb-3 table-responsive'>
                          <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            role='table'
                          >
                            <thead>
                              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Audit Name{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Last Audit By{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Audit Date{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Site{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Location{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Notes{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Map{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Action{' '}
                                </th>
                              </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                              <tr role='row'>
                                <td role='cell' colSpan={8} className='text-center'>
                                  No data available in table
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='history'
                      role='tabpanel'
                      aria-labelledby='history-tab'
                    >
                      <div className='tab-content'>
                        <h4 className='border-bottom pb-3 mb-10'>History</h4>
                        <div className='table-responsive'>
                          <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            role='table'
                          >
                            <thead>
                              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Date{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Event{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Field{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Changed from{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Changed to{' '}
                                </th>
                                <th role='columnheader' className='min-w-125px'>
                                  {' '}
                                  Action by{' '}
                                </th>
                              </tr>
                            </thead>

                            <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                              {AssetDetail?.asset?.assetshistories?.length ? (
                                AssetDetail?.asset?.assetshistories?.map((item: any, idx: any) => {
                                  return (
                                    <tr key={idx} role='row'>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>
                                            {item?.date
                                              ? moment(item?.date).format('DD/MM/YYYY')
                                              : ''}
                                          </div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>{item?.event}</div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>{item?.field}</div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>{item?.changedFrom}</div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>{item?.changedTo}</div>
                                        </div>
                                      </td>
                                      <td role='cell' >
                                        <div >
                                          <div className='table-elipses'>{item?.actionBy}</div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan={8}>
                                    <h3 className='text-center'>No History Found!</h3>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AssetDetails
