import { ListViewProvider, useListView } from './Core/ListViewProvider'
import { QueryRequestProvider } from './Core/QueryRequestProvider'
import { QueryResponseProvider } from './Core/QueryResponseProvider'
import { UsersListHeader } from './Components/Header/UsersListHeader'
import { UsersTable } from './Table/UsersTable'
import { UserEditModal } from './UserEditModal/UserEditModal'
import { KTCard } from '../../../../../_metronic/helpers'

const UsersList = () => {
	const { itemIdForUpdate } = useListView()
	return (
		<>
			<KTCard>
				<UsersListHeader />
				<UsersTable />
			</KTCard>
			{itemIdForUpdate !== undefined && <UserEditModal />}
		</>
	)
}

const UsersListWrapper = () => (
	<QueryRequestProvider>
		<QueryResponseProvider>
			<ListViewProvider>
				<UsersList />
			</ListViewProvider>
		</QueryResponseProvider>
	</QueryRequestProvider>
)

export { UsersListWrapper }
