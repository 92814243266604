import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css';

const AboutUs: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const video = videoRef.current;

        const handlePlayStateChange = () => {
            setIsPlaying(!!(video && !video.paused));
        };

        const handleCanPlay = () => {
            if (video) {
                video.removeEventListener('canplay', handleCanPlay);
            }
        };

        if (video) {
            video.addEventListener('play', handlePlayStateChange);
            video.addEventListener('pause', handlePlayStateChange);
            video.addEventListener('canplay', handleCanPlay);
        }

        return () => {
            if (video) {
                video.removeEventListener('play', handlePlayStateChange);
                video.removeEventListener('pause', handlePlayStateChange);
                video.removeEventListener('canplay', handleCanPlay);
            }
        };
    }, []);

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (video) {
            if (video.readyState >= 2) {
                if (video.paused) {
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    };

    return (
        <section className='about-us'>
            <div className='custom-container container'>
                <div className='row flex-lg-row flex-column-reverse'>
                    <div className='col-xl-5 col-lg-6'>
                        <div className='custom-video position-relative'>
                            <video ref={videoRef}>
                                <source src={require(`../../../../Videos/FinalCoin.mp4`)} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                            <button className='btn video-btn' onClick={handlePlayPause}>
                                {isPlaying ? <i className="las la-pause"></i> : <i className="las la-play ps-1"></i>}
                            </button>
                        </div>
                    </div>
                    <div className='col-xl-7 col-lg-6'>
                        <div className='text ps-lg-5 mb-lg-0 mb-sm-10 mb-5'>
                            <h2 className='h-main mb-xl-5 mb-4'>Who is Assets Rack?</h2>
                            <p className='mb-xl-5 mb-4'>
                                Phasellus mollis tincidunt semper. Curabitur pharetra tortor nisi, ut consectetur
                                risus faucibus in. Donec ac tortor luctus, condimentum neque in, congue diam. Morbi
                                faucibus lorem et nunc consectetur sagittis. Donec in finibus sem, ac sodales quam.
                                Aliquam varius libero vitae dui finibus faucibus.
                            </p>
                            <p className='mb-xl-5 mb-4'>
                                Mivamus aliquet maximus varius. Morbi in est et tortor vulputate efficitur non
                                placerat metus. Suspendisse lacinia, lacus non vestibulum porta, nisl eros egestas
                                lorem, eget finibus magna arcu at odio. Etiam a blandit neque.
                            </p>
                            <div className='button-group d-flex justify-content-lg-start justify-content-center flex-wrap pt-4'>
                                <Link to="/" className='main-btn main-btn-yellow m-1'>Contact with Experts</Link>
                                <Link to="/" className='main-btn main-btn-white m-1'>Join us Today</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
