import { useNavigate, Link } from 'react-router-dom';

interface HeaderListingProps {
	assetsData: any;
	fileName: any;
	userAssets: any;
	deleteDataArr: number[];
	setDeleteModal: (value: boolean) => void;
	setCheck: (ids: number[]) => void;
	setShowSearch: (value: boolean) => void;
	setShowSetupColumns: (value: boolean) => void;
	handleExport: () => void;
	// handleExport:any;
	handleMaintenanceFilter: any
}
const MaintenancesHeader = (props: HeaderListingProps) => {
	const navigate = useNavigate();

	return (
		<>
			<div className='d-flex justify-content-between mb-3 flex-wrap' data-kt-user-table-toolbar='base'>
				<div className='d-flex align-items-center mb-xl-0 mb-4'>
					<h3 className="card-title me-4 mb-0">
						<span className='me-2 align-middle'><i className="la la-list fs-1" aria-hidden="true"></i></span>
						<span className="card-label fw-bold fs-3 mb-1">List of Maintenances</span>
					</h3>
				</div>
				<div className='d-flex flex-wrap'>
					<button
						type='button'
						className='btn btn-light-primary main-btn-style m-1'
						onClick={() => props.setShowSearch(true)}
					>
						<i className='fa fa-search fs-xl-5 fs-6' />
						{' '}Search<span className='d-xl-inline d-none'>{' '}Criteria</span>
					</button>
					<button className='btn btn-light-primary main-btn-style m-1' onClick={() => { props.handleExport() }}>
						<i className='far fa-file-excel fs-5'></i>
						{' '}Export<span className='d-xl-inline d-none'>{' '}to Excel</span>
					</button>
					<Link className='btn btn-light-primary main-btn-style m-1' to={'/import'}>
						<i className='la la-cloud-download fs-2 me-2'></i>{''}Import <span className='d-xl-inline d-none'>Maintenances</span>
					</Link>
					{props.deleteDataArr.length > 0 && (
						<button
							type='button'
							className='btn btn-danger main-btn-style m-1'
							onClick={() => {
								props.setDeleteModal(true)
								props.setCheck(props.deleteDataArr)
							}}
						>
							Delete Selected ({props.deleteDataArr.length})
						</button>
					)}
				</div>
			</div>
			<div className='row'>
				<div className='col-xl-4 col-sm-6 col-12'>
					<div className='form-group mb-3'>
						<select id="" className="form-select" onChange={props.handleMaintenanceFilter}>
							<option value="All">All Maintenances (except cancelled and completed)</option>
							<option value="Due">Maintenance Due</option>
							<option value="OverDue">Maintenance Overdue</option>
							<option value="DueInFuture">Maintenance Due in future</option>
							<option value="Completed">Maintenance Completed</option>
							<option value="Cancelled">Maintenance Cancelled</option>
						</select>
					</div>
				</div>
			</div>
		</>
	)
}

export { MaintenancesHeader }
