import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { CAccount, defaultCompanyDetail as initialValues } from './Core/SetupModel'
import { toAbsoluteUrlImage } from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from '../../../Auth'
import { toast } from 'react-toastify'
import { Images } from '_metronic/assets/Assets'
import {
	useDeleteCompanyMutation,
	useGetCompanyQuery,
	useUpdateCompanyMutation,
	useDeleteImageMutation,
} from '../../../Services/Company'
import { useNavigate } from 'react-router-dom'
import timezones from 'timezones-list'
import { countries } from 'country-list-json'
import { currencies } from '../Asset/Components/Currency'
import { cp } from 'fs'
import SplashScreen from 'App/SplashScreen'
import { checkUserUnauthorized } from '../../../Auth'
import DeleteAccount from './DeleteAccount'
import { MoonLoader } from 'react-spinners'
import SetupWizard from '../Setup-wizard'
import { imageMimeType, months } from 'config'

const accountDetailsSchema = Yup.object().shape({
	companyName: Yup.string()
		.required('Company is required')
		.min(8, 'Company must be at least 8 characters')
		.max(50, 'Company must not exceed 50 characters')
		.matches(/^[^\d]+$/, 'Company must not contain numbers'),
	country: Yup.string().required('Country is required'),
	address: Yup.string()
		.required('Address is required')
		.min(5, 'Address must be at least 5 characters')
		.max(50, 'Address must not exceed 50 characters'),
	city: Yup.string()
		.required('City is required')
		.min(4, 'City must be at least 4 characters')
		.max(20, 'City must not exceed 20 characters'),
	state: Yup.string()
		.required('State is required')
		.min(4, 'State must be at least 4 characters')
		.max(20, 'State must not exceed 20 characters'),
	postalCode: Yup.string()
		.required('Postal Code is required')
		.matches(/^[0-9]+$/, 'Only digits are allowed in Postal Code')
		.min(4, 'Postal Code must be at least 4 characters')
		.max(10, 'Postal Code must not exceed 10 characters')
		.test('no-alphabets', 'Alphabets are not allowed in Postal Code', function (value) {
			if (value) {
				return !/[a-zA-Z]/.test(value);
			}
			return true;
		}),
	timeZone: Yup.string().required('Time zone is required').trim().min(1, 'Time zone is required'),
	currencySymbol: Yup.string().required('Currency is required'),
	financialYearStart: Yup.string(),
	dateFormat: Yup.string().required('Date Format is required'),
	financialYearStartDate: Yup.string().required('Date is required'),
	financialYearStartMonth: Yup.string().required('Month is required'),
})
const CompanyInfo = () => {
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const date = Array.from({ length: 31 }, (_, index) => index + 1)
	const [companyLogo, setCompanyLogo] = useState<string>('')
	const [isCompanyAdded, setIsCompanyAdded] = useState<boolean>(false)
	const [loadingAvatar, setLoadingAvatar] = useState(false)
	const { data: companyData, isError, error } = useGetCompanyQuery()
	const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation()
	const [deleteCompanyImage] = useDeleteImageMutation()
	const [deleteCompany, { isLoading: isLoadingDeleteCompany }] = useDeleteCompanyMutation()
	const [file, setFile] = useState(null)
	const [fileDataURL, setFileDataURL] = useState<string | ArrayBuffer | null | undefined>(null)
	const [isDataExist, setIsDataExist] = useState<any>(false)

	const checkVistingPage = (dataList: any) => {
		if (isDataExist === false) {
			if (dataList && dataList?.length > 0) {
				setIsDataExist(dataList?.length)
			} else {
				setIsDataExist(0)
			}
		}
	}
	useEffect(() => {
		if (currentUser && companyData) {
			checkVistingPage(companyData?.company)
		}
	}, [companyData])

	useEffect(() => {
		if (isError) {
			const errorData = error as { data: any }
			checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
		}
	}, [isError])

	const changeHandler = (e) => {
		const file = e?.target?.files[0]
		if (!file?.type?.match(imageMimeType)) {
			toast.error('This file format is not allowed!')
			return
		} else {
			setFile(file)
			const reader = new FileReader()
			reader.onload = (event) => {
				setFileDataURL(event?.target?.result)
			}
			reader.readAsDataURL(file)
		}
	}

	const handleDelete = () => {
		if (currentUser) {
			deleteCompany(companyData?.company?.id)
				.then((res: any) => {
					if (res.data) {
						toast.success(res?.data?.message)
						window.location.reload()
					} else {
						toast.error(res?.error?.data?.message)
					}
				})
				.catch((err: any) => {
					toast.error('Error in responce!')
					checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
				})
		}
	}

	const formik = useFormik<CAccount>({
		initialValues,
		validationSchema: accountDetailsSchema,
		onSubmit: async (values: CAccount) => {
			try {
				const financialYearStart = `${values?.financialYearStartDate} ${values?.financialYearStartMonth}`

				const updatedValues = { ...values, financialYearStart }
				const formData = new FormData()
				for (const key of Object.keys(updatedValues)) {
					if (Object.prototype.hasOwnProperty.call(updatedValues, key)) {
						if (updatedValues) {
							const value = updatedValues[key as keyof CAccount]
							formData.append(key, value)
						}
					}
				}
				const data = await updateCompany({ formData }).unwrap()
				if (data?.success) {
					toast.success(data?.message)
				}
			} catch (error: any) {
				toast.error('Something went wrong')
				checkUserUnauthorized(error?.response?.data, saveAuth, setCurrentUser, toast)
			}
		},
	})

	useEffect(() => {
		let fileReader,
			isCancel = false
		if (file) {
			fileReader = new FileReader()
			fileReader.onload = (e) => {
				const { result } = e.target
				if (result && !isCancel) {
					setFileDataURL(result)
				}
			}
			fileReader.readAsDataURL(file)
		}
		return () => {
			isCancel = true
			if (fileReader && fileReader.readyState === 1) {
				fileReader.abort()
			}
		}
	}, [file])

	useEffect(() => {
		if (companyData?.company?.length === 0) {
			formik.setValues(initialValues)
			setIsCompanyAdded(false)
		} else if (currentUser && companyData?.company) {
			if (
				typeof companyData?.company === 'object' &&
				companyData?.company !== null &&
				!Array.isArray(companyData?.company)
			) {
				setIsCompanyAdded(true)
				setCompanyLogo(companyData?.company?.companyLogo)
			}

			const data = companyData.company?.financialYearStart?.split(' ') || []
			const selectedMonth = data[1] || ''
			const daysInMonth = new Date(
				new Date().getFullYear(),
				months.findIndex((month) => month === selectedMonth) + 1,
				0
			).getDate()

			const dateOptions = Array.from({ length: daysInMonth }, (_, index) => index + 1) as number[]

			formik.setValues({
				dateOptions,
				companyName: companyData.company.companyName,
				address: companyData.company?.address,
				city: companyData.company?.city,
				country: companyData.company?.country,
				state: companyData.company?.state,
				postalCode: companyData.company?.postalCode,
				aptSuite: companyData.company?.aptSuite,
				timeZone: companyData.company?.timeZone,
				currencySymbol: companyData.company?.currencySymbol,
				financialYearStart: companyData.company?.financialYearStart,
				dateFormat: companyData.company?.dateFormat,
				companyLogo: companyData.company?.companyLogo,
				financialYearStartDate: data[0] || '',
				financialYearStartMonth: selectedMonth,
			})
		}
	}, [companyData])

	const deleteImage = async (e) => {
		e?.preventDefault()
		setLoadingAvatar(true)
		try {
			const data = await deleteCompanyImage(companyData?.company?.id).unwrap()
			if (data?.success) {
				setFileDataURL(null)
				toast.success(data?.message)
			}
		} catch (error: any) {
			if (!error?.data?.success) {
				toast.error(error?.data?.message)
			}
			checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
		} finally {
			setLoadingAvatar(false)
		}
	}

	const formSubmit = (e) => {
		e?.preventDefault()
		if (Object.keys(formik.errors).length === 0) {
			formik.handleSubmit()
		} else {
			toast.error('All fields are required')
			window.scrollTo({ top: 0, behavior: 'smooth' })
		}
	}

	const handleMonthChange = (e) => {
		const selectedMonth = e.target.value
		const daysInMonth = new Date(
			new Date().getFullYear(),
			months.findIndex((month) => month === selectedMonth) + 1,
			0
		).getDate()

		formik.setFieldValue('financialYearStartDate', '')
		formik.setFieldTouched('financialYearStartDate', false)

		const newDateOptions = Array.from({ length: daysInMonth }, (_, index) => index + 1)

		formik.setFieldError('financialYearStartDate', '')
		formik.setFieldError('financialYearStartMonth', '')
		formik.setFieldValue('financialYearStartDate', '')
		formik.setFieldValue('financialYearStartMonth', selectedMonth)
		formik.setFieldValue('dateOptions', newDateOptions)
	}
	return (
		<>
			<SplashScreen isLoadingTemplate={isLoadingDeleteCompany} />
			<div className='card mb-5 mb-xl-10'>
				<SetupWizard isDataExist={isDataExist}/>
				<div id='kt_account_profile_details' className='collapse show'>
					<form noValidate className='form'>
						<div className='card-body border-top p-9'>
							<div className='pb-5'>
								{' '}
								<h3 className='fw-bolder m-0 mb-3'>
									<span className='me-2 align-middle'>
										<i className='la la-briefcase fs-1' aria-hidden='true'></i>
									</span>{' '}
									Company Details
								</h3>
								<span className='fw-bolder m-0'>Provide the name and site of the main office.</span>
							</div>

							<div className='row'>
								<div className='col-lg-10 offset-lg-1 col-12'>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>Company</label>
										<div className='col-lg-9 fv-row'>
											<input
												type='text'
												className='form-control  form-control-solid'
												placeholder='Company'
												{...formik.getFieldProps('companyName')}
											/>
											{formik.touched.companyName && formik.errors.companyName && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.companyName}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label fw-bold fs-6'>
											<span className='required'>Country</span>
										</label>

										<div className='col-lg-9 fv-row'>
											<select
												className='form-select form-control form-control-solid form-select-solid  '
												{...formik.getFieldProps('country')}
											>
												<option value=''>Select a Country...</option>
												{countries?.map((country) => (
													<option value={country?.code}>{country?.name}</option>
												))}
											</select>
											{formik.touched.country && formik.errors.country && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.country}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>Address</label>

										<div className='col-lg-9 fv-row'>
											<input
												type='text'
												className='form-control  form-control-solid'
												placeholder='Address'
												{...formik.getFieldProps('address')}
											/>
											{formik.touched.address && formik.errors.address && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.address}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label fw-bold fs-6'>Apt./Suite</label>
										<div className='col-lg-9 fv-row'>
											<input
												type='text'
												className='form-control  form-control-solid'
												placeholder='Apt./Suite'
												{...formik.getFieldProps('aptSuite')}
											/>
											{formik.touched.aptSuite && formik.errors.aptSuite && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.aptSuite}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>City</label>
										<div className='col-lg-9 fv-row'>
											<input
												type='text'
												className='form-control  form-control-solid'
												placeholder='City'
												{...formik.getFieldProps('city')}
											/>
											{formik.touched.city && formik.errors.city && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.city}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>State</label>

										<div className='col-lg-9 fv-row'>
											<input
												type='text'
												className='form-control  form-control-solid'
												placeholder='State'
												{...formik.getFieldProps('state')}
											/>
											{formik.touched.state && formik.errors.state && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.state}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label fw-bold fs-6'>
											<span className='required'>Postal Code</span>
										</label>

										<div className='col-lg-9 fv-row'>
											<input
												type='text'
												className='form-control  form-control-solid'
												placeholder='Postal Code'
												{...formik.getFieldProps('postalCode')}
											/>

											{formik.touched.postalCode && formik.errors.postalCode && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.postalCode}</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className='pb-5'>
								{' '}
								<h3 className='card-title mb-4'>
									<span className='me-2 align-middle'>
										<i className='la la-clock fs-1' aria-hidden='true'></i>
									</span>
									<span className='card-label fw-bold fs-3 mb-1'>Time zone & Currency</span>
								</h3>
								<span className='fw-bolder m-0'>
									Adjust the settings to fit your company's local timezone, currency, and date
									format.
								</span>
							</div>
							<div className='row'>
								<div className='col-lg-10 offset-lg-1 col-12'>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>
											Time Zone
										</label>

										<div className='col-lg-9 fv-row'>
											<select
												className='form-select form-control form-control-solid form-select-solid  '
												{...formik.getFieldProps('timeZone')}
											>
												<option value=''>Select a Timezone..</option>
												{timezones?.map((time) => (
													<option value={time?.label}>{time?.label}</option>
												))}
											</select>
											{formik.touched.timeZone && formik.errors.timeZone && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.timeZone}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>
											Currency Symbol
										</label>

										<div className='col-lg-9 fv-row'>
											<select
												className='form-select form-control form-control-solid form-select-solid  '
												{...formik.getFieldProps('currencySymbol')}
											>
												<option value=''>Select a Currency</option>
												{currencies?.map((currency, index) => (
													<option key={index} value={currency?.value}>
														{currency?.name}
													</option>
												))}
											</select>
											{formik.touched.currencySymbol && formik.errors.currencySymbol && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.currencySymbol}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>
											Date format
										</label>
										<div className='col-lg-9 fv-row'>
											<select
												className='form-select form-control form-control-solid form-select-solid  '
												{...formik.getFieldProps('dateFormat')}
											>
												<option value=''>Select a date format..</option>
												<option value='USD'>MM/dd/yyyy</option>
												<option value='GBP'>yyyy-MM-dd</option>
												<option value='AUD'>dd/MM/yyyy</option>
											</select>
											{formik.touched.dateFormat && formik.errors.dateFormat && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>{formik.errors.dateFormat}</div>
												</div>
											)}
										</div>
									</div>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label required fw-bold fs-6'>
											Financial Year begins on
										</label>
										<div className='col-lg-9 fv-row'>
											<div className='row'>
												<div className='col-sm-6'>
													<div className='mb-sm-0 mb-3'>
														<select
															className='form-select form-control form-control-solid form-select-solid   me-2'
															{...formik.getFieldProps('financialYearStartMonth')}
															onChange={handleMonthChange}
														>
															<option value=''>Select month</option>
															{months.map((value, index) => (
																<option key={index} value={value}>
																	{value}
																</option>
															))}
														</select>
														{formik.touched.financialYearStartMonth &&
															formik.errors.financialYearStartMonth && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>
																		{formik.errors.financialYearStartMonth}
																	</div>
																</div>
															)}
													</div>
												</div>
												<div className='col-sm-6'>
													<div>
														<select
															className='form-select form-control form-control-solid form-select-solid  '
															{...formik.getFieldProps('financialYearStartDate')}
														>
															<option value=''>Select date</option>
															{formik.values.dateOptions.map((value) => (
																<option key={value} value={value}>
																	{value}
																</option>
															))}
														</select>
														{formik.touched.financialYearStartDate &&
															formik.errors.financialYearStartDate && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>
																		{formik.errors.financialYearStartDate}
																	</div>
																</div>
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='pb-5'>
								{' '}
								<h3 className='card-title mb-4'>
									<span className='me-2 align-middle'>
										<i className='la la-image fs-1' aria-hidden='true'></i>
									</span>
									<span className='card-label fw-bold fs-3 mb-1'>Company Logo</span>
								</h3>
								<span className='fw-bolder m-0'>
									Upload your organization’s logo to make this space your own.
								</span>
							</div>
							<div className='row'>
								<div className='col-lg-10 offset-lg-1 col-12'>
									<div className='row mb-sm-6 mb-4'>
										<label className='col-lg-3 col-form-label fw-bold fs-6'>Logo</label>
										<div className='col-lg-9'>
											{loadingAvatar ? (
												<MoonLoader color='#0d145c' loading={loadingAvatar} />
											) : (
												<div className='image-input image-input-outline' data-kt-image-input='true'>
													<label htmlFor='companyLogo' className='cursor-pointer position-relative'>
														<div>
															<img
																className='image-input-wrapper w-125px h-125px'
																src={
																	fileDataURL
																		? fileDataURL.toString()
																		: companyLogo
																			? toAbsoluteUrlImage(companyLogo)
																			: Images.Avatar
																}
																alt='profile'
																crossOrigin='anonymous'
															/>
														</div>
														<span className='edit-icon'>
															<i className='las la-camera fs-2'></i>
														</span>
														{companyLogo ? (
															<span onClick={(e) => deleteImage(e)} className='delete-icon'>
																<i className='las la-trash-alt fs-2'></i>
															</span>
														) : null}
													</label>
													<div>
														<input
															className='form-control d-none'
															type='file'
															name='companyLogo'
															id='companyLogo'
															accept='.png, .jpg, .jpeg'
															onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																changeHandler(e)
																formik.setFieldValue(
																	'companyLogo',
																	e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType)
																		? e.target.files![0]
																		: companyLogo
																)
															}}
														/>

													</div>
													<p className='mb-0'>
														Only (<strong>JPG, GIF, PNG</strong>) are allowed
													</p>
												</div>
											)}

										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='card-footer d-flex justify-content-end py-6 px-9'>
							<button
								onClick={(e) => formSubmit(e)}
								className='btn btn-primary'
								disabled={isUpdating}
							>
								{' '}
								{!isUpdating && 'Submit'}
								{isUpdating && (
									<span className='indicator-progress d-block'>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
			<DeleteAccount handleDelete={handleDelete} />
		</>
	)
}

export default CompanyInfo
