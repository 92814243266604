import { useState, useEffect } from 'react'
import { initialQueryState, KTSVG, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../Core/QueryRequestProvider'

const UsersListSearchComponent = () => {
	const { updateState } = useQueryRequest()
	const [searchTerm, setSearchTerm] = useState<string>('')
	const debouncedSearchTerm = useDebounce(searchTerm, 150)
	useEffect(
		() => {
			if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
				updateState({ search: debouncedSearchTerm, ...initialQueryState })
			}
		},
		[debouncedSearchTerm]
	)

	return (
		<div className='card-title'>
			<div className='d-flex align-items-center position-relative my-1'>
				<KTSVG
					path='/media/icons/duotune/general/gen021.svg'
					className='svg-icon-1 position-absolute ms-6'
				/>
				<div>
					<input
						type='text'
						data-kt-user-table-filter='search'
						className='form-control form-control-solid w-250px ps-14'
						placeholder='Search user'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
			</div>
		</div>
	)
}

export { UsersListSearchComponent }
