import axios from 'axios'
import {
  allAssetsFields,
  ContractFields,
  Location,
  StandardFields,
  updatedStandardAndCustomData,
  WarrantyFields,
} from './_models'

const URL = process.env.REACT_APP_API_URL

const getListofSites = (id: number) => {
  return axios.get(`${URL}/site/list/${id}`).then((response) => {
    return response.data.sites
  })
}
const addLocation = async (data: Location, id: number) => {
  try {
    const result = await axios.post(`${URL}/location/create/${id}`, data)
    return result
  } catch (error) {
    throw error 
  }
}

const addCustomersFields = async (data: StandardFields, token: string | undefined) => {
  try {
    const result = await axios.post(`${URL}/customer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return result
  } catch (error) {
    throw error
  }
}

const addWarrantiesFields = async (data: WarrantyFields, token: string | undefined) => {
  try {
    const result = await axios.post(`${URL}/warranty`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return result
  } catch (error) {
    throw error
  }
}

const addContractFields = async (data: ContractFields, token: string | undefined) => {
  try {
    const result = await axios.post(`${URL}/contract`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return result
  } catch (error) {
    throw error
  }
}

const addAssetsFields = async (data: allAssetsFields, token: string | undefined) => {
  try {
    const result = await axios.post(`${URL}/asset`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return result
  } catch (error) {
    throw error
  }
}

const getWarrantiesFields = async (token: string | undefined): Promise<WarrantyFields> => {
  try {
    const result = await axios.get(`${URL}/warranty`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const warranty = result.data.warranty[0]
    return {
      hasLength: warranty?.hasLength,
      isLengthRequired: warranty?.isLengthRequired,
      hasExpirationDate: warranty?.hasExpirationDate,
      isExpirationDateRequired: warranty?.isExpirationDateRequired,
      hasNotes: warranty?.hasNotes,
      isNotesRequired: warranty?.isNotesRequired,
      customFields: warranty?.customFields ? JSON.parse(warranty?.customFields) : '',
    }
  } catch (error) {
    throw error
  }
}

const getContractFields = async (token: string | undefined): Promise<ContractFields> => {
  try {
    const result = await axios.get(`${URL}/contract`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const contract = result.data.contract
    return {
      hasContractTitle: contract.hasContractTitle,
      isContractTitleRequired: contract.isContractTitleRequired,
      hasDescription: contract.hasDescription,
      isDescriptionRequired: contract.isDescriptionRequired,
      hasHyperlink: contract.hasHyperlink,
      isHyperlinkRequired: contract.isHyperlinkRequired,
      hasContractNo: contract.hasContractNo,
      isContractNoRequired: contract.isContractNoRequired,
      hasCost: contract.hasCost,
      isCostRequired: contract.isCostRequired,
      hasStartDate: contract.hasStartDate,
      isStartDateRequired: contract.isStartDateRequired,
      hasEndDate: contract.hasEndDate,
      isEndDateRequired: contract.isEndDateRequired,
      hasNodEndDate: contract.hasNodEndDate,
      hasContractPerson: contract.hasContractPerson,
      isContractPersonRequired: contract.isContractPersonRequired,
      hasPhone: contract.hasPhone,
      isPhoneRequired: contract.isPhoneRequired,
      hasNoOfLicenses: contract.hasNoOfLicenses,
      isNoOfLicensesRequire: contract.isNoOfLicensesRequire,
      hasIsSoftware: contract.hasIsSoftware,
      hasVendor: contract.hasVendor,
      isVendorRequired: contract.isVendorRequired,
      customFields: JSON.parse(contract.customFields),
    }
  } catch (error) {
    throw error
  }
}

const getCustomersFields = async (
  token: string | undefined
): Promise<updatedStandardAndCustomData> => {
  try {
    const result = await axios.get(`${URL}/customer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const customer = result.data.customer
    return {
      hasFullName: customer.hasFullName,
      isFullNameKeyField: customer.isFullNameKeyField,
      hasEmail: customer.hasEmail,
      isEmailKeyField: customer.isEmailKeyField,
      isEmailRequired: customer.isEmailRequired,
      hasCompany: customer.hasCompany,
      isCompanyRequired: customer.isCompanyRequired,
      hasAddress: customer.hasAddress,
      isAddressRequired: customer.isAddressRequired,
      hasPhone: customer.hasPhone,
      isPhoneRequired: customer.isPhoneRequired,
      hasMobilePhone: customer.hasMobilePhone,
      isMobilePhoneRequired: customer.isMobilePhoneRequired,
      hasNotes: customer.hasNotes,
      isNotesRequired: customer.isNotesRequired,
      customFields: customer?.customFields?.length > 0 ? JSON.parse(customer.customFields) : '',
    }
  } catch (error) {
    throw error
  }
}

const getAssetsFields = async (token: string | undefined): Promise<allAssetsFields> => {
  try {
    const result = await axios.get(`${URL}/asset`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const assets = result.data.assets
    return {
      hasAssetTagId: assets?.hasAssetTagId,
      hasAssetDescription: assets?.hasAssetDescription,
      hasPurchaseDate: assets?.hasPurchaseDate,
      isPurchaseDateRequired: assets.isPurchaseDateRequired,
      hasEstimatedCost: assets.hasEstimatedCost,
      isEstimatedCostRequired: assets.isEstimatedCostRequired,
      hasPurchaseFrom: assets.hasPurchaseFrom,
      isPurchaseFromRequired: assets.isPurchaseFromRequired,
      hasBrand: assets.hasBrand,
      isBrandRequired: assets.isBrandRequired,
      hasModel: assets.hasModel,
      isModelRequired: assets.isModelRequired,
      hasSerialNo: assets.hasSerialNo,
      isSerialNoRequired: assets.isSerialNoRequired,
      customFields: assets.length > 0 ? JSON.parse(assets.customFields) : '',
      categoryList: assets.categoryList,
    }
  } catch (error) {
    throw error
  }
}

const getAllLocation = async (id: number) => {
  return axios.get(`${URL}/location/list/${id}`).then((response) => {
    return response.data.locations
  })
}

const deleteLocation = async (userId: number | undefined, data: number[]) => {
  return axios.put(`${URL}/location/delete-all/${userId}`, data).then((response) => {
    return response.data
  })
}

const updateLocation = (id: number, data: Location) => {
  return axios
    .put(`${URL}/location/update/${id}`, data)
    .then((response) => {
      return response
    })
    .catch((err) => {
      return err.response
    })
}

const getEmployee = async (token: string | undefined) => {
  return axios.get(`${URL}/employee`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const postEmployeeData = async (token: string | undefined, data?: any) => {
  try {
    const response = await axios.post(`${URL}/employee`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  } catch (error) {
    throw error
  }
}

const getMaintenance = async (token: string | undefined) => {
  return axios.get(`${URL}/maintenance`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}


const postMaintenanceData = async (token: string | undefined, data?: any) => {
  try {
    const response = await axios.post(`${URL}/maintenance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  } catch (error) {
    throw error
  }
}

export {
  getListofSites,
  addLocation,
  getAllLocation,
  deleteLocation,
  updateLocation,
  getEmployee,
  postEmployeeData,
  getMaintenance,
  postMaintenanceData,
  addCustomersFields,
  getCustomersFields,
  getAssetsFields,
  addAssetsFields,
  addWarrantiesFields,
  getWarrantiesFields,
  addContractFields,
  getContractFields,
}
