import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useAuth } from 'App/Modules/Auth'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SplashScreen from 'App/SplashScreen'

const LocationHeader = (props: any) => {
	const { currentUser } = useAuth()
	const { data: siteData, refetch: refetchSites, isLoading } = useGetAllSitesQuery({ userId: currentUser?.id })
	const handleSiteClick = (e: any) => {
		const id = e.target.value
		props.setSiteId(id)
		props.setSkip(false)
		props.setLoading(true)
	}

	useEffect(() => {
		refetchSites()
	}, [])

	useEffect(() => {
		if (siteData?.sites && siteData?.sites?.length > 0) {
			props.setSiteId(siteData?.sites[0]?.id)
		}
	}, [siteData])

	return (
		<>

			<SplashScreen isLoadingTemplate={isLoading} />
			{
				!isLoading ? (
					<div className='card-body d-sm-flex border-bottom border-bottom-gray-200 justify-content-between pb-3 mb-3 align-items-center flex-wrap'>
						<h3 className='card-title align-items-start flex-column mb-xl-0 mb-3 me-3'>
							<span className='me-2 align-middle'>
								<i className='la la-list fs-1' aria-hidden='true'></i>
							</span>
							<span className='card-label fw-bold fs-3'>List of Locations</span>
						</h3>
						<div className='d-flex flex-wrap' data-kt-user-table-toolbar='base'>
							{siteData?.sites.length > 0 && (
								<div className='d-flex m-1 w-100 flex-1'>
									<label className='col-form-label fw-bold fs-6 me-2'>
										<span >Site</span>
									</label>
									<div className='fv-row d-flex w-100'>
										<select
											onChange={(e) => {
												handleSiteClick(e)
											}}
											className='form-select form-control form-control-solid form-select-solid   site-select'
										>
											{siteData?.sites.map((item: any, idx: number) => {
												return (
													<option key={idx} value={item?.id}>
														{item?.name}
													</option>
												)
											})}
										</select>
									</div>
								</div>
							)}
							<button
								type='button'
								className='btn btn-light-primary main-btn-style m-1'
								onClick={() => props.handleShow()}
							>
								<i className='la la-plus fs-3' />
								Add{' '}<span className='d-xl-inline d-none'>New Location</span>
							</button>
							<Link className='btn btn-light-primary main-btn-style m-1' to={'/import'}>
								<i className='la la-cloud-download fs-2 me-2'></i>{''}Import <span className='d-xl-inline d-none'>Locations</span>
							</Link>
							{props.deleteDataArr.length > 0 && (
								<button
									type='button'
									className='btn btn-danger m-1'
									onClick={() => {
										props.setDeleteModal(true)
										props.setCheck(props.deleteDataArr)
									}}
								>
									Delete Selected ({props.deleteDataArr.length})
								</button>
							)}
						</div>
					</div>
				) : null}

		</>
	)
}

export { LocationHeader }
