import { useState, useEffect } from 'react'
import Editor from './Editor'
import { useGetEmailTypesQuery } from 'App/Modules/Services/EmailTemplate'


export const CustomEmail = () => {
	const { data } = useGetEmailTypesQuery("")
	const [templateName, setTemplateName] = useState('Check Out Email')
	const [templateId, setTemplateId] = useState(null)
	const handleClickTabs = async (t: any) => {
		setTemplateName(t?.title)
		setTemplateId(t?.id)
	}
	useEffect(() => {
			setTemplateId(data?.emailTypes[0]?.id)
	}, [data])

	return (
		<>
			<div className='customize-email'>
				<div className='card-title mb-3'>
					<h3 className='fw-bolder'>
						<span className='me-2 align-middle'>
							<i className='la la-mail-bulk fs-2x' aria-hidden='true'></i>
						</span>{' '}
						Customize Emails
					</h3>
				</div>
				<div className='card'>
					<div
						className='border-0 cursor-pointer'
						role='button'
						data-bs-toggle='collapse'
						data-bs-target='#kt_account_profile_details'
						aria-expanded='true'
						aria-controls='kt_account_profile_details'
					>
						<div className='card-body border-top p-lg-9 p-md-7 p-6'>
							<div className='row'>
								<div className='col-xl-3'>
									<ul className='nav-tabs list-unstyled mb-5 fs-6 d-xl-inline-block d-block'>
										<li className='mb-2'>
											<span className='fs-6 fw-bold d-inline-block '>Check Out/Check In Emails</span>
										</li>
										{data?.emailTypes?.map((t, index) => {
											return t?.category === 1 ? (
												<li className='mb-2' key={index}>
													<span
														className={`d-block px-2 py-1 cursor-pointer ${templateName === t?.title ? 'active' : ''}`}
														onClick={() => handleClickTabs(t)}
													>
														{' '}
														{t?.title}
													</span>
												</li>
											) : null;
										})
										}
										<li className='mb-2'>
											<span className='tab_title nav-item fs-6 fw-bold d-inline-block '>
												Lease/Lease Return Email
											</span>
										</li>
										{data?.emailTypes?.map((t, index) => {
											return t?.category === 2 ? (
												<li className='mb-2' key={index}>
													<span
														className={`d-block px-2 py-1 cursor-pointer ${templateName === t?.title ? 'active' : ''}`}
														onClick={() => handleClickTabs(t)}
													>
														{' '}
														{t?.title}
													</span>
												</li>
											) : null;
										})
										}
									</ul>
								</div>
								<div className='col-xl-9'>
									<div >
										<Editor templateId={templateId} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default CustomEmail
