import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../Core/QueryResponseProvider'
import { useQueryRequest } from '../../Core/QueryRequestProvider'
import { Link } from 'react-router-dom'

const mappedLabel = (label: string): string => {
	if (label === '&laquo; Previous') {
		return 'Previous'
	}

	if (label === 'Next &raquo;') {
		return 'Next'
	}

	return label
}

const UsersListPagination = () => {
	const pagination = useQueryResponsePagination()
	const isLoading = useQueryResponseLoading()
	const { updateState } = useQueryRequest()
	const updatePage = (page: number | null) => {
		if (!page || isLoading || pagination.page === page) {
			return
		}

		updateState({ page, items_per_page: pagination.items_per_page || 10 })
	}

	return (
		<div className='row'>
			<div className='col-sm-12 col-md-7 offset-md-5 d-flex align-items-center justify-content-center justify-content-md-end'>
				<div id='kt_table_users_paginate'>
					<ul className='pagination'>
						{pagination.links
							?.map((link) => {
								return { ...link, label: mappedLabel(link.label) }
							})
							.map((link) => (
								<li
									key={link.label}
									className={clsx('page-item', {
										active: pagination.page === link.page,
										disabled: isLoading,
										previous: link.label === 'Previous',
										next: link.label === 'Next',
									})}
								>
									<Link
									   to={'/dummy-link'}
										className={clsx('page-link cursor-pointer', {
											'page-text': link.label === 'Previous' || link.label === 'Next',
											'me-5': link.label === 'Previous',
										})}
										onClick={() => updatePage(link.page)}
									>
										{mappedLabel(link.label)}
									</Link>
								</li>
							))}
					</ul>
				</div>
			</div>
		</div>
	)
}

export { UsersListPagination }
