import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { GroupResponse } from '../Core/_models'
import { SecurityGroupHeader } from './SecurityGroupHeader'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { useDeleteSitesMutation, useGetAllSitesQuery } from '../../../../Services/Sites'
import { useGetAllGroupsQuery, useDeleteGroupMutation } from 'App/Modules/Services/groups'
import { useNavigate } from 'react-router-dom'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import { useIntl } from 'react-intl'
import { PageTitle } from '_metronic/layout/core'
import ActionIcon from '_metronic/assets/icons/ActionIcon'
import { Link } from 'react-router-dom'

const SecurityGroupListing = () => {
	const intl = useIntl()
	const { currentUser } = useAuth()
	const navigate = useNavigate()
	const userId = currentUser?.id
	const [check, setCheck] = useState<number>()
	const [show, setShow] = useState<boolean>(false)
	const [data, setData] = useState({})
	const [selectAll, setSelectAll] = useState(false)
	const [selected, setSelected] = useState<number>()
	const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
	const [deleteSites] = useDeleteSitesMutation()
	const [deleteGroup] = useDeleteGroupMutation();
	const [deleteData, setDeleteData] = useState<number>(0);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);

	const [isSitesExist, setIsSitesExist] = useState<any>(false)
	const [deleteCascade, setDeleteCascade] = useState(false)
	const [pagination, setPagination] = useState<any>({})
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const { data: userSitesList } = useGetAllSitesQuery({ page, limit })
	const { data: groupsList } = useGetAllGroupsQuery({})

	const handleClose = () => {
		setShow(false)
	}

	const handleEditGroup = (groupId: number) => {
		navigate(`/groupmanager/editGroup/${groupId}`)
	}
	const handlePageChange = (currentPage: any) => {
		setPage(currentPage)
	}

	const handleShow = (item: GroupResponse) => {
		setData(item)
		setShow(true)
	}

	const checkVistingPage = (dataList: any) => {
		if (isSitesExist === false) {
			if (dataList && dataList?.length > 0) {
				setIsSitesExist(dataList?.length)
			} else {
				setIsSitesExist(0)
			}
		}
	}

	const cancel = () => {
		setDeleteModal(false)
		setDeleteCascade(false)
	}
	const handleCheckboxChange = (id: number) => {
		setSelected(id);
		setDeleteData(id);
	};
	const deleteSelectedGroup = async (id: number) => {
		try {
			const res = await deleteGroup(id).unwrap();
			toast.success(res.message);
			setDeleteModal(false);
		} catch (error: any) {
			if (!error?.data?.success) {
				toast.error(error?.data?.message);
			}
		}
	};

	const confirm = () => deleteSelectedGroup(deleteData);


	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Security Groups' })}</PageTitle>
			<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
				<div className='post d-flex flex-column-fluid' id='kt_post'>
					<div id='kt_content_container' className='container'>
						<div className='card'>
							<ConfirmationPopup
								deleteModal={deleteModal}
								cancel={cancel}
								confirm={confirm}
								deleteCascade={deleteCascade}
							/>
							<SecurityGroupHeader
								deleteDataArr={deleteDataArr}
								setDeleteModal={setDeleteModal}
								setCheck={setCheck}
							/>
							<div className='card-body py-4'>
								<p className={'text-dark'}>
									Decide which parts of <strong>AssetRack</strong> you want accessible to your users
									by assigning them to Security Groups. You can use and edit the predetermined
									groups or you can create your own custom security groups.
								</p>
								<div className='d-flex limit-options align-items-center mb-3'>
									<span className='text-muted'>Showing</span>
									<select
										className='form-select form-select-sm fw-bold mx-3 border-1'
										onChange={(e) => {
											setLimit(Number(e.target.value))
											setPage(Number(1))
										}}
										value={limit}
									>
										<option value='10'>10</option>
										<option value='20'>20</option>
										<option value='30'>30</option>
									</select>
									<span className='text-muted'>entries</span>
								</div>

								<div className='table-responsive pb-4 custom-table'>
									<table
										id='kt_table_users'
										className='table align-middle table-row-dashed table-row-gray-300 fs-6 gy-5 dataTable no-footer'
										role='table'
									>
										<thead>
											<tr className='text-start text-gray-800 border-bottom border-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
												<th role='columnheader' className='w-10px pe-2'>
												</th>
												<th role='columnheader' >
													{' '}
													Group Name{' '}
												</th>
												<th role='columnheader' >
													{' '}
													Group Description{' '}
												</th>
												<th role='columnheader' >
													{' '}
													Active Users{' '}
												</th>
												<th role='columnheader' >
													{' '}
													Actions{' '}
												</th>
											</tr>
										</thead>
										<tbody className='text-gray-600 fw-bol' role='rowgroup'>
											{groupsList?.groups?.length ? (
												groupsList?.groups?.map((item: GroupResponse, idx: number) => {
													return (
														<tr key={idx} role='row'>
															<td role='cell' >
																&nbsp;
															</td>
															<td role='cell' >
																<div >
																	<div className='text-truncate'>{item.name}</div>
																</div>
															</td>
															<td role='cell' >
																<div >
																	<div className='d-flex flex-column'>{item.description}</div>
																</div>
															</td>
															<td role='cell' >
																<div >
																	<div className='d-flex flex-column'>
																		{item.activeUser ? item.activeUser : '0 User'}
																	</div>
																</div>
															</td>

															<td role='cell' >
																<div className='dropdown'>
																	<button
																		className='dropdown-toggle'
																		type='button'
																		id='dropdownMenuButton1'
																		data-bs-toggle='dropdown'
																		aria-expanded='false'
																	>
																		Action
																		<ActionIcon />
																	</button>
																	<ul
																		className='dropdown-menu actions-dropdown'
																		aria-labelledby='dropdownMenuButton1'
																	>
																		<li onClick={() => handleEditGroup(item.id)}>
																			<button className='dropdown-item'>Edit</button>
																		</li>
																		<li
																			onClick={() => {
																				setDeleteModal(true);
																				setDeleteData(item.id);
																			}}
																		>
																			<button className='dropdown-item'>Delete</button>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													)
												})
											) : (
												<tr>
													<td colSpan={12}>
														<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
															No records found
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
							{isSitesExist === 0 ? (
								<div className='card-footer d-flex justify-content-end py-6 px-9'>
									<button
										onClick={() => {
											navigate(`/apps/setup/location`)
										}}
										className='btn btn-primary'
									>
										Continue
									</button>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SecurityGroupListing
