import { Images } from '_metronic/assets/Assets'
import { toAbsoluteUrlImage } from '_metronic/helpers'
import moment from 'moment'
interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
}

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, currencyLogo }) => {
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <div className='d-inline align-items-center asset-img-table'>
            {asset.photo ? (
              <img
                src={toAbsoluteUrlImage(asset.photo)}
                alt='Image'
                className='img-fluid'
                crossOrigin='anonymous'
              />
            ) : (
              <img src={Images.AssetsPlaceholder} alt='dummy' className='img-fluid' />
            )}
          </div>
        ))
      case 'Asset Tag ID':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset.assetTagId}</span>
        ))
      case 'Description':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset.description}</span>
        ))
      case 'Brand':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset.brand}</span>
        ))
      case 'Purchased Date':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{moment(asset?.purchasedDate).format('DD-MM-YYYY')}</span>
        ))
      case 'Cost':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.cost ? currencyLogo + ' ' + asset?.cost : ''}</span>
        ))
      case 'Status':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>
            <div
						className={`d-inline align-items-center px-3 py-2 rounded ${asset?.statusType === 'available'
							? 'bg-light-green'
							: asset?.statusType === 'lease'
								? 'bg-light-blue'
								: asset?.statusType === 'dispose'
									? 'bg-light-pink'
									: asset?.statusType === 'check_out'
										? 'bg-light-blue'
										: ''
							}`}
					>
						{asset?.statusType === 'check_out'
							? 'Checked out'
							: asset?.statusType === 'lease'
								? 'Leased'
								: asset?.statusType === 'dispose'
									? 'Disposed'
									: asset?.statusType === 'available'
										? 'Available'
										: asset?.statusType}
					</div>
          </span>
        ))
      case 'Created By':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.createdBy}</span>
        ))
      case 'Date Created':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{moment(asset?.createdAt).format('DD-MM-YYYY')}</span>
        ))
      case 'Model':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.model}</span>
        ))
      case 'Purchased From':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.purchasedFrom}</span>
        ))
      case 'Relation':
        return 'Sample Relation'
      case 'Serial No':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.serialNo}</span>
        ))
      case 'Reservation':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.reservation}</span>
        ))
      case 'Transact As Whole':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.transact_as_whole}</span>
        ))
      case 'Category':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.category?.category}</span>
        ))
      case 'Department':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.department?.department}</span>
        ))
      case 'Location':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.location?.location}</span>
        ))
      case 'Site':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.site?.name}</span>
        ))
      case 'Assigned To':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.site?.name}</span>
        ))
      case 'Event Date':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.evenDate}</span>
        ))
      case 'Event Due Date':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.evenDueDate}</span>
        ))
      case 'Event Notes':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.eventNotes}</span>
        ))
      case 'Leased To':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.assetslease?.customer?.fullName}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return 'Sample Photo'
      case 'Asset Tag ID':
        return 'Sample TagId'
      case 'Description':
        return 'Sample Description'
      case 'Brand':
        return 'Sample Brand'
      case 'Purchased Date':
        return 'Sample Purchased Date'
      case 'Cost':
        return 'RS 1000'
      case 'Status':
        return 'Sample Status'
      case 'Created By':
        return 'Sample userName'
      case 'Date_Created':
        return '12/2/2024'
      case 'Model':
        return 'Sample Model'
      case 'Purchased From':
        return 'Sample Purchased Location'
      case 'Relation':
        return 'Sample Relation'
      case 'Serial No':
        return 'Sr-12'
      case 'Date Created':
        return '20/20/2020'
      case 'Reservation':
        return 'Sample Reservation'
      case 'Transact As Whole':
        return 'Sample Transact'
      case 'Category':
        return 'Sample Category'
      case 'Department':
        return 'Sample Department'
      case 'Location':
        return 'Sample Location'
      case 'Site':
        return 'Sample Site'
      case 'Assigned To':
        return 'Sample Assigned'
      case 'Event Date':
        return '12/2/2024'
      case 'Event Due Date':
        return '25/2/2024'
      case 'Event Notes':
        return 'Sample Event Notes'
      case 'Leased To':
        return 'Sample Customer Name'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
