
function DashboardTab({ checked, handleCheckboxChange }) {
    return (
        <div className='mb-5'>
            <h4 className="mb-3">Filter Assets</h4>
            <p className="mb-5">Allow access of assets by site or category :</p>
            <div className="row">
                <p>
                    <label className='radio mb-0 d-inline-flex' htmlFor='allDashboardItems'>
                        <div>
                            <input
                                type='checkbox'
                                className='form-check-input custom-form-check-input sig_input me-2'

                                id='allDashboardItems'
                                name='hasCheckOut'
                                value='True'
                            />
                        </div>
                        <span className='vertically_align'>All Dashboard Items</span>
                    </label>
                </p>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-4 co-sm-6">
                    <div className="table-responsive mb-4">
                        <table className="table table-bordered mb-4">
                            <thead className="thead-light  light-yellow-table">
                                <tr>
                                    <th>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='widgets'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='widgets'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Widgets</span>
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>All Assets</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='NumberOfAssets'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='NumberOfAssets'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Number of Assets</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='ValueOfAssets'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='ValueOfAssets'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Value of Assets</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='netAssetValue'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='netAssetValue'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Net Asset Value (NAV)</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='purchaseFiscalYear'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='purchaseFiscalYear'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Purchase in Fiscal Year</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='fundsSpent'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='fundsSpent'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Funds / Spent</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='underInsurance'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='underInsurance'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Under Insurance</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='underWarranty'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='underWarranty'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Under Warranty</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Asset Statuses</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusAvailable'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusAvailable'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Available</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusCheckOut'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusCheckOut'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Check-Out</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusLeased'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusLeased'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Leased</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusLost'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusLost'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Lost</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusUnderRepair'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusUnderRepair'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Under Repair</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusBroken'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusBroken'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Broken</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusDisposed'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusDisposed'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Disposed</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusDonated'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusDonated'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Donated</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='statusSold'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='statusSold'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Sold</span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 co-sm-6">
                    <div className="table-responsive mb-4">
                        <table className="table table-bordered mb-4">
                            <thead className="thead-light  light-yellow-table">
                                <tr>
                                    <th>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='charts'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='charts'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Charts</span>
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Asset Value</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='byDepartment'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='byDepartment'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="las la-chart-bar me-1 fs-3"></i> By Department</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='byDepartment'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='byDepartment'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="fas fa-chart-pie me-1 fs-3"></i> By Department</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='byCategory'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='byCategory'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="las la-chart-bar me-1 fs-3"></i> By Category</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='byCategory'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='byCategory'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="fas fa-chart-pie me-1 fs-3"></i> By Category</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='bySiteLocation'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='bySiteLocation'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="las la-chart-bar me-1 fs-3"></i> By Site / Location</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='bySiteLocation'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='bySiteLocation'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="fas fa-chart-pie me-1 fs-3"></i> By Site / Location</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='byStatus'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='byStatus'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="las la-chart-bar me-1 fs-3"></i> By Status</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='byStatus'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='byStatus'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="fas fa-chart-pie me-1 fs-3"></i> By Status</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='byFundingBudget'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='byFundingBudget'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'><i className="las la-chart-bar me-1 fs-3"></i> By Funding / Budget</span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 co-sm-6">
                    <div className="table-responsive mb-4">
                        <table className="table table-bordered mb-4">
                            <thead className="thead-light  light-yellow-table">
                                <tr>
                                    <th>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='feeds'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Feeds'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Feeds</span>
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newAssets'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='newAssets'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>New Assets</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='checkout'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='checkout'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Checked-out</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='checkin'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='checkin'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Checked in</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='Leased'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Leased'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Leased</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='leasedReturn'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='leasedReturn'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Leased Return</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='Broken'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Broken'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Broken</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='disposed'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='disposed'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Disposed</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='donated'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='donated'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Donated</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='Lost'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Lost'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Lost</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='Found'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Found'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Found</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='Sold'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Sold'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Sold</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='Under Repair'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Under Repair'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Under Repair</span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 co-sm-6">
                    <div className="table-responsive mb-4">
                        <table className="table table-bordered mb-4">
                            <thead className="thead-light  light-yellow-table">
                                <tr>
                                    <th>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='Calendars'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='Calendars'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Calendars</span>
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Alerts</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='maintenanceDue'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='maintenanceDue'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Maintenance Due</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='contractsExpiring'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='contractsExpiring'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Contracts Expiring</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='insuranceExpiring'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='insuranceExpiring'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Insurance Expiring</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='warrantyExpiring'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='warrantyExpiring'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>warrantyExpiring</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='fundsExpiring'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='fundsExpiring'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>fundsExpiring</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='LeaseExpiring'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='LeaseExpiring'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Lease Expiring</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Assets Due</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='assetsDue'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='assetsDue'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Assets Due</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='reservations'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                    
                                                    id='reservations'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Reservations</span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardTab