import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { User } from '../../Core/_models'
import { Link } from 'react-router-dom'

type Props = {
	user: User
}

const UserInfoCell: FC<Props> = ({ user }) => (
	<div className='d-flex align-items-center'>
		<div className='symbol   symbol-50px overflow-hidden me-3'>
			<Link to='/dummy-link'>
				{user.avatar ? (
					<div className='symbol-label'>
						<img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' />
					</div>
				) : (
					<div
						className={clsx(
							'symbol-label fs-3',
							`bg-light-${user.initials?.state}`,
							`text-${user.initials?.state}`
						)}
					>
						{user.initials?.label}
					</div>
				)}
			</Link>
		</div>
		<div className='d-flex flex-column'>
			<Link to='/dummy-link' className='text-gray-800 text-hover-primary mb-1'>
				{user.name}
			</Link>
			<span>{user.email}</span>
		</div>
	</div>
)

export { UserInfoCell }
