import React, { useEffect, useState } from 'react'
import { toAbsoluteUrlImage } from '../../../../../../_metronic/helpers'
import { IProfileDetails, profileDetailsInitValues as initialValues } from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuth} from '../../../../Auth'
import {toast} from 'react-toastify'
import { Images } from '_metronic/assets/Assets'
import Localization from './Localization'
import {useNavigate} from 'react-router-dom'
import { useAddSubUserMutation } from 'App/Modules/Services/SubUser'
import { useDeleteProfileImageMutation } from 'App/Modules/Services/Profile'


const imageMimeType = /image\/(png|jpg|jpeg)/i
const addSubUserSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(3, 'First name must be at least 3 characters')
		.required('First name is required')
		.nullable(),

	lastName: Yup.string()
		.min(3, 'Last name must be at least 3 characters')
		.required('Last name is required')
		.nullable(),
	title: Yup.string().min(5, 'Title must be at least 5 characters').nullable(),
	phone: Yup.string().required('Phone is required').nullable(),
	email: Yup.string()
		.matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address')
		.max(50, 'Maximum 50 characters')
		.required('Email is required'),
	timeZone: Yup.string().required('Time zone is required').nullable(),
	dateFormat: Yup.string().required('Date Format is required').nullable(),
	timeFormat: Yup.string().required('Time Format is required').nullable(),
})

const AddSubUser = ({ userData, selectedGroupName }) => {
	const navigate = useNavigate()
	const { currentUser, setCurrentUser } = useAuth()
	const [loading, setLoading] = useState(false)
	const [image, setImage] = useState<string>('')
	const [file, setFile] = useState(null)
	const [fileDataURL, setFileDataURL] = useState<string | ArrayBuffer | null | undefined>(null)
	const [deleteProfileImage] = useDeleteProfileImageMutation()
	const [addSubUser] = useAddSubUserMutation()
	const changeHandler = (e) => {
		const file = e.target.files[0]
		if (!file.type.match(imageMimeType)) {
			toast.error('This file format is not allowed!')
			return
		} else {
			setFile(file)
			const reader = new FileReader()
			reader.onload = (event) => {
				setFileDataURL(event?.target?.result)
			}
			reader.readAsDataURL(file)
		}
	}
	useEffect(() => {
		let fileReader,
			isCancel = false
		if (file) {
			fileReader = new FileReader()
			fileReader.onload = (e) => {
				const { result } = e.target
				if (result && !isCancel) {
					setFileDataURL(result)
				}
			}
			fileReader.readAsDataURL(file)
		}
		return () => {
			isCancel = true
			if (fileReader && fileReader.readyState === 1) {
				fileReader.abort()
			}
		}
	}, [file])

	useEffect(() => {
		if (currentUser) {
			if (userData) {
				setCurrentUser(userData?.user)
				formik.setValues({
					image: '',
					firstName: '',
					lastName: '',
					title: '',
					phone: '',
					email: '',
					timeZone: '',
					dateFormat: '',
					timeFormat: '',
					groupName: ''
				})
			}
		}
	}, [userData])

	const formik = useFormik<IProfileDetails>({
		initialValues,
		validationSchema: addSubUserSchema,
		onSubmit: async (values) => {
			setLoading(true)
			try {
				const formData = new FormData()
				for (const key of Object.keys(values)) {
					if (Object.prototype.hasOwnProperty.call(values, key)) {
						if (values) {
							values.groupName = selectedGroupName
							const value = values[key as keyof IProfileDetails]
							formData.append(key, value)
						}
					}
				}
				const { data }: any = await addSubUser(formData)
				toast.success(data?.message)
				navigate('/users')
			} catch (error: any) {
				toast.error(error?.data?.message)
			} finally {
				setLoading(false)
			}
		},
	})

	const deleteImage = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e?.preventDefault()
		try {
			const data = await deleteProfileImage('').unwrap()
			if (data?.success) {
				setFileDataURL(null)
				toast.success(data?.message)
			}
		} catch (error: any) {
			if (!error?.data?.success) {
				toast.error(error?.data?.message)
			}
		}
	}

	return (
		<div className='card mb-5 mb-xl-10'>
			<div className='card-header border-0'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'>Add-User</h3>
				</div>
			</div>
			<div id='kt_account_profile_details' className='collapse show'>
				<form onSubmit={formik.handleSubmit} noValidate className='form'>
					<div className='card-body border-top p-lg-9 p-md-7 p-6'>
						<h3 className='card-title mb-4'>
							<span className='me-2 align-middle'>
								<i className='las la-user fs-1' aria-hidden='true'></i>
							</span>
							<span className='card-label fw-bold fs-3 mb-1'>User</span>
						</h3>
						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
							<div className='col-lg-8'>
								<div className='image-input image-input-outline' data-kt-image-input='true'>
									<label htmlFor='photo' className='cursor-pointer position-relative'>
										<img
											className='image-input-wrapper w-125px h-125px'
											src={
												fileDataURL
													? fileDataURL.toString()
													: image
														? toAbsoluteUrlImage(image)
														: Images.Avatar_JPG
											}
											alt='profile'
											crossOrigin='anonymous'
										/>
										<span className="edit-icon">
											<i className="las la-camera fs-2"></i>
										</span>
										{image ? (
											<span onClick={(e) => deleteImage(e)} className='delete-icon'>
												<i className='las la-trash-alt fs-2'></i>
											</span>
										) : null}
									</label>
									<input
										className='form-control d-none'
										type='file'
										name='image'
										id='photo'
										accept='.png, .jpg, .jpeg'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											changeHandler(e)
											formik.setFieldValue(
												'image',
												e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType)
													? e.target.files![0]
													: image
											)
										}}
									/>
								</div>
							</div>
						</div>
						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
							<div className='col-lg-8'>
								<div className='row'>
									<div className='col-sm-6 fv-row'>
										<input
											type='text'
											className='form-control  form-control-solid mb-3 mb-lg-0'
											placeholder='First name'
											{...formik.getFieldProps('firstName')}
										/>
										{formik.touched.firstName && formik.errors.firstName && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>{formik.errors.firstName}</div>
											</div>
										)}
									</div>

									<div className='col-sm-6 fv-row'>
										<input
											type='text'
											className='form-control  form-control-solid'
											placeholder='Last name'
											{...formik.getFieldProps('lastName')}
										/>
										{formik.touched.lastName && formik.errors.lastName && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>{formik.errors.lastName}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>Title</label>
							<div className='col-lg-8 fv-row'>
								<input
									type='text'
									className='form-control  form-control-solid'
									placeholder='Title'
									{...formik.getFieldProps('title')}
								/>
								{formik.touched.title && formik.errors.title && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.title}</div>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>
								<span className='required'>Phone</span>
							</label>
							<div className='col-lg-8 fv-row'>
								<input
									type='tel'
									className='form-control  form-control-solid'
									placeholder='Phone number'
									{...formik.getFieldProps('phone')}
								/>
								{formik.touched.phone && formik.errors.phone && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.phone}</div>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>
								<span className='required'>Email</span>
							</label>

							<div className='col-lg-8 fv-row'>
								<input
									type='tel'
									className='form-control  form-control-solid'
									placeholder='Email Address'
									{...formik.getFieldProps('email')}
								/>
								{formik.touched.email && formik.errors.email && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.email}</div>
									</div>
								)}
							</div>
						</div>
						<Localization formik={formik} />
					</div>

					<div className='card-footer d-flex justify-content-end py-6 px-9'>
						<button type='submit' className='btn btn-primary main-btn-style' disabled={loading}>
							{!loading && 'Add User'}
							{loading && (
								<span className='indicator-progress d-block'>
									Please wait...{' '}
									<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export { AddSubUser }
