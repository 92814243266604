import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {PageTitle} from '_metronic/layout/core'
import AssetsTab from './AssetsTab'
import PrivilegesTab from './PrivilegesTab'
import ReportsTab from './ReportsTab'
import DashboardTab from './DashboardTab'
import AdminRights from './AdminRights'
import {useAddGroupsMutation , useUpdateGroupMutation , useGetGroupQuery} from 'App/Modules/Services/groups'
import { GroupDetails } from 'App/Modules/Accounts/Components/Settings/Cards/GroupsModel'
import {useNavigate , useParams} from 'react-router-dom'
import { Link } from 'react-router-dom'
const createGroupSchema = Yup.object().shape({
  name: Yup.string().required('Group name is required'),
  description: Yup.string(),
})
interface CheckedState {
  [key: string]: any
}
function CreateGroup() {
  const navigate = useNavigate()
  const { id } = useParams(); 
  const isEditMode = !!id; 
  const [loading, setLoading] = useState(false)
  const [addGroup] = useAddGroupsMutation()
  const [updateGroup] = useUpdateGroupMutation()
  const [checked, setChecked] = useState<CheckedState>({})

  const handleCheckboxChange = (name: string, value: any) => {
    setChecked({
      ...checked,
      [name]: value,
    })
  }

  const formik = useFormik<GroupDetails>({
    initialValues: {
      name: '',
	  description:''
    },

    validationSchema: createGroupSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const data1 = {name: values.name, description: values.description}
        for (const key in checked) {
          if (checked.hasOwnProperty(key)) {
            data1[key] = checked[key]
          }
        }
        if (isEditMode) {
          const {data}: any = await updateGroup({data1 , id} )

          toast.success(data?.message)
          navigate('/groupmanager')
        }
        else{
          const {data}: any = await addGroup(data1)
          toast.success(data?.message)
          navigate('/groupmanager')
        }
       
      } catch (error: any) {
        toast.error(error?.data?.message)
      } finally {
        setLoading(false)
      }
    },
  })


  const { data: groupDetails } = useGetGroupQuery(id, { skip: !isEditMode });
    useEffect(() => {
    if (groupDetails) {
      formik.setValues({
        name: groupDetails?.group?.name || '',
        description: groupDetails?.group?.description || '',
      });
    }
  }, [groupDetails]);
  const intl = useIntl()
  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Add Users'})}</PageTitle>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <form onSubmit={formik.handleSubmit}>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row mb-5 mb-xl-10'>
                    <div className='offset-2 col-lg-8'>
                      <div className='row mb-6'>
                        <div className='col-lg-3 col-form-label fw-bold fs-6 required'>
                          Group Name
                        </div>
                        <div className='col-lg-9 fv-row'>
                          <input
                            className='form-control  form-control-solid flex-1'
                            placeholder='Group Name'
                            {...formik.getFieldProps('name')}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.name}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-lg-3 col-form-label fw-bold fs-6'>Description</div>
                        <div className='col-lg-9 fv-row'>
                          <input
                            className='form-control  form-control-solid flex-1'
                            placeholder='Description'
                            {...formik.getFieldProps('description')}
                          />
                          {formik.touched.description && formik.errors.description && (
                            <div>{formik.errors.description}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='pt-5'>
                    <ul
                      className='nav nav-tabs view-assets-tabs mb-5 justify-content-start'
                      id='myTab'
                      role='tablist'
                    >
                      <li className='nav-item me-2' role='presentation'>
                        <button
                          className='nav-link fs-6 fw-bold col-form-label active'
                          id='assets-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#assets'
                          type='button'
                          role='tab'
                          aria-controls='assets'
                          aria-selected='true'
                        >
                          Assets
                        </button>
                      </li>
                      <li className='nav-item me-2' role='presentation'>
                        <button
                          className='nav-link fs-6 fw-bold col-form-label'
                          id='privileges-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#privileges'
                          type='button'
                          role='tab'
                          aria-controls='privileges'
                          aria-selected='false'
                        >
                          Privileges
                        </button>
                      </li>
                      <li className='nav-item me-2' role='presentation'>
                        <button
                          className='nav-link fs-6 fw-bold col-form-label'
                          id='reports-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#reports'
                          type='button'
                          role='tab'
                          aria-controls='reports'
                          aria-selected='false'
                        >
                          Reports
                        </button>
                      </li>
                      <li className='nav-item me-2' role='presentation'>
                        <button
                          className='nav-link fs-6 fw-bold col-form-label'
                          id='dashboard-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#dashboard'
                          type='button'
                          role='tab'
                          aria-controls='dashboard'
                          aria-selected='false'
                        >
                          Dashboard
                        </button>
                      </li>
                      <li className='nav-item me-2' role='presentation'>
                        <button
                          className='nav-link fs-6 fw-bold col-form-label'
                          id='admin-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#admin'
                          type='button'
                          role='tab'
                          aria-controls='admin'
                          aria-selected='false'
                        >
                          Admin Rights
                        </button>
                      </li>
                    </ul>
                    <div className='tab-content' id='myTabContent'>
                      <div
                        className='tab-pane fade show active'
                        id='assets'
                        role='tabpanel'
                        aria-labelledby='assets-tab'
                      >
                        <div className='tab-content'>
                          <AssetsTab
                            checked={groupDetails?.group}
                            handleCheckboxChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='privileges'
                        role='tabpanel'
                        aria-labelledby='privileges-tab'
                      >
                        <div className='tab-content'>
                          <PrivilegesTab checked={groupDetails?.group}
                            handleCheckboxChange={handleCheckboxChange}/>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='reports'
                        role='tabpanel'
                        aria-labelledby='reports-tab'
                      >
                        <div className='tab-content'>
                          <ReportsTab checked={groupDetails?.group}
                            handleCheckboxChange={handleCheckboxChange}/>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='dashboard'
                        role='tabpanel'
                        aria-labelledby='dashboard-tab'
                      >
                        <div className='tab-content'>
                          <DashboardTab checked={groupDetails?.group}
                            handleCheckboxChange={handleCheckboxChange}/>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='admin'
                        role='tabpanel'
                        aria-labelledby='admin-tab'
                      >
                        <div className='tab-content'>
                          <AdminRights checked={groupDetails?.group}
                            handleCheckboxChange={handleCheckboxChange}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer right notForWizard'>
                  <div className='row'>
                    <div className='col-md-12 text-end'>
                      <Link
                        to='/groupmanager'
                        className='btn btn-light-secondary main-btn-style me-2'
                      >
                        Cancel
                      </Link>
                      <button className='btn btn-primary' type='submit' disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}{' '}
                      </button>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateGroup
