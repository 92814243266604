import { toAbsoluteUrlImage } from '_metronic/helpers'
import moment from 'moment'
import { dateFormat } from 'config'
import { Images } from '_metronic/assets/Assets'

interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
  assetMainteneces?: any
}

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, currencyLogo }) => {
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <div className='d-inline align-items-center asset-img-table'>
            {asset?.userasset?.photo ? (
              <img
                src={toAbsoluteUrlImage(asset?.userasset?.photo)}
                alt='Image'
                className='img-fluid'
                crossOrigin='anonymous'
              />
            ) : (
              <img src={Images.AssetsPlaceholder} alt='dummy' className='img-fluid' />
            )}
          </div>
        ))
      case 'Asset Tag ID':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.assetTagId}</span>
        ))
      case 'Description':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset.userasset.description}</span>
        ))
      case 'Brand':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset.userasset.brand}</span>
        ))
      case 'Purchased Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{moment(asset?.purchasedDate).format('DD-MM-YYYY')}</span>
        ))
      case 'Cost':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.cost ? currencyLogo + ' ' + asset?.cost : ''}</span>
        ))
      case 'Status':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.statusType}</span>
        ))
      case 'Created By':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.createdBy}</span>
        ))
      case 'Date Created':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{moment(asset?.userasset?.createdAt).format('DD-MM-YYYY')}</span>
        ))
      case 'Model':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.model}</span>
        ))
      case 'Purchased From':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.purchasedFrom}</span>
        ))
      case 'Serial No':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.serialNo}</span>
        ))
      case 'Reservation':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.reservation}</span>
        ))
      case 'Transact As Whole':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.transact_as_whole}</span>
        ))
      case 'Category':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.category?.category}</span>
        ))
      case 'Department':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.department?.department}</span>
        ))
      case 'Location':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.location?.location}</span>
        ))
      case 'Site':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.site?.name}</span>
        ))
      case 'Assigned To':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.site?.name}</span>
        ))
      case 'Event Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.eventDate}</span>
        ))
      case 'Event Due Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.eventDueDate}</span>
        ))
      case 'Event Notes':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.eventNotes}</span>
        ))
      case 'Leased To':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.assetslease?.customer?.fullName}</span>
        ))
      case 'Title':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceTitle}</span>
        ))
      case 'Expires':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{dateFormat(asset?.maintenanceDueDate)}</span>
        ))
      case 'Frequency':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceFrequency}</span>
        ))
      case 'Is Repeating':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceReapting}</span>
        ))
      case 'Maintenance By':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceBy}</span>
        ))
      case 'Maintenance Completion Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{dateFormat(asset?.maintenanceDateCompleted)}</span>
        ))
      case 'Maintenance Cost':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceCost}</span>
        ))
      case 'Maintenance Detail':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceDetails}</span>
        ))
      case 'Recur on every':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.recureOnEvery}</span>
        ))
      case 'Maintenance Status':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceStatus}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return 'Sample Photo'
      case 'Asset Tag ID':
        return 'Sample TagId'
      case 'Description':
        return 'Sample Description'
      case 'Brand':
        return 'Sample Brand'
      case 'Purchased Date':
        return 'Sample Purchased Date'
      case 'Cost':
        return 'RS 1000'
      case 'Status':
        return 'Sample Status'
      case 'Created By':
        return 'Sample userName'
      case 'Date_Created':
        return '12/2/2024'
      case 'Model':
        return 'Sample Model'
      case 'Purchased From':
        return 'Sample Purchased Location'
      case 'Relation':
        return 'Sample Relation'
      case 'Serial No':
        return 'Sr-12'
      case 'Date Created':
        return '20/20/2020'
      case 'Reservation':
        return 'Sample Reservation'
      case 'Transact As Whole':
        return 'Sample Transact'
      case 'Category':
        return 'Sample Category'
      case 'Department':
        return 'Sample Department'
      case 'Location':
        return 'Sample Location'
      case 'Site':
        return 'Sample Site'
      case 'Assigned To':
        return 'Sample Assigned'
      case 'Event Date':
        return '12/2/2024'
      case 'Event Due Date':
        return '25/2/2024'
      case 'Event Notes':
        return 'Sample Event Notes'
      case 'Leased To':
        return 'Sample Customer Name'
      case 'Title':
        return 'Sample Title'
      case 'Expires':
        return '25/2/2024'
      case 'Frequency':
        return 'Sample Frequency'
      case 'Is Repeating':
        return 'Weekly'
      case 'Maintenance By':
        return 'Sample Company Name'
      case 'Maintenance Completion Date':
        return '25/2/2024'
      case 'Maintenance Cost':
        return '500'
      case 'Maintenance Detail':
        return 'Sample Deatils'
      case 'Recur on every':
        return 'Sample'
      case 'Maintenance Status':
        return 'In-Progress'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
