import { toAbsoluteUrl } from '../../../../_metronic/helpers'


export function TermsCondition() {


	return (
    <div
			className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
		>
      <h1 className='text-center mt-5'>Terms and Conditions</h1>
      </div>
	)
}
