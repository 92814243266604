import { FC, PropsWithChildren } from 'react'
import { HeaderProps } from 'react-table'
import { useListView } from '../../Core/ListViewProvider'
import { User } from '../../Core/_models'

type Props = {
	tableProps: PropsWithChildren<HeaderProps<User>>
}

const UserSelectionHeader: FC<Props> = ({ tableProps }) => {
	const { isAllSelected, onSelectAll } = useListView()
	return (
		<th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
			<div className='me-3'>
				<input
					className='form-check-input custom-form-check-input'
					type='checkbox'
					data-kt-check={isAllSelected}
					data-kt-check-target='#kt_table_users .form-check-input'
					checked={isAllSelected}
					onChange={onSelectAll}
				/>
			</div>
		</th>
	)
}

export { UserSelectionHeader }
