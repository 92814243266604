
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl, toAbsoluteUrlImage } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../_metronic/partials'
import { useLocation } from 'react-router-dom'
import { checkUserUnauthorized, getAuth, useAuth } from '../Auth'
import { StatisticsWidget2 } from '../../../_metronic/partials/widgets'
import { getAccountDetail } from './AccountDetailsList/Core/_requests'
import { AccountDetail } from './AccountDetailsList/Core/_models'
import { string } from 'yup'
import SplashScreen from 'App/SplashScreen'
import { toast } from 'react-toastify'

const AccountDetailHeader: React.FC = () => {
	const location = useLocation()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const auth = getAuth()
	const [account, setAccount] = useState<AccountDetail>()
	const [loader, setLoader] = useState(false)
	useEffect(() => {
		if (currentUser) {
			getAccountDetailFunc()
		}
	}, [])

	const getAccountDetailFunc = async () => {
		try {
			setLoader(true)
			const res = await getAccountDetail(auth?.token)
			if (res?.data?.success) {
				setAccount(res?.data?.accountDetails)
			}
			setLoader(false)
		} catch (err: any) {
			checkUserUnauthorized(err?.response?.data, saveAuth, setCurrentUser, toast)
		}
	}

	const formatDate = (date) => {
		const originalDate = new Date(date);
		const formattedDate = originalDate.toLocaleDateString('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		});
		return formattedDate;
	}

	return (
		<>
			<SplashScreen isLoadingTemplate={loader} />
			{!loader ?
				<div className='row g-5 g-xl-8 mb-5'>
					<div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
						<div className="card  h-100">
							<div className='account_details_card d-flex px-3 py-5 justify-content-start' >
								<div className='account_details_icon me-2'>
									<i className="la la-user fs-2" aria-hidden="true"></i>
								</div>
								<div className='account_details_desc'>
									<div className=" mb-2">Account No.</div>
									<div><strong>{account?.accountNo ? account?.accountNo : "N/A"}</strong></div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
						<div className="card  h-100">
							<div className='account_details_card d-flex px-3 py-5 justify-content-start' >
								<div className='account_details_icon me-2'>
									<i className="la la-dollar fs-2" aria-hidden="true"></i>
								</div>
								<div className='account_details_desc'>
									<div className=" mb-2">Balance:</div>
									<div><strong>${account?.balance}</strong></div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
						<div className="card  h-100">
							<div className='account_details_card d-flex px-3 py-5 justify-content-start' >
								<div className='account_details_icon me-2'>
									<i className="la la-user-clock fs-2" aria-hidden="true"></i>
								</div>
								<div className='account_details_desc'>
									<div className=" mb-2">Account Status:</div>
									<div><strong>{account?.accountStatus ? account?.accountStatus : "N/A"}</strong></div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
						<div className="card  h-100">
							<div className='account_details_card d-flex px-3 py-5 justify-content-start' >
								<div className='account_details_icon me-2'>
									<i className="la la-puzzle-piece fs-2" aria-hidden="true"></i>
								</div>
								<div className='account_details_desc'>
									<div className=" mb-2">No. of Assets:</div>
									<div><strong>{account?.assetCount ? account?.assetCount : "N/A"}</strong></div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
						<div className="card  h-100">
							<div className='account_details_card d-flex px-3 py-5 justify-content-start' >
								<div className='account_details_icon me-2'>
									<i className="la la-calendar fs-2" aria-hidden="true"></i>
								</div>
								<div className='account_details_desc'>
									<div className=" mb-2">Account Since:</div>
									<div><strong>{account?.createdAt ? formatDate(account?.createdAt) : "N/A"}</strong></div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
						<div className="card  h-100">
							<div className='account_details_card d-flex px-3 py-5 justify-content-start' >
								<div className='account_details_icon me-2'>
									<i className="la la-calendar-check fs-1" aria-hidden="true"></i>
								</div>
								<div className='account_details_desc'>
									<div className=" mb-2">Last Active:</div>
									<div><strong>{account?.lastActive ? formatDate(account?.lastActive) : "N/A"}</strong></div>
								</div>
							</div>
						</div>
					</div>
				</div> : null
			}
		</>
	)
}

export { AccountDetailHeader }
