import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const sitesApi = createApi({
  reducerPath: 'sitesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/site/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Sites'],
  endpoints: (build) => ({
    getAllSites: build.query<any, any>({
      query: ({ page = 0, limit = 0 }) => ({
        url: `list?page=${page}&limit=${limit}`,
        method: 'GET',
      }),
      providesTags: ['Sites'],
    }),
    getlistOfSites: build.query<any, any>({
      query: () => ({
        url: `list`,
        method: 'GET',
      }),
      providesTags: ['Sites'],
    }),
    addSite: build.mutation<any, any>({
      query: (body) => ({
        url: `create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Sites' }],
    }),
    updateSite: build.mutation<any, { siteId: any; values: any }>({
      query: ({ siteId, values }) => ({
        url: `update/${siteId}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { siteId }) => [{ type: 'Sites', siteId }],
    }),
    deleteSites: build.mutation<any, { cascade: number; siteIds: object }>({
      query({ cascade, siteIds }) {
        return {
          url: `delete-all?cascade=${cascade}`,
          method: 'DELETE',
          body: siteIds,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(assetsApi.util.invalidateTags(['Assets']))
        })
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Sites', userId }],
    }),
  }),
})

export const {
  useGetlistOfSitesQuery,
  useGetAllSitesQuery,
  useAddSiteMutation,
  useUpdateSiteMutation,
  useDeleteSitesMutation,
} = sitesApi
