import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import "./ContactUs.css"

function ContactUs() {
    return (
        <div className="contact-us" id='contact'>
            <h2 className="mb-lg-10 mb-5">Contact Us</h2>
            <Form>
                <Form.Group className="input-wrap position-relative mb-lg-4 mb-3" controlId="formBasicName">
                    <Form.Control type="text" placeholder="Your name" />
                    <span className='icon'><i className='las la-user'></i></span>
                </Form.Group>
                <Form.Group className="input-wrap position-relative mb-lg-4 mb-3" controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Your email" />
                    <span className='icon'><i className='las la-envelope'></i></span>
                </Form.Group>
                <FloatingLabel controlId="floatingTextarea2" label="Your message" className="input-wrap mb-lg-5 mb-4">
                    <Form.Control as="textarea" placeholder="Your message" />
                </FloatingLabel>
                <div className="text-end mb-lg-5 mb-4">
                    <button className="btn main-btn main-btn-yellow" type="submit">Submit</button>
                </div>
            </Form>
        </div>
    )
}

export default ContactUs