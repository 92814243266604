import React, { useState } from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import ReactDatePicker from 'react-datepicker'
import { useGetAllSitesQuery } from '../../../../Services/Sites'
import { useAuth } from '../../../../Auth'
import { useGetAllCategoriesQuery } from '../../../../Services/Categories'
import { useGetAllLocationsQuery } from '../../../../Services/Locations'
import { useGetAllDepartmentsQuery } from '../../../../Services/Departments'
import { KTSVG } from '../../../../../../_metronic/helpers'
import moment from 'moment'
import { Link } from 'react-router-dom'

const AssetsSearch = (props: any) => {
  const { currentUser } = useAuth()
  const [searchFields, setSearchFields] = useState<any[]>([])
  const customSelect = 'custom-multiselect'
  const [statusTypes, setStatusTypes] = useState<any[]>([])
  const [dateRange, setDateRange] = useState<any[]>([null, null])
  const [startDate, endDate] = dateRange
  const [quickDate, setQuickDate] = useState<any>(0)
  const { data: siteData } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: categoryData } = useGetAllCategoriesQuery({ userId: currentUser?.id, page: 1 })
  const { data: locationData } = useGetAllLocationsQuery(currentUser?.id)
  const { data: departmentData } = useGetAllDepartmentsQuery({ userId: currentUser?.id })

  const handleSubmit = () => { }

  const searchFieldsOptions = [
    { label: 'All', value: '*' },
    { value: 'assetTagId', label: 'Asset Tag ID' },
    { value: 'brand', label: 'Brand' },
    { value: 'description', label: 'Description' },
    { value: 'model', label: 'Model' },
    { value: 'purchasedFrom', label: 'Purchased from' },
    { value: 'serialNo', label: 'Serial No' },
  ]

  const statusTypesOptions = [
    { label: 'All', value: '*' },
    { value: 'available', label: 'Available' },
    { value: 'broken', label: 'Broken' },
    { value: 'checked_out', label: 'Check out' },
    { value: 'check_in', label: 'Check in' },
    { value: 'dispose', label: 'Dispose' },
    { value: 'donate', label: 'Donate' },
    { value: 'lease', label: 'Lease' },
    { value: 'lease_return', label: 'Lease return' },
    { value: 'lost', label: 'Lost/Missing' },
    { value: 'found', label: 'Found' },
    { value: 'reserved', label: 'Reserved' },
    { value: 'sold', label: 'Sold' },
    { value: 'under_repair', label: 'Under repair' },
  ]

  function onChangeSearchFields(value, event) {
    if (event.action === 'select-option' && event.option.value === '*') {
      setSearchFields(searchFieldsOptions)
      value = searchFieldsOptions.slice(1)
    } else if (event.action === 'deselect-option' && event.option.value === '*') {
      setSearchFields([])
      value = []
    } else if (event.action === 'deselect-option') {
      setSearchFields(value.filter((o) => o.value !== '*'))
    } else if (value.length === searchFieldsOptions.length - 1) {
      setSearchFields(searchFieldsOptions)
    } else {
      setSearchFields(value)
    }
    props.handleSearchAssets({ name: event.name, value: value })
  }

  function onChangeStatus(value, event) {
    if (event.action === 'select-option' && event.option.value === '*') {
      setStatusTypes(statusTypesOptions)
      value = statusTypesOptions.slice(1)
    } else if (event.action === 'deselect-option' && event.option.value === '*') {
      setStatusTypes([])
      value = []
    } else if (event.action === 'deselect-option') {
      setStatusTypes(value.filter((o) => o.value !== '*'))
    } else if (value.length === statusTypesOptions.length - 1) {
      setStatusTypes(statusTypesOptions)
    } else {
      setStatusTypes(value)
    }
    props.handleSearchAssets({ name: event.name, value: value })
  }

  const handleQuickDateRange = (e) => {
    let startDate = ''
    let endDate = ''
    let selectedValue = e.target.value
    setQuickDate(selectedValue)
    setDateRange([null, null])
    if (selectedValue === 'CurrentMonth') {
      const today = moment()
      startDate = today.startOf('month').format('YYYY-MM-DD')
      endDate = today.endOf('month').format('YYYY-MM-DD')
    } else if (selectedValue === 'PreviousMonth') {
      const lastMonth = moment().subtract(1, 'months')
      startDate = lastMonth.startOf('month').format('YYYY-MM-DD')
      endDate = lastMonth.endOf('month').format('YYYY-MM-DD')
    } else if (selectedValue === 'CurrentQuarter') {
      const currentQuarter = moment().quarter()
      startDate = moment().quarter(currentQuarter).startOf('quarter').format('YYYY-MM-DD')
      endDate = moment().quarter(currentQuarter).endOf('quarter').format('YYYY-MM-DD')
    } else if (selectedValue === 'PreviousQuarter') {
      const lastQuarter = moment().subtract(1, 'quarters').quarter()
      startDate = moment().quarter(lastQuarter).startOf('quarter').format('YYYY-MM-DD')
      endDate = moment().quarter(lastQuarter).endOf('quarter').format('YYYY-MM-DD')
    } else if (selectedValue === '2023' || /^\d{4}$/.test(selectedValue)) {
      const selectedYear = parseInt(selectedValue)
      startDate = moment([selectedYear, 0]).format('YYYY-MM-DD')
      endDate = moment([selectedYear, 11]).endOf('month').format('YYYY-MM-DD')
    }

    props.handleSearchAssets({ name: 'customDate', update: [startDate, endDate] })
  }

  return (
    <div className={`${!props.showSearch && 'd-none'}`}>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className={`container`}>
            <div className='card card-custom gutter-b'>
              <div className='card-body'>
                <div className='form_section'>
                  <div className='form-title fw-bold fs-4 pb-3 d-flex align-items-center'>
                    <span className='fs-5 pb-1 me-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='17px'
                        height='17px'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill='#ff0000'
                          fill-rule='evenodd'
                          d='M5.29289,3.70711 C4.90237,3.31658 4.90237,2.68342 5.29289,2.29289 C5.68342,1.90237 6.31658,1.90237 6.70711,2.29289 L11.7071,7.29289 C12.0976,7.68342 12.0976,8.31658 11.7071,8.70711 L6.70711,13.7071 C6.31658,14.0976 5.68342,14.0976 5.29289,13.7071 C4.90237,13.3166 4.90237,12.6834 5.29289,12.2929 L9.58579,8 L5.29289,3.70711 Z'
                        />
                      </svg>
                    </span>
                    <span>Search Criteria</span>
                  </div>
                  <div className='row'>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Search</label>
                        <div>
                          <input
                            className='form-control'
                            data-val='true'
                            data-val-length='Maximum length is 50.'
                            data-val-length-max='50'
                            id='Search'
                            name='keyword'
                            placeholder='Keyword'
                            type='search'
                            value={props.searchAssets.keyword}
                            onChange={(e) => props.handleSearchAssets(e)}
                          />
                        </div>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='Search'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3 assetSearch_field'>
                        <label className='col-form-label pt-0 pb-1'>Search fields</label>
                        <div className='ReactMultiSelectCheckboxes custom-checkbox-select'>
                          <ReactMultiSelectCheckboxes
                            options={searchFieldsOptions}
                            value={searchFields}
                            onChange={onChangeSearchFields}
                            name='searchField'
                            labelledBy='Select'
                            className='form-control'
                            width='100%'
                            placeholderButtonLabel='Asset Tag ID, Brand, Description, Model'
                          />
                        </div>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='chk_searchFields'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Site</label>
                        <select
                          className='form-select'
                          data-val='true'
                          data-val-number='The field Site must be a number.'
                          id='SiteId'
                          name='siteId'
                          onChange={(e) => props.handleSearchAssets(e)}
                        >
                          <option value=''>All Sites</option>
                          {siteData?.sites?.map((site, index) => (
                            <option key={index} value={site.id}>
                              {site.name}
                            </option>
                          ))}
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='SiteId'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3' id='AssetsLocationListWrapper'>
                        <label className='col-form-label pt-0 pb-1'>Location</label>
                        <select
                          className='form-select'
                          data-val='true'
                          data-val-number='The field Location must be a number.'
                          id='LocationId'
                          name='locationId'
                          onChange={(e) => props.handleSearchAssets(e)}
                        >
                          <option value=''>All Locations</option>
                          {locationData?.locations?.map((item: any, idx: number) => {
                            return (
                              <option key={idx} value={item.id}>
                                {item.location}
                              </option>
                            )
                          })}
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='LocationId'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Category</label>
                        <select
                          className='form-select'
                          data-val='true'
                          data-val-number='The field Category must be a number.'
                          id='CategoryId'
                          name='categoryId'
                          onChange={(e) => props.handleSearchAssets(e)}
                        >
                          <option value=''>All Categories</option>
                          {categoryData?.category?.map((item: any, idx: number) => {
                            return (
                              <option key={idx} value={item.id}>
                                {item.category}
                              </option>
                            )
                          })}
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='CategoryId'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Department</label>
                        <select
                          className='form-select'
                          data-val='true'
                          data-val-number='The field Department must be a number.'
                          id='DepartmentId'
                          name='departmentId'
                          onChange={(e) => props.handleSearchAssets(e)}
                        >
                          <option value=''>All Departments</option>
                          {departmentData?.department?.map((item: any, idx: number) => {
                            return (
                              <option key={idx} value={item.id}>
                                {item.department}
                              </option>
                            )
                          })}
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='DepartmentId'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Person</label>
                        <div>
                          <input className='form-control' placeholder='Any Person' type='text' />
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Customer</label>
                        <div>
                          <input className='form-control' placeholder='Any Customer' type='text' />
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3 customSelect' id='divStatusType'>
                        <label className='col-form-label pt-0 pb-1'>Status</label>
                        <div className='ReactMultiSelectCheckboxes custom-checkbox-select'>
                          <ReactMultiSelectCheckboxes
                            options={statusTypesOptions}
                            value={statusTypes}
                            onChange={onChangeStatus}
                            labelledBy='Select'
                            name='status'
                            width='100%'
                            placeholderButtonLabel='Any Status'
                            className='form-control'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='separator my-5'></div>
                  <div className='row align-items-end'>
                    <div className='col-xl-3 col-md-4'>
                      <div className='mb-3 fw-bolder'>Display Results</div>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>No. of Results </label>
                        <select
                          name='recordCount'
                          id='recordCount'
                          className='form-select'
                          onChange={(e) => props.handleSearchAssets(e)}
                        >
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                          <option value='100'>100</option>
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='recordCount'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Date Range By</label>
                        <select
                          className='form-select'
                          data-val='true'
                          id='DateRangeBy'
                          name='dateRangeBy'
                          onChange={(e) => props.handleSearchAssets(e)}
                        >
                          <optgroup label='Standard fields'>
                            <option value='purchaseDate'>Purchase Date</option>
                            <option value='createdAt'>Date Created</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1'>Quick Date Range</label>
                        <select
                          className='form-select'
                          id='QuickDateRange'
                          name='quickDateRange'
                          onChange={(e) => handleQuickDateRange(e)}
                          value={quickDate}
                        >
                          <option value='0'>...</option>
                          <optgroup label='Month'>
                            <option value='CurrentMonth'>Current Month</option>
                            <option value='PreviousMonth'>Previous Month</option>
                          </optgroup>
                          <optgroup label='Quarter'>
                            <option value='CurrentQuarter'>Current Quarter</option>
                            <option value='PreviousQuarter'>Previous Quarter</option>
                          </optgroup>
                          <optgroup label='Year'>
                            <option value='2023'>Current Year</option>
                            <option value='2022'>Year 2022</option>
                            <option value='2021'>Year 2021</option>
                            <option value='2020'>Year 2020</option>
                            <option value='2019'>Year 2019</option>
                            <option value='2018'>Year 2018</option>
                          </optgroup>
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='QuickDateRange'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3 custom-date-picker'>
                        <label className='col-form-label pt-0 pb-1'>Custom Date Range</label>
                        <ReactDatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            setDateRange(update)
                            setQuickDate(0)
                            props.handleSearchAssets({ name: 'customDate', update: update })
                          }}
                          isClearable={true}
                          className='form-control'
                          placeholderText={'MM/dd/yyyy - MM/dd/yyyy'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer text-end'>
                <Link
                  to=''
                  className='me-2 main-btn-style btn btn-light-secondary'
                  onClick={() => props.setShowSearch(false)}
                >
                  Cancel
                </Link>
                <Link
                  to=''
                  className='btn btn-primary btnRefreshData main-btn-style'
                  onClick={() => props.handleSearchSubmit()}
                >
                  Search
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetsSearch
