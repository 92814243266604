import React, { useState, useEffect, useRef } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { toAbsoluteUrl } from '_metronic/helpers';
import { Images } from '_metronic/assets/Assets';
import { Link } from 'react-router-dom';
import { useAuth } from 'App/Modules/Auth';
import "./Header.css"

function Header() {
	const [mobileMenu, setMobileMenu] = useState(window.innerWidth > 991);
	const { currentUser } = useAuth();

	const handleLinkToggle = () => {
		if (window.innerWidth <= 991) {
			setMobileMenu(!mobileMenu);
		}
	};
	useEffect(() => {
		const handleResize = () => {
			setMobileMenu(window.innerWidth > 991);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<header className="main-header">
			<Navbar expand="lg" className='py-4' expanded={mobileMenu}>
				<Container className='custom-container'>
					<Link to="/" className='brand-logo navbar-brand'>
						<div className="image">
							<img
								src={toAbsoluteUrl(Images.DefaultWhiteLogo)}
								alt='Logo'
								className='img-fluid'
							/>
						</div>
					</Link>
					<div className="d-flex">
						<Navbar.Collapse id="basic-navbar-nav" className={mobileMenu ? "show collapsing" : ""}>
							<Nav className="ms-auto align-items-lg-center">
								<Nav.Link onClick={handleLinkToggle} href="/">Home</Nav.Link>
								<Nav.Link onClick={handleLinkToggle} href="#review">Review</Nav.Link>
								<Nav.Link onClick={handleLinkToggle} href="#features">Features</Nav.Link>
								<Nav.Link onClick={handleLinkToggle} href="#benefits">Benefits</Nav.Link>
								<Nav.Link onClick={handleLinkToggle} href="#pricing">Pricing</Nav.Link>
								<Nav.Link onClick={handleLinkToggle} href="#faqs">Faqs</Nav.Link>
								<Nav.Link onClick={handleLinkToggle} href="#contact">Contact</Nav.Link>
							</Nav>
						</Navbar.Collapse>
						<div className='header-btns d-flex me-lg-0 me-2'>
							{!currentUser ?
								<>
									<Link to="/auth/registration" className='main-btn main-btn-white me-2'>
										<span className='d-lg-none d-block'><i className="las la-user"></i></span>
										<span className='d-lg-block d-none'>Create an Account</span>
									</Link>
									<Link to="/auth" className='main-btn main-btn-yellow' onClick={handleLinkToggle}>
										<span className='d-lg-none d-block'><i className="las la-sign-in-alt"></i></span>
										<span className='d-lg-block d-none'>Sign In</span>
									</Link>
								</> :
								<Link to="/dashboard" className='main-btn main-btn-yellow' onClick={handleLinkToggle}>
									<span className='d-lg-none d-block'><i className="las la-sign-in-alt"></i></span>
									<span className='d-lg-block d-none'>Dashboard</span>
								</Link>
							}
						</div>
						<Navbar.Toggle aria-controls="basic-navbar-nav" className='main-btn' onClick={handleLinkToggle} />
					</div>
				</Container>
			</Navbar>
		</header>
	);
}

export default Header;
