import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialPersons, PersonResponse } from '../Core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import {
  useAddPersonMutation,
  useUpdatePersonMutation,
} from '../../../../Services/Persons'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useGetAllDepartmentsQuery } from 'App/Modules/Services/Departments'
import { useGetAllLocationsBySiteQuery } from 'App/Modules/Services/Locations'
import DepartmentsModel from '../../Departments/Components/DepartmentsModel'
import LocationModal from '../../Locations/Components/LocationModal'
import SitesModal from '../../Sites/Components/SitesModal'

const CustomersSechma = Yup.object().shape({
  fullName: Yup.string().trim().required('Person Name is required'),
})

const CustomersModal = (props: any) => {
  const { currentUser } = useAuth()
  const [siteId, setSiteId] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const { data: siteData } = useGetAllSitesQuery('')
  const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)
  const [addCustomer, { isLoading: isAdding }] = useAddPersonMutation()
  const [updateCustomer, { isLoading: isUpdating }] = useUpdatePersonMutation()
  const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery(
    { id: siteId },
    { skip: !siteId }
  )
  const { data: departmentData } = useGetAllDepartmentsQuery({ page: 0, limit: 0 })

  useEffect(() => {
    formik.resetForm()
    if (props?.personId) {
      formik.setValues({
        fullName: props?.PersonData?.person?.fullName,
        employeeId: props?.PersonData?.person?.employeeId,
        title: props?.PersonData?.person?.title,
        phone: props?.PersonData?.person?.phone,
        email: props?.PersonData?.person?.email,
        siteId: props?.PersonData?.person?.siteId,
        locationID: props?.PersonData?.person?.locationID,
        departmentId: props?.PersonData?.person?.departmentId,
        notes: props?.PersonData?.person?.notes,
        createdBy: props?.PersonData?.person?.createdBy,
      })
    }
  }, [props])

  useEffect(() => {
    setSkip(false)
  }, [])

  const handleSiteClick = (e: any) => {
    let id = e.target.value
    setSiteId(id)
    setSkip(false)
  }
  const handleShowSiteModal = () => {
    setShowSiteModal(true)
  }

  const handleCloseSiteModal = () => {
    setShowSiteModal(false)
  }

  const handleShowLocationModal = () => {
    setShowLocationModal(true)
  }

  const handleCloseLocationModal = () => {
    setShowLocationModal(false)
  }

  const handleShowDepartmentModal = () => {
    setShowDepartmentModal(true)
  }

  const handleCloseDepartmentModal = () => {
    setShowDepartmentModal(false)
  }
  const formik = useFormik({
    initialValues: { ...initialPersons },
    validationSchema: CustomersSechma,
    onSubmit: async (values) => {
      if (currentUser) {
        values.fullName = values.fullName.trim()
        values.createdBy = `${currentUser?.firstName} ${currentUser?.lastName}`
        try {
          const data = props?.personId
            ? await updateCustomer({ id: props?.personId, values }).unwrap()
            : await addCustomer(values).unwrap()
          if (data) {
            formik.resetForm()
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              props.handleClose()
              props.setLoading(true)
              props.setPersonId()
            } else {
              toast.error(data?.message)
            }
          }
        } catch (error: any) {
          if (!error?.data?.success) {
            toast.error(error?.data?.message)
          }
          formik.setSubmitting(false)
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <>
      <Modal
        className='custom-model-sm'
        show={props.show}
        onHide={props.handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props?.personId ? 'Update Person' : 'Add Person'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  Person Name
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('fullName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.fullName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Employee ID</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('employeeId')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.employeeId && formik.errors.employeeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.employeeId}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Title</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('title')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label  fw-bold fs-6 mb-2'>Phone</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('phone')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Email</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('email')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span >Site</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('siteId')}
                    onChange={(e) => {
                      formik.handleChange(e)
                      handleSiteClick(e)
                    }}
                  >
                    <option value=''>Select Site</option>
                    {siteData?.sites.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  <SitesModal
                    show={showSiteModal}
                    handleClose={handleCloseSiteModal}
                    SiteData={[]}
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span >Location</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('locationId')}
                  >
                    <option value=''>Select Location</option>
                    {locationData?.locations?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.location}
                        </option>
                      )
                    })}
                  </select>
                  <LocationModal
                    show={showLocationModal}
                    handleClose={handleCloseLocationModal}
                    locationData={[]}
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span >Department</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('departmentId')}
                  >
                    <option value=''>Select Department</option>
                    {departmentData?.department?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.department}
                        </option>
                      )
                    })}
                  </select>
                  <DepartmentsModel
                    show={showDepartmentModal}
                    handleClose={handleCloseDepartmentModal}
                    data={[]}
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Notes</label>
                <div className='col-lg-9 fv-row'>
                  <textarea
                    placeholder=''
                    {...formik.getFieldProps('notes')}
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
            </div>

            <div className='text-end pt-2'>
              <button className='btn btn-light-secondary main-btn-style me-2' onClick={handleClose}>
                Close{' '}
              </button>

              <button
                type='submit'
                className='btn btn-primary main-btn-style'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isAdding && !isUpdating && (
                  <span className='indicator-label'>
                    {props?.personId ? 'Update Person' : 'Add Person'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomersModal
