import React from 'react'
import {KTSVG} from '../../../helpers'
import { Link } from 'react-router-dom'

type Props = {
  className: string
}

const TablesWidget8: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column mb-0'>
          <span className='card-label fw-bold fs-3 mb-1'>Latest Products</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>More than 100 new orders</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <Link
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                to='#kt_table_widget_8_tab_1'
              >
                Month
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                to='#kt_table_widget_8_tab_2'
              >
                Week
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                data-bs-toggle='tab'
                to='#kt_table_widget_8_tab_3'
              >
                Day
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_8_tab_1'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-danger'>
                          <KTSVG
                            path='/media/icons/duotune/coding/cod002.svg'
                            className='svg-icon-2x svg-icon-danger'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        School Music Festival
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>by Rose Liam</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>4:20PM, 03 Sep</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-warning'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen024.svg'
                            className='svg-icon-2x svg-icon-warning'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Maths Championship
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Tom Gere</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>10:05PM, 25 Oct</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-info'>
                          <KTSVG
                            path='/media/icons/duotune/maps/map004.svg'
                            className='svg-icon-2x svg-icon-info'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Who Knows Geography
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Zoey Dylan</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>3:22PM, 07 Sep</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-primary'>
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs027.svg'
                            className='svg-icon-2x svg-icon-primary'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Napoleon Days
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Luke Owen</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>1:20PM, 02 Dec</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-success'>
                          <KTSVG
                            path='/media/icons/duotune/art/art007.svg'
                            className='svg-icon-2x svg-icon-success'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        The School Art Leads
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Ellie Cole</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>6:20PM, 07 Sep</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_table_widget_8_tab_2'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-info'>
                          <KTSVG
                            path='/media/icons/duotune/maps/map004.svg'
                            className='svg-icon-2x svg-icon-info'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Who Knows Geography
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Zoey Dylan</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>3:22PM, 07 Sep</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-warning'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen024.svg'
                            className='svg-icon-2x svg-icon-warning'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Maths Championship
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Tom Gere</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>10:05PM, 25 Oct</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-danger'>
                          <KTSVG
                            path='/media/icons/duotune/coding/cod002.svg'
                            className='svg-icon-2x svg-icon-danger'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        School Music Festival
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>by Rose Liam</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>4:20PM, 03 Sep</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_table_widget_8_tab_3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-warning'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen024.svg'
                            className='svg-icon-2x svg-icon-warning'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Maths Championship
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Tom Gere</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>10:05PM, 25 Oct</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-info'>
                          <KTSVG
                            path='/media/icons/duotune/maps/map004.svg'
                            className='svg-icon-2x svg-icon-info'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Who Knows Geography
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Zoey Dylan</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>3:22PM, 07 Sep</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-primary'>
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs027.svg'
                            className='svg-icon-2x svg-icon-primary'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Napoleon Days
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>By Luke Owen</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>1:20PM, 02 Dec</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-danger'>
                          <KTSVG
                            path='/media/icons/duotune/coding/cod002.svg'
                            className='svg-icon-2x svg-icon-danger'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to='/dummy-link' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        School Music Festival
                      </Link>
                      <span className='text-muted fw-semibold d-block fs-7'>by Rose Liam</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>4:20PM, 03 Sep</span>
                      <span className='text-muted fw-semibold d-block fs-8'>Date</span>
                    </td>
                    <td className='text-end'>
                      <Link
                        to='/dummy-link'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TablesWidget8}
