
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { Link } from 'react-router-dom'

type Props = {
  className: string
}

const ListsWidget8: React.FC<Props> = ({ className }) => {
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column mb-0'>
          <span className='fw-bold text-dark'>Latest Products</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gifts and more</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='card-body pt-3'>
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
            <div className='flex-grow-1 my-lg-0 my-2 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Cup &amp; Green
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>Visually stunning</span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='me-6'>
                <i className='fa fa-star-half-alt me-1 text-warning fs-5'></i>
                <span className='text-gray-800 fw-bold'>4.2</span>
              </div>
              <Link to='/dummy-link' className='btn btn-icon btn-light btn-sm border-0'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-2 svg-icon-primary'
                />
              </Link>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
            <div className='flex-grow-1 my-lg-0 my-2 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Pink Patterns
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>Feminine all around</span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='me-6'>
                <i className='fa fa-star-half-alt me-1 text-warning fs-5'></i>
                <span className='text-gray-800 fw-bold'>5.0</span>
              </div>
              <Link to='/dummy-link' className='btn btn-icon btn-light btn-sm border-0'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-2 svg-icon-primary'
                />
              </Link>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
            <div className='flex-grow-1 my-lg-0 my-2 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Abstract Art
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>
                The will to capture readers
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='me-6'>
                <i className='fa fa-star-half-alt me-1 text-warning fs-5'></i>
                <span className='text-gray-800 fw-bold'>5.7</span>
              </div>
              <Link to='/dummy-link' className='btn btn-icon btn-light btn-sm border-0'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-2 svg-icon-primary'
                />
              </Link>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
            <div className='flex-grow-1 my-lg-0 my-2 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Desserts platter
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>
                Food trends &amp; inspirations
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='me-6'>
                <i className='fa fa-star-half-alt me-1 text-warning fs-5'></i>
                <span className='text-gray-800 fw-bold'>3.7</span>
              </div>
              <Link to='/dummy-link' className='btn btn-icon btn-light btn-sm border-0'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-2 svg-icon-primary'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ListsWidget8 }
