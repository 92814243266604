import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuth } from '../Auth'


const API_URL = process.env.REACT_APP_API_URL;

export const emailTemplateApi = createApi({
	reducerPath: 'emailTemplateApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}/customize-email/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['CustomizeEmail'],
	endpoints: (build) => ({
		getEmailTypes: build.query({
			query: () => `get-email-types`,
			providesTags: ["CustomizeEmail"]
		}),
		getEmailDefaultTemplate: build.query({
			query: (id) => `get-email-template/${id}`,
			providesTags: ["CustomizeEmail"]
		}),
		updateEmailTemplate: build.mutation({
			query: (body) => ({
				url: `update`,
				method: 'PUT',
				body: body,
			}),
		}),
		getEmailTemplatesss: build.mutation({
			query: (templateName) => ({
				url: `get-template`,
				method: 'POST',
				body: { "templateName": templateName },
			}),
		}),
		getUserEmailTemplate: build.query<any, any>({
			query: (id) => `get-user-email-template/${id}`,
			providesTags: ["CustomizeEmail"]
		}),
	}),
});

export const { useGetEmailTemplatesssMutation, useUpdateEmailTemplateMutation, useGetEmailTypesQuery, useGetEmailDefaultTemplateQuery, useGetUserEmailTemplateQuery } = emailTemplateApi;
