interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
}

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData }) => {
  const renderData = () => {
    switch (column) {
      case 'Created By':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.createdBy}</span>
        ))
      case 'Name':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.fullName}</span>
        ))
      case 'Cell':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.cell}</span>
        ))
      case 'Email':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.email}</span>
        ))
      case 'Company':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.company}</span>
        ))
      case 'Address1':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.address1}</span>
        ))
      case 'Address2':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.address2}</span>
        ))
      case 'Phone':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.phone}</span>
        ))
      case 'City':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.city}</span>
        ))
      case 'State':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.state}</span>
        ))
      case 'Country':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.country}</span>
        ))
      case 'Zip':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.zip}</span>
        ))
      case 'Notes':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.notes}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const renderData = () => {
    switch (column) {
      case 'Created By':
        return 'Sample Created By'
      case 'Name':
        return 'Sample Name'
      case 'Cell':
        return 'Sample Cell'
      case 'Email':
        return 'Sample Email'
      case 'Company':
        return 'Sample Company'
      case 'Address1':
        return 'Sample Address1'
      case 'Address2':
        return 'Sample Address2'
      case 'Notes':
        return 'Sample Notes'
      case 'Phone':
        return 'Sample Phone'
      case 'City':
        return 'Sample City'
      case 'State':
        return 'Sample State'
      case 'Zip':
        return 'Sample Zip'
      case 'Country':
        return 'Sample Country'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
