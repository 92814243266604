import React, { useEffect, useState } from 'react'
import { CustomEventResponce, EventsType } from '../Core/_models'
import { getAuth } from '../../../../Auth'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
	useGetAllEventsQuery,
	useGetCustomEventsQuery,
	useAddEventMutation,
	useDeleteEventsMutation,
} from '../../../../Services/Events'
import { Link } from 'react-router-dom'

const Events = () => {
	const auth = getAuth()
	const navigate = useNavigate()
	const [events, setEvents] = useState<EventsType>({
		isCheckOutAssetsRequired: true,
		hasCheckOutEnableTime: true,
		hasCheckOutSignature: true,
		isCheckOutSignatureRequired: true,
		isLeaseSignatureRequired: true,
		hasCheckOutReservation: true,
		isLeaseAssetsRequired: true,
		hasLeaseEnableTime: true,
		hasLeaseSignature: true,
		hasLeaseReservation: true,
		hasCheckOutReservationEnableTime: true,
		hasLeaseReservationEnableTime: true,
		isLostFoundAssetsRquired: true,
		hasLostFoundEnableTime: true,
		isRepairAssetsRequired: true,
		hasRepairAssetsEnableTime: true,
		isBrokenAssetsRequired: true,
		hasBrokenAssetsEnableTime: true,
		isDisposeAssetsRequired: true,
		hasDisposeAssetsEnableTime: true,
		isDonateAssetsRequired: true,
		hasDonateEnableTime: true,
		isSellAssetsRequired: true,
		hasSellEnableTime: true,
		checkOutSignatureDisclaimer:
			'I accept receipt and delivery of the goods and further acknowledge that the goods have been inspected and are without defect.',
		leaseSignatureDisclaimer:
			'I accept receipt and delivery of the goods and further acknowledge that the goods have been inspected and are without defect.',
		customEvents: '',
	})
	const [flag, setFlag] = useState<Boolean>(false)
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [deleteIdx, setDeleteIdx] = useState<number>()
	const { data } = useGetAllEventsQuery(auth?.token)
	const { data: customEventsResponse, error: customEventError } = useGetCustomEventsQuery(auth?.token)
	const [addEvents, { isLoading: isUpdating }] = useAddEventMutation()
	const [deleteEvents] = useDeleteEventsMutation()
	const [isSitesExist, setIsSitesExist] = useState<any>(false)

	const checkVistingPage = (dataList: any) => {
		if (isSitesExist === false) {
			if (dataList?.length > 0) {
				setIsSitesExist(dataList?.length)
			} else {
				setIsSitesExist(dataList?.length)
			}
		}
	}
	const confirm = async () => {
		if (typeof deleteIdx === 'number') {
			const res = await deleteEvents({ token: auth?.token, id: deleteIdx }).unwrap()
			if (res && res?.status == 200) {
				setFlag(!flag)
				toast.success(res?.message)
			}
			setDeleteModal(false)
		}
	}

	const cancel = () => {
		setDeleteModal(false)
	}

	const submitCustomEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
		event?.preventDefault()
		try {
			addEvents({ data: events, token: auth?.token }).unwrap()
			if (data?.status == 200) {
				setFlag(!flag)
				toast.success('Database updated successfully')
				if (isSitesExist === 0) {
					navigate(`/apps/setup/table`)
				}
			}
		} catch (error: any) {
			toast.error(error || 'Something went Wrong!')
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			if (data?.events) {
				try {
					setEvents(data?.events)

					if (customEventsResponse?.userEvents) {
						setEvents((prevEvents) => ({
							...prevEvents,
							customEvents: JSON.stringify(customEventsResponse?.userEvents),
						}))
						checkVistingPage(customEventsResponse?.userEvents)
					}
				} catch (error) {
					setEvents((prevEvents) => ({
						...prevEvents,
						customEvents: '',
					}))
				}
			}
		}
		fetchData()
	}, [data, customEventsResponse])

	return (
		<div className='card asset_related_event'>
			<ConfirmationPopup deleteModal={deleteModal} cancel={cancel} confirm={confirm} />
			<form className='form w-100 mt-6' noValidate id='kt_login_signin_form'>
				<span className='asset_related mt-4 ml-2'>
					<strong>Asset-related Events</strong>
				</span>
				<div className='register_events '>
					<span>Do you want to register these events for the assets?</span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i
								className='la la-user-check fs-2 text-primary me-1 ver_bottom'
								aria-hidden='true'
							></i>
							Check-out assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasCheckOut_1'
									onClick={() => setEvents({ ...events, isCheckOutAssetsRequired: true })}
								>
									<div>
										<input
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											checked={events?.isCheckOutAssetsRequired}
											id='hasCheckOut_1'
											name='hasCheckOut'
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasCheckOut_0'
									onClick={() => setEvents({ ...events, isCheckOutAssetsRequired: false })}
								>
									<div>
										<input
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											checked={events?.isCheckOutAssetsRequired}
											id='hasCheckOut_1'
											name='hasCheckOut'
											value='True'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isCheckOutAssetsRequired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock'>
									<label className='checkbox enable_time m_23 mb-0' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												className='sig_input form-check-input enable_time'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												checked={events?.hasCheckOutEnableTime}
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({
													...events,
													hasCheckOutEnableTime: !events?.hasCheckOutEnableTime,
												})
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
							<div className='col-sm-8 col-md-9 offset-sm-4 offset-md-3 mb-3'>
								<span className='help-block mb-2 m_7'>
									Assets are 'checked out' or 'assigned to' individuals. Enter individuals in
									'Advanced &gt; Persons/Employees' table.
								</span>
							</div>
							{events?.isCheckOutAssetsRequired && (
								<>
									<div className='CheckOutEnableBlock d-block'>
										<div className='form-group row mb-3'>
											<div className='col-sm-3 col-xl-2 offset-sm-4 offset-md-3 mb-3 mb-sm-0'>
												<label
													className='checkbox show_sig_size enable_time'
													htmlFor='CheckoutSignature'
													onClick={() =>
														setEvents({
															...events,
															hasCheckOutSignature: !events?.hasCheckOutSignature,
														})
													}
												>
													<span className='custom_sig_check'>
														<input
															type='checkbox'
															checked={events?.hasCheckOutSignature}
															className='sig_input form-check-input'
														/>{' '}
														Show Signature Pad
													</span>
												</label>
											</div>
											{events?.hasCheckOutSignature && (
												<>
													<div className='col-sm-5 col-md-6'>
														<div className='CheckoutSignatureEnableBlock d-block'>
															<label
																className='checkbox sig_input sig_padding enable_time'
																htmlFor='CheckoutSignatureRequired'
															>
																<div>
																	<input
																		data-val='true'
																		data-val-required='The CheckoutSignatureRequired field is required.'
																		className='form-check-input'
																		id='CheckoutSignatureRequired'
																		name='CheckoutSignatureRequired'
																		type='checkbox'
																		checked={events?.isCheckOutSignatureRequired}
																		value='true'
																	/>
																	<input
																		name='CheckoutSignatureRequired'
																		type='hidden'
																		value='false'
																	/>
																</div>
																<span
																	className='custom_sig_check show_sig_size'
																	onClick={() =>
																		setEvents({
																			...events,
																			isCheckOutSignatureRequired:
																				!events?.isCheckOutSignatureRequired,
																		})
																	}
																>
																	{' '}
																	Make signature a required field (force the person to sign)
																</span>
															</label>
														</div>
													</div>
													<div className='form-group row CheckoutSignatureEnableBlock m_5'>
														<label
															htmlFor='CheckoutSignatureDisclaimer'
															className='col-sm-8 col-xxl-7 col-form-label-sm offset-sm-4 offset-md-3 mb-0'
														>
															Signature Disclaimer
														</label>
														<div className='col-sm-8 col-xxl-7 offset-sm-4 offset-md-3'>
															<textarea
																className='form-control'
																onChange={(e) =>
																	setEvents({
																		...events,
																		checkOutSignatureDisclaimer: e.target.value,
																	})
																}
																value={events?.checkOutSignatureDisclaimer}
																cols={20}
																data-val='true'
																data-val-length='Maximum length is 1000.'
																data-val-length-max='1000'
																id='CheckoutSignatureDisclaimer'
																name='CheckoutSignatureDisclaimer'
																rows={3}
															></textarea>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
									<div className='CheckOutEnableBlock d-block'>
										<div className='form-group row align-items-center mb-3'>
											<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label text-sm-end'>
												Reservation:
											</label>
											<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
												<div className='radio-inline d-flex ms-2'>
													<label
														className='radio mb-0 sig_input d-flex'
														htmlFor='hasPersonReserve_1'
														onClick={() => setEvents({ ...events, hasCheckOutReservation: true })}
													>
														<div>
															<input
																type='checkbox'
																className='form-check-input custom-form-check-input sig_input'
																checked={events?.hasCheckOutReservation}
																id='hasPersonReserve_1'
																name='hasPersonReserve'
																value='True'
															/>
														</div>
														<span className='vertically_align'> Yes</span>
													</label>
													<label
														className='radio mb-0 sig_input d-flex'
														htmlFor='hasPersonReserve_0'
														onClick={() => setEvents({ ...events, hasCheckOutReservation: false })}
													>
														<div>
															<input
																type='checkbox'
																className='form-check-input custom-form-check-input sig_input'
																checked={!events?.hasCheckOutReservation}
																id='hasPersonReserve_0'
																name='hasPersonReserve'
																value='False'
															/>
														</div>
														<span className='vertically_align'>No</span>
													</label>
												</div>
											</div>
											{events?.hasCheckOutReservation && (
												<div className='col-sm-3 col-xl-2'>
													<div className='PersonReserveEnable d-block '>
														<label
															className='checkbox enable_time m_23 mb-0'
															onClick={() =>
																setEvents({
																	...events,
																	hasCheckOutReservationEnableTime:
																		!events?.hasCheckOutReservationEnableTime,
																})
															}
															htmlFor='hasTimeReserve'
														>
															<div>
																<input name='hasTimeReserve' type='hidden' value='false' />
															</div>
															<span className='custom_sig_check d-flex'>
																<input
																	type='checkbox'
																	checked={events?.hasCheckOutReservationEnableTime}
																	className='sig_input form-check-input enable_time'
																/>
																Enable Time
															</span>
														</label>
													</div>
												</div>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>

				<div className='register_events '>
					<span></span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i className='la la-send-o fs-2 text-primary me-1 ver_bottom' aria-hidden='true'></i>
							Lease assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='leaseaAssets_1'
									onClick={() => setEvents({ ...events, isLeaseAssetsRequired: true })}
								>
									<div>
										<input
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											checked={events?.isLeaseAssetsRequired}
											id='leaseaAssets_1'
											name='leaseaAssets'
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='leaseaAssets_0'
									onClick={() => setEvents({ ...events, isLeaseAssetsRequired: false })}
								>
									<div>
										<input
											id='leaseaAssets_0'
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											checked={!events?.isLeaseAssetsRequired}
											name='leaseaAssets'
											value='False'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isLeaseAssetsRequired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock m_5'>
									<label className='checkbox enable_time m_23 mb-0' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												checked={events?.hasLeaseEnableTime}
												className='sig_input form-check-input'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({ ...events, hasLeaseEnableTime: !events?.hasLeaseEnableTime })
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
							<div className='col-sm-8 col-md-9 offset-sm-4 offset-md-3 mb-3'>
								<span className='help-block mb-2 m_7'>
									Assets are 'leased' or 'rented/loaned' to customers. Maintain a list of customers
									in the 'Advanced &gt; Customers' table.
								</span>
							</div>
							{events?.isLeaseAssetsRequired && (
								<>
									<div className='CheckOutEnableBlock d-block'>
										<div className='form-group row mb-3'>
											<div className='col-sm-3 col-xl-2 offset-sm-4 offset-md-3 mb-3 mb-sm-0'>
												<label
													className='checkbox show_sig_size enable_time'
													onClick={() =>
														setEvents({ ...events, hasLeaseSignature: !events?.hasLeaseSignature })
													}
													htmlFor='CheckoutSignature'
												>
													<span className='custom_sig_check'>
														<input
															type='checkbox'
															checked={events?.hasLeaseSignature}
															className='sig_input form-check-input'
														/>{' '}
														Show Signature Pad
													</span>
												</label>
											</div>
											{events?.hasLeaseSignature && (
												<>
													<div className='col-sm-5 col-md-6'>
														<div className='CheckoutSignatureEnableBlock d-block'>
															<label
																className='checkbox sig_input sig_padding enable_time'
																htmlFor='CheckoutSignatureRequired'
															>
																<div>
																	<input
																		data-val='true'
																		data-val-required='The CheckoutSignatureRequired field is required.'
																		className='form-check-input'
																		id='CheckoutSignatureRequired'
																		name='CheckoutSignatureRequired'
																		type='checkbox'
																		checked={events?.isLeaseSignatureRequired}
																		value='true'
																	/>
																	<input
																		name='CheckoutSignatureRequired'
																		type='hidden'
																		value='false'
																	/>
																</div>
																<span
																	className='custom_sig_check show_sig_size'
																	onClick={() =>
																		setEvents({
																			...events,
																			isLeaseSignatureRequired: !events?.isLeaseSignatureRequired,
																		})
																	}
																>
																	{' '}
																	Make signature a required field (force the person to sign)
																</span>
															</label>
														</div>
													</div>
													<div className='form-group row CheckoutSignatureEnableBlock'>
														<label
															htmlFor='CheckoutSignatureDisclaimer'
															className='col-sm-8 col-xxl-7 col-form-label-sm offset-sm-4 offset-md-3 mb-0'
														>
															Signature Disclaimer
														</label>
														<div className='col-sm-8 col-xxl-7 offset-sm-4 offset-md-3'>
															<textarea
																className='form-control'
																cols={20}
																onChange={(e) =>
																	setEvents({ ...events, leaseSignatureDisclaimer: e.target.value })
																}
																value={events?.leaseSignatureDisclaimer}
																data-val='true'
																data-val-length='Maximum length is 1000.'
																data-val-length-max='1000'
																id='CheckoutSignatureDisclaimer'
																name='CheckoutSignatureDisclaimer'
																rows={3}
															></textarea>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
									<div className='CheckOutEnableBlock d-block'>
										<div className='form-group row align-items-center mb-3'>
											<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label text-sm-end'>
												Reservation:
											</label>
											<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
												<div className='radio-inline d-flex ms-2'>
													<label
														className='radio mb-0 sig_input d-flex'
														htmlFor='hasLeaseReservation_1'
														onClick={() => setEvents({ ...events, hasLeaseReservation: true })}
													>
														<div>
															<input
																className='form-check-input custom-form-check-input sig_input'
																type='checkbox'
																id='hasLeaseReservation_1'
																name='hasLeaseReservation'
																checked={events?.hasLeaseReservation}
																value='True'
															/>
														</div>
														<span className='vertically_align'> Yes</span>
													</label>
													<label
														className='radio mb-0 sig_input d-flex'
														htmlFor='hasLeaseReservation_0'
														onClick={() => setEvents({ ...events, hasLeaseReservation: false })}
													>
														<div>
															<input
																className='form-check-input custom-form-check-input sig_input'
																type='checkbox'
																id='hasLeaseReservation_0'
																name='hasLeaseReservation'
																checked={!events?.hasLeaseReservation}
																value='False'
															/>
														</div>
														<span className='vertically_align'>No</span>
													</label>
												</div>
											</div>
											{events?.hasLeaseReservation && (
												<div className='col-sm-3 col-xl-2'>
													<div className='PersonReserveEnable d-block '>
														<label
															className='checkbox enable_time m_23 mb-0'
															htmlFor='hasTimeReserve'
														>
															<div>
																<input name='hasTimeReserve' type='hidden' value='false' />
															</div>
															<span
																className='custom_sig_check d-flex'
																onClick={() =>
																	setEvents({
																		...events,
																		hasLeaseReservationEnableTime:
																			!events?.hasLeaseReservationEnableTime,
																	})
																}
															>
																<input
																	type='checkbox'
																	checked={events?.hasLeaseReservationEnableTime}
																	className='sig_input form-check-input enable_time'
																/>
																Enable Time
															</span>
														</label>
													</div>
												</div>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>

				<div className='register_events '>
					<span></span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i
								className='la la-thumbs-o-down fs-2 text-primary me-1 ver_bottom'
								aria-hidden='true'
							></i>
							Lost/Found assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasLoastAssets_1'
									onClick={() => setEvents({ ...events, isLostFoundAssetsRquired: true })}
								>
									<div>
										<input
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											id='hasLoastAssets_1'
											name='hasLoastAssets'
											checked={events?.isLostFoundAssetsRquired}
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasLoastAssets_0'
									onClick={() => setEvents({ ...events, isLostFoundAssetsRquired: false })}
								>
									<div>
										<input
											id='hasLoastAssets_0'
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											checked={!events?.isLostFoundAssetsRquired}
											name='hasLoastAssets'
											value='False'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isLostFoundAssetsRquired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock'>
									<label className='checkbox enable_time' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												checked={events?.hasLostFoundEnableTime}
												className='sig_input form-check-input'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({
													...events,
													hasLostFoundEnableTime: !events?.hasLostFoundEnableTime,
												})
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className='register_events '>
					<span></span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i className='la la-wrench fs-2 text-primary me-1 ver_bottom' aria-hidden='true'></i>
							Repair assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasRepairAssets_1'
									onClick={() => setEvents({ ...events, isRepairAssetsRequired: true })}
								>
									<div>
										<input
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											checked={events?.isRepairAssetsRequired}
											id='hasRepairAssets_1'
											name='hasRepairAssets'
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasRepairAssets_0'
									onClick={() => setEvents({ ...events, isRepairAssetsRequired: false })}
								>
									<div>
										<input
											id='hasRepairAssets_0'
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											checked={!events?.isRepairAssetsRequired}
											name='hasRepairAssets'
											value='False'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isRepairAssetsRequired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock'>
									<label className='checkbox enable_time' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												checked={events?.hasRepairAssetsEnableTime}
												className='sig_input form-check-input'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({
													...events,
													hasRepairAssetsEnableTime: !events?.hasRepairAssetsEnableTime,
												})
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className='register_events '>
					<span></span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i className='la la-unlink fs-2 text-primary me-1 ver_bottom' aria-hidden='true'></i>
							Broken assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasBrokenAssets_1'
									onClick={() => setEvents({ ...events, isBrokenAssetsRequired: true })}
								>
									<div>
										<input
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											checked={events?.isBrokenAssetsRequired}
											id='hasBrokenAssets_1'
											name='hasBrokenAssets'
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasBrokenAssets_0'
									onClick={() => setEvents({ ...events, isBrokenAssetsRequired: false })}
								>
									<div>
										<input
											id='hasBrokenAssets_0'
											className='form-check-input custom-form-check-input sig_input'
											type='checkbox'
											checked={!events?.isBrokenAssetsRequired}
											name='hasBrokenAssets'
											value='False'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isBrokenAssetsRequired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock'>
									<label className='checkbox enable_time' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												checked={events?.hasBrokenAssetsEnableTime}
												className='sig_input form-check-input'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({
													...events,
													hasBrokenAssetsEnableTime: !events?.hasBrokenAssetsEnableTime,
												})
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className='register_events '>
					<span></span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i className='la la-recycle fs-2 text-primary me-1 ver_bottom' aria-hidden='true'></i>
							Dispose assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasDisposeAssets_1'
									onClick={() => setEvents({ ...events, isDisposeAssetsRequired: true })}
								>
									<div>
										<input
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											id='hasDisposeAssets_1'
											checked={events?.isDisposeAssetsRequired}
											name='hasDisposeAssets'
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasDisposeAssets_0'
									onClick={() => setEvents({ ...events, isDisposeAssetsRequired: false })}
								>
									<div>
										<input
											id='hasDisposeAssets_0'
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											checked={!events?.isDisposeAssetsRequired}
											name='hasDisposeAssets'
											value='False'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isDisposeAssetsRequired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock'>
									<label className='checkbox enable_time' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												checked={events?.hasDisposeAssetsEnableTime}
												className='sig_input form-check-input'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({
													...events,
													hasDisposeAssetsEnableTime: !events?.hasDisposeAssetsEnableTime,
												})
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='register_events '>
					<span></span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i className='la la-heart-o fs-2 text-primary me-1 ver_bottom' aria-hidden='true'></i>
							Donate assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasDonateAssets_1'
									onClick={() => setEvents({ ...events, isDonateAssetsRequired: true })}
								>
									<div>
										<input
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											checked={events?.isDonateAssetsRequired}
											id='hasDonateAssets_1'
											name='hasDonateAssets'
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasDonateAssets_0'
									onClick={() => setEvents({ ...events, isDonateAssetsRequired: false })}
								>
									<div>
										<input
											id='hasDonateAssets_0'
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											checked={!events?.isDonateAssetsRequired}
											name='hasDonateAssets'
											value='False'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isDonateAssetsRequired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock'>
									<label className='checkbox enable_time' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												checked={events?.hasDonateEnableTime}
												className='sig_input form-check-input'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({ ...events, hasDonateEnableTime: !events?.hasDonateEnableTime })
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className='register_events '>
					<span></span>
					<div className='form-group row align-items-center mb-3 custom_border_line'>
						<label className='col-sm-4 col-md-3 col-xl-2 offset-xl-1 offset-md-0 col-form-label check_out_asset'>
							<i className='la la-gavel fs-2 text-primary me-1 ver_bottom' aria-hidden='true'></i>
							Sell assets:
						</label>
						<div className='col-sm-3 col-xl-2 mb-3 mb-sm-0'>
							<div className='radio-inline d-flex'>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasSellAssets_1'
									onClick={() => setEvents({ ...events, isSellAssetsRequired: true })}
								>
									<div>
										<input
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											checked={events?.isSellAssetsRequired}
											id='hasSellAssets_1'
											name='hasSellAssets'
											value='True'
										/>
									</div>
									<span className='vertically_align'>Yes</span>
								</label>
								<label
									className='radio mb-0 sig_input d-flex'
									htmlFor='hasSellAssets_0'
									onClick={() => setEvents({ ...events, isSellAssetsRequired: false })}
								>
									<div>
										<input
											id='hasSellAssets_0'
											type='checkbox'
											className='form-check-input custom-form-check-input sig_input'
											checked={!events?.isSellAssetsRequired}
											name='hasSellAssets'
											value='False'
										/>
									</div>
									<span className='vertically_align'> No</span>
								</label>
							</div>
						</div>
						<div>
							{events?.isSellAssetsRequired && (
								<div className='col-sm-3 col-xl-2 CheckOutEnableBlock'>
									<label className='checkbox enable_time' htmlFor='hasTimeCheckOut'>
										<div>
											<input
												data-val='true'
												data-val-required='CheckOut is required.'
												checked={events?.hasSellEnableTime}
												className='sig_input form-check-input'
												id='hasTimeCheckOut'
												name='hasTimeCheckOut'
												type='checkbox'
												value='true'
											/>
										</div>
										<div>
											<input name='hasTimeCheckOut' type='hidden' value='false' />
										</div>
										<span
											className='custom_sig_check enable_time'
											onClick={() =>
												setEvents({ ...events, hasSellEnableTime: !events?.hasSellEnableTime })
											}
										>
											{' '}
											Enable Time
										</span>
									</label>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='form_section'>
					<div className='card-body'>
						<div className='form-title'>
							<strong className='custom_event'>Custom Events</strong>
						</div>
						<p className='mt-5 mb-5 add_custom_events'>
							{' '}
							Add custom events As per your requirements. Some example events are 'Retired', 'Out of
							Service', 'Surplus', 'In Storage', etc. Also define the data fields that should be
							displayed on the form for this event.
						</p>
						<div className='mt-3 mt-md-0 mb-5'>
							<Link to='/apps/setup/add' className='btn btn-light-primary'>
								{' '}
								Add Custom Event{' '}
							</Link>
						</div>
						<div className="table-responsive pb-3 mb-5">
							<table
								className='table table-bordered table-sm vertical_middle mb-0'
								id='custom_fields_table'
								aria-label='Asset Custom Fields'
							>
								<thead className='thead-light'>
									<tr>
										<th> Active </th>
										<th> Event Name </th>
										<th> Description </th>
										<th className='text-center'> Edit </th>
										<th className='text-center'> Customize Form </th>
										<th className='text-center'> Delete </th>
									</tr>
								</thead>
								<tbody>
									{events?.customEvents &&
										JSON.parse(events?.customEvents)?.map(
											(item: CustomEventResponce, idx: number) => (
												<tr key={idx}>
													<td className='fw-bolder'>
														{item?.enableEvent ? (
															<i className='fas fa-check'></i>
														) : (
															<i className='fas fa-times'></i>
														)}
													</td>
													<td>{item?.name}</td>
													<td>{item?.description}</td>
													<td className='text-nowrap'>
														<Link
															onClick={() => {
																navigate(`edit/${item?.id}`)
															}}
															to='/dummy-link'
															className='btn btn-outline btn-outline-success btn-active-success btn-xs'
														>
															<i className='fa fa-pencil-alt fs-7' aria-hidden='true'></i>Edit
														</Link>
													</td>
													<td className='text-nowrap'></td>
													<td className='text-nowrap'>
														<span
															onClick={() => {
																setDeleteModal(true)
																setDeleteIdx(item?.id)
															}}
															className='confirmDeleteCustomField btn btn-outline btn-outline-danger btn-active-danger btn-xs'
														>
															<i className='la la-trash fs-4' aria-hidden='true'></i>Delete
														</span>
													</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='card-footer notForWizard'>
					<div className='row'>
						<div className='col-md-9 offset-md-3 text-end'>
							<Link to='/apps/setup/events' className='btn btn-light-secondary main-btn-style me-2'>
								Cancel
							</Link>
							<button
								type='submit'
								onClick={(e) => submitCustomEvent(e)}
								className='btn btn-primary'
							>
								{isUpdating ? (
									<span className='indicator-progress d-block'>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle'></span>
									</span>
								) : isSitesExist === 0 ? (
									'Continue'
								) : (
									'Submit'
								)}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Events
