import SecurityGroupListing from './Components/SecurityGroupListing'

const SecurityGroups = () => {
  return (
    <div>
      <SecurityGroupListing />
    </div>
  )
}

export default SecurityGroups
