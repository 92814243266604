import { Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login } from '../../core/_requests';
import { useAuth } from '../../core/Auth'
import { toast } from 'react-toastify';
import { useState } from 'react'

const otpSchema = Yup.object().shape({
    otp: Yup.string().trim().required("OTP is required.")
});

const OTPModal = ({ showOTP, handleClose, loginValues }) => {

    const { saveAuth, setCurrentUser } = useAuth()
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: otpSchema,
        onSubmit: async (values) => {
            const data = { ...loginValues, ...values }
            try {
                setIsLoading(true)
                const { data: user } = await login(data.email, data.password, data.rememberme, data.otp)
                setIsLoading(false)
                if (user?.success) {
                    toast.dismiss()
                    toast.success(user?.message)
                    setCurrentUser(user.data)
                    saveAuth({ token: user.token })
                }
            } catch (err) {
                setIsLoading(false);
                if (err.response && err.response.data) {
                    toast.dismiss();
                    toast.error(err.response.data.message);
                }
            }
        },
    });

    return (
        <>
            <Modal show={showOTP} onHide={handleClose}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title">Two-Step Verification</h5>
                    </Modal.Header>
                    <Modal.Body>

                        <p>For added security, please enter the One Time Password (OTP) that has been sent to your register email.</p>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="OTP">Enter OTP</label>
                            <div className="col-sm-8">
                                <div>
                                    <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" data-val-required="OTP is required." id="OTP" name="otp" placeholder="" type="text" value={formik.values.otp} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik?.touched?.otp && formik?.errors?.otp ? (
                                        <span className={formik?.errors?.otp ? `` : `d-none`}>
                                            <label className="pl-1 text-danger">{formik?.errors?.otp}</label>
                                        </span>
                                    ) : null}
                                </div>
                                <span className="field-validation-valid material-validate" data-valmsg-for="OTP" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {!isLoading && 'Sign In'}
                            {isLoading && (
                                <span className='indicator-progress d-block'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default OTPModal;