import { useQueryClient, useMutation } from 'react-query'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../Core/ListViewProvider'
import { useQueryResponse } from '../../Core/QueryResponseProvider'
import { deleteSelectedUsers } from '../../Core/_requests'

const UsersListGrouping = () => {
	const { selected, clearSelected } = useListView()
	const queryClient = useQueryClient()
	const { query } = useQueryResponse()

	const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
		onSuccess: () => {
			queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
			clearSelected()
		},
	})

	return (
		<div className='d-flex justify-content-end align-items-center'>
			<div className='fw-bolder me-5'>
				<span className='me-2'>{selected.length}</span> Selected
			</div>

			<button
				type='button'
				className='btn btn-danger'
				onClick={async () => await deleteSelectedItems.mutateAsync()}
			>
				Delete Selected
			</button>
		</div>
	)
}

export { UsersListGrouping }
