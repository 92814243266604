import Accordion from 'react-bootstrap/Accordion';
import "./Faqs.css"
import ContactUs from '../ContactUs/ContactUs';

function Faqs() {
	return (
		<section className="faqs-section position-relative z-2" id='faqs'>
			<div className="custom-container container">
				<h2 className="h-main mb-xl-5 mb-4">Frequently Asked Questions</h2>
				<div className="row pt-5">
					<div className="col-lg-7">
						<Accordion defaultActiveKey="0" className='faq-accordion  mb-lg-0 mb-10'>
							<Accordion.Item eventKey="0">
								<Accordion.Header>Is AssetRack really free?</Accordion.Header>
								<Accordion.Body>
									Staying on top of your assets saves you time and money. It’s harder to lose or misplace assets when your system tracks every asset’s history and exact location. There’s a huge opportunity cost to finding your lost assets. Without a system in place, you lose productivity, too.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>Why should I track my assets?</Accordion.Header>
								<Accordion.Body>
								    Staying on top of your assets saves you time and money. It’s harder to lose or misplace assets when your system tracks every asset’s history and exact location. There’s a huge opportunity cost to finding your lost assets. Without a system in place, you lose productivity, too.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>How to save time and money?</Accordion.Header>
								<Accordion.Body>
								    Staying on top of your assets saves you time and money. It’s harder to lose or misplace assets when your system tracks every asset’s history and exact location. There’s a huge opportunity cost to finding your lost assets. Without a system in place, you lose productivity, too.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>How to find your lost assets?</Accordion.Header>
								<Accordion.Body>
								    Staying on top of your assets saves you time and money. It’s harder to lose or misplace assets when your system tracks every asset’s history and exact location. There’s a huge opportunity cost to finding your lost assets. Without a system in place, you lose productivity, too.
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="4">
								<Accordion.Header>Without a system in place?</Accordion.Header>
								<Accordion.Body>
								    Staying on top of your assets saves you time and money. It’s harder to lose or misplace assets when your system tracks every asset’s history and exact location. There’s a huge opportunity cost to finding your lost assets. Without a system in place, you lose productivity, too.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="5">
								<Accordion.Header>Why should I track my assets?</Accordion.Header>
								<Accordion.Body>
								    Staying on top of your assets saves you time and money. It’s harder to lose or misplace assets when your system tracks every asset’s history and exact location. There’s a huge opportunity cost to finding your lost assets. Without a system in place, you lose productivity, too.
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
					<div className="col-lg-5">
						<ContactUs />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Faqs