export interface DisposeInterface {
    dateDispose: Date | string,
    disposedTo: string | null,
    notes: string | null,
    assetId: number | null
}
export const defaultDisposeDetail: DisposeInterface = {
    dateDispose: new Date().toISOString().split('T')[0],
    disposedTo: null,
    notes: null,
    assetId: null
}
