import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../Core/ListViewProvider'
import { UsersListFilter } from './UsersListFilter'

const UsersListToolbar = () => {
	const { setItemIdForUpdate } = useListView()
	const openAddUserModal = () => {
		setItemIdForUpdate(null)
	}

	return (
		<div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
			<UsersListFilter />
			<button type='button' className='btn btn-light-primary me-3'>
				<KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
				Export
			</button>
			<button type='button' className='btn btn-primary' onClick={openAddUserModal}>
				<i className='la la-plus fs-3' />
				Add User
			</button>
		</div>
	)
}

export { UsersListToolbar }
