import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'

const SecurityGroupHeader = (props: any) => {
	return (
		<div
			className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between align-items-center pb-2'
			data-kt-user-table-toolbar='base'
		>
			<h3 className='card-title mb-0'>
				<span className='me-2 align-middle'>
					<i className='la la-users-cog fs-1' aria-hidden='true'></i>
				</span>
				<span className='card-label fw-bold fs-3 mb-1'>Security Groups</span>
			</h3>
			<div className='d-flex justify-content-between'>
				<Link
					to={'/groupmanager/creategroup'}
					className='btn btn-light-primary main-btn-style'
				>
					<i className='la la-plus fs-3' />
					Add{' '}<span className='d-xl-inline d-none'>New Groups</span>
				</Link>
				{props.deleteDataArr.length > 0 && (
					<button
						type='button'
						className='btn btn-danger ms-2'
						onClick={() => {
							props.setDeleteModal(true)
							props.setCheck(props.deleteDataArr)
						}}
					>
						Delete Selected ( {props.deleteDataArr.length} )
					</button>
				)}
			</div>
		</div>
	)
}

export { SecurityGroupHeader }
