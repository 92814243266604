import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Images } from '_metronic/assets/Assets';
import "./Features.css"

function Features() {
    return (
        <section className="features-section" id='features'>
            <div className="custom-container container">
                <div className="text text-center mb-xl-5 mb-4">
                    <h2 className='h-main mb-xl-5 mb-4'>Features</h2>
                    <p>Vivamus eget aliquam dui. Integer eu arcu vel arcu suscipit ultrices quis non mauris.</p>
                </div>
                <div className="custom-tabs">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="1">Ultimate User</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2">Unlimited Custom Reports</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="3">Cloud-based</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="4">Configurable Email Alerts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="5">Barcode Scanning</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="6">Maintenance Scheduling</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="7">Check-in & check-out Features</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="1">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="text pe-4">
                                            <h2 className='h-main mb-xl-5 mb-4'>Unlimited Users</h2>
                                            <p>Unlimited users at no additional charge. Make your system as broad, or as narrow, as you need. Each user can have their own unique level of access ranging from limited viewing to full administrator. AssetRack is designed as a convenient solution for everyone in your organization.</p>
                                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In auctor egestas dolor et iaculis. Suspendisse et ipsum consectetur, elementum enim et, dignissim risus. Phasellus id diam erat. Pellentesque hendrerit sem sit amet odio sodales, at placerat metus gravida. Duis venenatis quam eget ligula euismod vulputate.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="image ps-lg-3 mt-lg-0 mt-3">
                                            <img src={Images.Feature_1} alt="features" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="text pe-4">
                                            <h2 className='h-main mb-xl-5 mb-4'>Unlimited Custom Reports</h2>
                                            <p>Unlimited users at no additional charge. Make your system as broad, or as narrow, as you need. Each user can have their own unique level of access ranging from limited viewing to full administrator. AssetTiger is designed as a convenient solution for everyone in your organization.</p>
                                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In auctor egestas dolor et iaculis. Suspendisse et ipsum consectetur, elementum enim et, dignissim risus. Phasellus id diam erat. Pellentesque hendrerit sem sit amet odio sodales, at placerat metus gravida. Duis venenatis quam eget ligula euismod vulputate.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="image ps-lg-3 mt-lg-0 mt-3">
                                            <img src={Images.Feature_1} alt="features" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="text pe-4">
                                            <h2 className='h-main mb-xl-5 mb-4'>Cloud-based</h2>
                                            <p>Unlimited users at no additional charge. Make your system as broad, or as narrow, as you need. Each user can have their own unique level of access ranging from limited viewing to full administrator. AssetTiger is designed as a convenient solution for everyone in your organization.</p>
                                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In auctor egestas dolor et iaculis. Suspendisse et ipsum consectetur, elementum enim et, dignissim risus. Phasellus id diam erat. Pellentesque hendrerit sem sit amet odio sodales, at placerat metus gravida. Duis venenatis quam eget ligula euismod vulputate.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="image ps-lg-3 mt-lg-0 mt-3">
                                            <img src={Images.Feature_1} alt="features" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="4">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="text pe-4">
                                            <h2 className='h-main mb-xl-5 mb-4'>Barcode Scanning</h2>
                                            <p>Unlimited users at no additional charge. Make your system as broad, or as narrow, as you need. Each user can have their own unique level of access ranging from limited viewing to full administrator. AssetTiger is designed as a convenient solution for everyone in your organization.</p>
                                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In auctor egestas dolor et iaculis. Suspendisse et ipsum consectetur, elementum enim et, dignissim risus. Phasellus id diam erat. Pellentesque hendrerit sem sit amet odio sodales, at placerat metus gravida. Duis venenatis quam eget ligula euismod vulputate.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="image ps-lg-3 mt-lg-0 mt-3">
                                            <img src={Images.Feature_1} alt="features" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="5">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="text pe-4">
                                            <h2 className='h-main mb-xl-5 mb-4'>Maintenance Scheduling</h2>
                                            <p>Unlimited users at no additional charge. Make your system as broad, or as narrow, as you need. Each user can have their own unique level of access ranging from limited viewing to full administrator. AssetTiger is designed as a convenient solution for everyone in your organization.</p>
                                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In auctor egestas dolor et iaculis. Suspendisse et ipsum consectetur, elementum enim et, dignissim risus. Phasellus id diam erat. Pellentesque hendrerit sem sit amet odio sodales, at placerat metus gravida. Duis venenatis quam eget ligula euismod vulputate.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="image ps-lg-3 mt-lg-0 mt-3">
                                            <img src={Images.Feature_1} alt="features" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="6">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="text pe-4">
                                            <h2 className='h-main mb-xl-5 mb-4'>Check-in & check-out Features</h2>
                                            <p>Unlimited users at no additional charge. Make your system as broad, or as narrow, as you need. Each user can have their own unique level of access ranging from limited viewing to full administrator. AssetTiger is designed as a convenient solution for everyone in your organization.</p>
                                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In auctor egestas dolor et iaculis. Suspendisse et ipsum consectetur, elementum enim et, dignissim risus. Phasellus id diam erat. Pellentesque hendrerit sem sit amet odio sodales, at placerat metus gravida. Duis venenatis quam eget ligula euismod vulputate.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="image ps-lg-3 mt-lg-0 mt-3">
                                            <img src={Images.Feature_1} alt="features" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="7">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="text pe-4">
                                            <h2 className='h-main mb-xl-5 mb-4'>Check-in & check-out Features</h2>
                                            <p>Unlimited users at no additional charge. Make your system as broad, or as narrow, as you need. Each user can have their own unique level of access ranging from limited viewing to full administrator. AssetTiger is designed as a convenient solution for everyone in your organization.</p>
                                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In auctor egestas dolor et iaculis. Suspendisse et ipsum consectetur, elementum enim et, dignissim risus. Phasellus id diam erat. Pellentesque hendrerit sem sit amet odio sodales, at placerat metus gravida. Duis venenatis quam eget ligula euismod vulputate.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="image ps-lg-3 mt-lg-0 mt-3">
                                            <img src={Images.Feature_1} alt="features" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </section>
    )
}

export default Features