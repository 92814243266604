export type Customer = {
  fullName: string
  company?: string | null
  address1?: string | null
  address2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  phone?: string | null
  cell?: string | null
  email?: string | null
  notes?: string | null
  createdBy: string
}

export const initialCustomers: Customer = {
  fullName: '',
  company: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  cell: null,
  email: null,
  notes: null,
  createdBy: '',
}

export type CustomerResponse = {
  id: number
  fullName: string
  company?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  phone?: string
  cell?: string
  email?: string
  notes?: string
  createdBy: string
}
