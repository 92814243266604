import { Link } from 'react-router-dom'

const CustomersHeader = (props: any) => {
	return (

		<div className='card-body d-sm-flex border-bottom border-bottom-gray-200 justify-content-between pb-3 mb-3 align-items-center flex-wrap' data-kt-user-table-toolbar='base'>
			<h3 className="card-title align-items-start flex-column mb-xl-0 mb-3 me-3">
				<span className='me-2 align-middle'><i className="la la-list fs-1" aria-hidden="true"></i></span>
				<span className="card-label fw-bold fs-3 mb-1">List of Persons/Employees</span>
			</h3>

			<div className='d-flex flex-wrap'>
				<button
					type='button'
					className='btn btn-light-primary main-btn-style m-1'
					onClick={() => props.handleShow()}
				>
					<i className='la la-plus fs-3' />
					{' '}Add<span className='d-xl-inline d-none'>{' '}New Persons/Employees</span>
				</button>
				<Link className='btn btn-light-primary main-btn-style m-1' to={'/import'}>
					<i className='la la-cloud-download fs-2 me-2'></i>{''}Import <span className='d-xl-inline d-none'>Persons/Employees</span>
				</Link>
				<div className='d-flex justify-content-end align-items-center'>
					{props.deleteDataArr.length > 0 && (
						<button
							type='button'
							className='btn btn-danger m-1'
							onClick={() => {
								props.setDeleteModal(true)
								props.setCheck(props.deleteDataArr)
							}}
						>
							Delete Selected ({props.deleteDataArr.length})
						</button>
					)}
				</div>
			</div>

		</div>
	)
}

export { CustomersHeader }
