import { Modal } from 'react-bootstrap'
import moment from 'moment'

const DuplicateReserveModal = (props: any) => {
    const handleClose = () => {
        props.handleClose()
    }

    return (
        <Modal className="custom-modal-lg px-2" show={props.show} onHide={handleClose} backdrop='static' size='lg' keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Already Reserved</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div>
                        <p>Following assets are reserved:</p>
                        <ul className='list-unstyled already-reserved-assets'>
                            {props?.reservedData && props?.reservedData?.length > 0 && props?.reservedData?.map((item: any) => {
                                return (
                                    <li className='border p-4 mb-1'>
                                        <p className='mb-0'><strong>{item?.assetTagId}</strong> is reserved by <span>{item?.reservedBy}</span> for {moment(item?.startDate).format('MMM DD, YYYY')} to {moment(item?.endDate).format('MMM DD, YYYY')}.</p>
                                    </li>
                                )
                            })
                            }
                        </ul>
                        <p>Please select different dates. Refer to the calendar for available dates.</p>
                    </div>
                    <div className=' d-flex align-items-center justify-content-end'>
                        <button className='me-2 main-btn-style btn btn-light-secondary' onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DuplicateReserveModal
