import Lottie from 'react-lottie';
import { Images } from "_metronic/assets/Assets";
import "./Banner.css"

function Banner() {
    const RocketLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: Images.RocketLottie,
    };
    const BarsLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: Images.BarsLottie,
    };
  return (
    <section className='banner d-flex align-items-center justify-content-center position-relative'>
        <div className="custom-container container">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-6">
                    <div className='text'>
                        <div className="mb-5">
                            <span className='mini-heading'>OVER 25,000 ACTIVE</span>
                            <h1 className="ultra-bold">Accounts.</h1>
                            <p className="mb-2">Manage up to 250 Assets for FREE*.</p>
                            <p className="mb-0"> Guaranteed best prices for cloud based asset management !</p>
                        </div>
                        <div className="rating d-flex">
                            <ul className="list-unstyled d-flex me-4 mb-0">
                                <li><i className="las la-star"></i></li>
                                <li><i className="las la-star"></i></li>
                                <li><i className="las la-star"></i></li>
                                <li><i className="las la-star"></i></li>
                                <li><i className="las la-star"></i></li>
                            </ul>
                            <p className="mb-0">Rated 5-Star by Independent Users</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 d-lg-block d-none">
                    <div className="arrows text-lg-end text-center position-relative z-2">
                        <img src={Images.Arrows} alt="arrows" className="img-fluid"/>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6">
                    <div className="lottie-section position-relative z-2 d-flex justify-content-end">
                        <div className='image'>
                            <img src={Images.BannerImage} alt='banner' className='img-fluid'/>
                        </div>
                        <div className="rocket-lottie">
                            <Lottie options={RocketLottieOptions} />
                        </div>
                        <div className="bars-lottie">
                            <Lottie options={BarsLottieOptions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Banner