import SitesListing from './Components/SitesListing'

const Sites = () => {
  return (
    <div>
      <SitesListing />
    </div>
  )
}

export default Sites
