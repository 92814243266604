import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'

import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import CategoryModel from './CategoryModel'
import { Category } from '../Core/_models'
import { useAuth } from '../../../../Auth'
import { useDeleteCategoryMutation, useGetAllCategoriesQuery } from '../../../../Services/Categories'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import SplashScreen from 'App/SplashScreen'
import { checkUserUnauthorized } from '../../../../Auth'
import { setupKeys } from 'config'
import Loader from 'App/BeatLoader'

const CategoryListing = () => {
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [show, setShow] = useState<boolean>(false)
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [categories, setCategories] = useState<Category[]>([])
	const [selectAll, setSelectAll] = useState<boolean>(false)
	const [check, setCheck] = useState<string[]>([])
	const [selected, setSelected] = useState<string[]>([])
	const [deleteDataArr, setDeleteDataArr] = useState<string[]>([])
	const [data, setData] = useState({})
	const [loading, setLoading] = useState<boolean>(false)
	const [pagination, setPagination] = useState<any>({})
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const { data: userCategoryList, error, isError, isLoading: isLoadingGetCategories }: any = useGetAllCategoriesQuery({
		page,
		limit,
	})
	const [deleteCategories, { isLoading: isLoadingDeleteCategories }] = useDeleteCategoryMutation()
	const [deleteCascade, setDeleteCascade] = useState(false)
	const [isSitesExist, setIsSitesExist] = useState<any>(false)
	const handleClose = () => setShow(false)

	useEffect(() => {
		if (currentUser && userCategoryList) {
			setCategories(userCategoryList?.category)
			checkVistingPage(userCategoryList?.category)
			setPagination(userCategoryList?.pagination)
			setLoading(false)
		}
	}, [userCategoryList])

	useEffect(() => {
		if (isError) {
			const errorData = error as { data: any };
			checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
		}
	}, [isError])


	const handleLimitChange = (newLimit: number) => {
		setLoading(true);
		setLimit(Number(newLimit));
		setPage(1);

	};

	const checkVistingPage = (dataList: any) => {
		if (isSitesExist === false) {
			if (dataList && dataList?.length > 0) {
				setIsSitesExist(dataList?.length)
			} else {
				setIsSitesExist(0)
			}
		}
	}

	const handleCheckboxChange = (id: string) => {
		setSelected(prevSelected => {
			const updatedSelected = prevSelected.includes(id) ? prevSelected.filter(itemId => itemId !== id) : [...prevSelected, id];
			const isAllSelected = updatedSelected.length === categories.length;
			setSelectAll(isAllSelected);
			return updatedSelected;
		});
		setDeleteDataArr(prevSelected => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter(itemId => itemId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = categories.map((item) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const handleShow = (item: Category) => {
		setData(item)
		setShow(true)
	}

	const cancel = () => {
		setDeleteModal(false)
		setDeleteCascade(false)
	}

	const confirm = async () => {
		try {
			await deleteSelectedCategory(check)
		} catch (err: any) {
			toast.error(err?.data?.message)
		}
	}

	const deleteSelectedCategory = async (ids: any[]) => {
		try {
			const categoryIds = { categoryIds: ids }
			const res = await deleteCategories({
				cascade: deleteCascade ? 1 : 0,
				id: categoryIds,
			}).unwrap()
			toast.success(res.message)
			setSelected([])
			setDeleteDataArr([])
			setCheck([])
			setDeleteModal(false)
			setDeleteCascade(false)
			setSelectAll(false)
			userCategoryList()
			setLoading(true)
		} catch (err: any) {
			setCheck([])
			setDeleteDataArr([])
			setSelectAll(false)
			setDeleteModal(false)
			setDeleteCascade(false)
			setSelected([])
			toast.error(err?.data?.message)
			checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
		}
	}

	const handlePageChange = (currentPage: any) => {
		setLoading(true)
		setPage(currentPage)
	}

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoadingGetCategories || isLoadingDeleteCategories} />
			{!(isLoadingGetCategories || isLoadingDeleteCategories) ?
				<div className='post d-flex flex-column-fluid' id='kt_post'>
					<div id='kt_content_container' className='container'>
						<div className='card'>
							<div className='card-body d-sm-flex border-bottom border-bottom-gray-200 justify-content-between pb-3 mb-3 align-items-center flex-wrap'>
								<h3 className='card-title align-items-start flex-column mb-xl-0 mb-3 me-3'>
									<span className='me-2 align-middle'>
										<i className='la la-list fs-1' aria-hidden='true'></i>
									</span>
									<span className='card-label fw-bold fs-3 mb-1'>List of Categories</span>
								</h3>
								<div className='card-toolbar d-flex flex-wrap'>
									<div className='d-flex flex-wrap' data-kt-user-table-toolbar='base'>
										<button
											type='button'
											className='btn btn-light-primary main-btn-style m-1'
											onClick={() => {
												setShow(true)
												setData({})
											}}
										>
											<i className='la la-plus fs-3' />
											Add{' '}<span className='d-xl-inline d-none'>New Category</span>
										</button>
										<ConfirmationPopup
											deleteModal={deleteModal}
											cancel={cancel}
											confirm={confirm}
											deleteCascade={deleteCascade}
											setupName={setupKeys.category}
										/>
										<CategoryModel handleClose={handleClose} show={show} data={data} page={page} setLoading={setLoading} />
									</div>
									<Link className='btn btn-light-primary main-btn-style m-1' to={'/import'}>
										<i className='la la-cloud-download fs-2 me-2'></i>{''}Import <span className='d-xl-inline d-none'>Category</span>
									</Link>
									<div>
										{deleteDataArr?.length > 0 && (
											<button
												type='button'
												className='btn btn-danger m-1'
												onClick={() => {
													setDeleteModal(true)
													setCheck(deleteDataArr)
												}}
											>
												Delete Selected ({deleteDataArr.length})
											</button>
										)}
									</div>
								</div>
							</div>
							<div className='card-body py-4'>
								<p className={' text-dark'}>
									Add the type of groups of assets. To start with, commonly used{' '}
									<strong>categories</strong> have already been created for you. Make them as broad or
									as specific as you want. <strong>Categories</strong> can be{' '}
									<em>'laptops and printers'</em>, <em>'equipment'</em>, or <em>'chairs'</em>. Customize
									to your particular need.
								</p>
								<div className='d-flex limit-options align-items-center mb-3'>
									<span className="text-muted">Showing</span>
									<select
										className='form-select form-select-sm fw-bold mx-3 border-1'
										onChange={(e) => handleLimitChange(Number(e.target.value))}
										value={limit}
									>
										<option value='10'>10</option>
										<option value='15'>15</option>
										<option value='20'>20</option>
										<option value='25'>25</option>
									</select>
									<span className="text-muted">entries</span>
								</div>
								<div className='custom_table_responsive table-responsive pb-4 custom-table'>
									<table
										id='kt_table_users'
										className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
										role='table'
									>
										<thead>
											<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
												<th role='columnheader' className='w-10px pe-2'>
													<div className='header-checkbox me-3'>
														<input
															className='form-check-input custom-form-check-input'
															type='checkbox'
															data-kt-check='false'
															data-kt-check-target='#kt_table_users .form-check-input'
															checked={selectAll}
															onChange={handleSelectAll}
														/>
													</div>
												</th>
												<th role='columnheader' className='min-w-125px'>
													Name
												</th>
												<th role='columnheader' className='w-100px text-center'>
													Actions
												</th>
											</tr>
										</thead>
										<tbody className='text-gray-600 fw-bol' role='rowgroup'>
											{categories?.length > 0 ? (
												categories?.map((item: Category, idx: number) => {
													return (
														<tr key={item?.id} role='row'>
															<td role='cell' >
																<div className='form-check form-check-sm form-check-custom '>
																	<input
																		className='form-check-input'
																		type='checkbox'
																		data-kt-check='false'
																		data-kt-check-target='#kt_table_users .form-check-input'
																		checked={selected.includes(item?.id)}
																		onChange={() => handleCheckboxChange(item?.id)}
																	/>
																</div>
															</td>
															<td role='cell' >
																<div className='d-flex align-items-center'>
																	<div>
																		{item?.category}
																	</div>
																</div>
															</td>
															<td role='cell' className='text-center'>
																<div className='dropdown'>
																	<button
																		className='dropdown-toggle'
																		type='button'
																		id='dropdownMenuButton1'
																		data-bs-toggle='dropdown'
																		aria-expanded='false'
																	>
																		Action
																		<ActionIcon />
																	</button>
																	<ul className='dropdown-menu actions-dropdown' aria-labelledby='dropdownMenuButton1'>
																		<li>
																			<button className='dropdown-item' onClick={() => handleShow(item)}>
																				Edit
																			</button>
																		</li>
																		<li>
																			<button
																				className='dropdown-item'
																				onClick={() => {
																					setDeleteModal(true)
																					setCheck([item.id])
																				}}
																			>
																				Delete
																			</button>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													)
												})
											) : (
												<tr>
													<td colSpan={12}>
														<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
															No records found
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								{pagination && categories?.length > 0 && (
									<Pagination
										className='m-3 custom-pagination pt-5'
										defaultCurrent={1}
										pageSize={limit}
										current={pagination?.page}
										total={pagination?.total}
										onChange={handlePageChange}
										locale={localeInfo}
									/>
								)}
							</div>
							{isSitesExist === 0 ? (
								<div className='card-footer d-flex justify-content-end py-6 px-9'>
									<button
										onClick={() => {
											navigate(`/apps/setup/department`)
										}}
										className='btn btn-primary'
									>
										Continue
									</button>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					<Loader loading={loading}/>
				</div>
				: null
			}

		</>
	)
}

export default CategoryListing
