import { Images } from "_metronic/assets/Assets"
import "./Pricing.css"

function Pricing() {
    return (
        <section className='pricing-section'>
            <div className="custom-container container">
                <div className="text text-center mb-xl-5 mb-4">
                    <h2 className='h-main mb-xl-5 mb-4 text-white'>Pricing Comparison</h2>
                    <p className='mb-xl-5 mb-4'>AssetsRack is the first fully functional asset management program, completely free for first 300 assets.</p>
                    <p className='mb-xl-5 mb-4'>The world’s biggest asset tag retailer, AssetsRack means no more paying for other websites’ expensive services. With AssetsRack, you’ll get quality, flexibility, and security.</p>
                </div>
                <div className="row pt-15">
                    <div className="col-lg-4">
                        <div className="pricing-box">
                            <div className="text mb-xl-4 mb-3">
                                <h2 className="h-main mb-xl-5 mb-4">Competitor #1</h2>
                                <p className="mb-xl-5 mb-4">Smartphone Compatible ($15 per device per month) in addition to yearly price</p>
                            </div>
                            <div className="mini-box">
                                <strong className="mb-2 d-block">Subscription</strong>
                                <div className="inner-mini d-flex mb-xl-5 mb-4">
                                    <div className="left d-flex justify-content-center align-items-center flex-column">
                                        <div className="number mb-xl-5 mb-lg-4 mb-2">5</div>
                                        <div className="description">Users</div>
                                    </div>
                                    <div className="right d-flex justify-content-center align-items-center flex-column">
                                       <div className="number mb-xl-5 mb-lg-4 mb-2">10 GB</div>
                                        <div className="description">Cloud Storage</div>
                                    </div>
                                </div>
                                <div className="price">
                                    <strong><span>$8,700</span>{" / "}<span>per Year</span></strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="pricing-box">
                            <div className="text mb-xl-4 mb-3">
                                <div className="image mb-xl-5 mb-4">
                                    <img src={Images.Logo} alt="Logo" className="img-fluid"/>
                                </div>
                                <p className="mb-xl-5 mb-4">Smartphone Compatible (No Extra Charges)</p>
                            </div>
                            <div className="mini-box active">
                                <strong className="mb-2 d-block">Subscription</strong>
                                <div className="inner-mini d-flex mb-xl-5 mb-4">
                                    <div className="left d-flex justify-content-center align-items-center flex-column">
                                        <div className="number mb-xl-5 mb-lg-4 mb-2">Unlimited</div>
                                        <div className="description">Users</div>
                                    </div>
                                    <div className="right d-flex justify-content-center align-items-center flex-column">
                                       <div className="number mb-xl-5 mb-lg-4 mb-2">Unlimited</div>
                                        <div className="description">Cloud Storage</div>
                                    </div>
                                </div>
                                <div className="price">
                                    <strong className="d-block mb-3 free"><span>FREE*</span>{" / "}<span>for 250 Assets</span></strong>
                                    <p className="mb-0">* See Subscription Plans for details</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="pricing-box">
                            <div className="text mb-xl-4 mb-3">
                                <h2 className="h-main mb-xl-5 mb-4">Competitor #2</h2>
                                <p className="mb-xl-5 mb-4">Smartphone Compatible ($295/device) in addition to yearly price</p>
                            </div>
                            <div className="mini-box">
                                <strong className="mb-2 d-block">Subscription</strong>
                                <div className="inner-mini d-flex mb-xl-5 mb-4">
                                    <div className="left d-flex justify-content-center align-items-center flex-column">
                                        <div className="number mb-xl-5 mb-lg-4 mb-2">5</div>
                                        <div className="description">Users</div>
                                    </div>
                                    <div className="right d-flex justify-content-center align-items-center flex-column">
                                       <div className="number mb-xl-5 mb-lg-4 mb-2">5 GB</div>
                                        <div className="description">Cloud Storage</div>
                                    </div>
                                </div>
                                <div className="price">
                                    <strong><span>$900</span>{" / "}<span>per Year</span></strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing