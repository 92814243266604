import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const API_URL = process.env.REACT_APP_API_URL

export const tableOptionApi = createApi({
	reducerPath: 'tableOptionApi',
	baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/` }),
	tagTypes: ['TableOption'],
	endpoints: (build) => ({
		getTableOption: build.query<any, any>({
			query: (token) => ({
				url: `table-option`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`
				}
			}),
			providesTags: ['TableOption'],
		}),
		addTableOption: build.mutation<any, { data: any, token: any }>({
			query: ({ data, token }) => ({
				url: `table-option`,
				method: 'POST',
				body: {
					data
				},
				headers: {
					Authorization: `Bearer ${token}`
				}
			}),
			invalidatesTags: () => [{ type: 'TableOption' }],
		}),
	}),
})

export const {
	useGetTableOptionQuery,
	useAddTableOptionMutation,
} = tableOptionApi
