import axios from 'axios'
import {AuthModel, UserModel, Response} from './_models'
import {setupAxios} from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_URL}/auth/change-password`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const REQUERT_LOGOUT_URL = `${API_URL}/auth/logout`

export function login(email: string, password: string, rememberme: boolean, otp?: string) {
  return axios.post<any>(LOGIN_URL, {
    email,
    password,
    rememberme,
    otp,
  })
}
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  agreedToTerms: boolean
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
    agreedToTerms,
  })
}
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resetPassword(
  newPassword: string,
  passwordConfirmation: string,
  verifyToken: string | null,
  flag : Number | null
) {
  return axios.post<any>(
    REQUEST_RESET_PASSWORD_URL,
    {
      newPassword,
      passwordConfirmation,
      verifyToken,
      flag
    }
  )
}

export function getUserByToken(token: string) {
  return axios.post<any>(GET_USER_BY_ACCESSTOKEN_URL, {
    token,
  })
}

export function verifyEmail(token: string | undefined) {
  return axios.get(`${API_URL}/auth/verify-email/${token}`)
}

export function resendEmail(email: string) {
  return axios.post(`${API_URL}/auth/resend-email`, {email})
}

export function googleLogin() {
  return axios.get(`${API_URL}/auth/google`)
}

export function changePassword(
  currentPassword: string,
  newPassword: string,
  passwordConfirmation: string
) {
  return axios.patch<any>(REQUEST_CHANGE_PASSWORD_URL, {
    currentPassword,
    newPassword,
    passwordConfirmation,
  })
}

export function logoutUser(token: string) {
  setupAxios(axios)
  return axios.post<any>(REQUERT_LOGOUT_URL, {token})
}
