import { ListViewProvider, useListView } from './Core/ListViewProvider'
import { QueryRequestProvider } from './Core/QueryRequestProvider'
import { QueryResponseProvider } from './Core/QueryResponseProvider'
import { AccountTable } from './Table/AccountTable'
import { KTCard } from '../../../../_metronic/helpers'

const AccountList = () => {

	return (
		<>
			<KTCard>
				<AccountTable />
			</KTCard>

		</>
	)
}

const AccountListWrapper = () => (
	<QueryRequestProvider>
		<QueryResponseProvider>
			<ListViewProvider>
			</ListViewProvider>
		</QueryResponseProvider>
	</QueryRequestProvider>
)

export { AccountListWrapper }
