import React, { useState } from 'react'
import {useGetAllGroupsQuery} from 'App/Modules/Services/groups'
import { GroupResponse } from '../../SecurityGroups/Core/_models';

function ManageGroup({onSelectGroup}) {
  const {data: groupsList} = useGetAllGroupsQuery({})
  const [selectedGroup, setSelectedGroup] = useState('');
  const handleGroupSelect = (groupName) => {
    setSelectedGroup(groupName);
    onSelectGroup(groupName);
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
      >
        <div className='card-title m-0'>
          <span className='me-2 align-middle pt-1'>
            <i className='la la-key fs-1'></i>
          </span>
          <h3 className='fw-bolder m-0'> Security Group</h3>
        </div>
      </div>
      <div id='kt_two-factor-authentication' className='collapse show'>
        <div className='card-body border-top p-lg-9 p-md-7 p-6'>
          <p className='mb-lg-10 mb-5'>
            Decide which parts of AssetTiger be accessible to this user by assigning a Security
            Group. Select the <strong>Administrator group</strong> to give full access, the{' '}
            <strong>Manager group</strong> to give full access without Admin. Rights, the{' '}
            <strong>Viewer group</strong> to give this user the ability to view the basics of this
            account. Or, you can create your own custom Security Groups.
          </p>
          <div className='row'>
            <div className='offset-2 col-lg-8'>
              <div className='d-flex align-items-end mb-3'>
                <div className='form-group me-3 mb-0 w-100 d-flex align-items-center'>
                  <label className='col-form-label pt-0 pb-1 me-3'>Group Name </label>
                  <select
                    name='recordCount'
                    id='recordCount'
                    className='form-select flex-1'
                    onChange={(e) => handleGroupSelect(e.target.value)}
                  >
                    <option value=''>Select Group</option>
                    {groupsList?.groups.map((item: GroupResponse, idx: number) => (
                      <option key={idx} >
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  <span
                    className='field-validation-valid'
                    data-valmsg-for='recordCount'
                    data-valmsg-replace='true'
                  ></span>
                </div>

                <button className='btn btn-light-primary main-btn-style'>Manage Groups</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageGroup
