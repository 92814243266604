import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { companyApi } from "./Modules/Services/Company";
import { sitesApi } from "./Modules/Services/Sites";
import { personsApi } from "./Modules/Services/Persons";
import { customersApi } from "./Modules/Services/Customers";
import { assetsApi } from "./Modules/Services/Assets";
import { optionApi } from "./Modules/Services/Option";
import { tableOptionApi } from "./Modules/Services/TableOption";
import { eventsApi } from "./Modules/Services/Events";
import { locationsApi } from "./Modules/Services/Locations";
import { categoriesApi } from "./Modules/Services/Categories";
import { departmentsApi } from "./Modules/Services/Departments";
import { checkoutApi } from "./Modules/Services/Checkout";
import { leaseApi } from "./Modules/Services/Lease";
import { moveApi } from "./Modules/Services/Move";
import { maintenanceApi } from "./Modules/Services/Maintenance";
import { disposeApi } from "./Modules/Services/Dispose";
import { leaseReturnApi } from "./Modules/Services/LeaseReturn";
import { reserveApi } from "./Modules/Services/Reserve";
import { exportsApi } from "./Modules/Services/Exports";
import { importsApi } from "./Modules/Services/Imports";
import { emailTemplateApi } from "./Modules/Services/EmailTemplate";
import { profileApi } from "./Modules/Services/Profile";
import { subUserApi } from "./Modules/Services/SubUser";
import { groupsApi } from './Modules/Services/groups'
import { setupApi } from './Modules/Services/setupColumn'
import { warrantyApi } from './Modules/Services/Warranty'
export const store = configureStore({
  reducer: {
    [companyApi.reducerPath]: companyApi.reducer,
    [sitesApi.reducerPath]: sitesApi.reducer,
    [personsApi.reducerPath]: personsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [optionApi.reducerPath]: optionApi.reducer,
    [tableOptionApi.reducerPath]: tableOptionApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [checkoutApi.reducerPath]: checkoutApi.reducer,
    [leaseApi.reducerPath]: leaseApi.reducer,
    [moveApi.reducerPath]: moveApi.reducer,
    [maintenanceApi.reducerPath]: maintenanceApi.reducer,
    [disposeApi.reducerPath]: disposeApi.reducer,
    [leaseReturnApi.reducerPath]: leaseReturnApi.reducer,
    [reserveApi.reducerPath]: reserveApi.reducer,
    [exportsApi.reducerPath]: exportsApi.reducer,
    [importsApi.reducerPath]: importsApi.reducer,
    [emailTemplateApi.reducerPath]: emailTemplateApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [subUserApi.reducerPath]: subUserApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [setupApi.reducerPath]: setupApi.reducer,
    [warrantyApi.reducerPath]: warrantyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      companyApi.middleware,
      sitesApi.middleware,
      personsApi.middleware,
      customersApi.middleware,
      optionApi.middleware,
      tableOptionApi.middleware,
      eventsApi.middleware,
      locationsApi.middleware,
      categoriesApi.middleware,
      departmentsApi.middleware,
      assetsApi.middleware,
      checkoutApi.middleware,
      leaseApi.middleware,
      moveApi.middleware,
      maintenanceApi.middleware,
      disposeApi.middleware,
      leaseReturnApi.middleware,
      reserveApi.middleware,
      exportsApi.middleware,
      importsApi.middleware,
      emailTemplateApi.middleware,
      profileApi.middleware,
      subUserApi.middleware,
      groupsApi.middleware,
      setupApi.middleware,
      warrantyApi.middleware
    ),
})

setupListeners(store.dispatch)
