import { useState, useEffect } from "react"
import PreviewTable from "./PreviewTable"

const OrderTableColumns = ({ setColumnsData, columnsData }: any) => {
    const [allColumns, setAllColumns] = useState(false)
    const [organizedData, setOrganizedData] = useState({});

    const handleAllColumns = () => {
        setAllColumns((prev) => !prev)
        setColumnsData((prevAssets: any) =>
            prevAssets.map((asset: any) => ({ ...asset, defaultActive: !allColumns }))
        );
    }

    const handleCheckboxChange = (itemId: number): void => {
        setColumnsData((prevData: any) => {
            const updatedData = prevData?.map((item: any) =>
                item.id === itemId
                    ? { ...item, defaultActive: !item.defaultActive }
                    : item
            );

            const hasTrueValue = updatedData.some((item: any) => item.defaultActive);

            if (!hasTrueValue && updatedData.length > 1) {
                return prevData;
            }

            return updatedData;
        });
    };

    useEffect(() => {
        const calculatedOrganizedData = columnsData?.reduce((acc: any, item: any) => {
            if (!acc[item.field]) {
                acc[item.field] = [];
            }
            acc[item.field].push(item);
            return acc;
        }, {});

        setOrganizedData(calculatedOrganizedData);
    }, [columnsData]);
    return (
        <>
            <div className='card-body border-top p-9 px-0'>
                <div className='heading'>
                    <h5>Step 2: Select and Order Table Columns</h5>
                </div>
                <p>
                    Choose which columns you want in your table by checking the appropriate boxes.
                </p>
                <label className="mb-4">
                    <input
                        type="checkbox"
                        className="custom-form-check-input form-check-input sig_input me-lg-3 me-2"
                        checked={allColumns}
                        onChange={() => handleAllColumns()}
                    />
                    {"All columns"}
                </label>
                <div className="row">
                    {Object.entries(organizedData)?.map(([heading, items]: any) => (
                        <div key={heading} className="col-md-4 col-sm-6 col-12">
                            <h5>{heading}</h5>
                            <ul className="list-unstyled">
                                {items?.map((item: any) => (
                                    <li key={item?.id} className="mb-2">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="custom-form-check-input form-check-input sig_input me-lg-3 me-2"
                                                checked={item?.defaultActive}
                                                onChange={() => handleCheckboxChange(item?.id)}
                                            />
                                            {item?.header}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <PreviewTable columnsData={columnsData} />
        </>
    )
}

export default OrderTableColumns