import React, { useEffect } from 'react'
import { KTSVG, toAbsoluteUrl, toAbsoluteUrlImage } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../_metronic/partials'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../Auth'
import { Images } from '_metronic/assets/Assets'

const ProfileHeader: React.FC = () => {
	const location = useLocation()
	const { currentUser } = useAuth()

	useEffect(() => {
	}, [currentUser])


	return (
		<div className='card mb-5 mb-xl-10'>
			<div className='card-body pt-9 pb-0'>
				<div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
					<div className='me-7 mb-4'>
						<div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
							<img className='image-input-wrapper w-125px h-125px' src={currentUser?.photo ? toAbsoluteUrlImage(currentUser?.photo) : Images.Avatar_JPG} alt="profile" crossOrigin="anonymous" />
						</div>
					</div>
					<div className='flex-grow-1'>
						<div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
							<div className='d-flex flex-column'>
								<div className='d-flex align-items-center mb-2'>
									<Link to='/dummy-link' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 text-captitalize'>
										{currentUser?.firstName} {currentUser?.lastName}
									</Link>
									<Link to='/dummy-link'>
										<KTSVG
											path='/media/icons/duotune/general/gen026.svg'
											className='svg-icon-1 svg-icon-primary'
										/>
									</Link>
								</div>

								<div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
									{currentUser?.title ? <Link
										to='/dummy-link'
										className='d-flex align-items-center text-hover-primary me-5 mb-2'
									>
										<KTSVG
											path='/media/icons/duotune/communication/com006.svg'
											className='svg-icon-4 me-1'
										/>
										{currentUser?.title}
									</Link> : ""}
									<Link
										to='/dummy-link'
										className='d-flex align-items-center text-hover-primary mb-2'
									>
										<KTSVG
											path='/media/icons/duotune/communication/com011.svg'
											className='svg-icon-4 me-1'
										/>
										{currentUser?.email}
									</Link>
								</div>
							</div>

							<div className='d-flex my-4'>
								<Link to='/dummy-link' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
									<KTSVG
										path='/media/icons/duotune/arrows/arr012.svg'
										className='svg-icon-3 d-none'
									/>

									<span className='indicator-label'>Follow</span>
									<span className='indicator-progress'>
										Please wait...
										<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								</Link>
								<div className='me-0'>
									<button
										className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
										data-kt-menu-trigger='click'
										data-kt-menu-placement='bottom-end'
										data-kt-menu-flip='top-end'
									>
										<i className='bi bi-three-dots fs-3'></i>
									</button>
									<Dropdown1 />
								</div>
							</div>
						</div>

						<div className='d-flex flex-wrap flex-stack'>
							<div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
								<div className='d-flex justify-content-between w-100 mt-auto mb-2'>
									<span className='fw-bold fs-6 text-gray-400'>Profile Completion</span>
									<span className='fw-bolder text-gray-400 fs-6'>50%</span>
								</div>
								<div className='h-5px mx-3 w-100 bg-light mb-3'>
									<div
										className='bg-success rounded h-5px progressbar'
										role='progressbar'
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='d-flex overflow-auto h-55px'>
					<ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
						<li className='nav-item'>
							<Link
								className={
									`nav-link text-active-primary me-6 ` +
									(location.pathname === '/crafted/pages/profile/overview' && 'active')
								}
								to='/crafted/pages/profile/overview'
							>
								Overview
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export { ProfileHeader }
