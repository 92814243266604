import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { Link } from 'react-router-dom'

type Props = {
  className: string
}

const FeedsWidget4: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body pb-0'>
        <div className='d-flex align-items-center mb-5'>
          <div className='d-flex align-items-center flex-grow-1'>
            <div className='symbol symbol-45px me-5'>
              <img src={toAbsoluteUrl('/media/avatars/300-7.jpg')} alt='' />
            </div>
            <div className='d-flex flex-column'>
              <Link to='/dummy-link' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Carles Nilson
              </Link>
              <span className='text-gray-400 fw-semibold'>Last week at 10:00 PM</span>
            </div>
          </div>
          <div className='my-0'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <Dropdown1 />
          </div>
        </div>
        <div className='mb-7'>
          <div className='text-gray-800 mb-5'>
            Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
            about driving and keep you focused on the overall structure of your post
          </div>
          <div className='d-flex align-items-center mb-5'>
            <Link
              to='/dummy-link'
              className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
            >
              <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-3' />
              22
            </Link>

            <Link
              to='/dummy-link'
              className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
            >
              <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
              59
            </Link>
          </div>
        </div>
        <div className='separator mb-4'></div>
        <form className='position-relative mb-6'>
          <textarea
            className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
            rows={1}
            placeholder='Reply..'
          ></textarea>

          <div className='position-absolute top-0 end-0 me-n5'>
            <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
              <KTSVG
                path='/media/icons/duotune/communication/com008.svg'
                className='svg-icon-3 mb-3'
              />
            </span>

            <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
              <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-2 mb-3' />
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export { FeedsWidget4 }
