import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/user/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getProfile: build.query<any, void>({
      query: () => `getProfile`,
      providesTags: ['Profile'],
    }),
    updateProfile: build.mutation<any, any>({
      query: (formData) => ({
        url: `profile`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ['Profile'],
    }),
    updateTwoFactor: build.mutation<any, any>({
      query: (data) => ({
        url: `two-factor-authentication`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Profile'],
    }),
    verifyPhone: build.mutation<any, any>({
      query: (data) => ({
        url: `verify-number`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteProfileImage: build.mutation<any, any>({
      query: () => ({
        url: `delete-image`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
})

export const {
  useGetProfileQuery,
  useUpdateTwoFactorMutation,
  useVerifyPhoneMutation,
  useUpdateProfileMutation,
  useDeleteProfileImageMutation,
} = profileApi
