import { useNavigate } from 'react-router'

function SetupWizard({ isDataExist }) {
    const navigate = useNavigate()
    const onClickVisit = (path) => {
        if (isDataExist === 0) {
            navigate(`/apps/setup/${path}`)
        } else {
            return ''
        }
    }
    return (
        <div className='border-bottom'>
            <div className='wizard-steps'>
                <span className='wizard-step active'>
                    <div className='wizard-label'>
                        <span className='wizard-icon'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                <path d='M0 8a8 8 0 1116 0A8 8 0 010 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 005.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 01.64-1.539 6.7 6.7 0 01.597-.933A7.025 7.025 0 002.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 00-.656 2.5h2.49zM4.847 5a12.5 12.5 0 00-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 00-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 00.337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 01-.597-.933A9.268 9.268 0 014.09 12H2.255a7.024 7.024 0 003.072 2.472zM3.82 11a13.652 13.652 0 01-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0013.745 12H11.91a9.27 9.27 0 01-.64 1.539 6.688 6.688 0 01-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 01-.312 2.5zm2.802-3.5a6.959 6.959 0 00-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 00-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 00-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z'></path>
                            </svg>
                        </span>
                        <h2 className='wizard-title'>1. Company</h2>
                    </div>
                    <span className='wizard-arrow'>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                    </span>
                </span>
                <span className='wizard-step'>
                    <div className='wizard-label'>
                        <div className='wizard-icon'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                <path d='M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 018 14.58a31.481 31.481 0 01-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0110 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 002 6c0 4.314 6 10 6 10z'></path>
                                <path d='M8 8a2 2 0 110-4 2 2 0 010 4zm0 1a3 3 0 100-6 3 3 0 000 6z'></path>
                            </svg>
                        </div>
                        <h2 className='wizard-title'>2. Sites</h2>
                    </div>
                    <span className='wizard-arrow' onClick={() => onClickVisit('sites')}>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                    </span>
                </span>
                <span className='wizard-step'>
                    <div className='wizard-label'>
                        <div className='wizard-icon'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                <path
                                    fill-rule='evenodd'
                                    d='M3.1 11.2a.5.5 0 01.4-.2H6a.5.5 0 010 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 010-1h2.5a.5.5 0 01.4.2l3 4a.5.5 0 01-.4.8H.5a.5.5 0 01-.4-.8l3-4z'
                                ></path>
                                <path
                                    fill-rule='evenodd'
                                    d='M8 1a3 3 0 100 6 3 3 0 000-6zM4 4a4 4 0 114.5 3.969V13.5a.5.5 0 01-1 0V7.97A4 4 0 014 3.999z'
                                ></path>
                            </svg>
                        </div>
                        <h2 className='wizard-title'>3. Locations</h2>
                    </div>
                    <span className='wizard-arrow' onClick={() => onClickVisit('location')}>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                    </span>
                </span>
                <span className='wizard-step'>
                    <div className='wizard-label'>
                        <div className='wizard-icon'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                <path d='M5 10.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5z'></path>
                                <path d='M3 0h10a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2v-1h1v1a1 1 0 001 1h10a1 1 0 001-1V2a1 1 0 00-1-1H3a1 1 0 00-1 1v1H1V2a2 2 0 012-2z'></path>
                                <path d='M1 5v-.5a.5.5 0 011 0V5h.5a.5.5 0 010 1h-2a.5.5 0 010-1H1zm0 3v-.5a.5.5 0 011 0V8h.5a.5.5 0 010 1h-2a.5.5 0 010-1H1zm0 3v-.5a.5.5 0 011 0v.5h.5a.5.5 0 010 1h-2a.5.5 0 010-1H1z'></path>
                            </svg>
                        </div>
                        <h2 className='wizard-title'>4. Categories</h2>
                    </div>
                    <span className='wizard-arrow' onClick={() => onClickVisit('categories')}>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                    </span>
                </span>
                <span className='wizard-step'>
                    <div className='wizard-label'>
                        <div className='wizard-icon'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                <path d='M8.235 1.559a.5.5 0 00-.47 0l-7.5 4a.5.5 0 000 .882L3.188 8 .264 9.559a.5.5 0 000 .882l7.5 4a.5.5 0 00.47 0l7.5-4a.5.5 0 000-.882L12.813 8l2.922-1.559a.5.5 0 000-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 00.47 0l3.515-1.874zM8 9.433L1.562 6 8 2.567 14.438 6 8 9.433z'></path>
                            </svg>
                        </div>
                        <h2 className='wizard-title'>5. Database</h2>
                    </div>
                    <span className='wizard-arrow' onClick={() => onClickVisit('categories')}>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                    </span>
                </span>
                <span className='wizard-step'>
                    <div className='wizard-label'>
                        <div className='wizard-icon'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                <path d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z'></path>
                            </svg>
                        </div>
                        <h2 className='wizard-title'>6. Table Options</h2>
                    </div>
                    <span className='wizard-arrow'>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                    </span>
                </span>
                <span className='wizard-step'>
                    <div className='wizard-label'>
                        <div className='wizard-icon'>
                            <i className='la la-calendar-check-o' aria-hidden='true'></i>
                        </div>
                        <h2 className='wizard-title'>7. Event Options</h2>
                    </div>
                    <span className='wizard-arrow last'>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                    </span>
                </span>
            </div>
        </div>
    )
}

export default SetupWizard
