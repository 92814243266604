import Lottie from 'react-lottie';
import { Images } from '_metronic/assets/Assets';

import "./Benefits.css"

function Benefits() {
    const LayersLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: Images.LayersLottie,
    };
    return (
        <section className='benefits-section' id='benefits'>
            <div className="text text-center mb-xl-5 mb-4">
                <h2 className='h-main mb-xl-5 mb-4'>Benefits</h2>
                <p>Yovamus eget aliquam dui. Integer eu arcu vel arcu suscipit ultrices quis non mauris.</p>
            </div>
            <div className="benefits-listing pt-5">
                <div className="custom-container container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_1} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Assets</strong>
                                <p className="description mb-0">Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque enean enim.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_2} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Contracts and Licenses</strong>
                                <p className="description mb-0">View predefined reports which include statements about your assets to help clean up your workload.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_3} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Reports</strong>
                                <p className="description mb-0">View predefined reports which include statements about your assets to help clean up your workload.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_4} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Security</strong>
                                <p className="description mb-0">SSL Encryption protects data as it transfers from your computer, tablet, or smartphone</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_5} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Mobile</strong>
                                <p className="description mb-0">You’re constantly on the move, but that shouldn’t affect your asset management.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_6} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Innovation</strong>
                                <p className="description mb-0">We’re continually evolving to meet and exceed customers’ expectations, advances in technology, and the world around us.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_7} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Users</strong>
                                <p className="description mb-0">Set reminders and alarms for assets that require regular maintenance, assets</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="box d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="layers-lottie">
                                    <Lottie options={LayersLottieOptions} />
                                </div>
                                <div className="icon mb-xl-5 mb-4">
                                    <img src={Images.Benefit_8} alt="icon" className="img-fluid" />
                                </div>
                                <strong className="name mb-3 d-block">Reserve & Check Out</strong>
                                <p className="description mb-0">Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque enean enim.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits