import "@fontsource/outfit";
import Header from "./Header/Header"
import Banner from "./Banner/Banner"
import TrustedBy from "./TrustedBy/TrustedBy";
import AboutUs from "./AboutUs/AboutUs";
import Benefits from "./Benefits/Benefits";
import Reviews from "./Reviews/Reviews";
import Features from "./Features/Features";
import PricingAndSubsrcription from "./PricingAndSubsrcription/PricingAndSubsrcription";
import Faqs from "./Faqs/Faqs";
import Footer from "./Footer/Footer";

function Home() {
	return (
		<div className="home">
			<Header />
			<Banner />
			<TrustedBy />
			<AboutUs />
			<Benefits />
			<Reviews />
			<Features />
			<PricingAndSubsrcription />
			<Faqs />
			<Footer />
		</div>
	)
}

export default Home