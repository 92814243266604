import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../Pages/Dashboard/DashboardWrapper'
import { MenuTestPage } from '../Pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../Pages/LayoutBuilder/BuilderPageWrapper'
import Checkout from '../Pages/Checkout/Checkout'
import Reserve from 'App/Pages/Reserve/Reserve'
import Checkin from '../Pages/Checkin/Checkin'
import AddAssets from 'App/Modules/Apps/CompanySetup/Asset/Components/AssetsInfo'
import Lease from '../Modules/Lease/Lease'
import LeaseReturn from '../Modules/LeaseReturn/LeaseReturn'
import Move from '../Modules/Move/Move'
import Maintenance from '../Modules/Maintenance/Maintenance'
import Maintenances from 'App/Modules/Maintenance/Maintenances'
import Warranties from 'App/Modules/Warranties/Warranties'
import Dispose from '../Modules/Dispose/Dispose'
import Import from "../Modules/Import/Import"
import Export from 'App/Modules/Export/Export'
import Customers from '../Modules/Apps/CompanySetup/Customers/Customers'
import Persons from '../Modules/Apps/CompanySetup/Persons/Persons'
import Users from "../Modules/Apps/CompanySetup/Users/Users"
import SecurityGroups from "../Modules/Apps/CompanySetup/SecurityGroups/SecurityGroups"
import UserAssets from '../Modules/Apps/CompanySetup/Asset/Assets'
import AssetDetails from '../Modules/Apps/CompanySetup/Asset/Components/AssetDetail'
import ProfilePage from '../Modules/Profile/ProfilePage'
import WizardsPage from '../Modules/Wizards/WizardsPage'
import AccountPage from '../Modules/Accounts/AccountPage'
import AccountDetailsPage from '../Modules/AccountDetails/AccountDetailsPage'
import WidgetsPage from '../Modules/Widgets/WidgetsPage'
import ChatPage from '../Modules/Apps/Chat/ChatPage'
import UsersPage from '../Modules/Apps/UserManagement/UsersPage'
import SetupPage from '../Modules/Apps/CompanySetup/SetupPage'
import AssetPage from '../Modules/Apps/CompanySetup/AssetPage'
import AddUsers from 'App/Modules/Apps/CompanySetup/Users/Components/AddUsers'
import CreateGroup from 'App/Modules/Apps/CompanySetup/SecurityGroups/Components/CreateGroup'


const PrivateRoutes = () => {
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route path='auth/*' element={<Navigate to='/dashboard' />} />
				<Route path='dashboard' element={<DashboardWrapper />} />
				<Route path='checkout' element={<Checkout />} />
				<Route path='checkin' element={<Checkin />} />
				<Route path='asset/add' element={<AddAssets />} />
				<Route path='asset/edit/:id' element={<AddAssets />} />
				<Route path='asset/view/:id' element={<AssetDetails />} />
				<Route path='lease' element={<Lease />} />
				<Route path='lease-return' element={<LeaseReturn />} />
				<Route path='maintenance' element={<Maintenance />} />
				<Route path='maintenances' element={<Maintenances />} />
				<Route path='warranties' element={<Warranties />} />
				<Route path='dispose' element={<Dispose />} />
				<Route path='move' element={<Move />} />
				<Route path='assets' element={<UserAssets />} />
				<Route path='list-of-assets' element={<UserAssets />} />
				<Route path='import' element={<Import />} />
				<Route path='export' element={<Export />} />
				<Route path='persons' element={<Persons />} />
				<Route path='customers' element={<Customers />} />
				<Route path='users' element={<Users />} />
				<Route path='users/add' element={<AddUsers />} />
				<Route path='groupmanager' element={<SecurityGroups />} />
				<Route path='groupmanager/creategroup' element={<CreateGroup />} />
				<Route path='reserve' element={<Reserve />} />
				<Route path='menu-test' element={<MenuTestPage />} />
				<Route
					path='crafted/pages/profile/*'
					element={
						<SuspensedView>
							<ProfilePage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/pages/account-details/*'
					element={
						<SuspensedView>
							<AccountDetailsPage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/pages/wizards/*'
					element={
						<SuspensedView>
							<WizardsPage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/widgets/*'
					element={
						<SuspensedView>
							<WidgetsPage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/account/*'
					element={
						<SuspensedView>
							<AccountPage />
						</SuspensedView>
					}
				/>
				<Route
					path='apps/user-management/*'
					element={
						<SuspensedView>
							<UsersPage />
						</SuspensedView>
					}
				/>
				<Route
					path='apps/setup/*'
					element={
						<SuspensedView>
							<SetupPage />
						</SuspensedView>
					}
				/>
				<Route
					path='apps/asset/*'
					element={
						<SuspensedView>
							<AssetPage />
						</SuspensedView>
					}
				/>
				<Route path='*' element={<Navigate to='/error/404' />} />
			</Route>
		</Routes>
	)
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
