import React, { FC, useEffect, useRef, useState } from 'react'
import { Step1 } from './Steps/Step1'
import { Step2 } from './Steps/Step2'
import { Step3 } from './Steps/Step3'
import { Step4 } from './Steps/Step4'
import { Step5 } from './Steps/Step5'
import { KTSVG } from '../../../../_metronic/helpers'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Formik, Form, FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateAccountWizardHelper'

const Horizontal: FC = () => {
	const stepperRef = useRef<HTMLDivElement | null>(null)
	const stepper = useRef<StepperComponent | null>(null)
	const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
	const [initValues] = useState<ICreateAccount>(inits)
	const [isSubmitButton, setSubmitButton] = useState(false)

	const loadStepper = () => {
		stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
	}

	const prevStep = () => {
		if (!stepper.current) {
			return
		}

		stepper.current.goPrev()

		setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

		setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
	}

	const submitStep = (values: ICreateAccount, actions: FormikValues) => {
		if (!stepper.current) {
			return
		}

		if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
			stepper.current.goNext()
		} else {
			stepper.current.goto(1)
			actions.resetForm()
		}

		setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)

		setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
	}

	useEffect(() => {
		if (!stepperRef.current) {
			return
		}

		loadStepper()
	}, [stepperRef])

	return (
		<div className='card'>
			<div className='card-body'>
				<div
					ref={stepperRef}
					className='stepper stepper-links d-flex flex-column pt-15'
					id='kt_create_account_stepper'
				>
					<div className='stepper-nav mb-5'>
						<div className='stepper-item current' data-kt-stepper-element='nav'>
							<h3 className='stepper-title'>Account Type</h3>
						</div>

						<div className='stepper-item' data-kt-stepper-element='nav'>
							<h3 className='stepper-title'>Account Info</h3>
						</div>

						<div className='stepper-item' data-kt-stepper-element='nav'>
							<h3 className='stepper-title'>Business Info</h3>
						</div>

						<div className='stepper-item' data-kt-stepper-element='nav'>
							<h3 className='stepper-title'>Billing Details</h3>
						</div>

						<div className='stepper-item' data-kt-stepper-element='nav'>
							<h3 className='stepper-title'>Completed</h3>
						</div>
					</div>

					<Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
						{() => (
							<Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
								<div className='current' data-kt-stepper-element='content'>
									<Step1 />
								</div>

								<div data-kt-stepper-element='content'>
									<Step2 />
								</div>

								<div data-kt-stepper-element='content'>
									<Step3 />
								</div>

								<div data-kt-stepper-element='content'>
									<Step4 />
								</div>

								<div data-kt-stepper-element='content'>
									<Step5 />
								</div>

								<div className='d-flex flex-stack pt-15'>
									<div className='mr-2'>
										<button
											onClick={prevStep}
											type='button'
											className='btn btn-lg btn-light-primary me-3'
											data-kt-stepper-action='previous'
										>
											<KTSVG
												path='/media/icons/duotune/arrows/arr063.svg'
												className='svg-icon-4 me-1'
											/>
											Back
										</button>
									</div>

									<div>
										<button type='submit' className='btn btn-lg btn-primary me-3'>
											<span className='indicator-label'>
												{!isSubmitButton && 'Continue'}
												{isSubmitButton && 'Submit'}
												<KTSVG
													path='/media/icons/duotune/arrows/arr064.svg'
													className='svg-icon-3 ms-2 me-0'
												/>
											</span>
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export { Horizontal }
