import { toast } from 'react-toastify'
import { SampleColumnData } from './RenderColumnData'
import { useEffect, useState } from 'react'
import {
    useGetSetupColumnsQuery,
    useStoreSetupColumnsMutation,
} from 'App/Modules/Services/setupColumn'
import { assetFieldCheck, eventFieldsCheck, linkingFieldsCheck, warrantyFieldsCheck } from "config"

interface AssetFields {
    [key: string]: string | boolean
}
const SetupCoulmnListing = (props: any) => {
    const path = window.location.pathname.split('/').pop()
    const { data: setupColumnsData, isLoading, isError } = useGetSetupColumnsQuery({ path })
    const [loading, setLoading] = useState(false)
    const [storeSetupColumns] = useStoreSetupColumnsMutation()
    useEffect(() => {
        if (setupColumnsData) {
            const assetFields: AssetFields = setupColumnsData?.setupColumns?.selectedColumns
            const initialSelectedColumns = Object.entries(assetFields).map(([key, value]) => ({
                key,
                value,
            }))
            props.setSelectedColumns(initialSelectedColumns)
        }
    }, [setupColumnsData])

    const handleCheckboxChange = (key: string) => {
        props.setSelectedColumns((prevSelectedColumns) => {
            const updatedColumns = prevSelectedColumns.map((column) => {
                if (column.key === key) {
                    return { ...column, value: !column.value }
                }
                return column
            })
            return updatedColumns
        })
    }

    const handleSaveSetup = async () => {
        const assetFields = props.selectedColumns.reduce((acc, { key, value }) => {
            acc[key] = value
            return acc
        }, {})
        setLoading(true)
        try {
            const data: any = await storeSetupColumns({ selectedColumns: assetFields, pathName: path })
            toast.success(data?.data?.message)
            props.setShowSetupColumns(false)
        } catch (error: any) {
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className={`${!props.showSetupColumns && 'd-none'}`}>
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                    <div id='kt_content_container' className={`container`}>
                        <div className='card card-custom gutter-b'>
                            <div className='card-body'>
                                <div className='form_section'>
                                    <div className='form-title fw-bold fs-4 pb-3 d-flex align-items-center'>
                                        <span className='fs-5 pb-1 me-2'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='17px'
                                                height='17px'
                                                viewBox='0 0 16 16'
                                            >
                                                <path
                                                    fill='#ff0000'
                                                    fill-rule='evenodd'
                                                    d='M5.29289,3.70711 C4.90237,3.31658 4.90237,2.68342 5.29289,2.29289 C5.68342,1.90237 6.31658,1.90237 6.70711,2.29289 L11.7071,7.29289 C12.0976,7.68342 12.0976,8.31658 11.7071,8.70711 L6.70711,13.7071 C6.31658,14.0976 5.68342,14.0976 5.29289,13.7071 C4.90237,13.3166 4.90237,12.6834 5.29289,12.2929 L9.58579,8 L5.29289,3.70711 Z'
                                                />
                                            </svg>
                                        </span>
                                        <span>Select Table Columns</span>
                                    </div>
                                    <p className='mb-5'>Check the columns you want to see in the List.</p>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='form-title fw-bold fs-5 mb-4 pb-0'>Asset fields</div>
                                            <ul className='list-unstyled'>
                                                {props.selectedColumns.map((column, index) => {
                                                    if (
                                                        assetFieldCheck.includes(column.key)
                                                    ) {
                                                        return null
                                                    }
                                                    return (
                                                        <li key={index}>
                                                            <label className='radio mb-3'>
                                                                <input
                                                                    type='checkbox'
                                                                    id={column.key.toLowerCase().replace(/\s/g, '')}
                                                                    checked={column.value}
                                                                    onChange={() => handleCheckboxChange(column.key)}
                                                                    className='custom-form-check-input form-check-input sig_input m_5'
                                                                />
                                                                <span>{column.key.replace(/_/g, ' ')}</span>
                                                            </label>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='form-title fw-bold fs-5 mb-4 pb-0'>Linking fields</div>
                                            <ul className='list-unstyled'>
                                                <li>
                                                    {props.selectedColumns.map((column, index) => {
                                                        if (
                                                            linkingFieldsCheck.includes(column.key)
                                                        ) {
                                                            return (
                                                                <li key={index}>
                                                                    <label className='radio mb-3'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={column.key.toLowerCase().replace(/\s/g, '')}
                                                                            checked={column.value}
                                                                            onChange={() => handleCheckboxChange(column.key)}
                                                                            className='custom-form-check-input form-check-input sig_input m_5'
                                                                        />
                                                                        <span>{column.key.replace(/_/g, ' ')}</span>
                                                                    </label>
                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='form-title fw-bold fs-5 mb-4 pb-0'>Event fields</div>
                                            <ul className='list-unstyled'>
                                                <li>
                                                    {props.selectedColumns.map((column, index) => {
                                                        if (
                                                            eventFieldsCheck.includes(column.key)
                                                        ) {
                                                            return (
                                                                <li key={index}>
                                                                    <label className='radio mb-3'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={column.key.toLowerCase().replace(/\s/g, '')}
                                                                            checked={column.value}
                                                                            onChange={() => handleCheckboxChange(column.key)}
                                                                            className='custom-form-check-input form-check-input sig_input m_5'
                                                                        />
                                                                        <span>{column.key.replace(/_/g, ' ')}</span>
                                                                    </label>
                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='form-title fw-bold fs-5 mb-4 pb-0'>Warranty fields</div>
                                            <ul className='list-unstyled'>
                                                <li>
                                                    {props.selectedColumns.map((column, index) => {
                                                        if (
                                                            warrantyFieldsCheck.includes(column.key)
                                                        ) {
                                                            return (
                                                                <li key={index}>
                                                                    <label className='radio mb-3'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={column.key.toLowerCase().replace(/\s/g, '')}
                                                                            checked={column.value}
                                                                            onChange={() => handleCheckboxChange(column.key)}
                                                                            className='custom-form-check-input form-check-input sig_input m_5'
                                                                        />
                                                                        <span>{column.key.replace(/_/g, ' ')}</span>
                                                                    </label>
                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='form_section'>
                                    <div className='form-title fw-bold fs-4 pb-3 d-flex align-items-center'>
                                        <span className='fs-5 pb-1 me-2'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='17px'
                                                height='17px'
                                                viewBox='0 0 16 16'
                                            >
                                                <path
                                                    fill='#ff0000'
                                                    fill-rule='evenodd'
                                                    d='M5.29289,3.70711 C4.90237,3.31658 4.90237,2.68342 5.29289,2.29289 C5.68342,1.90237 6.31658,1.90237 6.70711,2.29289 L11.7071,7.29289 C12.0976,7.68342 12.0976,8.31658 11.7071,8.70711 L6.70711,13.7071 C6.31658,14.0976 5.68342,14.0976 5.29289,13.7071 C4.90237,13.3166 4.90237,12.6834 5.29289,12.2929 L9.58579,8 L5.29289,3.70711 Z'
                                                />
                                            </svg>
                                        </span>
                                        <span>Order Table Columns</span>
                                    </div>
                                    <p className='mb-5'>
                                        Rearrange the table column sequence by dragging and dropping columns.
                                    </p>
                                    <div className='table-responsive'>
                                        <table id='preview-table' className='table table-bordered mb-5'>
                                            <thead className='thead-light  light-yellow-table'>
                                                <tr>
                                                    {props.selectedColumnsRender.map((column, index) => (
                                                        <th key={index}>{column.key.replace(/_/g, ' ')}</th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    {props.selectedColumnsRender.map((column, colIndex) => (
                                                        <td key={colIndex}>
                                                            <SampleColumnData column={column.key.replace(/_/g, ' ')} />
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer text-end'>
                                <button
                                    className='me-2 main-btn-style btn btn-light-secondary'
                                    onClick={() => props.setShowSetupColumns(false)}
                                >
                                    Cancel
                                </button>
                                <button className='btn btn-primary main-btn-style' disabled={loading} onClick={handleSaveSetup}>
                                    {!loading && 'Save Setup'}
                                    {loading && (
                                        <span className='indicator-progress d-block'>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetupCoulmnListing
