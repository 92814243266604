import { PageTitle } from "_metronic/layout/core"
import MaintenancesListing from "./Components/MaintenancesListing"
import { useIntl } from "react-intl"

const Maintenances = () => {
	const intl = useIntl()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'List of Maintenances' })}</PageTitle>
			<MaintenancesListing />
		</>
	)
}

export default Maintenances