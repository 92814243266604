import { ProfileDetails } from 'App/Modules/Accounts/Components/Settings/Cards/ProfileDetails'
import { useGetProfileQuery } from 'App/Modules/Services/Profile'
import { useIntl } from 'react-intl'
import { PageTitle } from '_metronic/layout/core'
import ManageGroup from './ManageGroup'
import { useState } from 'react'
import { AddSubUser } from 'App/Modules/Accounts/Components/Settings/Cards/AddSubUser'


function AddUsers() {
	const intl = useIntl()
	const { data } = useGetProfileQuery()
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const handleGroupSelect = (groupName) => {
    setSelectedGroupName(groupName);
  };
  return (
    <div>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Add Users' })}</PageTitle>
        <ManageGroup onSelectGroup={handleGroupSelect} />
      <AddSubUser userData={data} selectedGroupName={selectedGroupName} />
    </div>

   
  )
}

export default AddUsers