import {createRoot} from 'react-dom/client'
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "react-datepicker/dist/react-datepicker.css";
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'nouislider/dist/nouislider.css'
import './_metronic/assets/sass/Style.scss'
import './_metronic/assets/sass/Plugins.scss'
import './_metronic/assets/sass/StyleReact.scss'
import './_metronic/assets/sass/AssetsRack.scss'
import {AppRoutes} from './App/Routing/AppRoutes'
import {AuthProvider, setupAxios} from './App/Modules/Auth'
import {ThemeModeProvider} from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { store } from './App/Store'
import { Provider } from 'react-redux';
import "../src/CustomStyles.css"

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
    <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </ThemeModeProvider>
          <ToastContainer />
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  )
}
