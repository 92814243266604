const PreviewTable = ({ editableData }) => {
    return (
        <table id="preview-table">
            <thead>
                <tr>
                    {editableData.map(column => column.defaultActive && (
                        <th key={column.id} className="th-id-column">
                            {column.columnName}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {editableData.map(column => column.defaultActive && (
                        <td key={column.id} className="td-id-column">
                            Sample
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};

export default PreviewTable;
