import { FC, useMemo } from 'react'
import { ID } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../Core/ListViewProvider'

type Props = {
	id: ID
}

const UserSelectionCell: FC<Props> = ({ id }) => {
	const { selected, onSelect } = useListView()
	const isSelected = useMemo(() => selected.includes(id), [id, selected])
	return (
		<div >
			<input
				className='form-check-input custom-form-check-input'
				type='checkbox'
				data-kt-check={isSelected}
				data-kt-check-target='#kt_table_users .form-check-input'
				checked={isSelected}
				onChange={() => onSelect(id)}
			/>
		</div>
	)
}

export { UserSelectionCell }
