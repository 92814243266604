interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
}

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData }) => {
  const renderData = () => {
    switch (column) {
      case 'Created By':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.createdBy}</span>
        ))
      case 'Name':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.fullName}</span>
        ))
      case 'Department':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.department?.department}</span>
        ))
      case 'Email':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.email}</span>
        ))
      case 'Employee ID':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.employeeId}</span>
        ))
      case 'Location':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.location?.location}</span>
        ))
      case 'Notes':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.notes}</span>
        ))
      case 'Phone':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.phone}</span>
        ))
      case 'Site':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.site?.name}</span>
        ))
      case 'Title':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.title}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const renderData = () => {
    switch (column) {
      case 'Created By':
        return 'Sample Created By'
      case 'Name':
        return 'Sample Name'
      case 'Department':
        return 'Sample Department'
      case 'Email':
        return 'Sample Email'
      case 'Employee ID':
        return 'Sample Employee ID'
      case 'Location':
        return 'Sample Location'
      case 'Notes':
        return 'Sample Notes'
      case 'Phone':
        return 'Sample Phone'
      case 'Site':
        return 'Sample Site'
      case 'Title':
        return 'Sample Title'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
