
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { Link } from 'react-router-dom'

type Props = {
  className: string
}

const ListsWidget3: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Todo</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='card-body pt-2'>
        <div className='d-flex align-items-center mb-8'>
          <span className='bullet bullet-vertical h-40px bg-success'></span>
          <div className='form-check form-check-custom  mx-5'>
            <input className='form-check-input custom-form-check-input' type='checkbox' value='' />
          </div>
          <div className='flex-grow-1'>
            <Link to='/dummy-link' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Create FireStone Logo
            </Link>
            <span className='text-muted fw-semibold d-block'>Due in 2 Days</span>
          </div>
          <span className='badge badge-light-success fs-8 fw-bold'>New</span>
        </div>
        <div className='d-flex align-items-center mb-8'>
          <span className='bullet bullet-vertical h-40px bg-primary'></span>
          <div className='form-check form-check-custom  mx-5'>
            <input className='form-check-input custom-form-check-input' type='checkbox' value='' />
          </div>
          <div className='flex-grow-1'>
            <Link to='/dummy-link' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Stakeholder Meeting
            </Link>
            <span className='text-muted fw-semibold d-block'>Due in 3 Days</span>
          </div>
          <span className='badge badge-light-primary fs-8 fw-bold'>New</span>
        </div>
        <div className='d-flex align-items-center mb-8'>
          <span className='bullet bullet-vertical h-40px bg-warning'></span>
          <div className='form-check form-check-custom  mx-5'>
            <input className='form-check-input custom-form-check-input' type='checkbox' value='' />
          </div>
          <div className='flex-grow-1'>
            <Link to='/dummy-link' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Scoping &amp; Estimations
            </Link>
            <span className='text-muted fw-semibold d-block'>Due in 5 Days</span>
          </div>
          <span className='badge badge-light-warning fs-8 fw-bold'>New</span>
        </div>
        <div className='d-flex align-items-center mb-8'>
          <span className='bullet bullet-vertical h-40px bg-primary'></span>
          <div className='form-check form-check-custom  mx-5'>
            <input className='form-check-input custom-form-check-input' type='checkbox' value='' />
          </div>
          <div className='flex-grow-1'>
            <Link to='/dummy-link' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              KPI App Showcase
            </Link>
            <span className='text-muted fw-semibold d-block'>Due in 2 Days</span>
          </div>

          <span className='badge badge-light-primary fs-8 fw-bold'>New</span>
        </div>
        <div className='d-flex align-items-center mb-8'>
          <span className='bullet bullet-vertical h-40px bg-danger'></span>
          <div className='form-check form-check-custom  mx-5'>
            <input className='form-check-input custom-form-check-input' type='checkbox' value='' />
          </div>
          <div className='flex-grow-1'>
            <Link to='/dummy-link' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Project Meeting
            </Link>
            <span className='text-muted fw-semibold d-block'>Due in 12 Days</span>
          </div>
          <span className='badge badge-light-danger fs-8 fw-bold'>New</span>
        </div>
        <div className='d-flex align-items-center'>
          <span className='bullet bullet-vertical h-40px bg-success'></span>
          <div className='form-check form-check-custom  mx-5'>
            <input className='form-check-input custom-form-check-input' type='checkbox' value='' />
          </div>
          <div className='flex-grow-1'>
            <Link to='/dummy-link' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Customers Update
            </Link>
            <span className='text-muted fw-semibold d-block'>Due in 1 week</span>
          </div>
          <span className='badge badge-light-success fs-8 fw-bold'>New</span>
        </div>
      </div>
    </div>
  )
}

export { ListsWidget3 }
