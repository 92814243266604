import React, { useState, useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers'
import CheckoutModal from './Modal/CheckoutModal'
import { useAuth } from '../../Modules/Auth'
import axios from 'axios'
import { Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetAllSitesQuery } from '../../Modules/Services/Sites'
import { useGetAllLocationsBySiteQuery } from '../../Modules/Services/Locations'
import { useGetAllDepartmentsQuery } from '../../Modules/Services/Departments'
import { useGetAllPersonsQuery } from 'App/Modules/Services/Persons'
import { getEmployee } from '../../Modules/Apps/CompanySetup/Database/Core/_requests'
import { CheckoutInterface, defaultCHeckoutDetail as initialValues } from './Core/SetupModel'
import { toast } from 'react-toastify'
import { useAddCheckoutMutation } from '../../Modules/Services/Checkout'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import PersonsModal from 'App/Modules/Apps/CompanySetup/Persons/Components/PersonsModal'
import DuplicateReserveModal from './Modal/DuplicateReserveModal'
import { checkUserUnauthorized } from '../../Modules/Auth'
import { emailRegex } from 'config'
import SplashScreen from 'App/SplashScreen'
import Loader from 'App/BeatLoader'

const Checkout = () => {
	const intl = useIntl()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [assets, setAssets] = useState<any>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [dataLoading, setDataLoading] = useState<boolean>(false)
	const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false)
	const [showPersonModal, setShowPersonModal] = useState<boolean>(false)
	const [reserveModalData, setReserveModalData] = useState<any>({})
	const [duplicateReserveModal, setDuplicateReserveModal] = useState(false)
	const [reservedData, setReservedData] = useState<any>([])
	const [pendingAssets, setPendingAssets] = useState<any>([])
	const [selectedAssets, setSelectedAssets] = useState<any>([])
	const [selectAll, setSelectAll] = useState(false)
	const [personCheckout, setPersonCheckout] = useState(false)
	const [emailEnabled, setEmailEnabled] = useState(false)
	const [selected, setSelected] = useState<any>([])
	const [employeeData, setEmployeeData] = useState<any>([])
	const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
	const [skip, setSkip] = useState<boolean>(false)
	const [siteId, setSiteId] = useState<boolean>(false)
	const [customErrors, setCustomErrors] = useState({
		assignToField: '',
		siteField: '',
		emailField: '',
	})
	const [isNewPersonAdded, setIsNewPersonAdded] = useState(false)
	const accountDetailsSchema = Yup.object().shape({})
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const { data: userSitesList } = useGetAllSitesQuery({ userId: currentUser?.id })
	const { data: userLocationList, error: locationError } = useGetAllLocationsBySiteQuery(
		{ userId: currentUser?.id, id: siteId },
		{ skip: !siteId }
	)
	const { data: userDepartmentList } = useGetAllDepartmentsQuery({ userId: currentUser?.id })
	const [addCheckout, { isLoading: isAdding }] = useAddCheckoutMutation()

	const {
		data: assetsData,
		isLoading,
		error,
		isSuccess,
	} = useGetAllAssetsQuery({
		body: new URLSearchParams({}).toString(),
		page: page,
		limit: limit,
	})
	const { data: personData } = useGetAllPersonsQuery({ id: currentUser?.id, values: '' })

	useEffect(() => {
		if (isNewPersonAdded) {
			formik.setFieldValue(
				'assignTo',
				personData?.persons ? personData?.persons[personData?.persons?.length - 1]?.id : ''
			)
		}
	}, [personData])

	useEffect(() => {
		setDataLoading(false)
	}, [userLocationList])

	const handleSiteClick = ({ target: { value } }: any) => {
		if (value === '') {
			setCustomErrors({ siteField: 'Site is required', assignToField: '', emailField: '' })
			return undefined
		}
		setDataLoading(true)
		setSiteId(value)
		setSkip(false)
		if (value) {
			setCustomErrors({ siteField: '', assignToField: '', emailField: '' })
		}
		setDataLoading(false)
	}

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: accountDetailsSchema,
		onSubmit: async (values) => {
			setLoading(true)
			try {
				if (currentUser) {
					if (
						(values?.checkedTo === '1' || values?.checkedTo === 1) &&
						values?.assignTo === undefined
					) {
						setCustomErrors({ assignToField: 'Person is required', siteField: '', emailField: '' })
						setLoading(false)
						return undefined
					} else if (
						(values?.checkedTo === '0' || values?.checkedTo === 0) &&
						values?.siteId === undefined
					) {
						setCustomErrors({ siteField: 'Site is required', assignToField: '', emailField: '' })
						setLoading(false)
						return undefined
					} else if (emailEnabled && (!values.email || !emailRegex.test(values?.email))) {
						if (!values.email) {
							setCustomErrors({ siteField: '', emailField: 'Email is required', assignToField: '' })
							setLoading(false)
							return undefined
						} else if (!emailRegex.test(values?.email)) {
							setCustomErrors({
								siteField: '',
								emailField: 'Invalid email address',
								assignToField: '',
							})
							return undefined
						}
					} else {
						setCustomErrors({ assignToField: '', siteField: '', emailField: '' })
						handleCheckoutSubmit(values)
					}
				}
			} catch (error: any) {
				toast.error(error?.data?.message)
			}
		},
	})

	useEffect(() => {
		if (isNewPersonAdded) {
			formik.setFieldValue(
				'assignTo',
				personData?.persons ? personData?.persons[personData?.persons?.length - 1]?.id : ''
			)
		}
	}, [personData])

	useEffect(() => {
		if (assetsData) {
			getAssetListing()
			setDataLoading(false)
		}
	}, [assetsData])

	useEffect(() => {
		if (assets && pendingAssets && showCheckoutModal == false) {
			handlePendingList()
		}
		setDataLoading(false)
	}, [assets, showCheckoutModal])

	const getAssetListing = async () => {
		setAssets(assetsData?.userAssets)
	}

	const handlePendingList = () => {
		const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
	}

	const handleCheckoutSubmit = async (values: CheckoutInterface) => {
		const data = {
			checkoutDate: values?.checkoutDate == '' ? undefined : values?.checkoutDate,
			checkedTo: values?.checkedTo == 0 ? 'site' : 'person',
			dueDate: values?.dueDate == '' ? undefined : values?.dueDate,
			checkoutNotes: values?.checkoutNotes,
			locationId: values?.locationId,
			assignTo: values?.assignTo,
			siteId: values?.siteId,
			departmentId: values?.departmentId,
			assetId: pendingAssets,
			overrideAssets: values?.overrideAssets,
			email: values?.email,
		}
		try {
			const result = await addCheckout(data).unwrap()
			if (result) {
				if (result.status === 409) {
					setReservedData(result?.ifReserveAssets)
					handleDuplicateReserveModal()
					setReserveModalData(data)
					getAssetListing()
				} else {
					toast.dismiss()
					toast.success(result?.message ? result?.message : result?.data?.message)
					setSelectedAssets([])
					navigate("/assets")
				}
			}
		} catch (error: any) {
			checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
		} finally {
			setLoading(false)
		}
	}

	const handleShowCheckoutModal = () => {
		setDataLoading(true)
		setShowCheckoutModal(true)
		setDataLoading(false)
	}

	const handleCloseCheckoutModal = () => {
		setShowCheckoutModal(false)
	}

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = assets?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const handleCheckboxChange = (id: number) => {
		setSelected((prevSelected) => {
			const updatedSelected = prevSelected.includes(id)
				? prevSelected.filter((itemId) => itemId !== id)
				: [...prevSelected, id]
			const isAllSelected = updatedSelected.length === assets?.length
			setSelectAll(isAllSelected)
			return updatedSelected
		})
		setDeleteDataArr((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((itemId) => itemId !== id)
			} else {
				return [...prevSelected, id]
			}
		})
	}

	const deleteSelectedAssests = async () => {
		const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
		setSelectAll(false)
		setSelected([])
	}

	const handleClosePersonModal = () => {
		setShowPersonModal(false)
	}

	const handleShowPersonModal = () => {
		setShowPersonModal(true)
		setIsNewPersonAdded(true)
	}

	const handleDuplicateReserveModal = () => {
		setDuplicateReserveModal(true)
	}

	const handleCloseDuplicateReserveModal = () => {
		setDuplicateReserveModal(false)
	}

	const handleCancel = () => {
		setSelectedAssets([])
		setPendingAssets([])
		setShowCheckoutModal(false)
	}
	return (
		<>
			<SplashScreen isLoadingTemplate={isLoading} />
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Checkout' })}</PageTitle>
			<div className='card mb-5 mb-xl-10'>
				<div className='card-header border-0'>
					<div className='card-title m-0 '>
						<h3 className='fw-bolder m-0'>
							<span className='me-2'>
								<i className='la la-user-check fs-1'></i>
							</span>{' '}
							Check-Out
						</h3>
					</div>
				</div>
				<div id='kt_account_profile_details' className='collapse show'>
					<div className='form'>
						<div className='card-body border-top p-lg-9 p-md-7 p-6'>
							<div >
								{' '}
								<p className='fw-bolder m-0 mb-3'>
									Keep track of your assets within your organization and create an even more
									detailed history of them.
								</p>
								<div className='card-footer d-flex justify-content-start border-0 p-0'>
									<button
										type='button'
										className='btn btn-light-primary me-3'
										onClick={() => handleShowCheckoutModal()}
									>
										<i className='la la-plus fs-3' />
										Select Assets
									</button>
									<CheckoutModal
										show={showCheckoutModal}
										setPendingAssets={setPendingAssets}
										selectedAssets={selectedAssets}
										handleClose={handleCloseCheckoutModal}
										handleCancel={handleCancel}
										setPage={setPage}
										setLimit={setLimit}
										page={page}
										limit={limit}
									/>
									<DuplicateReserveModal
										show={duplicateReserveModal}
										handleCheckoutSubmit={handleCheckoutSubmit}
										data={reserveModalData}
										reservedData={reservedData}
										handleClose={handleCloseDuplicateReserveModal}
									/>
								</div>
								{selectedAssets && selectedAssets?.length > 0 ? (
									<>
										<div className='separator border-2 my-10'></div>
										<div className='d-flex justify-content-between align-items-center'>
											<h4 className='mt-3'>Assets Pending Check-Out</h4>
											{deleteDataArr.length > 0 && (
												<button
													type='button'
													className='btn btn-danger  mb-2'
													onClick={() => {
														deleteSelectedAssests()
													}}
												>
													Delete Selected
												</button>
											)}
										</div>
										<div className='checkout-listing-table mb-3 table-responsive'>
											<table
												id='kt_table_users'
												className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
												role='table'
											>
												<thead>
													<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
														<th role='columnheader' className='w-10px pe-2'>
															<div className='me-3'>
																<input
																	type='checkbox'
																	className='form-check-input custom-form-check-input'
																	checked={selectAll}
																	onChange={handleSelectAll}
																/>
															</div>
														</th>
														<th role='columnheader' className='min-w-125px'>
															Asset Tag ID
														</th>
														<th role='columnheader' className='min-w-125px'>
															Description
														</th>
														<th role='columnheader' className='min-w-125px'>
															Status
														</th>
														<th role='columnheader' className='min-w-125px'>
															Assigned to
														</th>
														<th role='columnheader' className='min-w-125px'>
															Site
														</th>
														<th role='columnheader' className='min-w-125px'>
															Location
														</th>
														<th role='columnheader' className='min-w-125px'>
															Lease to
														</th>
													</tr>
												</thead>
												<tbody className='text-gray-600 fw-bol' role='rowgroup'>
													{selectedAssets?.length > 0 ? (
														selectedAssets?.map((item: any) => {
															return (
																<tr role='row'>
																	<td role='cell' >
																		<div >
																			<input
																				type='checkbox'
																				className='form-check-input custom-form-check-input'
																				checked={selected.includes(item.id)}
																				onChange={() => handleCheckboxChange(item.id)}
																			/>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.assetTagId}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.description}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item.statusType === 'check_out'
																					? 'Checked Out'
																					: item.statusType === 'lease'
																						? 'Leased'
																						: item.statusType === 'dispose'
																							? 'Disposed'
																							: item.statusType === 'available'
																								? 'Available'
																								: item.statusType}
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>
																					{item?.assetstatus &&
																						item?.assetstatus?.statusType !== 'lease'
																						? item?.location
																							? item?.site?.name + '/' + item?.location?.location
																							: item?.site?.name
																						: item?.assetstatus?.statusType == 'lease'
																							? ''
																							: ''}
																				</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.site?.name}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.location?.location}
																			</div>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.statusType == 'lease' && item?.assetslease
																					? item?.assetslease?.customer?.fullName
																					: ''}
																			</div>
																		</div>
																	</td>
																</tr>
															)
														})
													) : (
														<tr>
															<td colSpan={12}>
																<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																	No records found
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<div className='separator border-2 my-10'></div>
										<form onSubmit={formik.handleSubmit} noValidate className='form'>
											<div className='row'>
												<div className='col-lg-6'>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>Check-out to</label>
														<div className='col-lg-9 fv-row'>
															<div className='d-flex'>
																<div className='m-2 form-check-custom'>
																	<div>
																		<input
																			className='form-check-input custom-form-check-input'
																			{...formik.getFieldProps('checkedTo')}
																			onClick={() => {
																				setPersonCheckout(true)
																				setEmailEnabled(false)
																				formik.setFieldValue('siteId', undefined)
																				formik.setFieldValue('locationId', undefined)
																				setCustomErrors({
																					assignToField: '',
																					siteField: '',
																					emailField: '',
																				})
																			}}
																			type='checkbox'
																			value={1}
																			checked={personCheckout == false ? false : true}
																			name={'checkedTo'}
																			id='person'
																		/>
																	</div>
																	<label className='form-check-label fs-6 fw-bold' htmlFor='person'>
																		Person
																	</label>
																</div>
																<div className='m-2 form-check-custom'>
																	<div>
																		<input
																			className='form-check-input custom-form-check-input'
																			type='checkbox'
																			{...formik.getFieldProps('checkedTo')}
																			onClick={() => {
																				setPersonCheckout(false)
																				setEmailEnabled(false)
																				formik.setFieldValue('assignTo', undefined)
																				setCustomErrors({
																					assignToField: '',
																					siteField: '',
																					emailField: '',
																				})
																			}}
																			value={0}
																			checked={personCheckout == false ? true : false}
																			name={'checkedTo'}
																			id='location'
																		/>
																	</div>
																	<label className='form-check-label fs-6 fw-bold' htmlFor='location'>
																		Site / Location
																	</label>
																</div>
															</div>
														</div>
													</div>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>
															Check-out Date
														</label>
														<div className='col-lg-9 fv-row'>
															<input
																className='form-control form-control-solid'
																{...formik.getFieldProps('checkoutDate')}
																type='date'
																placeholder='Pick date rage'
																id='kt_daterangepicker_3'
															/>
														</div>
													</div>
													<div className={personCheckout === true ? '' : 'd-none'}>
														<div className='row mb-6'>
															<label className='col-lg-3 col-form-label fw-bold fs-6 required'>
																<span >Assign to</span>
															</label>
															<div className='col-lg-9 fv-row'>
																<div className='d-flex'>
																	<div className='flex-1'>
																		<select
																			className='form-select form-control form-control-solid form-select-solid  '
																			{...formik.getFieldProps('assignTo')}
																			onChange={(e) => {
																				formik.handleChange(e)
																				formik.setFieldValue('assignTo', e.target.value)
																			}}
																		>
																			<option value=''>Select person</option>
																			{personData?.persons?.map((item: any, idx: number) => {
																				return (
																					<option key={idx} value={item.id}>
																						{item.fullName}
																					</option>
																				)
																			})}
																		</select>
																	</div>
																	<button
																		type='button'
																		className='btn btn-light-primary d-flex align-items-center ms-3'
																		onClick={() => handleShowPersonModal()}
																	>
																		<i className='la la-plus fs-3' />
																		New
																	</button>
																</div>
																{formik.touched.assignTo && formik.errors.assignTo && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>{formik.errors.assignTo}</div>
																	</div>
																)}
																{customErrors?.assignToField !== '' && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			{customErrors?.assignToField}
																		</div>
																	</div>
																)}
																<PersonsModal
																	show={showPersonModal}
																	handleClose={handleClosePersonModal}
																	CustomerData={[]}
																/>
															</div>
														</div>
													</div>
													<div className='row mb-6'>
														<label
															className={
																personCheckout == true
																	? 'col-lg-3 col-form-label fw-bold fs-6'
																	: 'col-lg-3 col-form-label required fw-bold fs-6'
															}
														>
															<span >Site</span>
														</label>
														<div className='col-lg-9 fv-row'>
															<select
																className='form-select form-control form-control-solid form-select-solid  '
																{...formik.getFieldProps('siteId')}
																onChange={(e) => {
																	formik.handleChange(e)
																	handleSiteClick(e)
																}}
															>
																<option value=''>Select Site</option>
																{userSitesList?.sites.map((item: any, idx: number) => {
																	return (
																		<option key={idx} value={item.id}>
																			{item.name}
																		</option>
																	)
																})}
															</select>
															{formik.touched.siteId && formik.errors.siteId && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{formik.errors.siteId}</div>
																</div>
															)}
															{customErrors?.siteField !== '' && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{customErrors?.siteField}</div>
																</div>
															)}
														</div>
													</div>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>
															<span >Location</span>
														</label>
														<div className='col-lg-9 fv-row d-flex'>
															<select
																className='form-select form-control form-control-solid form-select-solid  '
																{...formik.getFieldProps('locationId')}
																onChange={(e) => {
																	formik.handleChange(e);
																	const value = e.target.value;
																	if (value === '') {
																		formik.setFieldValue('locationId', undefined);
																	}
																}}
															>
																<option value=''>Select Location</option>
																{userLocationList?.locations?.map((item: any, idx: number) => {
																	return (
																		<option key={idx} value={item?.id}>
																			{item?.location}
																		</option>
																	)
																})}
															</select>
														</div>
													</div>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>Due Date</label>
														<div className='col-lg-9 fv-row'>
															<div className='input-group'>
																<input
																	className='form-control form-control-solid'
																	{...formik.getFieldProps('dueDate')}
																	type='date'
																	placeholder='Pick date rage'
																	id='kt_daterangepicker_3'
																/>
																<span className='input-group-text border-0'>
																	<i className='fa fa-calendar'></i>
																</span>
															</div>
														</div>
													</div>
												</div>
												<div className='col-lg-6'>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>
															Department
														</label>
														<div className='col-lg-9 fv-row'>
															<select
																className='form-select form-select-solid mb-5'
																{...formik.getFieldProps('departmentId')}
																aria-label='Select example'
															>
																<option>Select Department</option>
																{userDepartmentList?.department?.map((item: any, idx: number) => {
																	return <option value={item?.id}>{item?.department}</option>
																})}
															</select>
														</div>
													</div>

													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>
															Check-out Notes
														</label>
														<div className='col-lg-9 fv-row'>
															<textarea
																className='form-control'
																{...formik.getFieldProps('checkoutNotes')}
																cols={20}
																rows={4}
															></textarea>
														</div>
													</div>
													<div className={personCheckout == true ? 'row mb-10' : 'd-none'}>
														<div className='col-lg-3 col-form-label fw-bold fs-6 d-none'>
															<div className='form-check form-check-custom  form-check-sm d-flex align-center me-5 mb-4'>
																<input
																	className='form-check-input me-2'
																	type='checkbox'
																	value=''
																	id='flexRadioLg'
																	checked={emailEnabled}
																	onChange={() => setEmailEnabled(!emailEnabled)}
																/>
																<label className='form-label mb-0 fw-bold fs-6'>Send Email</label>
															</div>
														</div>
														<div className='col-lg-9 fv-row d-none'>
															<div>
																<input
																	type='email'
																	disabled={emailEnabled == true ? false : true}
																	className='form-control form-control-solid'
																	{...formik.getFieldProps('email')}
																	placeholder='Email'
																/>
															</div>
															{formik.touched.email && formik.errors.email && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{formik.errors.email}</div>
																</div>
															)}
															{emailEnabled && customErrors?.emailField !== '' && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{customErrors?.emailField}</div>
																</div>
															)}
														</div>
													</div>
												</div>
												<div className=' d-flex align-items-center justify-content-start'>
													<button
														type='submit'
														className='btn btn-primary me-3 main-btn-style'
														disabled={isLoading}
													>
														{!loading && 'Check-Out'}
														{loading && (
															<span className='indicator-progress d-block'>
																Please wait...{' '}
																<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
															</span>
														)}
													</button>
													<button
														onClick={handleCancel}
														className='btn btn-light-secondary main-btn-style'
													>
														Cancel
													</button>
												</div>
											</div>
										</form>
									</>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</div>
				<Loader loading={dataLoading} />
			</div>
		</>
	)
}

export default Checkout
