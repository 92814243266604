import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth, checkUserUnauthorized } from 'App/Modules/Auth'
import { AssetResponse } from './Core/_models'
import { MaintenancesHeader } from './MaintenancesHeader'
import MaintenancesEditModal from './Modals/MaintenancesEditModal'
import MaintenancesDetailModal from './Modals/MaintenancesDetailModal'
import ConfirmationPopup from '_metronic/helpers/components/ConfirmationPopup'
import MaintenancesSearch from './MaintenancesSearch'
import { useDeleteAssetsMutation, useGetAllAssetsQuery, useUpdateAssetMutation } from 'App/Modules/Services/Assets'
import { Link } from 'react-router-dom'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import moment from 'moment'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import getSymbolFromCurrency from 'currency-symbol-map'
import { toAbsoluteUrlImage } from '_metronic/helpers'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import MaintenancesTagList from './MaintenancesTagList'
import SplashScreen from 'App/SplashScreen'
import { nameKeys, setupKeys } from 'config'
import { currencies } from './Currency'
import SetupColumns from './SetupColumns'
import SetupCoulmnListing from './SetupColumnListing'
import { ColumnData } from './RenderColumnData'
import Columns from './Columns.json'
import MaintenancesDetail from './MaintenancesDetail'
import Loader from 'App/BeatLoader'
import { useGetAllMaintenancesQuery, useGetMaintenanceQuery } from 'App/Modules/Services/Maintenance'

const MaintenancesListing = () => {
	const { currentUser } = useAuth()
	const [deleteDataArr, setDeleteDataArr] = useState<any>([])
	const [selectAllData, setSelectAllData] = useState<boolean>(false)
	const [selectedData, setSelectedData] = useState<boolean>(false)
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [check, setCheck] = useState<number[]>([])
	const [data, setData] = useState({})
	const [show, setShow] = useState<boolean>(false)
	const [showSearch, setShowSearch] = useState<boolean>(false)
	const [skip, setSkip] = useState<boolean>(false)
	const [selected, setSelected] = useState<any>([])
	const [currencyLogo, setCurrencyLogo] = useState<any>()
	const [selectAll, setSelectAll] = useState<any>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [pagination, setPagination] = useState<any>({})
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const [criteria, setCriteria] = useState([])
	const [dataId, setDataId] = useState<number>()
	const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)
	const [selectedColumns, setSelectedColumns] = useState<{ key: any; value: any }[]>(Columns)
	const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)
	const [showViewModal, setShowViewModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalCheck, setModalCheck] = useState(false)
	const [searchAssets, setSearchAssets] = useState<any>({
		keyword: '',
		searchField: '',
		siteId: '',
		locationId: '',
		categoryId: '',
		departmentId: '',
		person: '',
		customer: '',
		status: '',
		groupBy: '',
		recordCount: '',
		dateRangeBy: 'purchasedDate',
		quickDateRange: '',
		startDate: '',
		endDate: '',
		exportToExcel: false,
		maintenanceFilter: ''
	})
	const { data: singleMaintenanceData } = useGetMaintenanceQuery(
		{ id: dataId },
		{ skip: !dataId }
	)
	const {
		data: companyData,
		isSuccess: company,
		isLoading: isLoadingGetCompany,
		refetch: refetchComapnyData,
	} = useGetCompanyQuery()
	const { data: maintenancesData, isLoading: isLoadingGetMaintenances,isSuccess } = useGetAllMaintenancesQuery(
		{ body: new URLSearchParams(searchAssets).toString(), page, limit },
		{ skip }
	)
	useEffect(() => {
		refetchComapnyData()
	}, [])
	const [deleteAssets, { isLoading: isLoadingDelete }] = useDeleteAssetsMutation()
	useEffect(() => {
		const res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
		setCurrencyLogo(res)
	}, [company])
	const handleMaintenanceFilter = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
			setSearchAssets((prevState) => ({
				...prevState,
				maintenanceFilter: value,
			}))
		handleSearchSubmit()
	}
	
	const handleSearchAssets = (e) => {
		const { name, value, update, target } = e
		if (name === nameKeys.searchField || name === nameKeys.status) {
			const valuesArray = value.map((obj) => obj.value)
			setSearchAssets({ ...searchAssets, searchField: valuesArray.toString() })
			setCriteria((prevCriteria: any) => ({
				...prevCriteria,
				searchField: valuesArray.toString(),
			}))
		} else if (name === nameKeys.customDate) {
			const startDate = update[0] ? moment(update[0]).format('YYYY-MM-DD') : ''
			const endDate = update[1] ? moment(update[1]).format('YYYY-MM-DD') : ''
			setSearchAssets({ ...searchAssets, startDate, endDate })

			setCriteria((prevCriteria: any) => ({
				...prevCriteria,
				startDate,
			}))
			setCriteria((prevCriteria: any) => ({
				...prevCriteria,
				endDate,
			}))
		} else if (name === nameKeys.recordCount) {
			const { value: recordCount } = target
			setLimit(recordCount)
			setCriteria((prevCriteria: any) => ({
				...prevCriteria,
				recordCount,
			}))
		} else {
			const { name: targetName, value: targetValue, selectedOptions } = target
			setSearchAssets({ ...searchAssets, [targetName]: targetValue })
			setCriteria((prevCriteria: any) => ({
				...prevCriteria,
				[targetName]: selectedOptions ? selectedOptions[0]?.label : targetValue,
			}))
		}
	}

	const clearSearch = (keyToRemove) => {
		const updatedCriteria = { ...criteria }
		delete updatedCriteria[keyToRemove]
		setCriteria(updatedCriteria)
		const updatedSearchAssets = { ...searchAssets }
		delete updatedSearchAssets[keyToRemove]
		setSearchAssets(updatedSearchAssets)
		handleSearchSubmit()
		setSearchAssets({
			keyword: '',
			searchField: '',
			siteId: '',
			locationId: '',
			categoryId: '',
			departmentId: '',
			person: '',
			customer: '',
			status: '',
			groupBy: '',
			recordCount: '',
			dateRangeBy: 'purchasedDate',
			quickDateRange: '',
			startDate: '',
			endDate: '',
			exportToExcel: false,
		})
		setLoading(false)
	}
	const handleSearchSubmit = () => {
		setLoading(true)
		setPage(1)
		setSkip(false)
		setShowSearch(false)
	}

	useEffect(() => {
		if (isSuccess) {
			setSkip(true)
		}
	}, [showSearch])

	useEffect(() => {
		setSkip(false)
	}, [])

	useEffect(() => {
		if (currentUser && maintenancesData) {
		  setPagination(maintenancesData?.pagination)
		  setLoading(false)
		}
	  }, [maintenancesData])

	const handleClose = () => {
		setShow(false)
	}

	const handleCheckboxChange = (id: number) => {
		setSelected((prevSelected) => {
			const updatedSelected = prevSelected.includes(id)
				? prevSelected.filter((itemId) => itemId !== id)
				: [...prevSelected, id]
			const isAllSelected = updatedSelected.length === maintenancesData?.allAssets?.length
			setSelectAll(isAllSelected)
			return updatedSelected
		})
		setDeleteDataArr((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((itemId) => itemId !== id)
			} else {
				return [...prevSelected, id]
			}
		})
	}

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = maintenancesData?.allAssets?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	useEffect(() => {
		if (selectAllData) {
			const newSelecteds = maintenancesData?.allAssets?.map((item: any) => item.id) || []
			setSelected(newSelecteds)
			setDeleteDataArr(newSelecteds)
		}
		setSelectAllData(false)
	}, [selectedData])

	useEffect(() => {
		if (deleteDataArr && deleteDataArr.length === 0) {
			const multicheckSelectCheckboxes = document.querySelectorAll(nameKeys.multiCheckSelectName)

			multicheckSelectCheckboxes.forEach((checkbox) => {
				if (checkbox instanceof HTMLInputElement) {
					checkbox.checked = false
				}
			})
		} else if (selectAll == true) {
			const multicheckSelectCheckboxes = document.querySelectorAll(nameKeys.multiCheckSelectName)

			multicheckSelectCheckboxes.forEach((checkbox) => {
				if (checkbox instanceof HTMLInputElement) {
					checkbox.checked = true
				}
			})
		} else if (selectAll == false) {
			const multicheckSelectCheckbox = document.querySelector(
				nameKeys.multiCheckSelectName
			) as HTMLInputElement

			if (multicheckSelectCheckbox) {
				multicheckSelectCheckbox.checked = false
			}
		}
	}, [deleteDataArr])

	const deleteSelectedAssets = async (id: number[]) => {
		try {
			const deleteIds = { deleteIds: id }
			const res = await deleteAssets({ id: deleteIds }).unwrap()
			toast.success(res?.message)
			setSkip(false)
		} catch (err: any) {
			toast.error(err?.data?.message)
		}
		setCheck([])
		setDeleteDataArr([])
		setSelectAll(false)
		setDeleteModal(false)
		setSelected([])
		setLoading(true)
	}

	const cancel = () => setDeleteModal(false)

	const confirm = () => deleteSelectedAssets(check)

	const handlePageChange = (currentPage: any) => {
		setLoading(true)
		setPage(currentPage)
		setSkip(false)
	}

	const removeFilter = (fieldName) => {
		setSearchAssets((prevSearchAssets) => ({
			...prevSearchAssets,
			[fieldName]: '',
		}))
	}

	const handleLimitChange = (newLimit: number) => {
		setLoading(true)
		setLimit(Number(newLimit))
		setPage(1)
		setSkip(false)
	}

	const handleExport = () => {
		setSearchAssets((prevSearchAssets) => ({
			...prevSearchAssets,
			exportToExcel: true,
		}))
		handleSearchSubmit()
	}

	useEffect(() => {
		if (maintenancesData?.fileName && searchAssets?.exportToExcel) {
			downloadAndRefresh()
		}
	}, [maintenancesData])

	const viewMaintenanceDetails = (id: any) => {
		if (dataId === id) {
			return setShowViewModal(true)
		}
		setLoading(true)
		setDataId(id)
		setModalCheck(false)
		setShowModal(false)
	}
	const editMaintenanceDetails = (id: any) => {
		if (dataId === id) {
			return setShowEditModal(true)
		}
		setLoading(true)
		setDataId(id)
		setModalCheck(true)
		setShowModal(false)
	}

	useEffect(() => {
		if (singleMaintenanceData) {
			if (showModal) {
				return setLoading(false)
			}
			if (modalCheck === true) {
				setLoading(false)
				return setShowEditModal(true)
			}
			setLoading(false)
			setShowViewModal(true)
		}
	}, [singleMaintenanceData])

	const downloadAndRefresh = () => {
		const link = document.createElement('a')
		link.href = process.env.REACT_APP_PUBLIC_URL + maintenancesData?.fileName
		link.target = `_blank`
		link.download = `downloaded-file-name` 
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		setSearchAssets((prevSearchAssets) => ({
			...prevSearchAssets,
			exportToExcel: false,
		}))
	}

	const currentPage = pagination?.page || 0
	const totalRecords = pagination?.total || 0
	const pageSize = limit || 10
	const startRecord = (currentPage - 1) * pageSize + 1
	const endRecord = Math.min(currentPage * pageSize, totalRecords)
	return (
		<>
			<SplashScreen
				isLoadingTemplate={isLoadingGetMaintenances || isLoadingGetCompany || isLoadingDelete}
			/>
			{!(isLoadingGetMaintenances || isLoadingGetCompany || isLoadingDelete) ? (
				<>
					<MaintenancesSearch
						showSearch={showSearch}
						setShowSearch={setShowSearch}
						searchAssets={searchAssets}
						handleSearchAssets={handleSearchAssets}
						handleSearchSubmit={handleSearchSubmit}
						setLoading={setLoading}
					/>
					<SetupCoulmnListing
						showSetupColumns={showSetupColumns}
						setShowSetupColumns={setShowSetupColumns}
						selectedColumns={selectedColumns}
						setSelectedColumns={setSelectedColumns}
						assetsData={maintenancesData}
						selectedColumnsRender={selectedColumnsRender}
					/>

					<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
						<div className='post d-flex flex-column-fluid' id='kt_post'>
							<div
								id='kt_content_container'
								className={`container ${(showSearch || showSetupColumns) && 'd-none'}`}
							>
								<div className='card'>
									<div className='d-inline-block mx-2'></div>
									<div className='card-body'>
										<ConfirmationPopup
											deleteModal={deleteModal}
											cancel={cancel}
											confirm={confirm}
											setSkip={setSkip}
											setupName={setupKeys.asset}
										/>
										<MaintenancesHeader
											assetsData={maintenancesData} 
											userAssets={maintenancesData?.userAssets} 
											fileName={maintenancesData?.fileName} 
											deleteDataArr={deleteDataArr}
											setDeleteModal={setDeleteModal}
											setCheck={setCheck}
											setShowSearch={setShowSearch}
											setShowSetupColumns={setShowSetupColumns}
											handleExport={handleExport}
											handleMaintenanceFilter={handleMaintenanceFilter}
										/>
										<div className='mb-5'>
											<MaintenancesTagList clearSearch={clearSearch} criteria={criteria} />
										</div>
										<MaintenancesEditModal
											show={show}
											handleClose={handleClose}
											AssetData={data}
											setSkip={setSkip}
										/>
										<div className='d-md-flex align-items-center justify-content-between mb-4'>
											<div className='d-flex limit-options align-items-center mb-md-0 mb-3'>
												<span className='text-muted'>Showing</span>
												<select
													className='form-select form-select-sm fw-bold mx-3 border-1'
													onChange={(e) => handleLimitChange(Number(e.target.value))}
													value={limit}
												>
													<option value='10'>10</option>
													<option value='15'>15</option>
													<option value='20'>20</option>
													<option value='25'>25</option>
													<option value='50'>50</option>
												</select>
												<span className='text-muted'>Maintenances</span>
											</div>
											<SetupColumns setShowSetupColumns={setShowSetupColumns} />
										</div>
										<div className='react-grid-card'>
											<div className='table-responsive pb-4'>
												<table
													id='kt_table_users'
													className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
												>
													<thead>
														<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
															<th role='columnheader' className='w-10px pe-2'>
																<div className='header-checkbox me-3'>
																	<input
																		type='checkbox'
																		className='form-check-input custom-form-check-input'
																		checked={selectAll}
																		onChange={handleSelectAll}
																	/>
																</div>
															</th>
															{selectedColumnsRender?.map((column, index) => (
																<th key={index}>{column.key.replace(/_/g, ' ')}</th>
															))}
															<th className='text-center'>Action</th>
														</tr>
													</thead>
													<tbody>
														{maintenancesData?.allAssets?.length > 0 ? (
															maintenancesData?.allAssets?.map((asset: any, rowIndex: number) => (
																<tr key={rowIndex} role='row'>
																	<td role='cell'>
																		<div className='form-check form-check-sm form-check-custom '>
																			<input
																				type='checkbox'
																				className='form-check-input'
																				checked={selected.includes(asset?.id)}
																				onChange={() => handleCheckboxChange(asset?.id)}
																			/>
																		</div>
																	</td>
																	{selectedColumnsRender?.map((column, colIndex) => (
																		<td key={colIndex} className='table-elipses'>
																			<ColumnData
																				column={column.key.replace(/_/g, ' ')}
																				assetsData={{ allAssets: [asset] }}
																				currencyLogo={currencyLogo}
																			/>
																		</td>
																	))}
																	<td className='text-center'>
																		<button
																			onClick={() => editMaintenanceDetails(asset?.id)}
																			className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
																		>
																			Edit
																		</button>
																		<button
																			onClick={() => viewMaintenanceDetails(asset?.id)}
																			className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style'
																		>
																			View
																		</button>
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan={selectedColumnsRender?.length + 2} className='text-center'>
																	<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																		No records found
																	</div>
																</td>
															</tr>
														)}
													</tbody>

												</table>
											</div>
										</div>
										{maintenancesData?.allAssets?.length ? (
											<div className='d-sm-flex align-items-center justify-content-between mt-3'>
												<div className='mt-3'>
													Showing {startRecord} to {endRecord} of {totalRecords} records
												</div>
												{pagination && (
													<Pagination
														className='mt-3 custom-pagination'
														defaultCurrent={1}
														pageSize={limit}
														current={pagination?.page} 
														total={pagination?.total} 
														onChange={handlePageChange}
														locale={localeInfo}
													/>
												)}
											</div>
										) : (
											''
										)}
									</div>
								</div>
							</div>
						</div>
						<Loader loading={loading} />
						<MaintenancesDetailModal
							show={showViewModal}
							assetMaintenance={singleMaintenanceData?.maintenance}
							onHide={() => {
								setLoading(false)
								setShowViewModal(false)
							}}
						/>
						<MaintenancesEditModal
							show={showEditModal}
							assetMaintenance={singleMaintenanceData?.maintenance}
							setShowModal={setShowModal}
							setShowEditModal={setShowEditModal}
							onHide={() => {
								setLoading(false)
								setShowEditModal(false)
							}}
							setLoading={setLoading}
						/>
						<Loader loading={loading} />
					</div>
				</>
			) : null}
		</>
	)
}

export default MaintenancesListing
