import { useState, useEffect } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import SitesModal from '../../Sites/Components/SitesModal'
import LocationModal from '../../Locations/Components/LocationModal'
import CategoryModel from '../../Categories/Components/CategoryModel'
import DepartmentsModel from '../../Departments/Components/DepartmentsModel'
import {
  useAddAssetMutation,
  useUpdateAssetMutation,
  useGetSingleAssetQuery,
} from '../../../../Services/Assets'
import { useGetAllSitesQuery } from '../../../../Services/Sites'
import { useGetAllCategoriesQuery } from '../../../../Services/Categories'
import {
  useGetAllLocationsQuery,
  useGetAllLocationsBySiteQuery,
} from '../../../../Services/Locations'
import { useGetAllDepartmentsQuery } from '../../../../Services/Departments'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { initialAssets, Asset as AssetLayout } from '../Core/_models'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { PageTitle } from '_metronic/layout/core'
import { useIntl } from 'react-intl'
import { toAbsoluteUrlImage } from '../../../../../../_metronic/helpers'
import { Images } from '_metronic/assets/Assets'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import getSymbolFromCurrency from 'currency-symbol-map'
import { Link } from 'react-router-dom'
import { imageMimeType } from 'config'
import Loader from 'App/BeatLoader'

const AssetsSechma = Yup.object().shape({
  description: Yup.string()
    .required('Description is required')
    .test('no-empty-spaces', 'Description should not be empty spaces', (value) => {
      return typeof value === 'string' && value.trim() !== ''
    }),
  assetTagId: Yup.string()
    .required('Asset tag id is required')
    .test('no-empty-spaces', 'Asset tag id should not be empty spaces', (value) => {
      return typeof value === 'string' && value.trim() !== ''
    }),
  cost: Yup.string()
    .matches(/^\d+(\.\d+)?$/, 'Cost must be a positive number without exponential notation')
    .nullable(),
  purchasedDate: Yup.date().max(new Date(), 'Purchase date cannot be a future date'),
})

const AddAssets = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const intl = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [data, setData] = useState({})
  const [skip, setSkip] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<boolean>(false)
  const [isNewSiteAdded, setIsNewSiteAdded] = useState(false)
  const [isNewLocationAdded, setIsNewLocationAdded] = useState(false)
  const [isNewDepartmentAdded, setIsNewDepartmentAdded] = useState(false)
  const [isNewCategoryAdded, setIsNewCategoryAdded] = useState(false)
  const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false)
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)
  const [fileDataURL, setFileDataURL] = useState(null)
  const [photo, setPhoto] = useState<string>('')

  const [file, setFile] = useState(null)
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const [addAsset, { isLoading: isAdding }] = useAddAssetMutation()

  const [updateAsset, { isLoading: isUpdating }] = useUpdateAssetMutation()
  const { data: siteData } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: categoryData } = useGetAllCategoriesQuery({ userId: currentUser?.id, page: 1 })
  const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const { data: departmentData } = useGetAllDepartmentsQuery({ userId: currentUser?.id })
  const { data: assetData, isLoading, error } = useGetSingleAssetQuery({ assetId: id }, { skip: !id })
  const { data: companyData, isSuccess: company } = useGetCompanyQuery()
  useEffect(() => {
    let res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
    setCurrencyLogo(res)
  }, [company])

  useEffect(() => {
    if (isNewSiteAdded) {
      formik.setFieldValue('siteId', siteData?.sites ? siteData?.sites[0]?.id : '')
    }
    if (isNewDepartmentAdded) {
      formik.setFieldValue(
        'departmentId',
        departmentData?.department ? departmentData?.department[0]?.id : ''
      )
    }
    if (isNewCategoryAdded) {
      formik.setFieldValue(
        'categoryId',
        categoryData?.category ? categoryData?.category[0]?.id : ''
      )
    }
    if (isNewLocationAdded) {
      formik.setFieldValue(
        'locationId',
        locationData?.locations
          ? locationData?.locations[locationData?.locations?.length - 1]?.id
          : ''
      )
      formik.setFieldValue('siteId', siteId)
    }
    setDataLoading(false)
  }, [siteData, departmentData, categoryData, locationData])

  useEffect(() => {
    setDataLoading(true)
    Promise.all([siteData, departmentData, categoryData, locationData])
      .then((responses) => { })
      .catch((error: any) => {
        toast.error(error)
      })
      .finally(() => {
        setDataLoading(false)
      })
  }, [currentUser?.id, skip])
  const formSubmit = (e) => {
    e?.preventDefault()
    const { errors, handleSubmit } = formik
    if (!errors?.description && !errors?.assetTagId) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    if (errors?.description || errors?.assetTagId) {
      toast.error('description and assetTagId is required')
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      handleSubmit()
    }
  }

  const formik = useFormik({
    initialValues: { ...initialAssets },
    validationSchema: AssetsSechma,
    onSubmit: async (values: AssetLayout) => {
      setLoading(true)
      if (currentUser) {
        const formData = new FormData()
        Object.keys(values).map((key: any) => {
          if (values[key] !== undefined && values[key] !== null) {
            if (key === 'purchasedDate' && isNaN(Date.parse(values[key]))) {
            } else {
              if (values[key] !== '') {
                formData.append(key, values[key])
              }
            }
          }
        })
        const data = id
          ? updateAsset({ assetId: id, formData }).unwrap()
          : addAsset(formData).unwrap()
        await data
          .then((res) => {
            if (res?.status) {
              toast.dismiss()
              toast.success(res?.message)
              navigate(`/asset/view/${res?.newAssetId ? res?.newAssetId : id}`)
              formik.resetForm()
            }
          })
          .catch((error) => {
            if (!error?.data?.success) {
              toast.error(error.data.message)
            }
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
            formik.setSubmitting(false)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
  })

  const changeHandler = (e) => {
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      toast.error('This file format is not allowed!')
      return
    } else {
      setFile(file)
    }
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (file) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])

  useEffect(() => {
    setSkip(false)
  }, [])

  const handleSiteClick = ({ target: { value } }: any) => {
    setDataLoading(true)
    if (!value) {
      setDataLoading(false)
    }
    setSiteId(value)
    setSkip(false)
  }

  useEffect(() => {
    formik.resetForm()
    if (assetData && assetData?.asset?.length > 0) {
      setPhoto(assetData?.asset?.photo)
    }
    if (id) {
      const purchaseDateFromDatabase = assetData?.asset?.purchasedDate
      const purchasedDate = new Date(purchaseDateFromDatabase)
      const localPurchaseDate = purchasedDate.toLocaleDateString('en-CA')
      if (assetData?.asset?.photo && assetData?.asset?.photo !== '') {
        setPhoto(assetData?.asset?.photo)
      }
      if (assetData?.asset?.siteId) {
        setSiteId(assetData?.asset?.siteId)
      }

      formik.setValues({
        assetTagId: assetData?.asset?.assetTagId,
        brand: assetData?.asset?.brand,
        categoryId: assetData?.asset?.categoryId,
        departmentId: assetData?.asset?.departmentId,
        description: assetData?.asset?.description,
        model: assetData?.asset?.model,
        purchasedDate: assetData?.asset?.purchasedDate == null ? '' : localPurchaseDate,
        purchasedFrom: assetData?.asset?.purchasedFrom,
        serialNo: assetData?.asset?.serialNo,
        siteId: assetData?.asset?.siteId,
        locationId: assetData?.asset?.locationId,
        cost: assetData?.asset?.cost,
        statusType: assetData?.asset?.statusType,
        photo: assetData?.asset?.photo,
      })
    }
  }, [assetData])

  const handleCloseSiteModal = () => {
    setShowSiteModal(false)
  }

  const handleShowSiteModal = () => {
    setShowSiteModal(true)
    setIsNewSiteAdded(true)
  }

  const handleCloseLocationModal = () => {
    setShowLocationModal(false)
  }

  const handleShowLocationModal = () => {
    setShowLocationModal(true)
    setIsNewLocationAdded(true)
  }

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false)
  }

  const handleShowCategoryModal = () => {
    setShowCategoryModal(true)
    setIsNewCategoryAdded(true)
  }

  const handleCloseDepartmentModal = () => {
    setShowDepartmentModal(false)
  }

  const handleShowDepartmentModal = () => {
    setShowDepartmentModal(true)
    setIsNewDepartmentAdded(true)
  }

  return (
    <>
      {' '}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: id ? `Edit an Asset` : `Add an Asset` })}
      </PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 '
          role=''
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <span className='me-2 align-middle'>
              <i className='la la-plus-circle fs-1'></i>
            </span>
            <h3 className='fw-bolder m-0'>{id ? `Edit` : `Add`} an Asset</h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body border-top p-lg-9 p-md-7 p-6'>
              <div className='pb-5'>
                {' '}
                <h3 className='fw-bolder m-0 mb-3'>Asset details</h3>
              </div>
              <div className='row mb-6'>
                <div className='col-xl-6'>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Description
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control  form-control-solid'
                        placeholder=''
                        {...formik.getFieldProps('description')}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.description}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Asset Tag ID
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control  form-control-solid'
                        placeholder=''
                        {...formik.getFieldProps('assetTagId')}
                      />
                      {formik.touched.assetTagId && formik.errors.assetTagId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.assetTagId}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Purchase Date</label>
                    <div className='col-lg-9 fv-row'>
                      <div className='input-group'>
                        <input
                          type='date'
                          className='form-control  form-control-solid'
                          aria-label='Amount (to the nearest dollar)'
                          {...formik.getFieldProps('purchasedDate')}
                        />
                        <span className='input-group-text border-0'>
                          <i className='fa fa-calendar'></i>
                        </span>
                      </div>
                      {formik.touched.purchasedDate && formik.errors.purchasedDate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.purchasedDate}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Cost</label>
                    <div className='col-lg-9 fv-row'>
                      <div className='input-group currency-field'>
                        <span className='input-group-text border-0'>{currencyLogo}</span>
                        <input
                          type='string'
                          className='form-control  form-control-solid'
                          aria-label='Amount (to the nearest dollar)'
                          {...formik.getFieldProps('cost')}
                        />
                      </div>
                      {formik.touched.cost && formik.errors.cost && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.cost}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-xl-6'>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Purchased From</label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control  form-control-solid'
                        placeholder=''
                        {...formik.getFieldProps('purchasedFrom')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Brand</label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control  form-control-solid'
                        placeholder=''
                        {...formik.getFieldProps('brand')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Model</label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control  form-control-solid'
                        placeholder=''
                        {...formik.getFieldProps('model')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label  fw-bold fs-6'>Serial No</label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control  form-control-solid'
                        placeholder=''
                        {...formik.getFieldProps('serialNo')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='pb-5 border-top pt-9'>
                {' '}
                <h3 className='fw-bolder m-0 mb-3'>Site, Location, Category and Department</h3>
              </div>
              <div className='row mb-6'>
                <div className='col-xl-6'>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      <span >Site</span>
                    </label>
                    <div className='col-lg-9 fv-row d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid  '
                        {...formik.getFieldProps('siteId')}
                        onChange={(e) => {
                          formik.handleChange(e)
                          handleSiteClick(e)
                        }}
                      >
                        <option value=''>Select Site</option>
                        {siteData?.sites.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item?.id}>
                              {item?.name}
                            </option>
                          )
                        })}
                      </select>
                      <button
                        type='button'
                        className='btn btn-light-primary d-flex align-items-center ms-3'
                        onClick={() => handleShowSiteModal()}
                      >
                        <i className='la la-plus fs-3' />
                        New
                      </button>
                      <SitesModal
                        show={showSiteModal}
                        handleClose={handleCloseSiteModal}
                        SiteData={[]}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      <span >Location</span>
                    </label>
                    <div className='col-lg-9 fv-row d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid  '
                        {...formik.getFieldProps('locationId')}
                      >
                        <option value=''>Select Location</option>
                        {locationData?.locations?.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item?.id}>
                              {item?.location}
                            </option>
                          )
                        })}
                      </select>
                      <button
                        type='button'
                        className='btn btn-light-primary d-flex align-items-center ms-3'
                        onClick={() => handleShowLocationModal()}
                      >
                        <i className='la la-plus fs-3' />
                        New
                      </button>
                      <LocationModal
                        show={showLocationModal}
                        setSiteId={setSiteId}
                        handleClose={handleCloseLocationModal}
                        locationData={[]}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-xl-6'>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      <span >Category</span>
                    </label>
                    <div className='col-lg-9 fv-row d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid  '
                        {...formik.getFieldProps('categoryId')}
                      >
                        <option value=''>Select Category</option>
                        {categoryData?.category?.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item?.id}>
                              {item?.category}
                            </option>
                          )
                        })}
                      </select>
                      <button
                        type='button'
                        className='btn btn-light-primary d-flex align-items-center ms-3'
                        onClick={() => handleShowCategoryModal()}
                      >
                        <i className='la la-plus fs-3' />
                        New
                      </button>
                      <CategoryModel
                        show={showCategoryModal}
                        handleClose={handleCloseCategoryModal}
                        data={[]}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      <span >Department</span>
                    </label>
                    <div className='col-lg-9 fv-row d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid  '
                        {...formik.getFieldProps('departmentId')}
                      >
                        <option value=''>Select Department</option>
                        {departmentData?.department?.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item?.id}>
                              {item?.department}
                            </option>
                          )
                        })}
                      </select>
                      <button
                        type='button'
                        className='btn btn-light-primary d-flex align-items-center ms-3'
                        onClick={() => handleShowDepartmentModal()}
                      >
                        <i className='la la-plus fs-3' />
                        New
                      </button>
                      <DepartmentsModel
                        show={showDepartmentModal}
                        handleClose={handleCloseDepartmentModal}
                        data={[]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-xl-6'>
                  <div className='row'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Asset Image</label>
                    <div className='col-lg-9'>
                      <div
                        className='image-input image-input-outline mb-lg-4 mb-3'
                        data-kt-image-input='true'
                      >
                        <label htmlFor='photo' className='cursor-pointer position-relative'>
                          <img
                            className='image-input-wrapper w-125px h-125px'
                            src={
                              fileDataURL ? fileDataURL : photo ? toAbsoluteUrlImage(photo) : Images.Avatar_JPG
                            }
                            alt='profile'
                            crossOrigin='anonymous'
                          />
                          <span className='edit-icon'>
                            <i className='las la-camera fs-2'></i>
                          </span>
                        </label>
                        <div>
                          <input
                            className='form-control d-none'
                            type='file'
                            name='photo'
                            id='photo'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              changeHandler(e)
                              formik.setFieldValue(
                                'photo',
                                e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType)
                                  ? e.target.files![0]
                                  : photo
                              )
                            }}
                          />
                        </div>
                      </div>
                      <p>
                        Only (<strong>JPG, GIF, PNG</strong>) are allowed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <Link to='/assets' className='btn btn-light-secondary main-btn-style me-2'>
                Cancel
              </Link>
              <button
                onClick={(e) => formSubmit(e)}
                className='btn btn-primary'
                disabled={isLoading}
              >
                {!loading && 'Submit'}
                {loading && (
                  <span className='indicator-progress d-block'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        <Loader loading={dataLoading} />
      </div>
    </>
  )
}

export default AddAssets
