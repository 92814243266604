
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../App/Modules/Auth'
import { toAbsoluteUrlImage } from '../../../helpers'
import { Images } from '_metronic/assets/Assets'
import SplashScreen from 'App/SplashScreen'
import { toast } from 'react-toastify'

const HeaderUserMenu: FC = () => {
	const { currentUser, logout } = useAuth()
	const [loading, setloading] = useState<boolean>(false)

	useEffect(() => {
		const handleLogout = () => {
			try {
				logout()
				setloading(false)
			} catch (error: any) {
				toast.error('error::', error)
			}
		}

		if (loading) {
			handleLogout()
		}
	}, [loading, logout])

	function truncateEmail(email: string | undefined | null): string {
		if (!email) {
			return ''
		}

		const atTheIndex = email.indexOf('@')
		const atIndex = atTheIndex - 3
		if (atIndex === -1) {
			return email
		}

		const firstPart = email.slice(0, atIndex) 
		const secondPart = email.slice(atIndex)
		const truncatedFirstPart = firstPart.slice(0, 6)

		return `${truncatedFirstPart}...${secondPart}`
	}
	return (
		<>
			<SplashScreen isLoadingTemplate={loading} />
			{!loading ? (
				<div
					className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-primary fw-bold py-4 fs-6 custom-menu-dropdown'
					data-kt-menu='true'
					data-popper-placement=''
				>
					<div className='menu-item px-3'>
						<div className='menu-content d-flex align-items-center px-3'>
							<div className='symbol symbol-50px me-3'>
								<img
									className='image-input-wrapper '
									src={currentUser?.photo ? toAbsoluteUrlImage(currentUser?.photo) : Images.Avatar_JPG}
									alt='profile'
									crossOrigin='anonymous'
								/>
							</div>

							<div className='d-flex flex-column'>
								<div className='fw-bolder d-flex align-items-center fs-5 text-capitalize'>
									{currentUser?.firstName} {currentUser?.lastName}
								</div>
								<Link to='/' className='fw-bold text-muted text-hover-primary fs-7 email-link'>
									{truncateEmail(currentUser?.email)}
								</Link>
							</div>
						</div>
					</div>

					<div className='separator my-2'></div>

					<div className='menu-item px-3'>
						<Link to={'/crafted/account/settings'} className='menu-link px-5'>
							My Profile
						</Link>
					</div>

					<div className='menu-item px-3'>
						<Link to='/crafted/account/settings' className='menu-link px-5'>
							Account Settings
						</Link>
					</div>

					<div className='menu-item px-3'>
						<Link to={'/auth'} onClick={() => setloading(true)} className='menu-link px-5'>
							Logout
						</Link>
					</div>
				</div>
			) : null}
		</>
	)
}

export { HeaderUserMenu }
