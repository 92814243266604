import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import { useAuth } from '../../../../Auth'
import LocationModal from './LocationModal'
import { LocationHeader } from './LocationHeader'
import { LocationResponse } from '../Core/_models'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import {
	useDeleteLocationMutation,
	useGetAllLocationsBySiteQuery,
	useGetAllLocationsQuery,
} from '../../../../Services/Locations'
import { useNavigate } from 'react-router-dom'
import SplashScreen from 'App/SplashScreen'
import { checkUserUnauthorized } from '../../../../Auth'
import { SiteResponse } from '../../Sites/Core/_models'
import { useGetlistOfSitesQuery } from 'App/Modules/Services/Sites'
import { setupKeys } from 'config'
import { Link } from 'react-router-dom'
import Loader from 'App/BeatLoader'

const LocationListing = () => {
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [sites, setSites] = useState<SiteResponse[] | undefined>()
	const [check, setCheck] = useState<number[]>([])
	const [data, setData] = useState({})
	const [show, setShow] = useState<boolean>(false)
	const [skip, setSkip] = useState<boolean>(false)
	const [siteId, setSiteId] = useState<number>()
	const [selected, setSelected] = useState<number[]>([])
	const [selectAll, setSelectAll] = useState<boolean>(false)
	const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [pagination, setPagination] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const { data: listOfSites } = useGetlistOfSitesQuery({})
	const {
		data: userLocationList,
		isLoading,
		isError,
		error,
	} = useGetAllLocationsBySiteQuery({ id: siteId, page, limit }, { skip: !siteId })
	const [deleteLocations, { isLoading: isLoadingDeleteLocation }] = useDeleteLocationMutation()
	const [isSitesExist, setIsSitesExist] = useState<any>(false)
	const [deleteCascade, setDeleteCascade] = useState(false)

	useEffect(() => {
		if (isError) {
			const errorData = error as { data: any }
			checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
		}
	}, [isError])

	const handlePageChange = (currentPage: any) => {
		setLoading(true)
		setPage(currentPage)
	}
	const handleClose = () => {
		setShow(false)
	}
	const handleShow = (item: LocationResponse) => {
		setData(item)
		setShow(true)
	}
	const checkVistingPage = (dataList: any) => {
		if (isSitesExist === false) {
			if (dataList && dataList?.length > 0) {
				setIsSitesExist(dataList?.length)
			} else {
				setIsSitesExist(0)
			}
		}
	}
	const handleCheckboxChange = (id: number) => {
		setSelected(prevSelected => {
			const updatedSelected = prevSelected.includes(id) ? prevSelected.filter(itemId => itemId !== id) : [...prevSelected, id];
			const isAllSelected = updatedSelected.length === userLocationList?.locations?.length;
			setSelectAll(isAllSelected);
			return updatedSelected;
		});
		setDeleteDataArr(prevSelected => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter(itemId => itemId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};
	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = userLocationList?.locations?.map((item: any) => item.id)
			setSelectAll(true)
			setSelected(newSelecteds)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}
	const deleteSelectedLocation = async (ids: number[]) => {
		try {
			const locationIds = { locationIds: ids }
			const res = await deleteLocations({ cascade: deleteCascade ? 1 : 0, id: locationIds }).unwrap()
			toast.success(res.message)
			setCheck([])
			setDeleteDataArr([])
			setSelectAll(false)
			setSelected([])
			setDeleteModal(false)
			setDeleteCascade(false)
			setLoading(true)
		} catch (err: any) {
			setCheck([])
			setDeleteDataArr([])
			setSelectAll(false)
			setDeleteModal(false)
			setDeleteCascade(false)
			setSelected([])
			if (!err.data.success) {
				toast.error(err?.data?.message)
			}
			checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
		}
	}
	const cancel = () => {
		setDeleteModal(false)
		setDeleteCascade(false)
	}

	const confirm = () => {
		deleteSelectedLocation(check)
	}

	useEffect(() => {
		checkVistingPage(userLocationList?.locations);
		setPagination(userLocationList?.pagination);
		if (currentUser && userLocationList?.locations) {
			checkVistingPage(userLocationList?.locations);
			setPagination(userLocationList?.pagination);
			setLoading(false);
		}
	}, [userLocationList])

	useEffect(() => {
		setSkip(false)
	}, [])

	const handleLimitChange = (newLimit: number) => {
		setLoading(true)
		setLimit(Number(newLimit))
		setPage(1)
	}

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoadingDeleteLocation || isLoading} />
			{!(isLoadingDeleteLocation || isLoading) ? (
				<div id='kt_content' className='d-flex flex-column flex-column-fluid'>
					<div className='post d-flex flex-column-fluid' id='kt_post'>
						<div id='kt_content_container' className='container'>
							<div className='card'>
								<ConfirmationPopup
									deleteModal={deleteModal}
									cancel={cancel}
									confirm={confirm}
									deleteCascade={deleteCascade}
									setupName={setupKeys.location}
								/>
								<LocationHeader
									deleteDataArr={deleteDataArr}
									setSiteId={setSiteId}
									setSkip={setSkip}
									setDeleteModal={setDeleteModal}
									setCheck={setCheck}
									handleShow={handleShow}
									setLoading={setLoading}
								/>
								<LocationModal
									show={show}
									handleClose={handleClose}
									locationData={data}
									siteId={siteId}
									setLoading={setLoading}
								/>
								<div className='card-body py-4'>
									<p className={'text-dark'}>
										You may also add <strong>Locations</strong>. <strong>Locations</strong> are a
										subset of <strong>Sites</strong>. For example, the <strong>Site</strong> may be
										a building or address. The <strong>Location</strong> may be a specific room,
										office or floor within the <strong>Site</strong>. Select a Site and add your
										list of <strong>Locations</strong> here.
									</p>{' '}
									<div className='d-flex limit-options align-items-center mb-3'>
										<span className='text-muted'>Showing</span>
										<select
											className='form-select form-select-sm fw-bold mx-3 border-1'
											onChange={(e) => handleLimitChange(Number(e.target.value))}
											value={limit}
										>
											<option value='10'>10</option>
											<option value='20'>20</option>
											<option value='30'>30</option>
										</select>
										<span className='text-muted'>entries</span>
									</div>
									<div className='table-responsive pb-4 custom-table'>
										<table
											id='kt_table_users'
											className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
											role='table'
										>
											<thead>
												<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
													<th role='columnheader' className='w-10px pe-2'>
														<div className='header-checkbox me-3'>
															<input
																type='checkbox'
																className='form-check-input custom-form-check-input'
																checked={selectAll}
																onChange={handleSelectAll}
															/>
														</div>
													</th>
													<th role='columnheader' className='min-w-125px'>
														{' '}
														Locations{' '}
													</th>
													<th role='columnheader' className='w-100px text-center'>
														{' '}
														Actions{' '}
													</th>
												</tr>
											</thead>
											<tbody className='text-gray-600 fw-bol' role='rowgroup'>
												{userLocationList?.locations?.length > 0 ? (
													userLocationList?.locations?.map(
														(item: LocationResponse, idx: number) => {
															return (
																<tr key={idx} role='row'>
																	<td role='cell' >
																		<div className='form-check form-check-sm form-check-custom '>
																			<input
																				type='checkbox'
																				className='form-check-input'
																				checked={selected.includes(item.id)}
																				onChange={() => handleCheckboxChange(item.id)}
																			/>
																		</div>
																	</td>
																	<td role='cell' >
																		<div className='d-flex align-items-center'>
																			<div className='text-truncate'>{item.location}</div>
																		</div>
																	</td>
																	<td role='cell' className='text-center'>
																		<div className='dropdown'>
																			<button
																				className='dropdown-toggle'
																				type='button'
																				id='dropdownMenuButton1'
																				data-bs-toggle='dropdown'
																				aria-expanded='false'
																			>
																				Action
																				<ActionIcon />
																			</button>
																			<ul
																				className='dropdown-menu actions-dropdown'
																				aria-labelledby='dropdownMenuButton1'
																			>
																				<li onClick={() => handleShow(item)}>
																					<button className='dropdown-item'>Edit</button>
																				</li>
																				<li
																					onClick={() => {
																						setDeleteModal(true)
																						setCheck([item.id])
																					}}
																				>
																					<button className='dropdown-item'>Delete</button>
																				</li>
																			</ul>
																		</div>
																	</td>
																</tr>
															)
														}
													)
												) : (
													<tr>
														<td colSpan={12}>
															<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																No records found
															</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
									{pagination && (
										<Pagination
											className='m-3 custom-pagination pt-5'
											defaultCurrent={1}
											pageSize={limit}
											current={pagination?.page}
											total={pagination?.total}
											onChange={handlePageChange}
											locale={localeInfo}
										/>
									)}
								</div>
								{isSitesExist === 0 ? (
									<div className='card-footer d-flex justify-content-end py-6 px-9'>
										<button
											onClick={() => {
												navigate(`/apps/setup/categories`)
											}}
											className='btn btn-primary'
										>
											Continue
										</button>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</div>
					<Loader loading={loading} />
				</div>
			) : null}
		</>
	)
}

export default LocationListing
