import moment from 'moment'

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
export const imageMimeType = /image\/(png|jpg|jpeg)/i
export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
export const nameKeys = {
  searchField: 'searchField',
  status: 'status',
  customDate: 'customDate',
  recordCount: 'recordCount',
  multiCheckSelectName: 'multicheckSelect',
}
export const setupKeys = {
  asset: 'Asset',
  category: 'Category',
  customer: 'Customer',
  department: 'Department',
  location: 'Location',
  person: 'Person',
  site: 'Site',
}
export const dateFormat = (date) => {
  return moment(date).format('YYYY-MM-DD')
}
export const assetFieldCheck = [
  'Category',
  'Site',
  'Location',
  'Department',
  'Assigned_To',
  'Event_Date',
  'Event_Due_Date',
  'Event_Notes',
  'Leased_To',
  'Status',
  'Expires',
  'Frequency',
  'Is_Repeating',
  'Maintenance_By',
  'Maintenance_Completion_Date',
  'Maintenance_Cost',
  'Maintenance_Detail',
  'Recur_on_every',
  'Maintenance_Status',
  'Title',
  'Length',
  'Notes',
  'Warranty_Created_by',
  'Warranty_Status'
]
export const eventFieldsCheck = [
  'Assigned_To',
  'Event_Date',
  'Event_Due_Date',
  'Event_Notes',
  'Leased_To',
  'Status',
]
export const linkingFieldsCheck = ['Category', 'Site', 'Location', 'Department']
export const maintenanceFieldsCheck = [
  'Expires',
  'Frequency',
  'Is_Repeating',
  'Maintenance_By',
  'Maintenance_Completion_Date',
  'Maintenance_Cost',
  'Maintenance_Detail',
  'Recur_on_every',
  'Maintenance_Status',
  'Title',
]
export const warrantyFieldsCheck = [
  'Expires',
  'Length',
  'Notes',
  'Warranty_Created_by',
  'Warranty_Status'
]
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export let searchFieldsOptionsPersons = [
  { label: 'All', value: '*' },
  { value: 'fullName', label: 'Name' },
  { value: 'employeeId', label: 'Employee ID' },
  { value: 'title', label: 'Job Title' },
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
]
export const searchFieldsOptionsCustomer = [
  { label: 'All', value: '*' },
  { value: 'fullName', label: 'name' },
  { value: 'email', label: 'email' },
  { value: 'phone', label: 'phone' },
  { value: 'company', label: 'company' },
]
export const countryCode = {
  PAK: '+92' 
}