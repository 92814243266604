import { Images } from "_metronic/assets/Assets"
import "./TrustedBy.css"

function TrustedBy() {
    return (
        <section className='trusted-by-section position-relative mb-5'>
            <div className="position-relative z-2">
            <div className="custom-container container">
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="text mb-lg-0 mb-5">
                            <span className="ultra-mini-heading mb-2">Who endorse our work</span>
                            <h2 className="h-main">Trusted By:</h2>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="image-section">
                            <ul className="list-unstyled d-flex mb-0 align-items-center justify-content-start">
                                <li>
                                    <div className="image">
                                        <img src={Images.Trusted_1} alt="company" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="image">
                                        <img src={Images.Trusted_2} alt="company" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="image">
                                        <img src={Images.Trusted_3} alt="company" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="image">
                                        <img src={Images.Trusted_4} alt="company" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="image">
                                        <img src={Images.Trusted_5} alt="company" className="img-fluid" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default TrustedBy