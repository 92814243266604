import { dateFormat } from 'config'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
function AssetMinimalDetailModal(props) {
    return (
        <div>
            <Modal
                className='px-2'
                {...props}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.singleAssetData?.asset?.assetTagId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-title fw-bolder mb-4'>{props.singleAssetData?.asset?.description}</div>
                    <div className='assetProduct_detail'>
                        <div className='asset_detail_table table-responsive'>
                            <table
                                className='table table-bordered detail-table table-sm mb-0'
                                aria-label='Quick View'
                                role='presentation'
                            >
                                <tbody>
                                    <tr>
                                        <td className='label_col warning'>Purchase Date</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                            {dateFormat(props.singleAssetData?.asset?.purchasedDate)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Purchased From</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.purchasedfrom}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Cost</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.cost}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Brand</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.brand}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Model</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.model}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Serial No</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>{props.singleAssetData?.asset?.serialNo}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Site</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.site?.name}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Location</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.location?.location}
                                            </span> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Category</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.category?.category}    
                                            </span> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Department</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.department?.department}    
                                            </span> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Assigned to</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.site?.name}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col warning'>Status</td>
                                        <td className='success fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.statusType.replace(/_/g, ' ')}
                                            </span>                                        
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link
                        to={'/crafted/pages/account-details/overview'}
                        className='btn btn-primary'
                        href='/assets/detail/29962946'
                        id='eventUrl'
                    >
                        More Details
                    </Link>
                    <button
                        onClick={props.onHide}
                        type='button'
                        className='btn btn-secondary'
                        data-bs-dismiss='modal'
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AssetMinimalDetailModal
