import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../core/_requests'
import { toast } from 'react-toastify'
import { verifyEmail } from '../core/_requests'
import * as authHelper from '../core/AuthHelpers'
import { passwordRegex } from 'config'


const initialValues = {
  newPassword: '',
  passwordConfirmation: '',
}

const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Password must include at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Password must include at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const CreatePassword = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const emailVerification = async (token: string | undefined) => {
    try {
      const response = await verifyEmail(token)
      if (response?.data?.success) {
        toast.success(response?.data?.message)
        authHelper.setAuth(response?.data?.token)
        return true
      } else {
        toast.error(response?.data?.message)
        return false
      }
    } catch (error: any) {
      toast.error(error)
      return false
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)

      if (token) {
        const emailVerifiedToken = await emailVerification(token)
        const { passwordConfirmation, newPassword } = values
        if (emailVerifiedToken) {
          const flag = 1
          resetPassword(newPassword, passwordConfirmation, token, flag)
            .then(({ data: { result } }) => {
              setHasErrors(false)
              setLoading(false)
              toast.success('Password create successfuly')
              navigate('/auth')
            })
            .catch(() => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
            })
        } else {
          setHasErrors(true)
          setLoading(false)
          toast.error('Email verification failed')
        }
      } else {
        setStatus('Something went wrong')
      }
    },
  })

  return (
    <>
      <div>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Create Password</h1>
            <div className='text-gray-700 fs-5'>Create your password here</div>
          </div>
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}
          <div className='fv-row mb-4'>
            <label className='form-label fw-bolder text-gray-900 fs-6 required'>New Password</label>
            <div>
              <input
                type='password'
                placeholder='New Password'
                autoComplete='off'
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control  form-control-solid',
                  { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
              />
            </div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6 required'>
              Confirm Password
            </label>
            <div>
              <input
                type='password'
                placeholder='Confirm Password'
                autoComplete='off'
                {...formik.getFieldProps('passwordConfirmation')}
                className={clsx(
                  'form-control  form-control-solid',
                  {
                    'is-invalid':
                      formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                  },
                  {
                    'is-valid':
                      formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                  }
                )}
              />
            </div>
            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.passwordConfirmation}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary w-100 mb-sm-4 mb-3'
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}

            >
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link
              to='/auth/login'
              className='btn btn-lg btn-light-primary main-btn-style w-100'
            >
              Cancel
            </Link>{' '}
          </div>
        </form>
      </div>
    </>
  )
}

export default CreatePassword
