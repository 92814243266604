import Benefit_1 from "../../Images/Benefit1.svg"
import Benefit_2 from "../../Images/Benefit2.svg"
import Benefit_3 from "../../Images/Benefit3.svg"
import Benefit_4 from "../../Images/Benefit4.svg"
import Benefit_5 from "../../Images/Benefit5.svg"
import Benefit_6 from "../../Images/Benefit6.svg"
import Benefit_7 from "../../Images/Benefit7.svg"
import Benefit_8 from "../../Images/Benefit8.svg"
import Avatar from "../assets/images/CompanyAvatar.png"
import Avatar_JPG from "../assets/images/Avatar.jpg"
import BannerImage from "../../Images/BannerImage.png"
import Arrows from "../../Images/Arrows.png"
import BarsLottie from "../../Lotties/Bars.json"
import RocketLottie from "../../Lotties/Rocket.json"
import Feature_1 from "../../Images/Feature1.png"
import Logo from "../../Images/DefaultBlackLogo.svg"
import Client_1 from "../../Images/ClientImg1.png"
import Trusted_1 from "../../Images/Trusted1.png"
import Trusted_2 from "../../Images/Trusted2.png"
import Trusted_3 from "../../Images/Trusted3.png"
import Trusted_4 from "../../Images/Trusted4.png"
import Trusted_5 from "../../Images/Trusted5.png"
import DefaultWhiteLogo from "../../Images/DefaultWhiteLogo.svg"
import LayersLottie from "../../Lotties/Layers.json"
import AssetsPlaceholder from "../../Images/AssetsPlaceholder.jpg"
import SubtractionLines from "../../Images/SubtractionLines.png"
import DefaultBlackLogo from "../../Images/DefaultBlackLogo.svg"
export const Images = {
    Benefit_1,
    Benefit_2,
    Benefit_3,
    Benefit_4,
    Benefit_5,
    Benefit_6,
    Benefit_7,
    Benefit_8,
    Avatar,
    Arrows,
    BarsLottie,
    RocketLottie,
    BannerImage,
    Client_1,
    Feature_1,
    Logo,
    Trusted_1,
    Trusted_2,
    Trusted_3,
    Trusted_4,
    Trusted_5,
    DefaultWhiteLogo,
    LayersLottie,
    AssetsPlaceholder,
    SubtractionLines,
    DefaultBlackLogo,
    Avatar_JPG
}