export type Customer = {
	fullName: string
	employeeId?: string | null,
	title?: string | null
	phone?: string | null
	email?: string | null
	siteId?: number | null
	locationID?: number | null
	departmentId?: number | null
	notes?: string | null
	createdBy?: string
}

export const initialPersons: Customer = {
	fullName: "",
	employeeId: null,
	title: null,
	phone: null,
	email: null,
	siteId: undefined,
	locationID: undefined,
	departmentId: undefined,
	notes: null,
	createdBy: ""
}

export type PersonResponse = {
	id: number;
	fullName: string;
	employeeId?: string;
	title?: string;
	phone?: string;
	email?: string;
	siteId?: string;
	locationID?: string;
	departmentId?: string;
	notes?: string;
	createdBy?: string
};
