import { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { resendEmail } from '../core/_requests'
import { toast } from 'react-toastify'

const VerifyEmail = () => {
	const [disableBtn, setDisabledBtn] = useState(false)
	const [loader, setLoader] = useState(false)
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const email = searchParams.get('email')

	const resendVerificationEmail = async (email: string) => {
		try {
			setLoader(true)
			const response = await resendEmail(email)
			setLoader(false)
			toast.success(response?.data?.message)
			setDisabledBtn(true)
			setTimeout(() => {
				setDisabledBtn(false)
			}, 50000)
		} catch (error: any) {
			const message = error.response?.data?.message
			toast.error(message)
		}
	}

	return (
		<div>
			<h1 className='mb-xl-5 mb-4'>Verify Email</h1>
			<p className='mb-sm-5 mb-4'>
				Please click the link in the email we sent to{' '}
				<span className='text-nowrap fw-bolder'>{email}</span> to verify your account.
			</p>
			<div className="d-flex flex-wrap justify-content-end pt-5">
				{email && (
					<button
						className='btn btn-light-primary main-btn-style me-2'
						onClick={() => resendVerificationEmail(email)}
						disabled={disableBtn}
					>
						{!loader && 'Resend Verification Email'}
						{loader && (
							<span className='indicator-progress d-block'>
								Please wait...{' '}
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
				)}
				<div className='redirect-to-login'>
					<Link className='btn main-btn-style btn-primary' to='/auth/'>
						Login
					</Link>
				</div>
			</div>
		</div>
	)
}

export default VerifyEmail
