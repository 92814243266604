import "../import.css"
import { Dispatch, SetStateAction } from "react"
interface FormFieldProps {
    type: Number,
    setFormState: Dispatch<SetStateAction<any>>,
    formState: any,
}
function FormField({ type, setFormState, formState }: FormFieldProps) {
    return (
        <table className="table table-bordered mb-8" aria-label="Verify and Import" role="presentation">
            {
                type === 1 ?
                    <tbody>
                        <tr>
                            <td className="fw-bold text-sm-end w-45">If import data contains an Asset Tag ID already existing in AssetRack, then?</td>
                            <td className="warning w-55">
                                <div className="radio-inline">
                                    <label className="radio mb-2 d-flex">
                                        <div>
                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright"
                                                checked={formState.updateAssests}
                                                onChange={() => setFormState(prevState => ({
                                                    ...prevState,
                                                    updateAssests: true,
                                                }))}
                                            />
                                        </div>
                                        <span>Update Asset</span>
                                    </label>
                                    <label className="radio mb-2 d-flex">
                                        <div>
                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright"
                                                checked={!formState.updateAssests}
                                                onChange={() => setFormState(prevState => ({
                                                    ...prevState,
                                                    updateAssests: false,
                                                }))}
                                            />
                                        </div>
                                        <span>Ignore Asset</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr id="updateBlankWrapper">
                            <td className="fw-bold text-sm-end w-45">While updating, if the existing asset has a value in the cell and import data is blank, then...</td>
                            <td className="warning w-55">
                                <div className="radio-inline">
                                    <label className="radio mb-2 d-flex">
                                        <div>
                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radBlank" id="radBlank_false"
                                                checked={formState.keepExistingValueAssets}
                                                onChange={() => setFormState(prevState => ({
                                                    ...prevState,
                                                    keepExistingValueAssets: true,
                                                }))}
                                            />
                                        </div>
                                        <span>Keep existing value</span>
                                    </label>
                                    <label className="radio mb-2 d-flex">
                                        <div>
                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radBlank" id="radBlank_true"
                                                checked={!formState.keepExistingValueAssets}
                                                onChange={() => setFormState(prevState => ({
                                                    ...prevState,
                                                    keepExistingValueAssets: false,
                                                }))}
                                            />
                                        </div>
                                        <span>Make field blank</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-bold text-sm-end w-45">If import data has linking fields (like categories, sites, locations, etc.) and existing table is missing data, then in the linking table ...</td>
                            <td className="warning w-55">
                                <div className="radio-inline">
                                    <label className="radio mb-2 d-flex">
                                        <div>
                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radLinking"
                                                checked={formState.LinkedFieldsAssets}
                                                onChange={() => setFormState(prevState => ({
                                                    ...prevState,
                                                    LinkedFieldsAssets: true,
                                                }))} />
                                        </div>
                                        <span>Insert record</span>
                                    </label>
                                    <label className="radio mb-2 d-flex">
                                        <div>
                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radLinking"
                                                checked={!formState.LinkedFieldsAssets}
                                                onChange={() => setFormState(prevState => ({
                                                    ...prevState,
                                                    LinkedFieldsAssets: false,
                                                }))}
                                            />
                                        </div>
                                        <span>Skip record</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    : type === 3 ?
                        <tbody>
                            <tr>
                                <td className="fw-bold text-sm-end w-45">Update existing data if duplicate Customer Name?</td>
                                <td className="warning w-55">
                                    <div className="radio-inline">
                                        <label className="radio mb-2 d-flex">
                                            <div>
                                                <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright"
                                                    checked={formState.updateCustomers}
                                                    onChange={() => setFormState(prevState => ({
                                                        ...prevState,
                                                        updateCustomers: true,
                                                    }))}
                                                />
                                            </div>
                                            <span>Yes</span>
                                        </label>
                                        <label className="radio mb-2 d-flex">
                                            <div>
                                                <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright"
                                                    checked={!formState.updateCustomers}
                                                    onChange={() => setFormState(prevState => ({
                                                        ...prevState,
                                                        updateCustomers: false,
                                                    }))}
                                                />
                                            </div>
                                            <span> No</span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        : type === 6 ?
                            <tbody>
                                {
                                    !formState.deleteExistingMaintenance ?
                                        <tr>
                                            <td className="fw-bold text-sm-end w-45">Delete all existing maintenance records for ALL assets and replace with new records.</td>
                                            <td className="warning w-55">
                                                <div className="radio-inline">
                                                    <label className="radio mb-2 d-flex">
                                                        <div>
                                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radBlank" id="radBlank_true"
                                                                checked={formState.deleteAllMaintenance}
                                                                onChange={() => setFormState(prevState => ({
                                                                    ...prevState,
                                                                    deleteAllMaintenance: true,
                                                                }))} />
                                                        </div>
                                                        <span>Yes</span>
                                                    </label>
                                                    <label className="radio mb-2 d-flex">
                                                        <div>
                                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radBlank" id="radBlank_false"
                                                                checked={!formState.deleteAllMaintenance}
                                                                onChange={() => setFormState(prevState => ({
                                                                    ...prevState,
                                                                    deleteAllMaintenance: false,
                                                                }))} />
                                                        </div>
                                                        <span>No</span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        : null
                                }
                                {
                                    !formState.deleteAllMaintenance ?
                                        <>
                                            <tr>
                                                <td className="fw-bold text-sm-end w-45">Delete existing maintenance records (if any) ONLY FOR assets being imported and replace with new records.</td>
                                                <td className="warning w-55">
                                                    <div className="radio-inline">
                                                        <label className="radio mb-2 d-flex">
                                                            <div>
                                                                <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="rad_yes" id="radBlank_true"
                                                                    checked={formState.deleteExistingMaintenance}
                                                                    onChange={() => setFormState(prevState => ({
                                                                        ...prevState,
                                                                        deleteExistingMaintenance: true,
                                                                    }))}
                                                                />
                                                            </div>
                                                            <span>Yes</span>
                                                        </label>
                                                        <label className="radio mb-2 d-flex">
                                                            <div>
                                                                <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="rad_yes" id="radBlank_false"
                                                                    checked={!formState.deleteExistingMaintenance}
                                                                    onChange={() => setFormState(prevState => ({
                                                                        ...prevState,
                                                                        deleteExistingMaintenance: false,
                                                                    }))}
                                                                />
                                                            </div>
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            {!formState.deleteAllMaintenance && !formState.deleteExistingMaintenance ?
                                                <tr id="updateBlankWrapper">
                                                    <td className="fw-bold text-sm-end w-45">If import data contains a maintenance record for an asset (by matching Asset Tag ID, Maintenance Title and Maintenance Due Date) then</td>
                                                    <td className="warning w-55">
                                                        <div className="radio-inline">
                                                            <label className="radio mb-2 d-flex">
                                                                <div>
                                                                    <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="rad_update" id="radBlank_false"
                                                                        checked={formState.updateAssetsMaintenance}
                                                                        onChange={() => setFormState(prevState => ({
                                                                            ...prevState,
                                                                            updateAssetsMaintenance: true,
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <span>Update record</span>
                                                            </label>
                                                            <label className="radio mb-2 d-flex">
                                                                <div>
                                                                    <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="rad_update" id="radBlank_true"
                                                                        checked={!formState.updateAssetsMaintenance}
                                                                        onChange={() => setFormState(prevState => ({
                                                                            ...prevState,
                                                                            updateAssetsMaintenance: false,
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <span>Skip record</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr> : null}
                                        </>
                                        : null
                                }
                            </tbody>
                            :
                            type === 7 ?
                                <tbody>
                                    <tr>
                                        <td className="fw-bold text-sm-end w-45">Update existing data if duplicate Person Name?	</td>
                                        <td className="warning w-55">
                                            <div className="radio-inline">
                                                <label className="radio mb-2 d-flex">
                                                    <div>
                                                        <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright" checked={formState.updatePerson}
                                                            onChange={() => setFormState(prevState => ({
                                                                ...prevState,
                                                                updatePerson: true,
                                                            }))}
                                                        />
                                                    </div>
                                                    <span>Yes</span>
                                                </label>
                                                <label className="radio mb-2 d-flex">
                                                    <div>
                                                        <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright"
                                                            checked={!formState.updatePerson}
                                                            onChange={() => setFormState(prevState => ({
                                                                ...prevState,
                                                                updatePerson: false,
                                                            }))}
                                                        />
                                                    </div>
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                : type === 8 ?
                                    <tbody>
                                        <tr>
                                            <td className="fw-bold text-sm-end w-45">Update existing Site if duplicate Site Name?</td>
                                            <td className="warning w-55">
                                                <div className="radio-inline">
                                                    <label className="radio mb-2 d-flex">
                                                        <div>
                                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright" checked={formState.isUpdateSite}
                                                                onChange={() => setFormState(prevState => ({
                                                                    ...prevState,
                                                                    isUpdateSite: true,
                                                                }))}
                                                            />
                                                        </div>
                                                        <span>Yes</span>
                                                    </label>
                                                    <label className="radio mb-2 d-flex">
                                                        <div>
                                                            <input type="checkbox" className="form-check-input custom-form-check-input me-2" name="radOverright" checked={!formState.isUpdateSite}
                                                                onChange={() => setFormState(prevState => ({
                                                                    ...prevState,
                                                                    isUpdateSite: false,
                                                                }))}
                                                            />
                                                        </div>
                                                        <span>No</span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                : null
            }
        </table>
    )
}

export default FormField