import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getAuth} from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const subUserApi = createApi({
  reducerPath: 'subUserApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/sub-user/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['SubUser'],
  endpoints: (build) => ({
    getSubUsers: build.query<any, any>({
      query: ({limit = 10, page = 1}) => ({
        url: `getAll?page=${page}&limit=${limit}`,
        method: 'GET',
      }),
      providesTags: ['SubUser'],
    }),
    addSubUser: build.mutation<any, any>({
      query: (formData) => ({
        url: `create`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: () => [{type: 'SubUser'}],
    }),
    sendEmailSubUser: build.mutation<any, any>({
      query: (id) => ({
        url: `send-email-user`,
        method: 'POST',
        body: {id:id},
      }),
      invalidatesTags: () => [{type: 'SubUser'}],
    }),
    deleteSubUser: build.mutation<any, any>({
      query: (subUserIds) => ({
        url: `delete`,
        method: 'DELETE',
        body: {subUserIds:[subUserIds]},
      }),
      invalidatesTags: () => [{type: 'SubUser'}],
    }),
    updateSubUser: build.mutation<any, any>({
      query: (formData) => ({
        url: `update`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ['SubUser'],
    }),

    deleteProfileImage: build.mutation<any, any>({
      query: () => ({
        url: `delete-image`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SubUser'],
    }),
  }),
})

export const {
  useGetSubUsersQuery,
  useAddSubUserMutation,
  useSendEmailSubUserMutation,
  useDeleteSubUserMutation,
  useUpdateSubUserMutation,
  useDeleteProfileImageMutation,
} = subUserApi
