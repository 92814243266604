const PreviewTable = ({ columnsData }) => {
    return (
        <div className="table-responsive">
            <table id="preview-table" className="table table-bordered mb-5">
                <thead className="thead-light  light-yellow-table">
                    <tr>
                        {columnsData.map((column): any => column.defaultActive && (
                            <th key={column.id}>
                                {column.header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {columnsData.map((column): any => column.defaultActive && (
                            <td key={column.id}>
                                Sample
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PreviewTable;
