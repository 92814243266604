
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationPopup from '../../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { getAuth } from '../../../../../Auth'
import { getEmployee, postEmployeeData } from '../../Core/_requests'
import { EmployeeData, CustomField, Field } from '../../Core/_models'
import { Link } from 'react-router-dom'

const PersonEmployeeList = () => {
  const auth = getAuth()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deleteSelected, setDeleteSelected] = useState<number>(0)
  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    hasFullName: true,
    isFullNameKeyField: false,
    hasEmail: false,
    isEmailKeyField: false,
    hasDepartment: false,
    isEmailRequired: false,
    isDepartmentRequired: false,
    hasLocation: false,
    isLocationRequired: false,
    hasPhone: false,
    isPhoneRequired: false,
    hasTitle: false,
    isTitleRequired: false,
    hasNotes: false,
    isNotesRequired: false,
    hasEmployeeId: false,
    isEmployeeIdRequired: false,
    hasSite: false,
    isSiteRequired: false,
    categoryList: '',
    customFields: [] || true,
  })
  const [customFieldlabel, setCustomFieldlabel] = useState<string>('')
  const [customFieldlabelError, setCustomFieldlabelError] = useState<string>('')
  const [option, setOption] = useState<string>('')
  const [optionError, setOptionError] = useState<string>('')
  const [listOptions, setListOptions] = useState<string>('')
  const [listOptionError, setListOptionError] = useState<string>('')
  const [isRequired, setIsRequired] = useState<boolean>(true)
  const [fields, setFields] = useState<{ id: number; value: string }[]>([])
  const [fieldValues, setFieldValues] = useState<string[]>([])
  const [editEmpolyeeIndex, setEditEmpolyeeIndex] = useState<number>()
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const handleCustomFieldLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue === '' || inputValue.match(/^ *$/)) {
      setCustomFieldlabel('')
    } else {
      setCustomFieldlabel(inputValue)
    }
  }

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOption(event.target.value)
  }

  const handleListOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue === '' || inputValue.match(/^ *$/)) {
      setListOptions('')
    } else {
      setListOptions(inputValue)
    }
  }

  const handleIsRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRequired(event.target.value === 'yes')
  }

  const handleAddCustomField = () => {
    if (!customFieldlabel || !option) {
      console.error('Label is required.')
      setCustomFieldlabelError('Label is required.')
      setOptionError('Data Type is required.')
      setListOptionError(' Option is required.')
      setFormSubmitted(true)
      return
    }
    if (
      (option === 'Checkbox list' ||
        option === 'Dropdown list' ||
        option === 'Radio button list') &&
      !listOptions
    ) {
      console.error('Option is required.')
      setListOptionError('Option is required.')
      setFormSubmitted(true)
      return
    }
    const newCustomField: CustomField = {
      customFieldlabel,
      option,
      isRequired,
      listOptions,
      fields: [...fields],
    }
    if (employeeData && employeeData?.customFields) {
      if (editEmpolyeeIndex !== undefined) {
        employeeData.customFields[editEmpolyeeIndex] = newCustomField
        setEmployeeData({ ...employeeData })
        setEditEmpolyeeIndex(undefined)
        toast.success('Custom field updated successfully.')
      } else {
        employeeData?.customFields.push(newCustomField)
        setEmployeeData({ ...employeeData })
        toast.success('Custom field added successfully.')
      }
      postEmployeeData(auth?.token, employeeData)
        .then((res) => {
          setEmployeeData(res?.data?.updatedModel)
        })
        .catch((error) => {
          console.error(error)
        })
      setCustomFieldlabelError('')
      setOptionError('')
      setOption('')
      setListOptions('')
      setCustomFieldlabel('')
      setListOptions('')
      setOption('')
      setFields([])
      setFormSubmitted(false)
    } else {
      console.error('employeeData or customFields is not defined')
    }
  }

  const addNewField = () => {
    const newField: Field = { id: Date.now(), value: '' }
    setFields([...fields, newField])
  }


  const removeField = (index: number) => {
    const newFields = [...fields]
    newFields.splice(index, 1)
    setFields(newFields)
  }

  const handleChange = (index: number, value: string) => {
    const newFields = [...fields]
    newFields[index] = { ...newFields[index], value }
    setFields(newFields)
    const newFieldValues = [...fieldValues]
    newFieldValues[index] = value
    setFieldValues(newFieldValues)
  }


  function selectAllCheckboxes(event: React.ChangeEvent<HTMLInputElement>): void {
    const isChecked = event.target.checked
    setEmployeeData({
      ...employeeData,
      hasEmployeeId: isChecked,
      hasTitle: isChecked,
      hasPhone: isChecked,
      hasNotes: isChecked,
    })
  }


  const handleCheckboxChange = (checkboxName: string, isChecked: boolean) => {
    if (employeeData) {

      if (checkboxName === 'hasEmployeeId') {
        if (isChecked) {
          setEmployeeData({
            ...employeeData,
            hasEmployeeId: isChecked,
            isEmployeeIdRequired: true,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            hasEmployeeId: isChecked,
            isEmployeeIdRequired: false,
          })
        }
      } else if (checkboxName === 'isEmployeeIdRequired') {
        if (!isChecked) {
          setEmployeeData({
            ...employeeData,
            isEmployeeIdRequired: false,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            isEmployeeIdRequired: true,
          })
        }
      }


      else if (checkboxName === 'hasTitle') {
        if (isChecked) {
          setEmployeeData({
            ...employeeData,
            hasTitle: isChecked,
            isTitleRequired: true,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            hasTitle: isChecked,
            isTitleRequired: false,
          })
        }
      } else if (checkboxName === 'isTitleRequired') {
        if (!isChecked) {
          setEmployeeData({
            ...employeeData,
            isTitleRequired: false,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            isTitleRequired: true,
          })
        }
      }


      else if (checkboxName === 'hasPhone') {
        if (isChecked) {
          setEmployeeData({
            ...employeeData,
            hasPhone: isChecked,
            isPhoneRequired: true,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            hasPhone: isChecked,
            isPhoneRequired: false,
          })
        }
      } else if (checkboxName === 'isPhoneRequired') {
        if (!isChecked) {
          setEmployeeData({
            ...employeeData,
            isPhoneRequired: false,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            isPhoneRequired: true,
          })
        }
      }


      else if (checkboxName === 'hasNotes') {
        if (isChecked) {
          setEmployeeData({
            ...employeeData,
            hasNotes: isChecked,
            isNotesRequired: true,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            hasNotes: isChecked,
            isNotesRequired: false,
          })
        }
      } else if (checkboxName === 'isNotesRequired') {
        if (!isChecked) {
          setEmployeeData({
            ...employeeData,
            isNotesRequired: false,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            isNotesRequired: true,
          })
        }
      }

      else if (checkboxName === 'isFullNameKeyField') {
        if (isChecked) {
          setEmployeeData({
            ...employeeData,
            isFullNameKeyField: isChecked,
            isEmailKeyField: false,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            isFullNameKeyField: isChecked,
            isEmailKeyField: true,
          })
        }
      } else if (checkboxName === 'isFullNameKeyField') {
        if (!isChecked) {
          setEmployeeData({
            ...employeeData,
            isFullNameKeyField: false,
            isEmailKeyField: true,
          })
        } else {
          setEmployeeData({
            ...employeeData,
            isFullNameKeyField: true,
            isEmailKeyField: false,
          })
        }
      }


      else if (checkboxName === 'isSiteRequired') {
        setEmployeeData({
          ...employeeData,
          hasSite: true,
          isSiteRequired: isChecked,
        })
      } else if (checkboxName === 'isLocationRequired') {
        setEmployeeData({
          ...employeeData,
          hasLocation: true,
          isLocationRequired: isChecked,
        })
      } else if (checkboxName === 'isDepartmentRequired') {
        setEmployeeData({
          ...employeeData,
          DepartmentRequired: true,
          isDepartmentRequired: isChecked,
        })
      } else {
        console.warn('Unexpected checkbox name:', checkboxName)
      }
    }
  }


  useEffect(() => {
    getEmployee(auth?.token).then((res) => {
      setEmployeeData(res?.data?.persons)
    })
  }, [])


  const submit = (event?: React.FormEvent<HTMLFormElement>): void => {
    if (event) {
      event?.preventDefault()
    }
    setLoading(true)
    postEmployeeData(auth?.token, employeeData)
      .then((res) => {
        setEmployeeData(res?.data?.updatedModel)
        toast.success('Database updated successfully')
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }


  const cancel = () => {
    setDeleteModal(false)
  }
  const confirm = async () => {
    employeeData?.customFields.splice(deleteSelected, 1)
    setEmployeeData({ ...employeeData })
    postEmployeeData(auth?.token, employeeData)
      .then((res) => {
        setEmployeeData(res.data.updatedModel)
        toast.success('Custom field deleted successfully ')
      })
      .catch((error) => {
        console.error(error)
        toast.error('Custom field does not  deleted ')
      })
    setDeleteModal(false)
  }


  const EditHandler = async (index: number) => {
    const customField = employeeData?.customFields[index]
    setCustomFieldlabel(customField.customFieldlabel)
    setOption(customField.option || '')
    setIsRequired(customField.isRequired === true ? true : false)
    setListOptions(customField.listOptions || '')
    setFields(customField.fields || [])
    setEditEmpolyeeIndex(index)
    setCustomFieldlabelError('')
    setOptionError('')
  }


  const resetFormFields = () => {
    setCustomFieldlabel('')
    setOption('')
    setListOptions('')
    setFields([])
  }

  const handleClose = () => {
    resetFormFields()
    setEditEmpolyeeIndex(undefined)
  }

  return (
    <div>
      <div className='form-title d-flex'>
        <span className='fs-1 fw-bold me-2'>Database </span>
        <span className='fs-3 fw-bold'>Personal/Employees</span>
      </div>
      <div className='card'>
        <ConfirmationPopup deleteModal={deleteModal} cancel={cancel} confirm={confirm} />

				<div id='database_ajax_wrapper'>
					<form onSubmit={submit}>
						<input
							name='__RequestVerificationToken'
							type='hidden'
							value='bi5mAQGArgtf83vcFjyPEGK8rKsZ-9D3G-eRhsWQ-rTaCYjXK6gswp10gSiUZu3dXYXV2Zm9_cHp6yxRZbnLhFj5ZuJQOiJIAWnGosU6DIm2Zzc3Qcl40A_U_bv_VIr8fFJjrA2'
						/>{' '}
						<div className='card-body'>
							<div className='form-title d-flex'>
								<span className='me-2'>
									<svg
										version='1.1'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 32 32'
										fill='#c30'
									>
										<path
											fill='#9b9b9b'
											d='M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z'
										></path>
										<path
											fill='#9b9b9b'
											d='M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z'
										></path>
										<path
											fill='#9b9b9b'
											d='M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z'
										></path>
										<path
											fill='#9b9b9b'
											d='M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z'
										></path>
									</svg>
								</span>
								<h4 className='mt-1'>Persons/Employees Standard Fields</h4>
							</div>
							<div className='form_section'>
								<div className='mb-sm-6 mb-4'>
									<p>
										Persons/employees are individuals to whom you 'assign' (
										<i className='la la-user-check fs-4' aria-hidden='true'></i>{' '}
										<strong>check-out</strong>) assets. These could be employees in your
										organization or students in your school/university. Select the fields you would
										like to use for the persons/employees table.
									</p>
									<div className='table-responsive pb-3'>
										<table
											className='table table-bordered mb-0 '
											aria-label='Persons/Employees Standard Fields'
										>
											<thead className='thead-light  light-yellow-table '>
												<tr>
													<th className='w-2'>
														<label className='checkbox has_nolabel m-auto' htmlFor='field_all'>
															<input
																checked={
																	employeeData?.hasEmployeeId &&
																	employeeData?.hasTitle &&
																	employeeData?.hasPhone &&
																	employeeData?.hasNotes
																}
																onChange={selectAllCheckboxes}
																className='form-check-input custom-form-check-input sig_input'
																type='checkbox'
																id='field_all'
																value=''
															/>
															
															<span className='sr-only'>Field All</span>
														</label>
													</th>
													<th className='w-20'>Field name </th>
													<th className='w-20'>Data Required </th>
													<th>Description </th>
													<th className='w-20'>Example </th>
												</tr>
											</thead>
											{employeeData ? (
												<tbody>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasFullName}
																	disabled={true}
																	className='fieldDisabledCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Full Name is required.'
																	id='hasName'
																	name='hasName'
																	type='checkbox'
																	value='true'
																/>
																<input name='hasName' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>
															Full Name <span className='required'> </span>
															{employeeData?.isFullNameKeyField && (
																<i
																	className='la la-key fs-4 d-inline-block vertical-2'
																	aria-hidden='true'
																	id='isPersonEmailUnique_True_icon'
																	title='Key Field'
																></i>
															)}
														</td>
														<td>
															<div className='radio-inline'>
																<label className='radio'>
																	<input
																		type='checkbox'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={employeeData?.hasFullName}
																	/>{' '}
																	Yes
																</label>
															</div>
														</td>
														<td>Full name of the person / employee.</td>
														<td>John Doe</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	className='fieldDisabledCheck form-check-input custom-form-check-input sig_input sig_input'
																	data-val='true'
																	data-val-required='Email is required.'
																	id='hasEmail'
																	name='hasEmail'
																	type='checkbox'
																	value='true'
																	checked={employeeData?.hasEmail}
																	disabled={true}
																/>
																<input name='hasEmail' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>
															Email <span > </span>
															{employeeData?.isEmailKeyField && (
																<i
																	className='la la-key text-danger fs-4 vertical-2 d-inline-block'
																	aria-hidden='true'
																	id='isPersonEmailUnique_True_icon'
																	title='Key Field'
																></i>
															)}
														</td>
														<td>
															<div className='radio-inline'>
																<label className='radio mb-0 p_16'>
																	<input
																		id='isEmailRequired_True'
																		name='isEmailRequired'
																		type='checkbox'
																		value='True'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={employeeData?.isEmailRequired}
																	/>{' '}
																	Yes
																</label>
															</div>
														</td>
														<td>Email of the person</td>
														<td>johndoe@example.com</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasEmployeeId}
																	onChange={(e) =>
																		handleCheckboxChange('hasEmployeeId', e.target.checked)
																	}
																	className='fieldCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Employee ID is required.'
																	id='hasEmployeeId'
																	name='hasEmployeeId'
																	type='checkbox'
																	value='false'
																/>
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>Employee ID</td>
														<td>
															{employeeData?.hasEmployeeId && (
																<div
																	className='radio-inline standard_radio_can_hide hasEmpId_radio d-flex'
																>
																	<label className='radio mb-0 p_16'>
																		<input
																			id='isEmpIdRequired_Yes'
																			name='isEmpIdRequired'
																			type='checkbox'
																			value='Yes'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={employeeData?.isEmployeeIdRequired}
																			onChange={() =>
																				handleCheckboxChange('isEmployeeIdRequired', true)
																			}
																		/>
																		<span>Yes</span>
																	</label>
																	<label className='radio mb-0'>
																		<input
																			id='isEmpIdRequired_Optional'
																			name='isEmpIdRequired'
																			type='checkbox'
																			value='Optional'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={!employeeData?.isEmployeeIdRequired}
																			onChange={() =>
																				handleCheckboxChange('isEmployeeIdRequired', false)
																			}
																		/>
																		<span>Optional</span>
																	</label>
																</div>
															)}
														</td>
														<td>For example Employee ID, Student ID, etc.</td>
														<td>IT-1234</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasTitle ?? false}
																	onChange={(e) =>
																		handleCheckboxChange('hasTitle', e.target.checked)
																	}
																	className='fieldCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Title is required.'
																	id='hasTitle'
																	name='hasTitle'
																	type='checkbox'
																	value='true'
																/>
																<input name='hasTitle' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>Title</td>
														<td>
															{employeeData?.hasTitle && (
																<div
																	className='radio-inline standard_radio_can_hide hasTitle_radio d-flex'
																>
																	<label className='radio mb-0 p_16'>
																		<input
																			id='isTitleRequired_True'
																			name='isTitleRequired'
																			type='checkbox'
																			value='True'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={employeeData?.isTitleRequired}
																			onChange={() => handleCheckboxChange('isTitleRequired', true)}
																		/>{' '}
																		<span>Yes</span>
																	</label>
																	<label className='radio mb-0'>
																		<input
																			id='isTitleRequired_False'
																			name='isTitleRequired'
																			type='checkbox'
																			value='False'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={!employeeData?.isTitleRequired}
																			onChange={() =>
																				handleCheckboxChange('isTitleRequired', false)
																			}
																		/>{' '}
																		<span>Optional</span>
																	</label>
																</div>
															)}
														</td>
														<td>Title of the person.</td>
														<td>Sales Manager</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasPhone ?? false}
																	onChange={(e) =>
																		handleCheckboxChange('hasPhone', e.target.checked)
																	}
																	className='fieldCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Phone is required.'
																	id='hasPhone'
																	name='hasPhone'
																	type='checkbox'
																	value='true'
																/>
																<input name='hasPhone' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>Phone</td>
														<td>
															{employeeData?.hasPhone && (
																<div
																	className='radio-inline standard_radio_can_hide hasPhone_radio d-flex'
																>
																	<label className='radio mb-0 p_16'>
																		<input
																			id='isPhoneRequired_True'
																			name='isPhoneRequired'
																			type='checkbox'
																			value='True'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={employeeData?.isPhoneRequired}
																			onChange={() => handleCheckboxChange('isPhoneRequired', true)}
																		/>{' '}
																		<span>Yes</span>
																	</label>
																	<label className='radio mb-0'>
																		<input
																			id='isPhoneRequired_False'
																			name='isPhoneRequired'
																			type='checkbox'
																			value='False'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={!employeeData?.isPhoneRequired}
																			onChange={() =>
																				handleCheckboxChange('isPhoneRequired', false)
																			}
																		/>{' '}
																		<span>Optional</span>
																	</label>
																</div>
															)}
														</td>
														<td>Phone number of the person</td>
														<td>(555) 123-4567</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasNotes ?? false}
																	onChange={(e) =>
																		handleCheckboxChange('hasNotes', e.target.checked)
																	}
																	className='fieldCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Notes is required.'
																	id='hasNotes'
																	name='hasNotes'
																	type='checkbox'
																	value='true'
																/>
																<input name='hasNotes' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>Notes</td>
														<td>
															{employeeData?.hasNotes && (
																<div
																	className='radio-inline standard_radio_can_hide hasNotes_radio d-flex'
																>
																	<label className='radio mb-0 p_16'>
																		<input
																			id='isNotesRequired_True'
																			name='isNotesRequired'
																			type='checkbox'
																			value='True'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={employeeData?.isNotesRequired}
																			onChange={() => handleCheckboxChange('isNotesRequired', true)}
																		/>{' '}
																		<span>Yes</span>
																	</label>
																	<label className='radio mb-0'>
																		<input
																			id='isNotesRequired_False'
																			name='isNotesRequired'
																			type='checkbox'
																			value='False'
																			className='form-check-input custom-form-check-input sig_input m_5'
																			checked={!employeeData?.isNotesRequired}
																			onChange={() =>
																				handleCheckboxChange('isNotesRequired', false)
																			}
																		/>{' '}
																		<span>Optional</span>
																	</label>
																</div>
															)}
														</td>
														<td>Text area for notes</td>
														<td>Reports to CEO</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasSite}
																	disabled={true}
																	className='fieldDisabledCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Site is required.'
																	id='hasSite'
																	name='hasSite'
																	type='checkbox'
																	value='true'
																/>
																<input name='hasSite' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>Site</td>
														<td>
															<div className='radio-inline hasSite_radio d-flex'>
																<label className='radio mb-0 p_16'>
																	<input
																		id='isSiteRequired_True'
																		name='isSiteRequired'
																		type='checkbox'
																		value='True'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={employeeData?.isSiteRequired}
																		onChange={() => handleCheckboxChange('isSiteRequired', true)}
																	/>{' '}
																	Yes
																</label>
																<label className='radio mb-0'>
																	<input
																		id='isSiteRequired_False'
																		name='isSiteRequired'
																		type='checkbox'
																		value='False'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={!employeeData?.isSiteRequired}
																		onChange={() => handleCheckboxChange('isSiteRequired', false)}
																	/>{' '}
																	Optional
																</label>
															</div>
														</td>
														<td>System field to link person to a Site</td>
														<td>-</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasLocation}
																	disabled={true}
																	className='fieldDisabledCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Location is required.'
																	id='hasLocation'
																	name='hasLocation'
																	type='checkbox'
																	value='true'
																/>
																<input name='hasLocation' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>Location</td>
														<td>
															<div
																className='radio-inline hasLocation_radio d-flex'
															>
																<label className='radio mb-0 p_16'>
																	<input
																		id='isLocationRequired_True'
																		name='isLocationRequired'
																		type='checkbox'
																		value='True'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={employeeData?.isLocationRequired}
																		onChange={() =>
																			handleCheckboxChange('isLocationRequired', true)
																		}
																	/>{' '}
																	Yes
																</label>
																<label className='radio mb-0'>
																	<input
																		id='isLocationRequired_False'
																		name='isLocationRequired'
																		type='checkbox'
																		value='False'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={!employeeData?.isLocationRequired}
																		onChange={() =>
																			handleCheckboxChange('isLocationRequired', false)
																		}
																	/>{' '}
																	Optional
																</label>
															</div>
														</td>
														<td>System field to link person to a Location</td>
														<td>-</td>
													</tr>
													<tr>
														<td>
															<label className='checkbox has_nolabel m-auto '>
																<input
																	checked={employeeData?.hasDepartment}
																	disabled={true}
																	className='fieldDisabledCheck form-check-input custom-form-check-input sig_input'
																	data-val='true'
																	data-val-required='Department is required.'
																	id='hasDepartment'
																	name='hasDepartment'
																	type='checkbox'
																	value='true'
																/>
																<input name='hasDepartment' type='hidden' value='false' />
																
																<span className='sr-only'>Field</span>
															</label>
														</td>
														<td className='fw-bolder text-nowrap'>Department</td>
														<td>
															<div
																className='radio-inline hasDepartment_radio d-flex'
															>
																<label className='radio mb-0 p_16'>
																	<input
																		id='isDepartmentRequired_True'
																		name='isDepartmentRequired'
																		type='checkbox'
																		value='True'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={employeeData?.isDepartmentRequired}
																		onChange={() =>
																			handleCheckboxChange('isDepartmentRequired', true)
																		}
																	/>{' '}
																	Yes
																</label>
																<label className='radio mb-0'>
																	<input
																		id='isDepartmentRequired_False'
																		name='isDepartmentRequired'
																		type='checkbox'
																		value='False'
																		className='form-check-input custom-form-check-input sig_input m_5'
																		checked={!employeeData?.isDepartmentRequired}
																		onChange={() =>
																			handleCheckboxChange('isDepartmentRequired', false)
																		}
																	/>{' '}
																	Optional
																</label>
															</div>
														</td>
														<td>System field to link person to a Department</td>
														<td>-</td>
													</tr>
												</tbody>
											) : (
												<h3>Loading...</h3>
											)}
										</table>
									</div>
								</div>
							</div>
							<div className='form_section'>
								<div className='form-title d-flex'>
									{' '}
									<i className='la la-key fs-2 me-3' aria-hidden='true'></i>
									<h4>Key Field (Unique Identifier)</h4>
								</div>
								<div className='text_indent'>
									<p>
										Select the <strong>key field</strong> that you would like to use as a unique
										identifier. The key field should have unique values in the system.
									</p>
									<p>
										For example, if you select '<strong>Full Name</strong>' as a key field, you
										cannot have two persons with the name 'John Doe'. To make them unique, you may
										have to name them 'John&nbsp;Doe' and 'John&nbsp;Doe&nbsp;2'. As an alternative,
										use '<strong>Email</strong>' as a key field since email is always unique for
										each person.
									</p>
									<div className='row'>
										<div className='col-md-11 offset-md-1'>
											<div className='radio-list custom_flex_dir'>
												<label className='radio mb-3 '>
													<input
														type='checkbox'
														id='isPersonEmailUnique_False'
														name='isFullNameKeyField'
														value='False'
														className='form-check-input custom-form-check-input sig_input m_5'
														checked={employeeData?.isFullNameKeyField}
														onChange={() => handleCheckboxChange('isFullNameKeyField', true)}
													/>
													
													<strong className='pe-1'>Full Name</strong> as a unique identifier.
												</label>
												<label className='radio mb-3 '>
													<input
														type='checkbox'
														id='isPersonEmailUnique_True'
														name='isPersonEmailUnique'
														value='True'
														className='form-check-input custom-form-check-input sig_input m_5'
														checked={employeeData?.isEmailKeyField}
														onChange={() => handleCheckboxChange('isFullNameKeyField', false)}
													/>
													
													<strong className='pe-1'>Email</strong> as a unique identifier.
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='form_section' id='custom_ajax_wrapper'>
								<div className='separator separator-solid mb-6'></div>
								<div className='form-title d-flex'>
									<span className='me-2'>
										<svg
											version='1.1'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 32 32'
											fill='#c30' 
										>
											<path
												fill='#9b9b9b'
												d='M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z'
											></path>
											<path
												fill='#9b9b9b'
												d='M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z'
											></path>
											<path
												fill='#9b9b9b'
												d='M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z'
											></path>
											<path
												fill='#9b9b9b'
												d='M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z'
											></path>
										</svg>
									</span>
									<h4 className='mt-1'>Persons/Employees Custom Fields</h4>
								</div>
								<div className='mb-sm-6 mb-4'>
									<p>Add custom fields to join the standard fields that we provided.</p>
									<div className='form_section'>
										<div className='mb-4'>
											<Link
												data-bs-toggle='modal'
												to='#addEditFieldBox'
												className='btn btn-light-primary'
												onClick={handleClose}
											>
												<i className='fa fa-plus' aria-hidden='true'></i> Add Custom Field
											</Link>
										</div>
										<div className="table-responsive pb-3">
											<table
												className='table table-bordered table-sm vertical_middle mb-0 '
												id='custom_fields_table'
												aria-label='Asset Custom Fields'
											>
												<thead className='thead-light light-yellow-table '>
													{employeeData?.customFields?.length > 0 ? (
														<tr>
															<th className='td-delete'> Field Name </th>
															<th className='td-delete'> Data Type </th>
															<th className='td-delete'> Required </th>
															<th
																className='text-center w-80 td-delete'
															>
																Edit
															</th>
															<th
																className='text-center td-delete w-95'
															>
																Delete
															</th>
														</tr>
													) : (
														''
													)}
												</thead>
												{employeeData?.customFields?.map?.((items: any, index: number) => {
													return (
														<tbody>
															<tr>
																<td className='fw-bolder'>
																	{items?.customFieldlabel}
																</td>
																<td> {items?.option} </td>
																<td>{items && items.isRequired === true ? 'yes' : 'optional'}</td>
																<td className='text-nowrap'>
																	<Link
																		onClick={() => {
																			EditHandler(index)
																			setEditEmpolyeeIndex(index)
																		}}
																		data-bs-toggle='modal'
																		to='#addEditFieldBox'
																		className='btn btn-light-primary main-btn-style btn-sm'
																	>
																		<i className='fa fa-pencil-alt fs-7' aria-hidden='true'></i>
																		Edit
																	</Link>
																</td>
																<td className='text-nowrap'>
																	<span
																		onClick={() => {
																			setDeleteModal(true)
																			setDeleteSelected(index)
																		}}
																		className='confirmDeleteCustomField btn btn-light-primary main-btn-style btn-sm'
																	>
																		<i className='la la-trash fs-4' aria-hidden='true'></i>
																		Delete
																	</span>
																</td>
															</tr>
														</tbody>
													)
												})}
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='card-footer right notForWizard'>
							<div className='row'>
								<div className='col-md-12 text-end'>
									<Link to='/apps/setup/database/person' className='me-2 main-btn-style btn btn-light-secondary'>
										Cancel
									</Link>
									<button type='submit' className='btn btn-primary' disabled={loading}>
										{!loading && 'Submit'}
										{loading && (
											<span className='indicator-progress d-block'>
												Please wait...{' '}
												<span className='spinner-border spinner-border-sm align-middle'></span>
											</span>
										)}
									</button>
								</div>
							</div>
						</div>
						<input id='hasAssetDescription' name='hasAssetDescription' type='hidden' value='True' />
					</form>
					<form
						action='/assettiger/database/AddField?Length=8'
						className='form-horizontal'
						data-ajax='true'
						data-ajax-begin='javascript: funCustomFormSubmitBegin();'
						data-ajax-method='Post'
						data-ajax-mode='replace'
						data-ajax-success='javascript: funCustomFormSubmited();'
						data-ajax-update='#custom_ajax_wrapper'
						id='formCustomField'
						method='post'
						role='form'
					>
						<input
							name='__RequestVerificationToken'
							type='hidden'
							value='P6Drb77htOawFFsvekNgHfWBWhq60oDpCoubbGMBsqUDjl2iAaD8QBR9--EiX9PwaCFSBKs_1KJeo_cU8xCFJxl-CxmIsOWHGs6UMfZP8TvVLl9DSBSzMsLIvq24QW2RmsX0tQ2'
						/>
						<div
							className='modal fade'
							id='addEditFieldBox'
							tabIndex={-1}
							role='dialog'
							aria-hidden='true'
						>
							<div className='modal-dialog modal-dialog-scrollable  mw-md-600px'>
								<form>
									<div className='modal-content'>
										<div className='modal-header'>
											<h4 className='modal-title'>Add Custom field</h4>
											<button
												type='button'
												className='btn-close'
												data-bs-dismiss='modal'
												onClick={handleClose}
												aria-hidden='true'
												aria-label='close'
											></button>
										</div>
										<div className='modal-body form' data-bs-backdrop='static'>
											<div className='custom-field-details'>
												<div className='form-group row'>
													<label
														className='col-sm-4 col-form-label text-sm-end required'
														htmlFor='AddEditCustomFieldModel_Label'
													>
														Custom Field Label
													</label>
													<div className='col-sm-7'>
														<input
															className="form-control input-medium border border-black"
															id='AddEditCustomFieldModel_Label'
															maxLength={100}
															name='AddEditCustomFieldModel.Label'
															type='text'
															required
															value={customFieldlabel}
															onChange={handleCustomFieldLabel}
														/>
														{customFieldlabel === '' && (
															<div className='text-danger'>{customFieldlabelError}</div>
														)}
													</div>
												</div>
												<div className='form-group row mt-5'>
													<label
														className='col-sm-4 col-form-label text-sm-end'
														htmlFor='AddEditCustomFieldModel_FieldTypeId'
													>
														Data Type<span className='required'></span>
													</label>
													<div className='col-sm-7'>
														<select
															className="form-control input-medium border border-black"
															data-val='true'
															data-val-number='The field Data Type must be a number.'
															data-val-required='Data Type is required.'
															id='AddEditCustomFieldModel_FieldTypeId'
															name='AddEditCustomFieldModel.FieldTypeId'
															value={option}
															onChange={handleOptionChange}
														>
															<option value=''>Select Data Type</option>
															<option value='Checkbox list'>Checkbox list</option>
															<option value='Currency'>Currency</option>
															<option value='Date'>Date</option>
															<option value='Date and Time'>Date and Time</option>
															<option value='Dropdown list'>Dropdown list</option>
															<option value='Email'>Email</option>
															<option value='GPS Coordinates'>GPS Coordinates</option>
															<option value='Memo'>Memo</option>
															<option value='Numeric'>Numeric</option>
															<option value='Numeric Auto Increment'>Numeric Auto Increment</option>
															<option value='Radio button list'>Radio button list</option>
															<option value='Scanner'>Scanner</option>
															<option value='Text'>Text</option>
															<option value='URL'>URL</option>
														</select>
														{option === '' && <div className='text-danger'>{optionError}</div>}
														<span
															className='field-validation-valid'
															data-valmsg-for='AddEditCustomFieldModel.FieldTypeId'
															data-valmsg-replace='true'
														></span>
													</div>
												</div>
												<div className='form-group row align-items-center' id='ValidateType'>
													<label className='col-sm-4 col-form-label text-sm-end'>
														Data Required
													</label>
													<div className='col-sm-7'>
														<div className='radio-inline'>
															<label className='radio mb-0 p_16'>
																<input
																	id='isValidate_True'
																	type='checkbox'
																	className='form-check-input custom-form-check-input me-2'
																	value='yes'
																	checked={isRequired === true}
																	defaultChecked
																	onChange={handleIsRequiredChange}
																/>
																
																Yes
															</label>
															<label className='radio mb-0'>
																<input
																	id='isValidate_False'
																	type='checkbox'
																	className='form-check-input custom-form-check-input me-2'
																	value='optional'
																	checked={isRequired === false}
																	onChange={handleIsRequiredChange}
																/>
																
																Optional
															</label>
														</div>
													</div>
												</div>
												{(option === 'Dropdown list' ||
													option === 'Checkbox list' ||
													option === 'Radio button list') && (
														<div className='form-group row DataInput customFieldOption'>
															<label
																className='col-sm-4 col-form-label text-sm-end'
																htmlFor='AddEditCustomFieldModel_Option'
															>
																List Options
															</label>
															<div className="col-sm-8 input_fields_wrap">
																<div>
																	<div className="d-flex">
																		<div className='me-2'>
																			<input
																				     className={`form-control input-medium ${
                                        listOptions === '' && formSubmitted
                                          ? 'border border-danger'
                                          : listOptions !== ''
                                          ? 'border border-success'
                                          : 'border border-black'
                                      }`}
																				data-val="true"
																				data-val-length="Maximum length is 1000."
																				data-val-length-max="1000"
																				data-val-required="Option is required."
																				id="AddEditCustomFieldModel_Option"
																				maxLength={1000}
																				name="AddEditCustomFieldModel.Option"
																				type="text"
																				value={listOptions}
																				onChange={handleListOptions}
																			/>
																		</div>
																		<div>
																			<Link
																				onClick={addNewField}
																				to="javascript:void(0)"
																				className="btn btn-light-primary add_field_button"
																			>
																				Add
																			</Link>

																		</div>
																	</div>
																	{listOptions === "" && (
																		<div className="text-danger">
																			{listOptionError}
																		</div>
																	)}
																</div>

																{fields.map((field, index) => (
																	<div key={field.id} className='form-inline flex-nowrap mt-3 align-items-center'>
																		<div>
																			<input
																				className='form-control input-medium border border-black me-2'
																				type="text"
																				value={field.value}
																				onChange={(e) =>
																					handleChange(index, e.target.value)
																				}
																			/>
																		</div>
																		<div>
																			<Link to={'/dummy-link'} className='btn btn-light-primary remove_field' onClick={() => removeField(index)}>
																				Remove
																			</Link>
																		</div>
																	</div>
																))}
																<span
																	className="field-validation-valid"
																	data-valmsg-for="AddEditCustomFieldModel.Options"
																	data-valmsg-replace="true"
																></span>
																<span
																	className="field-validation-valid"
																	data-valmsg-for="AddEditCustomFieldModel.Option"
																	data-valmsg-replace="true"
																></span>
															</div>
														</div>
													)}
											</div>
										</div>
										<div className='modal-footer'>
											<button
												type='button'
												className='btn btn-light-secondary main-btn-style me-2'
												onClick={handleClose}
												data-bs-dismiss='modal'
											>
												Cancel
											</button>
											<button
												type='button'
												onClick={handleAddCustomField}
												className='btn btn-primary'
												{...(customFieldlabel &&
													option &&
													(listOptions || option !== 'Checkbox list') &&
													(listOptions || option !== 'Dropdown list') &&
													(listOptions || option !== 'Radio button list')
													? { 'data-bs-dismiss': 'modal' }
													: {})}
												value='Save'
											>
												Save
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<input
							data-val='true'
							data-val-length='Maximum length is 4000.'
							data-val-length-max='4000'
							id='AddEditCustomFieldModel_Options'
							name='AddEditCustomFieldModel.Options'
							type='hidden'
							value=''
						/>
						<input
							data-val='true'
							data-val-required="Is this field visible to assets of selective 'Categories'?"
							id='AddEditCustomFieldModel_hasCategory'
							name='AddEditCustomFieldModel.hasCategory'
							type='hidden'
							value='False'
						/>
						<input
							data-val='true'
							data-val-required='Is this field need to be required?'
							id='AddEditCustomFieldModel_isValidate'
							name='AddEditCustomFieldModel.isValidate'
							type='hidden'
							value='True'
						/>
						<input
							data-val='true'
							data-val-number='The field CustomFieldId must be a number.'
							data-val-required='The CustomFieldId field is required.'
							id='AddEditCustomFieldModel_CustomFieldId'
							name='AddEditCustomFieldModel.CustomFieldId'
							type='hidden'
							value='0'
						/>
					</form>{' '}
					<div
						className='modal fade'
						id='confirmDeleteCustomField'
						tabIndex={-1}
						role='basic'
						aria-hidden='true'
					>
						<div className='modal-dialog'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h4 className='modal-title'>Delete Custom field</h4>
									<button
										type='button'
										className='btn-close'
										data-bs-dismiss='modal'
										aria-hidden='true'
										aria-label='close'
									></button>
								</div>
								<div className='modal-body'>
									<p>Are you sure you want to delete this Custom field? </p>
									<p className='alert alert-danger' id='deleteCustomFieldObjWrapper'></p>
								</div>
								<div className='modal-footer'>
									<button
										type='button'
										className='btn btn-light-secondary main-btn-style me-2'
										data-bs-dismiss='modal'
									>
										Cancel
									</button>
									<Link to='/dummy-link' className='btn btn-danger' id='confirmDeleteCustomFieldBtn' data-ref=''>
										Confirm Delete
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonEmployeeList
