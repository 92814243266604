export interface CAccount {
  companyLogo: any
  companyName: string
  address: string
  city: string
  country: string
  state: string
  postalCode: string
  aptSuite: string
  timeZone: string
  currencySymbol: string
  financialYearStart: string
  dateFormat: string
  financialYearStartDate: string
  financialYearStartMonth: string
  dateOptions: number[];
}

export const defaultCompanyDetail: CAccount = {
  companyLogo: '',
  companyName: '',
  address: '',
  city: '',
  country: '',
  state: '',
  aptSuite: '',
  postalCode: '',
  timeZone: '',
  currencySymbol: '',
  financialYearStart: '',
  dateFormat: '',
  financialYearStartDate: '',
  financialYearStartMonth: '',
  dateOptions: [],
}
