

function AssetsTab({checked,handleCheckboxChange}) {
    
    return (
        <div>
            <div className='mb-5'>
                <h4 className="mb-3">View and Edit Assets</h4>
                <p className="mb-5">Set user's permission to add / edit / delete / view assets:</p>
                <div className="table-responsive mb-4">
                    <table className="table table-bordered mb-4">
                        <thead className="thead-light  light-yellow-table">
                            <tr>
                                <th className="w-14">
                                    <label className='radio mb-0 d-inline-flex' htmlFor='viewAssets'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.viewAssets}
                                                id='viewAssets'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('viewAssets', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>View Only</span>
                                    </label>
                                </th>
                                <th className="w-14">
                                    <label className='radio mb-0 d-inline-flex' htmlFor='addReserve'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.addReserve}
                                                id='addReserve'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('addReserve', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Reserve</span>
                                    </label>
                                </th>
                                <th className="w-14">
                                    <label className='radio mb-0 d-inline-flex' htmlFor='addAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.addAsset}
                                                id='addAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('addAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Add Assets</span>
                                    </label>
                                </th>
                                <th className="w-14">
                                    <label className='radio mb-0 d-inline-flex' htmlFor='updateAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.updateAsset}
                                                id='updateAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('updateAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Edit Assets</span>
                                    </label>
                                </th>
                                <th className="w-14">
                                    <label className='radio mb-0 d-inline-flex' htmlFor='deleteAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.deleteAsset}
                                                id='deleteAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('deleteAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Delete Assets</span>
                                    </label>
                                </th>
                                <th className="w-14">
                                    <label className='radio mb-0 d-inline-flex' htmlFor='importAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.importAsset}
                                                id='importAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('importAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Import Assets</span>
                                    </label>
                                </th>
                                <th className="w-14">
                                    <label className='radio mb-0 d-inline-flex' htmlFor='exportAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.exportAsset}
                                                id='exportAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('exportAsset', e.target.checked)}

                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Export Assets</span>
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>User can only view assets.</p>
                                        <label className='radio mb-0 d-inline-flex mb-3' htmlFor='canView1'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='canView1'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Hide Price info.</span>
                                        </label>
                                        <p>(This option will also curtail any Editing priviledges allowed elsewhere.)</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>User can reserve assets.</p>
                                        <label className='radio mb-0 d-inline-flex mb-3' htmlFor='canView'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='canView'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Override Reservation</span>
                                        </label>
                                        <p>(User can delete or override existing reservation with new reservation.)</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>User can view and create new assets. User can edit or delete <strong>self-created</strong> assets.</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>User can view and edit <strong>ANY</strong> asset.</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>User can view and delete <strong>ANY</strong> asset.</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>User can <strong>Import</strong> asset.</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>User can <strong>Export</strong> asset.</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mb-5'>
                <h4 className="mb-3">Filter Assets</h4>
                <p className="mb-5">Allow access of assets by site or category :</p>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered mb-4">
                                <thead className="thead-light  light-yellow-table">
                                    <tr>
                                        <th>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='allSites'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
        
                                                        id='allSites'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>All Sites</span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>New Site</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered mb-4">
                                <thead className="thead-light  light-yellow-table">
                                    <tr>
                                        <th>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='allCategories'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
        
                                                        id='allCategories'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>All Categories</span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Building improvements</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Buildings</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Computer equipment</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Equipment</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Furniture and fixtures</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Intangible assets</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Leasehold improvements</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Software</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
            
                                                            id='newSites'
                                                            name='hasCheckOut'
                                                            value='True'
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>Vehicles</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <p>
                    <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                        <div>
                            <input
                                type='checkbox'
                                className='form-check-input custom-form-check-input sig_input me-2'
                      
                                id='newSites'
                                name='hasCheckOut'
                                value='True'
                            />
                        </div>
                        <span className='vertically_align'>Only show Persons/Employees whose Site match the above checked Sites*.</span>
                    </label>
                </p>
                <p className="text-muted">* Please make sure to define 'Sites' for all in the 'Persons/Employees' list.</p>
            </div>
            <div className='mb-5'>
                <h4 className="mb-3">Actions on Assets</h4>
                <p className="mb-5">User can perform these actions on assets:</p>
                <div className="table-responsive mb-4">
                    <table className="table table-bordered mb-4">
                        <thead className="thead-light  light-yellow-table">
                            <tr>
                                <th>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'

                                                id='newSites'
                                                name='hasCheckOut'
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>All Actions</span>
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Check Out</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Dispose</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Donate</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Lease</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Broken</span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Lost</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Repair</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Sell</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
    
                                                    id='newSites'
                                                    name='hasCheckOut'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Move</span>
                                        </label>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AssetsTab