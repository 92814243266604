export interface LeaseReturnInterface {
    leaseReturnNotes: string | null,
    returnDate: string | Date,
    sendEmail: string | null,
    assetId: number
}
export const defaultLeaseReturnDetail: LeaseReturnInterface = {
    leaseReturnNotes: null,
    returnDate: new Date().toISOString().split('T')[0],
    sendEmail: null,
    assetId: 0
}
