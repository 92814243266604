import React, { useEffect } from 'react';

interface SplashScreenProps {
  isLoadingTemplate: boolean;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ isLoadingTemplate }) => {
  useEffect(() => {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
      splashScreen.style.setProperty('display', isLoadingTemplate ? 'flex' : 'none');
    }
  }, [isLoadingTemplate]);

  return null;
};

export default SplashScreen;
