
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { Link } from 'react-router-dom'

type Props = {
  className: string
}

const ListsWidget7: React.FC<Props> = ({ className }) => {
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column mb-0'>
          <span className='fw-bold text-dark'>Latest Media</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Articles and publications</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='card-body pt-3'>
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
            <div className='flex-grow-1 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Cup &amp; Green
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>Size: 87KB</span>
            </div>
            <span className='badge badge-light-success fs-8 fw-bold my-2'>Approved</span>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
            <div className='flex-grow-1 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Yellow Background
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>Size: 1.2MB</span>
            </div>
            <span className='badge badge-light-warning fs-8 fw-bold my-2'>In Progress</span>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
            <div className='flex-grow-1 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Nike &amp; Blue
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>Size: 87KB</span>
            </div>
            <span className='badge badge-light-success fs-8 fw-bold my-2'>Success</span>
          </div>
        </div>
        <div className='d-flex align-items-sm-center'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
            ></div>
          </div>
          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
            <div className='flex-grow-1 me-2'>
              <Link to='/dummy-link' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                Red Boots
              </Link>
              <span className='text-muted fw-semibold d-block pt-1'>Size: 345KB</span>
            </div>
            <span className='badge badge-light-danger fs-8 fw-bold my-2'>Rejected</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ListsWidget7 }
