import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './Columns/CustomHeaderColumn'
import { CustomRow } from './Columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../Core/QueryResponseProvider'
import { usersColumns } from './Columns/_Columns'
import { User } from '../Core/_models'
import { UsersListLoading } from '../Components/Loading/UsersListLoading'
import { UsersListPagination } from '../Components/Pagination/UsersListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'

const UsersTable = () => {
	const users = useQueryResponseData()
	const isLoading = useQueryResponseLoading()
	const data = useMemo(() => users, [users])
	const columns = useMemo(() => usersColumns, [])
	const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
		columns,
		data,
	})

	return (
		<KTCardBody className='py-4'>
			<div className='table-responsive'>
				<table
					id='kt_table_users'
					className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
					{...getTableProps()}
				>
					<thead>
						<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
							{headers.map((column: ColumnInstance<User>) => (
								<CustomHeaderColumn key={column.id} column={column} />
							))}
						</tr>
					</thead>
					<tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
						{rows.length > 0 ? (
							rows.map((row: Row<User>, i) => {
								prepareRow(row)
								return <CustomRow row={row} key={`row-${i}-${row.id}`} />
							})
						) : (
							<tr>
								<td colSpan={12}>
									<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
										No matching records found
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<UsersListPagination />
			{isLoading && <UsersListLoading />}
		</KTCardBody>
	)
}

export { UsersTable }
