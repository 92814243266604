import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { UsersHeader } from './UsersHeader'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { useNavigate } from 'react-router-dom'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import { useIntl } from 'react-intl'
import { PageTitle } from '_metronic/layout/core'
import { useGetSubUsersQuery, useSendEmailSubUserMutation, useDeleteSubUserMutation } from 'App/Modules/Services/SubUser'
import { UserResponse } from '../Core/_models'
const UsersListing = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [check, setCheck] = useState<number[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [selected, setSelected] = useState<number[]>([])
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [deleteSubUser] = useDeleteSubUserMutation()
  const [isSitesExist, setIsSitesExist] = useState<any>(false)
  const [deleteCascade, setDeleteCascade] = useState(false)
  const [pagination, setPagination] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const { data: userListData } = useGetSubUsersQuery({
    limit,
    page,
  })
  const [sendEmailMutation] = useSendEmailSubUserMutation()
  const sendEmail = async (id: number) => {
    try {
      const data = await sendEmailMutation(id).unwrap()
      toast.success(data?.message)
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
    }
  }

  const handleShow = (item: UserResponse) => {
    setData(item)
    setShow(true)
  }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const checkVistingPage = (dataList: any) => {
    if (isSitesExist === false) {
      if (dataList && dataList?.length > 0) {
        setIsSitesExist(dataList?.length)
      } else {
        setIsSitesExist(0)
      }
    }
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = userListData?.sites?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }

  const deleteSelectedSubUser = async (id: number[]) => {
    try {
      const subUserIds = { subUserIds: id }
      const res = await deleteSubUser({ cascade: deleteCascade ? 1 : 0, id }).unwrap()
      toast.success(res.message)
      setCheck([])
      setdeleteDataArr([])
      setSelectAll(false)
      setDeleteModal(false)
      setDeleteCascade(false)
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
    }
  }

  const cancel = () => {
    setDeleteModal(false)
    setDeleteCascade(false)
  }
  const confirm = () => deleteSelectedSubUser(check)

  useEffect(() => {
    checkVistingPage(userListData?.users)
    setPagination(userListData?.pagination)
  }, [userListData])

  useEffect(() => {
    if (selectAllData) {
      const newSelecteds = userListData?.users?.map((item: any) => item.id) || []
      setSelected(newSelecteds)
      setdeleteDataArr(newSelecteds)
    }
    setSelectAllData(false)
  }, [userListData])

  useEffect(() => {
    if (userListData?.users.length > 0) {
      setPagination(userListData?.pagination)
      setSkip(true)
    }
  }, [userListData])

  const handlePageChange = (currentPage: any) => {
    setPage(currentPage)
    setSkip(false)
  }
  const currentPage = pagination?.page || 0
  const totalRecords = pagination?.total || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Users' })}</PageTitle>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className='container'>
            <div className='card'>
              <ConfirmationPopup
                deleteModal={deleteModal}
                cancel={cancel}
                confirm={confirm}
                deleteCascade={deleteCascade}
              />
              <UsersHeader
                deleteDataArr={deleteDataArr}
                setDeleteModal={setDeleteModal}
                setCheck={setCheck}
                handleShow={handleShow}
              />
              <div className='card-body py-4'>
                <p className={'text-dark'}>
                  Create new users who will be able to access your <strong>AssetRack</strong>{' '}
                  system. You can decide each user's privileges and what they can and can't do
                  within your account.
                </p>
                <div className='d-flex limit-options align-items-center mb-3'>
                  <span className='text-muted'>Showing</span>
                  <select
                    className='form-select form-select-sm fw-bold mx-3 border-1'
                    onChange={(e) => {
                      setLimit(Number(e.target.value))
                      setPage(Number(1))
                    }}
                    value={limit}
                  >
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='30'>30</option>
                  </select>
                  <span className='text-muted'>entries</span>
                </div>

                <div className='table-responsive pb-4 custom-table'>
                  <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed table-row-gray-300 fs-6 gy-5 dataTable no-footer'
                    role='table'
                  >
                    <thead>
                      {userListData?.users?.map(() => { })}
                      <tr className='text-start text-gray-800 border-bottom border-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                        <th role='columnheader' className='w-10px pe-2'>
                        </th>
                        <th role='columnheader' className='w-10%'>
                          {' '}
                          Name{' '}
                        </th>
                        <th role='columnheader' >
                          {' '}
                          Group Name{' '}
                        </th>
                        <th role='columnheader' >
                          {' '}
                          Job Title{' '}
                        </th>
                        <th role='columnheader' >
                          {' '}
                          Email{' '}
                        </th>
                        <th role='columnheader' >
                          {' '}
                          Phone{' '}
                        </th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                      {userListData?.users?.length ? (
                        userListData?.users?.map((item: UserResponse, idx: number) => {
                          return (
                            <tr key={idx} role='row'>
                              <td role='cell' >
                                <div className='form-check form-check-sm form-check-custom '>
                                  <input
                                    type='checkbox'
                                    className='form-check-input'
                                    checked={selected.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                  />{' '}
                                </div>
                              </td>
                              <td role='cell' >
                                <div className='d-flex align-items-center'>
                                  <div className='text-truncate me-2'>
                                    {item.firstName + ' ' + item.lastName}
                                  </div>
                                  <button className="btn bg-success fw-bold text-white px-3 py-2 fs-8" onClick={() => sendEmail(item.id)}>{!item.isEmailVerified ? "Send verification email" : ""}</button>
                                </div>
                              </td>
                              <td role='cell' >
                                <div >
                                  <div className='d-flex flex-column'>{item.title}</div>
                                </div>
                              </td>
                              <td role='cell' >
                                <div >
                                  <div className='d-flex flex-column'>{item.title}</div>
                                </div>
                              </td>
                              <td role='cell' >
                                <div >
                                  <div className='d-flex flex-column'>{item.email}</div>
                                </div>
                              </td>
                              <td role='cell' >
                                <div >
                                  <div className='d-flex flex-column'>{item.phone}</div>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                              No records found
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {userListData?.users?.length ? (
                  <div className='d-sm-flex align-items-center justify-content-between mt-3'>
                    <div className='mt-3'>
                      Showing {startRecord} to {endRecord} of {totalRecords} records
                    </div>
                    {pagination && (
                      <Pagination
                        className='mt-3 custom-pagination'
                        defaultCurrent={1}
                        pageSize={limit}
                        current={pagination?.page}
                        total={pagination?.total}
                        onChange={handlePageChange}
                        locale={localeInfo}
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {isSitesExist === 0 ? (
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button
                    onClick={() => {
                      navigate(`/apps/setup/location`)
                    }}
                    className='btn btn-primary'
                  >
                    Continue
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UsersListing
