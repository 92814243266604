import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialCustomers, CustomerResponse } from '../Core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { countriesList } from './Countries'
import {
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useGetAllCustomersQuery,
} from '../../../../Services/Customers'

const CustomersSechma = Yup.object().shape({
  fullName: Yup.string().trim().required('Full Name is required'),
})

const CustomersModal = (props: any) => {
  const { currentUser } = useAuth()
  const [skip, setSkip] = useState<boolean>(false)
  const [addCustomer, { isLoading: isAdding }] = useAddCustomerMutation()
  const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation()
  const { data: userCustomersList, refetch: refetchUserCustomersList } = useGetAllCustomersQuery(
    { values: '' },
    { skip }
  )
  useEffect(() => {
    setSkip(false)
  }, [])

  useEffect(() => {
    formik.resetForm()
    if (props?.CustomerData) {
      formik.setValues({
        fullName: props?.CustomerData?.customer?.fullName,
        company: props?.CustomerData?.customer?.company,
        address1: props?.CustomerData?.customer?.address1,
        address2: props?.CustomerData?.customer?.address2,
        city: props?.CustomerData?.customer?.city,
        state: props?.CustomerData?.customer?.state,
        zip: props?.CustomerData?.customer?.zip,
        country: props?.CustomerData?.customer?.country,
        phone: props?.CustomerData?.customer?.phone,
        cell: props?.CustomerData?.customer?.cell,
        email: props?.CustomerData?.customer?.email,
        notes: props?.CustomerData?.customer?.notes,
        createdBy: props?.CustomerData?.customer?.createdBy,
      })
    }
  }, [props])

  const formik = useFormik({
    initialValues: { ...initialCustomers },
    validationSchema: CustomersSechma,
    onSubmit: async (values) => {
      if (currentUser) {
        values.fullName = values.fullName.trim()
        values.createdBy = `${currentUser?.firstName} ${currentUser?.lastName}`
        try {
          const data = props?.CustomerData
            ? await updateCustomer({ id: props.customerId, values }).unwrap()
            : await addCustomer(values).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              refetchUserCustomersList()
              formik.resetForm()
              props.handleClose()
            } else {
              toast.error(data?.message)
            }
          }
          userCustomersList()
        } catch (error: any) {
          if (error?.data?.message && error?.data?.status == 409) {
            toast.error(error?.data?.message)
          }
          formik.setSubmitting(false)
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <>
      <Modal
        className='custom-model-sm'
        show={props.show}
        onHide={props.handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props?.customerId ? 'Update Customer' : 'Add Customer'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  Full Name
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('fullName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.fullName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Company</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('company')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Address1</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('address1')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Address2</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('address2')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>City</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('city')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>State</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('state')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Zip</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('zip')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Country</label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('country')}
                  >
                    <option selected value=''>
                      {' '}
                      Select country
                    </option>
                    {countriesList?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.code}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Phone</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('phone')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Cell</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('cell')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Email</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('email')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />

                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Notes</label>
                <div className='col-lg-9 fv-row'>
                  <textarea
                    placeholder=''
                    {...formik.getFieldProps('notes')}
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
            </div>
            <div className='text-end pt-2'>
              <button type='button' className='btn btn-light-secondary main-btn-style me-2' onClick={handleClose}>
                Close{' '}
              </button>
              <button
                type='submit'
                className='btn btn-primary main-btn-style'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isAdding && !isUpdating && (
                  <span className='indicator-label'>
                    {props?.customerId ? 'Update Customer' : 'Add Customer'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomersModal
